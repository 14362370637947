// Packages
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

// Markup
import StripeLogo from '../../../elements/StripeLogo';
import MuiModal from '../MuiModal';

// Logic
import { displayStripeNeedsModal } from '../../../redux/actions';
import { FlattenedUser } from '../../../types/user';
import { showError } from '../../../blocks/Toast';
import { useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery } from './__generated__';
import MuiButton from '../../MuiButton';
import { gql } from '@apollo/client';
import { StripeOnboardingFormType } from '../../../types/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const muiStripeVerificationModal_getConnectOnboardingUrl = gql`
	query muiStripeVerificationModal_getConnectOnboardingUrl(
		$data: StripeOnboardingInput!
	) {
		getConnectOnboardingUrl(data: $data) {
			url
		}
	}
`;

export type MuiStripeVerificationModal = {
	open: boolean;
	purpose: string;
	vendorName: string;
};

const MuiStripeVerificationModal: React.FC<MuiStripeVerificationModal> = ( {
	open,
	purpose,
	vendorName,
} ) => {
	const user: FlattenedUser = useSelector(
		( state?: Record<string, any> ) => state?.user
	);
	const dispatch = useDispatch();

	const closeModal = useCallback( () => {
		dispatch( displayStripeNeedsModal( { active: false, props: null } ) );
	}, [ dispatch ] );

	const isAdmin =
		user?.isLoggedIn && user?.userType === 'OrgUser' && user?.isAdmin;

	const { data, loading, error } =
		useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery( {
			variables: {
				data: {
					return_url: `${ window.location.origin }/${ window.location.pathname }?returnFromStripe=true`,
					type: StripeOnboardingFormType.AccountOnboarding,
				},
			},
			skip: !isAdmin || !open,
		} );
	useEffect( () => {
		if ( error ) {
			showError( error );
		}
	}, [ error ] );

	const getCopy = useCallback( () => {
		return purpose === 'payInvoice' ? (
			<>
				{ vendorName } needs to enter in some additional information before you
				can make a payment. We have already notified them but feel free to give
				them a nudge.
			</>
		) : !isAdmin ? (
			<>
				Our payment processor{ ' ' }
				<span>
					<StripeLogo />
				</span>{ ' ' }
				needs some additional info from { vendorName }. We have already notified
				your teams admins, but feel free to give them a nudge.
			</>
		) : purpose === 'sendInvoice' ? (
			<>
				Our payment processor{ ' ' }
				<span>
					<StripeLogo />
				</span>{ ' ' }
				needs some additional info before we can send this invoice. Click the
				button below and fill out the required fields so you can get paid! Your
				invoice has been saved.
			</>
		) : (
			<>
				Our payment processor{ ' ' }
				<span>
					<StripeLogo />
				</span>{ ' ' }
				needs some additional info. Click the button below and fill out the
				required fields so you can get paid!
			</>
		);
	}, [
		isAdmin,
		purpose,
		vendorName
	] );

	return (
		<MuiModal
			muiModalProps={ {
				open,
				onClose: closeModal,
				PaperProps: {
					'data-cy': 'stripe-verification-modal',
				},
			} }
			canClose={ true }
			title={
				purpose === 'payInvoice'
					? 'We aren\'t quite ready'
					: 'We need some more info'
			}
			controls={
				<form
					onSubmit={ ( e ) => {
						e.preventDefault();
						e.stopPropagation();
						if ( isAdmin && data?.getConnectOnboardingUrl?.url ) {
							window.location.href = data?.getConnectOnboardingUrl?.url;
						}
						closeModal();
					} }
				>
					<MuiButton type='submit' loading={ loading }>
						{ isAdmin ? 'Lets Go!' : 'Got it' }
					</MuiButton>
				</form>
			}
		>
			<Typography variant='body1'>{ getCopy() }</Typography>
		</MuiModal>
	);
};

export default MuiStripeVerificationModal;
