import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MuiStripeVerificationModal_GetConnectOnboardingUrlQueryVariables = Types.Exact<{
  data: Types.StripeOnboardingInput;
}>;


export type MuiStripeVerificationModal_GetConnectOnboardingUrlQuery = { __typename?: 'Query', getConnectOnboardingUrl?: { __typename?: 'StripeOnboardingURL', url: string } | null };


export const MuiStripeVerificationModal_GetConnectOnboardingUrlDocument = gql`
    query muiStripeVerificationModal_getConnectOnboardingUrl($data: StripeOnboardingInput!) {
  getConnectOnboardingUrl(data: $data) {
    url
  }
}
    `;

/**
 * __useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery__
 *
 * To run a query within a React component, call `useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery(baseOptions: Apollo.QueryHookOptions<MuiStripeVerificationModal_GetConnectOnboardingUrlQuery, MuiStripeVerificationModal_GetConnectOnboardingUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MuiStripeVerificationModal_GetConnectOnboardingUrlQuery, MuiStripeVerificationModal_GetConnectOnboardingUrlQueryVariables>(MuiStripeVerificationModal_GetConnectOnboardingUrlDocument, options);
      }
export function useMuiStripeVerificationModal_GetConnectOnboardingUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MuiStripeVerificationModal_GetConnectOnboardingUrlQuery, MuiStripeVerificationModal_GetConnectOnboardingUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MuiStripeVerificationModal_GetConnectOnboardingUrlQuery, MuiStripeVerificationModal_GetConnectOnboardingUrlQueryVariables>(MuiStripeVerificationModal_GetConnectOnboardingUrlDocument, options);
        }
export type MuiStripeVerificationModal_GetConnectOnboardingUrlQueryHookResult = ReturnType<typeof useMuiStripeVerificationModal_GetConnectOnboardingUrlQuery>;
export type MuiStripeVerificationModal_GetConnectOnboardingUrlLazyQueryHookResult = ReturnType<typeof useMuiStripeVerificationModal_GetConnectOnboardingUrlLazyQuery>;
export type MuiStripeVerificationModal_GetConnectOnboardingUrlQueryResult = Apollo.QueryResult<MuiStripeVerificationModal_GetConnectOnboardingUrlQuery, MuiStripeVerificationModal_GetConnectOnboardingUrlQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    