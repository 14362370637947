/* eslint max-len: 0 */
const Camera = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M14.646 2.801c1.42 0 2.718.802 3.354 2.073l.463.927h1.787c2.004 0 3.641 1.572 3.745 3.55l.005.2v7.5c0 2.071-1.679 3.75-3.75 3.75H3.75c-2.071 0-3.75-1.679-3.75-3.75v-7.5c0-2.071 1.679-3.75 3.75-3.75h1.786L6 4.873c.604-1.206 1.806-1.99 3.142-2.066l.212-.006zm0 1.5H9.354c-.852 0-1.631.481-2.012 1.243L6.67 6.886c-.127.254-.387.415-.671.415H3.75c-1.243 0-2.25 1.007-2.25 2.25v7.5c0 1.243 1.007 2.25 2.25 2.25h16.5c1.243 0 2.25-1.007 2.25-2.25v-7.5c0-1.243-1.007-2.25-2.25-2.25H18c-.284 0-.544-.16-.67-.415l-.672-1.341c-.381-.763-1.16-1.244-2.012-1.244zM12 8.051c2.485 0 4.5 2.015 4.5 4.5s-2.015 4.5-4.5 4.5-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5zm0 1.5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z'
			transform='translate(-224 -1020) translate(144 183) translate(0 783) translate(0 53) translate(80 1)'
		/>
	</svg>
);

export default Camera;
