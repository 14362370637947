import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CompanyFoldersIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				xmlns='http://www.w3.org/2000/svg'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.78723 0C0.800171 0 0 0.800173 0 1.78723V17.1064C0 18.6575 1.25741 19.9149 2.80851 19.9149H21.1915C22.7426 19.9149 24 18.6575 24 17.1064V7.40426C24 6.41719 23.1998 5.61702 22.2128 5.61702H20.9362V3.82979C20.9362 2.84273 20.136 2.04255 19.1489 2.04255H12.4734L11.9461 0.987959C11.6433 0.382472 11.0245 0 10.3475 0H1.78723ZM19.4043 3.82979V5.61702H4.85106C3.864 5.61702 3.06383 6.41719 3.06383 7.40426V18.383H2.80851C2.10347 18.383 1.53191 17.8114 1.53191 17.1064V1.78723C1.53191 1.64623 1.64623 1.53191 1.78723 1.53191H10.3475C10.4442 1.53191 10.5327 1.58655 10.5759 1.67305L11.3149 3.15106C11.4447 3.41055 11.7099 3.57447 12 3.57447H19.1489C19.2899 3.57447 19.4043 3.68878 19.4043 3.82979ZM8.17925 18.383H4.59574V7.40426C4.59574 7.26325 4.71005 7.14894 4.85106 7.14894H22.2128C22.3538 7.14894 22.4681 7.26325 22.4681 7.40426V17.1064C22.4681 17.8114 21.8965 18.383 21.1915 18.383H17.954C17.9412 17.2422 17.3926 16.1804 16.4777 15.4161C15.9472 14.9729 15.3153 14.6465 14.6343 14.4492C15.6151 13.889 16.2718 12.8199 16.2718 11.606C16.2718 9.8199 14.8502 8.34761 13.0667 8.34761C11.2832 8.34761 9.86159 9.8199 9.86159 11.606C9.86159 12.8199 10.5183 13.8889 11.4991 14.4492C10.818 14.6465 10.1861 14.9728 9.65552 15.4161C8.74068 16.1804 8.19204 17.2422 8.17925 18.383ZM16.5911 18.383H9.54221C9.55485 17.6889 9.88992 16.9961 10.5293 16.4619C11.1848 15.9143 12.0961 15.5912 13.0666 15.5912C14.0371 15.5912 14.9485 15.9143 15.604 16.4619C16.2434 16.9961 16.5784 17.6889 16.5911 18.383ZM13.0667 9.71042C12.0626 9.71042 11.2244 10.5456 11.2244 11.606C11.2244 12.6663 12.0626 13.5015 13.0667 13.5015C14.0709 13.5015 14.909 12.6663 14.909 11.606C14.909 10.5456 14.0709 9.71042 13.0667 9.71042Z'
			/>
		</SvgIcon>
	);
}
