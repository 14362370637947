/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Facebook = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M13.567 0l-.263.004c-3.635.109-5.252 2.49-5.252 5.604v1.4H5.762c-.421 0-.763.335-.763.749v3.998l.007.102c.05.366.37.648.756.648h2.288l.001 10.745c0 .414.342.75.763.75h5.088l.103-.007c.373-.05.66-.363.66-.743V12.505h3.154c.391 0 .72-.291.76-.673l.416-3.999.004-.1c-.012-.399-.345-.726-.763-.726h-3.571v-1.16c-.011-.145.012-.215.06-.268.05-.052.118-.082.19-.081h3.057c.421 0 .763-.336.763-.75V.75c0-.414-.342-.75-.763-.75h-4.406zm3.643 1.5v2.498h-2.29c-.5-.002-.983.206-1.322.573-.34.367-.505.858-.454 1.352l-.004 1.834.007.101c.05.366.37.648.756.648h3.488l-.26 2.5h-3.228l-.104.006c-.372.05-.66.364-.66.743v10.746H9.578V11.755l-.006-.102c-.05-.366-.37-.648-.757-.648h-2.29V8.506h2.29c.422 0 .764-.335.764-.75V5.609c0-2.454 1.159-4.109 3.988-4.109h3.643z'
			transform='translate(-464 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(320)'
		/>
	</svg>
);

export default Facebook;
