import React from 'react';

import { MenuIcon } from '../../mui/Icons';
import SideNavComponent from './SideNavComponent';
import { CustomMenuItemType } from '../CustomMenuItem/CustomMenuItemComponent';
import { useSelector } from 'react-redux';
import useGetNavigationItems, {
	MenuItemsIds,
} from '../../config/useGetNavigationItems';
import { isClient } from '../../lib/helpers/index';
import { PremiumSubscriptionRequiredModal } from '../../blocks/PremiumSubscriptionRequiredModal';

export type DropdownType = CustomMenuItemType & { items: CustomMenuItemType[] };
export type MenuConfigType = ( CustomMenuItemType | DropdownType )[];

export default function SideNavContainer( {
	featureFlags,
}: {
	featureFlags: any;
} ) {
	const user = useSelector( ( state: any ) => state.user );
	const isUserSuperAdmin = user.isSuperAdmin;
	const {
		items: navigationItems,
		subscriptionNeededModalOpen: open,
		closeSubscriptionNeededModal: onClose,
	} = useGetNavigationItems( featureFlags );
	const isUserClient = isClient( user );

	const config: MenuConfigType = [
		navigationItems[ MenuItemsIds.Dashboard ],
		navigationItems[ MenuItemsIds.Leads ],
		navigationItems[ MenuItemsIds.Contacts ],
		navigationItems[ MenuItemsIds.Proposals ],
		navigationItems[ MenuItemsIds.Contracts ],
		navigationItems[ MenuItemsIds.Invoices ],
		navigationItems[ MenuItemsIds.Vendors ],
		navigationItems[ MenuItemsIds.ClientUsers ],
		navigationItems[ MenuItemsIds.OrgUsers ],
		navigationItems[ MenuItemsIds.Organizations ],
		...( isUserClient ? [ navigationItems[ MenuItemsIds.MyFolder ] ] : [] ),
		{
			id: 'menu',
			label: 'More',
			icon: <MenuIcon />,
			isDisabled: isUserSuperAdmin || isUserClient,
			items: [
				navigationItems[ MenuItemsIds.ProposalTemplates ],
				navigationItems[ MenuItemsIds.ContractTemplates ],
				navigationItems[ MenuItemsIds.InvoiceTemplates ],
				navigationItems[ MenuItemsIds.CustomerFolders ],
				navigationItems[ MenuItemsIds.CompanyFolders ],
				navigationItems[ MenuItemsIds.MyFolder ],
				navigationItems[ MenuItemsIds.Resources ],
				navigationItems[ MenuItemsIds.Timelines ],
			],
		},
	];

	return (
		<>
			<SideNavComponent menuConfig={ config } />
			<PremiumSubscriptionRequiredModal open={ open } onClose={ onClose } />
		</>
	);
}
