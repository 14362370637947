/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Folder = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M7.5 1.5H2.25C1.007 1.5 0 2.507 0 3.75v16.5c0 1.243 1.007 2.25 2.25 2.25h19.5c1.243 0 2.25-1.007 2.25-2.25V6.75l-.005-.154C23.915 5.426 22.94 4.5 21.75 4.5H10.875L9.3 2.4c-.425-.567-1.092-.9-1.8-.9zM2.25 3H7.5c.236 0 .458.111.6.3l1.8 2.4c.142.189.364.3.6.3h11.25c.414 0 .75.336.75.75v13.5c0 .414-.336.75-.75.75H2.25c-.414 0-.75-.336-.75-.75V3.75c0-.414.336-.75.75-.75z'
			transform='translate(-544 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(400)'
		/>
	</svg>
);

export default Folder;
