import Globals from '../../Globals.js';
import Query from '../../Query.js';
import {
	ClientUserDetailReturnFields,
	ContactWithIdentifiersReturnFields,
	ContactScalarFields,
	OrgUserDetailReturnFields,
	GuestUserDetailReturnFields,
} from '../returnFields';

const ContactDetailPageReturnFields = [
	...ContactScalarFields,
	{
		customer: [
			'id',
			'email',
			{ guestUser: [ 'id' ] },
			{ clientUser: ClientUserDetailReturnFields },
			{ orgUser: OrgUserDetailReturnFields },
		],
	},
	{ assignedMember: OrgUserDetailReturnFields },
	{ vendor: [ 'id' ] },
];

/** @typedef {{ name: string, id: string }} SERVICE */
/** @typedef {{ id: string, services: SERVICE[], defaultOrgUser: { id: string } }} ORGANIZATION */
/** @typedef {{ id: string, user: { userType: 'OrgUser' }, organization: ORGANIZATION}} ORGUSER */
/** @typedef {{ id: string, user: { userType: 'ClientUser' }}} CLIENTUSER */
/** @typedef {{ id: string, email: string }} GUESTUSER */
/** @typedef {{ clientUser: CLIENTUSER }} CLIENT */
/** @typedef {CLIENT | GUESTUSER} CLIENT_OR_GUEST */
/** @typedef {{ id: string, email: string } & CLIENT_OR_GUEST} CUSTOMER */
/** @typedef {{ eventDate?: Date | string | null, phone?: string | null, firstName: string, lastName: string }} CONTACT_DETAILS */
/** @typedef {{ id: string, status: 'Active'|'Pending'|'Inactive', firstName: string, lastName: string, archivedByVendor: boolean, customer: CUSTOMER, vendor: { id: string, organization: { id: string }, adminUsers: ORGUSER[] }, assignedMember: ORGUSER } & CONTACT_DETAILS} CONTACT */
/**
 * Get a single contact for the contact detail page.
 *
 * @param {object} params - ContactWhereInput
 * @returns {Promise<{ errors: Error[] } | { contact: CONTACT }>}
 */
const getContactWhere = async function( params ) {
	const getContactWhere = new Query( {
		type: 'query',
		name: 'getContactWhere',
		params,
		returnFields: [
			...ContactWithIdentifiersReturnFields,
			{
				customer: [
					{ clientUser: ClientUserDetailReturnFields },
					{ orgUser: OrgUserDetailReturnFields },
					{ guestUser: GuestUserDetailReturnFields },
				],
			},
		],
	} );

	const { data, errors } = await this.request( getContactWhere );

	if ( errors || data.errors ) return { errors: errors || data.errors };

	if ( data.data.getContactWhere ) {
		return { contact: data.data.getContactWhere };
	}
};

/**
 * Get contacts for the contacts index.
 *
 * @param {object} args.
 * @returns {Promise<{ errors: Error[] } | { total: number, moreToLoad: boolean, skip: number contacts: CONTACT[] }>}
 */
const getContactsWhere = async function( args ) {
	const params = {
		...args,
		take:
			typeof args.take === 'number'
				? args.take + 1
				: Globals.itemReturnLimit + 1,
	};

	const getContactsWhere = new Query( {
		type: 'query',
		name: 'getContactsWhere',
		params,
		returnFields: [
			'_count',
			{
				contacts: [
					...ContactWithIdentifiersReturnFields,
					{
						customer: [
							{ clientUser: ClientUserDetailReturnFields },
							{ orgUser: OrgUserDetailReturnFields },
							{ guestUser: [ { user: [ 'id', 'email' ] } ] },
						],
					},
				],
			},
		],
	} );

	const { data, errors } = await this.request( getContactsWhere );

	if ( errors || data.errors ) return { errors: errors || data.errors };

	let contacts = [];
	let total = 0;
	if ( data.data.getContactsWhere.contacts ) {
		contacts = data.data.getContactsWhere.contacts;
		total = data.data.getContactsWhere._count;
	}

	let moreToLoad = false;

	if ( contacts.length > args.take ) {
		contacts.pop();
		moreToLoad = true;
	}

	return {
		count: total,
		contacts,
		total,
		moreToLoad,
		skip: ( typeof args.skip === 'number' ? args.skip : 0 ) + contacts.length,
	};
};

// Begin contact section

/**
 * Gets a contact between a given organization and client user.
 *
 * @param { String } - clientID
 * @param { String } - orgID
 *
 * @returns { Promise<Object<{ errors, contact }>> }
 */
const getContact = async function( clientID, orgID ) {
	if ( !clientID || !orgID ) {
		const errorMessage = `Could not get contact. Arguments not received: ${
			!clientID && 'clientID'
		} ${!orgID && 'orgID'}`; //eslint-disable-line
		return { errors: [ { message: errorMessage } ] };
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationWhere',
			params: {
				where: {
					id: orgID,
				},
			},
			returnFields: [
				new Query( {
					name: 'contacts',
					params: { where: { customer: { clientUser: { id: clientID } } } },
					returnFields: [
						...ContactScalarFields,
						{ assignedMember: OrgUserDetailReturnFields },
						{ vendor: [ 'id' ] },
						{
							customer: [
								'id',
								'userType',
								'id',
								{
									clientUser: [
										'weddingDate',
										'id',
										'plannerOrgHasDocumentsPermissions',
										{ assignedPlanner: [ 'id', { organization: [ 'id' ] } ] },
									],
								},
							],
						},
					],
				} ),
			],
		} )
	);
	if ( errors ) {
		return { errors };
	}
	const { contacts } = data.data.getOrganizationWhere;
	return {
		contact:
			Array.isArray( contacts ) && contacts.length ? contacts[ 0 ] : undefined,
	};
};

/**
 * Wrapper for createContactAsClient.
 * allows a client to connect to an org,
 * or for their planner for connect to an org on their behalf.
 *
 * @param {String} clientID - ID of the client on the contact.
 * @param {String} orgID - ID of the org on the contact.
 *
 * @returns {Promise<{ contact: object } | { errors: string[] }} res { errors, contact }
 */
const createContactAsClient = async function( clientID, orgID ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createContactAsClient',
			params: {
				where: { id: clientID },
				data: { id: orgID },
			},
			returnFields: [
				...ContactScalarFields,
				{
					customer: [
						'id',
						'email',
						{ guestUser: [ 'id' ] },
						{ clientUser: ClientUserDetailReturnFields },
					],
				},
				{ vendor: [ 'id' ] },
				{ assignedMember: [ 'id' ] },
				{ invitation: [ 'recipientFirstName', 'recipientLastName' ] },
			],
		} )
	);
	if ( errors ) return { errors };

	const contact = data.data.createContactAsClient;

	return {
		contact,
	};
};

/**
 * Request a contact be made active by another party.
 *
 * @param {String} clientID - ID of the client on the contact.
 * @param {String} orgID - ID of the org on the contact.
 *
 * @returns {Promise<{ errors: string[] } | { contact: object }>} res { errors, contact }
 */
const requestContactActivation = async function( contactID ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'requestContactActivation',
			params: {
				where: { id: contactID },
			},
			returnFields: [
				// eslint-disable-next-line array-element-newline
				...ContactDetailPageReturnFields,
				{ invitation: [ 'recipientFirstName', 'recipientLastName' ] },
			],
		} )
	);
	if ( errors ) return { errors };

	const contact = data.data.requestContactActivation;

	return { contact };
};

/**
 * allows a client, client's planner, or vendor to accept a contact and set it active
 *
 * @param { String } contactID the id of the contact to be accepted
 * @param { boolean } [sendEmails]
 * @returns {Promise<{ contact: object } | { errors: string[] }}
 */
const activateContact = async function( contactID, sendEmails = true ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'activateContact',
			params: {
				where: { id: contactID },
				data: { sendEmails },
			},
			returnFields: [
				...ContactScalarFields,
				{ vendor: [ 'id' ] },
				{
					customer: [
						'id',
						'email',
						{ guestUser: [ 'id' ] },
						{ clientUser: ClientUserDetailReturnFields },
					],
				},
				{ invitation: [ 'recipientFirstName', 'recipientLastName' ] },
			],
		} )
	);
	if ( errors ) return { errors };

	const contact = data.data.activateContact;

	return {
		contact,
	};
};

/**
 * Declines a contact request.
 * Should be operable by the vendor, client, or client's planner.
 *
 * @param {String} contactId - ID of the contact to decline.
 *
 * @returns {Object} res - { errors, success }
 */
const declineContact = async function( contactId ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'declineContact',
			params: {
				where: { id: contactId },
			},
			returnFields: ContactScalarFields,
		} )
	);
	if ( errors || data.errors ) return { errors: errors || data.errors };

	const contact = data.data.declineContact;

	return {
		success: true,
		contact,
	};
};

/**
 * Mark a contact as inactive.
 *
 * @param {String} contactId - ID of the contact to cancel.
 *
 * @returns {Object} res - { errors, success }
 */
const cancelContact = async function( contactId ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'cancelContact',
			params: {
				where: { id: contactId },
			},
			returnFields: ContactDetailPageReturnFields,
		} )
	);
	if ( errors || data.errors ) return { errors: errors || data.errors };

	const contact = data.data.cancelContact;

	return {
		success: true,
		contact,
	};
};

/**
 * Gets all active/pending outgoing contacts of an organization.
 *
 * @param {String} id - The organization's ID.
 *
 * @returns {Promise<{ { errors: Array< error > } | { contacts: Array< Record< string, any > > } }>}
 */
const getOrgContacts = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationWhere',
			params: { where: { id } },
			returnFields: [
				'id',
				new Query( {
					name: 'contacts',
					returnFields: [
						...ContactScalarFields,
						{
							invoices: [
								{
									contact: [
										'firstName',
										'lastName',
										{ customer: [ 'userType', 'email' ] },
									],
								},
							],
						},
						{
							customer: [
								'email',
								{
									clientUser: [
										...ClientUserDetailReturnFields,
										'id',
										{ user: [ 'id', 'email' ] },
									],
								},
								{ guestUser: [ 'id user { id email }' ] },
							],
						},
						{ invitation: [ 'recipientFirstName', 'recipientLastName' ] },
					],
				} ),
			],
		} )
	);
	if ( errors ) return { errors };
	const contacts = data.data.getOrganizationWhere.contacts || [];
	return { contacts };
};

export {
	activateContact,
	createContactAsClient,
	cancelContact,
	declineContact,
	getContactsWhere,
	getContactWhere,
	getContact,
	requestContactActivation,
	getOrgContacts,
};
