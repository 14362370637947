import { ApiResponseType } from '../../../../hooks/useQuery';
import Query from '../../../Query';
import { GetProposalTemplatesWhereReturnFields } from '../../returnFields/proposals';
import {
	GetProposalTemplatesWhereInput,
	GetProposalTemplateWhereDataResponse,
} from '../types';

const getProposalTemplatesWhere = async function(
	this: any,
	paramsData: GetProposalTemplatesWhereInput
): Promise<ApiResponseType<GetProposalTemplateWhereDataResponse[]>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getProposalTemplatesWhere',
			params: paramsData,
			returnFields: [ '_count', { proposalTemplates: GetProposalTemplatesWhereReturnFields }, ],
		} )
	);

	if ( errors ) return { errors };

	return {
		data: data.data.getProposalTemplatesWhere.proposalTemplates,
		count: data.data.getProposalTemplatesWhere._count,
	};
};

export default getProposalTemplatesWhere;
