import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ChevronDown( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path d='M5.2261 8.23427C5.50064 7.95081 5.93075 7.92452 6.23415 8.15576L6.32108 8.23286L12.0077 14.0751L17.6781 8.23366C17.953 7.9505 18.3831 7.92469 18.6863 8.15628L18.7731 8.23347C19.0481 8.51654 19.0731 8.95955 18.8483 9.27178L18.7733 9.36123L12.5563 15.7663C12.2817 16.0493 11.852 16.0753 11.5488 15.8442L11.4619 15.7671L5.22746 9.36202C4.92471 9.05099 4.9241 8.54608 5.2261 8.23427Z' />
		</SvgIcon>
	);
}
