/* eslint max-len: 0 */
const Edit = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			d='M16.724.908c1.71-1.283 4.126-1.21 5.757.248l.188.177c1.623 1.625 1.762 4.171.416 5.954l-.016.02c-.121.16-.255.313-.4.458L10.976 19.457c-.5.499-1.108.875-1.778 1.099l-5.408 1.8c-.61.202-1.28.042-1.735-.412-.454-.454-.614-1.125-.412-1.737l1.8-5.406c.104-.311.24-.61.408-.889l.008-.012c.19-.319.42-.613.684-.877l11.69-11.69c.148-.147.302-.282.463-.405l.019-.014zM4.767 15.575L3.068 20.68c-.024.072-.005.15.049.204.053.054.132.073.202.05l5.105-1.7-3.657-3.658zM17.127 2.56L5.604 14.083c-.035.035-.069.071-.102.108l4.306 4.304.109-.1L21.44 6.873 17.127 2.56zm4.355-.285c-.868-.775-2.068-.966-3.1-.58l3.926 3.924c.405-1.083.175-2.349-.685-3.21z'
			transform='translate(-424 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(280)'
		/>
	</svg>
);

export default Edit;
