import Globals from '../../Globals';
import { getCookie } from '../getCookie';
import { BundleCookie } from './bundleCookieType';

/**
 * creates a bundle object based on users cookies and the contactID passed.
 */
export const createBundleObject = (
	contactID: string
): BundleCookie | undefined => {
	const documentCookie = getCookie(
		`${ Globals.DocumentBundle }=`,
		document.cookie
	);
	if ( documentCookie ) {
		const cookieData: BundleCookie = JSON.parse( documentCookie );
		if (
			cookieData &&
			cookieData.contactID &&
			cookieData.contactID === contactID &&
			cookieData.bundle
		) {
			return {
				contactID: contactID,
				proposalBundleStatus: cookieData.proposalBundleStatus,
				contractBundleStatus: cookieData.contractBundleStatus,
				invoiceBundleStatus: cookieData.invoiceBundleStatus,
				eventDate: cookieData.eventDate,
			};
		}
	}
};
