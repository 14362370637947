/* eslint max-len: 0 */
const NewUser = () => (
	<svg viewBox='0 0 32 32'>
		<path
			d='M23 14.25c4.832 0 8.75 3.918 8.75 8.75s-3.918 8.75-8.75 8.75-8.75-3.918-8.75-8.75 3.918-8.75 8.75-8.75zm0 1.5c-4.004 0-7.25 3.246-7.25 7.25s3.246 7.25 7.25 7.25 7.25-3.246 7.25-7.25-3.246-7.25-7.25-7.25zm0 2.5c.38 0 .693.282.743.648l.007.102v3.25H27c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L27 23.75h-3.25V27c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L22.25 27v-3.25H19c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L19 22.25h3.25V19c0-.414.336-.75.75-.75zm-9.173-4.217c.381.162.559.603.396.984-.162.38-.603.558-.984.396-2.548-1.086-5.472-.821-7.784.706C3.144 17.645 1.752 20.23 1.75 23c0 .414-.336.75-.75.749-.415 0-.75-.336-.75-.75.002-3.274 1.647-6.329 4.379-8.133 2.732-1.804 6.186-2.118 9.198-.834zM10 .25c3.452 0 6.25 2.798 6.25 6.25 0 .049 0 .097-.002.145v.003l-.001 1.359c-.047 1.388.314 2.76 1.037 3.946.216.354.104.816-.25 1.031-.353.216-.815.104-1.03-.25-.469-.768-.812-1.6-1.02-2.462-1.141 1.505-2.949 2.478-4.984 2.478s-3.844-.973-4.985-2.48c-.208.865-.55 1.697-1.019 2.464-.215.354-.677.466-1.03.25-.354-.215-.466-.677-.25-1.03.667-1.095 1.025-2.349 1.04-3.647L3.75 7.98V6.648l.001-.019L3.75 6.5C3.75 3.125 6.425.375 9.77.254zm-.001 5.238l-.06.058c-1.257 1.16-2.852 1.891-4.552 2.085.505 2.077 2.38 3.619 4.613 3.619 2.231 0 4.103-1.539 4.613-3.613-1.701-.195-3.297-.928-4.553-2.09L10 5.488zM10 1.75c-2.5 0-4.549 1.931-4.736 4.383 1.608-.193 3.097-.979 4.169-2.217.3-.346.836-.346 1.135 0 1.07 1.24 2.559 2.028 4.167 2.225C14.552 3.684 12.502 1.75 10 1.75z'
			transform='translate(-192 -1434) translate(144 183) translate(0 1197) translate(0 54) translate(48)'
		/>
	</svg>
);

export default NewUser;
