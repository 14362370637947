import { ApiResponseType } from '../../../../hooks/useQuery';
import Query from '../../../Query.js';
import { GetProposalTemplateWhereReturnFields } from '../../returnFields/proposals';
import { GetProposalTemplateWhereDataResponse } from '../types';

const getProposalTemplateWhere = async function(
	this: any,
	paramsData: { where: { id: string } }
): Promise<ApiResponseType<GetProposalTemplateWhereDataResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getProposalTemplateWhere',
			params: paramsData,
			returnFields: GetProposalTemplateWhereReturnFields,
		} )
	);

	if ( errors ) return { errors };

	return { data: data.data.getProposalTemplateWhere };
};

export default getProposalTemplateWhere;
