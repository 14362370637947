// packages
import React from 'react';
import Router from 'next/router';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { ToastContainer, Slide } from 'react-toastify';

// markup
import Alert from '../Alert';
import MuiStripeVerificationModal from '../../mui/MuiModal/MuiStripeVerificationModal';
import SubscriptionFailedModal from '../SubscriptionChargeFailedModal';
import Confirm from '../Confirm';
import LoadingAnimation from '../../elements/LoadingAnimation.jsx';
import MobileMenuContainer from './mobileMenu/MobileMenuContainer';
import BrowserCompatibility from './BrowserCompatibility';
import Intercom from '../Intercom';

import SideNavLayoutContainer from '../../mui/SideNavLayout/SideNavLayoutContainer';
import { StyledEngineProvider } from '@mui/material/styles';
import StickyHeaderLayout from '../../mui/layouts/StickyHeaderLayout/StickyHeaderLayout';

//logic
import {
	createAlert,
	displayConfirm,
	displayStripeNeedsModal,
	logout,
} from '../../redux/actions';
import Globals from '../../lib/Globals';
import { isOrgUser } from '../../lib/helpers';
import { hideSideNav } from './helpers';

NProgress.configure( { showSpinner: false } );

export const theme = {
	// Main palette
	green: 'rgb(4,149,95)',
	darkBlue: 'rgb(32,58,96)',
	pink: 'rgb(243,142,125)',
	gray: 'rgba(0,0,0,0.3)',
	lightGray: 'rgba(0, 0, 0, 0.03)',
	mediumGray: 'rgba( 238, 237, 240, 1)',
	darkGray: 'rgb(196,196,196)',
	white: '#FFFFFF',
	black: '#000000',
	readableGray: '#949494', // this is better than black with opacity

	//View Sizes
	tablet: 768,
	desktop: 960,

	//TODO REMOVE THE BELOW
	lightGreen: '#D5ECE1',
	lightBlue: '#B6D3E2',

	// Message and Error handling
	error: '#CC0000',
	warning: '#FBBC05',
	success: '#34A853',

	// Shades
	lightestShade: '#F9F9F9',
	lightShade: '#EAF1F4',
	middleShade: '#E7E9E8',
	darkerShade: '#848a87',
	darkShade: '#494B4A',

	// Border
	lightBorderColor: '#E1E1E1',
	lightBorder: '1px solid rgba(207, 206, 207, 0.37)',
	errorBorder: '1px solid #ff0000',

	// Breakpoints
	xlargeScreen: 1140,
	largeScreen: 850,
	mediumScreen: 640,
	minWidth: 320,

	// drop-shadows
	boxshadowBasic:
		'0px 0 8px rgba(221, 221, 221, 0.15), 30px 30px 60px rgba(221, 221, 221, 0.25)',
};

const ToastWrapper = styled.div`
	.Toastify {
		&__toast-container {
			width: calc(100% - 6.75rem);
			margin: 0;
			padding: 0;
			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				width: 25rem;
			}

			&--bottom-left {
				left: 1.5rem;
			}
		}

		&__toast {
			border-radius: 5px;
			border: 1px solid ${ ( { theme } ) => theme.darkBlue };
			padding: 1.5rem;
			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				margin: 0 0 1rem 0;
			}
		}

		&__close-button {
			margin-left: 0.5rem;
			align-self: center;
			color: ${ ( { theme } ) => theme.darkBlue };
			opacity: 0.8;
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}
`;

/**
 * Page wrapper - wrapper for all page components containing top level data
 *
 * Feature Flag
 */
class Page extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			currentRoute: null,
			loading: false,
			stripeNeedsModal: {
				active: false,
				props: null,
			},
			subscriptionFailedModal: {
				active: false,
			},
			confirm: {
				active: false,
				props: null,
			},
			mobileMenu: {
				active: false,
			},
		};

		this.closeSubscriptionFailedModal =
			this.closeSubscriptionFailedModal.bind( this );
		this.closeConfirm = this.closeConfirm.bind( this );
		this.toggleMobileMenu = this.toggleMobileMenu.bind( this );
	}

	componentDidMount() {
		this.setState( { currentRoute: Router.route }, () => {
			this.openSubscriptionFailedModal();
		} );

		window.addEventListener( 'beforeunload', this.handleBeforeUnload );

		Router.onRouteChangeStart = () => {
			NProgress.start();
		};

		Router.onRouteChangeComplete = () => {
			NProgress.done();
		};

		Router.onRouteChangeError = () => {
			NProgress.done();
		};
	}

	componentDidUpdate( prevProps, prevState ) {
		const props = this.props;
		if ( prevState.currentRoute !== Router.route ) {
			this.setState( { currentRoute: Router.route }, () => {
				this.openSubscriptionFailedModal();
			} );
		}

		if ( props.loading !== prevProps.loading ) {
			if ( props.loading ) {
				NProgress.start();
			} else {
				NProgress.done();
			}
		}

		if ( props.confirm.active !== prevProps.confirm.active ) {
			this.setState( { confirm: props.confirm } );
		}

		if ( props.stripeNeedsModal.active !== prevProps.stripeNeedsModal.active ) {
			this.setState( { stripeNeedsModal: props.stripeNeedsModal } );
		}
	}

	handleBeforeUnload() {
		NProgress.done(); // end any existing loading bars before leaving the page
	}

	openSubscriptionFailedModal() {
		const subscriptionFailedModal = this.state.subscriptionFailedModal;

		if (
			this.props.user &&
			isOrgUser( this.props.user ) &&
			this.props.user?.organization?.subscription &&
			[ 'Incomplete' ].includes( this.props.user.organization.subscription.status )
		) {
			subscriptionFailedModal.active = true;
		}

		this.setState( { subscriptionFailedModal } );
	}

	closeSubscriptionFailedModal() {
		this.setState( {
			subscriptionFailedModal: {
				active: false,
			},
		} );
	}

	closeConfirm() {
		this.props.displayConfirm( { props: null, active: false } );
	}

	toggleMobileMenu( active ) {
		const state = this.state;
		state.mobileMenu.active = active;
		this.setState( state );
	}

	render() {
		const {
			confirm,
			mobileMenu,
			currentRoute,
			stripeNeedsModal,
			subscriptionFailedModal,
		} = this.state;

		return (
			<ThemeProvider theme={ theme }>
				<StyledEngineProvider injectFirst>
					<BrowserCompatibility />
					<StickyHeaderLayout
						isActiveMobileMenu={ mobileMenu.active }
						toggleMobileMenu={ this.toggleMobileMenu }
					>
						<SideNavLayoutContainer
							featureFlags={ this.props.featureFlags }
							hideSideNav={ hideSideNav( Router.route ) }
						>
							{ typeof window !== 'undefined' && (
								<>
									<Alert currentRoute={ currentRoute } />
									<Intercom
										currentRoute={ currentRoute }
										featureFlags={ this.props.featureFlags }
										hide={ this.state.mobileMenu.active }
									/>
									{ this.props.children }
									<MuiStripeVerificationModal
										open={ stripeNeedsModal.active }
										closeModal={ () =>
											this.props.displayStripeNeedsModal( {
												props: null,
												active: false,
											} )
										}
										{ ...stripeNeedsModal.props }
									/>
									{ currentRoute === '/' && subscriptionFailedModal.active && (
										<SubscriptionFailedModal
											closeModal={ this.closeSubscriptionFailedModal }
											user={ this.props.user }
										/>
									) }
									{ confirm.active && (
										<Confirm
											title={ confirm.props.title }
											message={ confirm.props.message }
											options={ confirm.props.options }
											defaultCallback={ confirm.props.confirm }
											cancel={ this.closeConfirm }
										/>
									) }
									<div id={ Globals.rootPortalId } />
									{ this.props.user && this.props.user.isLoggedIn && (
										<MobileMenuContainer
											active={ mobileMenu.active }
											toggleMobileMenu={ this.toggleMobileMenu }
											featureFlags={ this.props.featureFlags }
										/>
									) }
								</>
							) }
							{ this.props.overlay && (
								<LoadingAnimation
									animation='Hand'
									cover={ true }
									transparent={ true }
									width='12rem'
									coverColor={ 'lightBlue' }
								/>
							) }
							<ToastWrapper>
								<ToastContainer
									position='bottom-left'
									transition={ Slide }
									autoClose={ 3000 }
									hideProgressBar={ true }
									newestOnTop={ true }
									closeOnClick={ false }
									pauseOnFocusLoss
									pauseOnHover
								/>
							</ToastWrapper>
						</SideNavLayoutContainer>
					</StickyHeaderLayout>
				</StyledEngineProvider>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = ( state, ownProps ) => ( {
	loading: state.loader.loading,
	confirm: state.confirm,
	user: state.user.isLoggedIn ? state.user : ownProps.user,
	overlay: state.loadingOverlay.overlay,
	stripeNeedsModal: state.stripeNeedsModal,
} );

const mapDispatchToProps = ( dispatch ) => ( {
	createAlert: ( data ) => {
		dispatch( createAlert( data ) );
	},
	displayStripeNeedsModal: ( data ) => {
		dispatch( displayStripeNeedsModal( data ) );
	},
	displayConfirm: ( data ) => {
		dispatch( displayConfirm( data ) );
	},
	onLogout: () => {
		dispatch( logout() );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( Page );
