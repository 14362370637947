import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DashboardIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.2326 0C13.1537 0 12.2791 0.874606 12.2791 1.95349V5.30233C12.2791 6.38121 13.1537 7.25581 14.2326 7.25581H19.814C20.8928 7.25581 21.7674 6.38121 21.7674 5.30233V1.95349C21.7674 0.874607 20.8928 0 19.814 0H14.2326ZM13.9535 1.95349C13.9535 1.79936 14.0784 1.67442 14.2326 1.67442H19.814C19.9681 1.67442 20.093 1.79936 20.093 1.95349V5.30233C20.093 5.45645 19.9681 5.5814 19.814 5.5814H14.2326C14.0784 5.5814 13.9535 5.45645 13.9535 5.30233V1.95349Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.95349 16.7442C0.874607 16.7442 0 17.6188 0 18.6977V22.0465C0 23.1254 0.874606 24 1.95349 24H7.53488C8.61376 24 9.48837 23.1254 9.48837 22.0465V18.6977C9.48837 17.6188 8.61376 16.7442 7.53488 16.7442H1.95349ZM1.67442 18.6977C1.67442 18.5435 1.79936 18.4186 1.95349 18.4186H7.53488C7.68901 18.4186 7.81395 18.5435 7.81395 18.6977V22.0465C7.81395 22.2006 7.68901 22.3256 7.53488 22.3256H1.95349C1.79936 22.3256 1.67442 22.2006 1.67442 22.0465V18.6977Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.2791 10.8837C12.2791 9.80484 13.1537 8.93023 14.2326 8.93023H19.814C20.8928 8.93023 21.7674 9.80484 21.7674 10.8837V22.0465C21.7674 23.1254 20.8928 24 19.814 24H14.2326C13.1537 24 12.2791 23.1254 12.2791 22.0465V10.8837ZM14.2326 10.6047C14.0784 10.6047 13.9535 10.7296 13.9535 10.8837V22.0465C13.9535 22.2006 14.0784 22.3256 14.2326 22.3256H19.814C19.9681 22.3256 20.093 22.2006 20.093 22.0465V10.8837C20.093 10.7296 19.9681 10.6047 19.814 10.6047H14.2326Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.95349 0C0.874607 0 0 0.874606 0 1.95349V13.1163C0 14.1952 0.874606 15.0698 1.95349 15.0698H7.53488C8.61376 15.0698 9.48837 14.1952 9.48837 13.1163V1.95349C9.48837 0.874607 8.61376 0 7.53488 0H1.95349ZM1.67442 1.95349C1.67442 1.79936 1.79936 1.67442 1.95349 1.67442H7.53488C7.68901 1.67442 7.81395 1.79936 7.81395 1.95349V13.1163C7.81395 13.2704 7.68901 13.3953 7.53488 13.3953H1.95349C1.79936 13.3953 1.67442 13.2704 1.67442 13.1163V1.95349Z'
			/>
		</SvgIcon>
	);
}
