const OrganizationScalarfields = [
	'id',
	'image',
	'name',
	'orgType',
	'brandedEmails',
	'addressLine1',
	'addressLine2',
	'postalCode',
	'website',
	'phone',
	'city',
	'state',
	'helloSignID',
];

const OrganizationFieldsForGetContractWhere = [
	'id',
	'image',
	'name',
	'helloSignID',
	{ adminUsers: [ 'id' ] },
];

export { OrganizationScalarfields, OrganizationFieldsForGetContractWhere };
