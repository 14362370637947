/* eslint max-len: 0 */
const Cross = () => (
	<svg viewBox='0 0 14 14'>
		<path
			d='M0.224121 10.7154C-0.0688477 11.0083 -0.0688477 11.4831 0.224121 11.7761C0.51709 12.0689 0.991699 12.0689 1.28467 11.7761L5.99951 7.06063L10.7153 11.7761C11.0083 12.069 11.4829 12.069 11.7759 11.7761C12.0688 11.4832 12.0688 11.0083 11.7759 10.7154L7.06104 5.99999L11.7759 1.2846C12.0688 0.991692 12.0688 0.516838 11.7759 0.22393C11.4829 -0.0689468 11.0083 -0.0689468 10.7153 0.22393L5.99951 4.93938L1.28467 0.223961C0.991699 -0.0689468 0.51709 -0.0689468 0.224121 0.223961C-0.0688477 0.516838 -0.0688477 0.991722 0.224121 1.28463L4.93896 5.99999L0.224121 10.7154Z'
			fill='#B1B1B1'
		/>
	</svg>
);

export default Cross;
