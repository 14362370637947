import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Routes } from '../config/routes';

const signUpPathNames: string[] = [
	`${ Routes.OrgOnboarding }/[step]`,
	`${ Routes.ClientSignUp }/[page]`,
	`${ Routes.OrgMemberSignUp }/[page]`,
];

export default function useIsOnBoardingRoute() {
	const router = useRouter();

	const isOnBoardingRoute = useMemo( () => {
		return (
			!!router?.query?.verificationToken ||
			signUpPathNames.includes( router.route )
		);
	}, [ router ] );

	return isOnBoardingRoute;
}
