import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import styles from './LinkButton.module.scss';

export type LinkButtonProps = HtmlHTMLAttributes<HTMLButtonElement>;

export const LinkButton: React.FC<LinkButtonProps> = ( {
	className,
	children,
	...rest
} ) => (
	<button
		className={ classNames( 'legacyLink', styles.container, className ) }
		{ ...rest }
	>
		{ children }
	</button>
);
