import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const Sheet = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<g fill='none' fillRule='evenodd'>
			<g fill='#000'>
				<g>
					<g>
						<g>
							<g>
								<path
									d='M18-.002c.587 0 1.15.23 1.567.635L22.57 3.52c.435.423.68 1.004.68 1.611v16.617c0 1.243-1.007 2.25-2.25 2.25H3c-1.243 0-2.25-1.007-2.25-2.25v-19.5C.75 1.005 1.757-.002 3-.002zm0 1.5H3c-.414 0-.75.336-.75.75v19.5c0 .414.336.75.75.75h18c.414 0 .75-.336.75-.75V5.131c0-.202-.082-.396-.223-.533L18.523 1.71c-.14-.137-.327-.213-.523-.213zm.749 5.25c.414 0 .75.336.75.75v12c0 .414-.336.75-.75.75h-13.5c-.414 0-.75-.336-.75-.75v-12c0-.414.336-.75.75-.75zm-9.751 9H5.997l.001 3h3v-3zm9 0h-7.5v3h7.501l-.001-3zm-9-4.5H5.997v3h3.001v-3zm9 0h-7.5v3h7.5v-3zm.001-3H5.998l-.001 1.5h12.001l.001-1.5z'
									transform='translate(-424 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(280)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Sheet;
