/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Hide = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props } xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M22.074 2.48c.26.272.277.69.053.978l-.075.083-18.75 18c-.298.287-.773.277-1.06-.022-.26-.271-.277-.688-.053-.977l.075-.083 18.75-18c.298-.287.773-.277 1.06.022zm-1.882 5.794c1.155.843 2.223 1.8 3.19 2.861.826.918.826 2.312-.003 3.232-3.184 3.505-7.482 5.949-11.373 5.883-1.107.008-2.208-.155-3.264-.483-.396-.123-.617-.543-.494-.938.123-.396.543-.617.938-.494.91.282 1.857.422 2.827.415 3.406.057 7.335-2.176 10.254-5.389.313-.347.313-.875.003-1.219-.896-.982-1.888-1.872-2.962-2.656-.335-.244-.408-.714-.164-1.048.244-.335.714-.408 1.048-.164zM15.75 12c.414 0 .75.336.75.75 0 1.193-.474 2.338-1.318 3.183-.844.844-1.988 1.318-3.182 1.318-.414 0-.75-.336-.75-.75s.336-.75.75-.75c.796 0 1.559-.316 2.122-.879.562-.563.878-1.326.878-2.122 0-.414.336-.75.75-.75zM11.992 5.25c.877-.01 1.751.092 2.602.304.402.1.647.507.547.91-.1.401-.507.646-.91.546-.726-.181-1.473-.268-2.243-.26C8.609 6.694 4.66 8.92 1.736 12.137c-.313.347-.313.875-.003 1.218.768.841 1.606 1.615 2.506 2.314.327.254.386.725.132 1.052-.254.327-.725.386-1.052.132-.967-.75-1.868-1.582-2.697-2.49-.827-.918-.827-2.312.002-3.233 3.189-3.507 7.51-5.944 11.368-5.88zm.008 3c.414 0 .75.336.75.75s-.336.75-.75.75c-.796 0-1.559.316-2.122.878C9.316 11.191 9 11.954 9 12.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-1.193.474-2.338 1.318-3.182C9.662 8.724 10.807 8.25 12 8.25z'
			transform='translate(-184 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(40)'
		/>
	</svg>
);

export default Hide;
