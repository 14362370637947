/* eslint max-len: 0 */
const NewContract = () => (
	<svg viewBox='0 0 32 32'>
		<path
			d='M23.111 14.222c4.91 0 8.889 3.98 8.889 8.89C32 28.02 28.02 32 23.111 32c-4.91 0-8.889-3.98-8.889-8.889 0-4.91 3.98-8.889 8.89-8.889zm0 1.524c-4.068 0-7.365 3.297-7.365 7.365s3.297 7.365 7.365 7.365 7.365-3.297 7.365-7.365-3.297-7.365-7.365-7.365zm0 2.293c.386 0 .705.287.755.659l.007.103v3.547l3.548.001c.42 0 .762.341.762.762 0 .386-.287.705-.659.755l-.103.007h-3.549l.001 3.548c0 .42-.341.762-.762.762-.386 0-.704-.287-.755-.659l-.007-.103v-3.549l-3.548.001c-.42 0-.762-.341-.762-.762 0-.386.287-.704.659-.755l.103-.007h3.547l.001-3.548c0-.42.341-.762.762-.762zM15.295 0c.607 0 1.193.213 1.654.598l.15.135 7.035 6.893c.435.426.698.99.747 1.588l.008.2v2.523c0 .42-.341.761-.762.761-.386 0-.705-.286-.755-.658l-.007-.103.001-.196-.014.002-.102.007H17c-1.463 0-2.658-1.142-2.745-2.582L14.25 9V1.523H2.56c-.535 0-.97.39-1.03.884l-.006.116v22.89c0 .51.397.935.914.993l.121.007h10.393c.421 0 .762.34.762.762 0 .385-.286.704-.658.755l-.104.007H2.56c-1.353 0-2.467-1.038-2.554-2.357L0 25.414V2.523C0 1.182 1.06.09 2.391.005L2.56 0h12.736zM7.437 20.819c.45.11.837.383 1.113.764l.1.15.277-.187c.776-.522 1.756-.458 2.467.137l.13.118c.297.29.302.764.012 1.06-.263.27-.68.299-.975.084l-.085-.072c-.188-.183-.416-.227-.621-.134l-.087.049-1.07.73c-.404.276-.949.096-1.12-.342l-.032-.098-.089-.364c-.066-.24-.218-.4-.406-.446-.153-.044-.313-.015-.451.083l-.08.067-.984 1.007c-.289.296-.764.302-1.06.012-.27-.263-.299-.68-.084-.975l.072-.085.994-1.018c.543-.534 1.296-.739 1.98-.54zM13 13.917c.414 0 .75.335.75.75 0 .38-.282.693-.648.743l-.102.007H5c-.414 0-.75-.336-.75-.75 0-.38.282-.694.648-.743L5 13.917h8zm2.752-12.29L15.75 9c0 .647.492 1.18 1.122 1.244l.128.006h6.25c.04 0 .078.003.116.009v-.844c0-.218-.075-.43-.21-.602l-.088-.098-7.036-6.893c-.082-.08-.177-.147-.28-.196zM11 6.916c.414 0 .75.335.75.75 0 .38-.282.693-.648.743L11 8.417H5c-.414 0-.75-.336-.75-.75 0-.38.282-.694.648-.743L5 6.917h6z'
			transform='translate(-240 -1434) translate(144 183) translate(0 1197) translate(0 54) translate(96)'
		/>
	</svg>
);

export default NewContract;
