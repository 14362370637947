import { Box } from '@mui/material';
import React from 'react';
import MuiButton from '../../../mui/MuiButton';
import styles from './LoadMore.module.scss';

export type LoadMoreProps = {
	loading?: boolean;
	loadMore: () => unknown | Promise<unknown>;
	hasMore: boolean;
};

export const LoadMore: React.FC<LoadMoreProps> = ( {
	loading,
	loadMore,
	hasMore,
} ) =>
	hasMore ? (
		<Box className={ styles.container }>
			<MuiButton variant='text' onClick={ loadMore } loading={ loading }>
				Load more
			</MuiButton>
		</Box>
	) : null;
