/* eslint max-len: 0 */
const Search = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<g fillRule='evenodd'>
			<path
				d='M4.907 1.315c3.036-1.753 6.776-1.753 9.813 0 3.036 1.753 4.906 4.992 4.906 8.498 0 2.44-.89 4.671-2.364 6.388l6.518 6.52c.293.292.293.767 0 1.06-.266.266-.683.29-.976.073l-.084-.073-6.519-6.519c-1.717 1.474-3.948 2.364-6.388 2.364C4.393 19.626 0 15.233 0 9.813c0-3.506 1.87-6.745 4.907-8.498zm9.063 1.299c-2.573-1.485-5.741-1.485-8.313 0C3.084 4.099 1.5 6.844 1.5 9.813c0 4.591 3.722 8.313 8.313 8.313 2.29 0 4.364-.926 5.867-2.424l.01-.012.011-.009c1.499-1.503 2.425-3.577 2.425-5.868 0-2.97-1.584-5.714-4.156-7.2z'
				transform='translate(-304 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(160)'
			/>
		</g>
	</svg>
);

export default Search;
