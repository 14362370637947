import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import CheckCircleOutline from '../../elements/icons/CheckCircleOutline';
import CheckCircleFilled from '../../elements/icons/CheckCircleFilled';
import CheckCircleIndeterminate from '../../elements/icons/CheckCircleIndeterminate';
import styles from './MuiCheckbox.module.scss';

export type MuiCheckboxProps = CheckboxProps & {
	// This is to assure MUI that we can indeed add `data-cy` as an attribute in the input here
	inputProps?: CheckboxProps['inputProps'] & { 'data-cy'?: string };
};

const MuiCheckbox: React.FC<MuiCheckboxProps> = ( props ) => (
	<Checkbox
		icon={ <CheckCircleOutline className={ styles.svg } /> }
		checkedIcon={
			<CheckCircleFilled className={ [ styles.svg, styles.checked ].join( ' ' ) } />
		}
		indeterminateIcon={ <CheckCircleIndeterminate className={ styles.svg } /> }
		{ ...props }
		className={ [ styles.container, props.className ].join( ' ' ) }
	/>
);

export default MuiCheckbox;
