import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ExportIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path d='M2.01796 1.76799C1.8797 1.76799 1.76762 1.88007 1.76762 2.01832V19.9571C1.76762 20.0953 1.8797 20.2074 2.01796 20.2074H9.19772C9.61248 20.2074 9.94871 20.5436 9.94871 20.9584C9.94871 21.3732 9.61248 21.7094 9.19772 21.7094H2.01796C1.05017 21.7094 0.265625 20.9249 0.265625 19.9571V2.01832C0.265625 1.05053 1.05017 0.265991 2.01796 0.265991H13.4842C13.9583 0.265991 14.4122 0.458091 14.7422 0.798444L17.6355 3.78211C17.9525 4.10905 18.1298 4.54658 18.1298 5.00199V7.66413C18.1298 8.07889 17.7936 8.41512 17.3788 8.41512C16.964 8.41512 16.6278 8.07889 16.6278 7.66413V5.00199C16.6278 4.93693 16.6025 4.87443 16.5572 4.82772L13.6639 1.84405C13.6168 1.79543 13.552 1.76799 13.4842 1.76799H2.01796Z' />
			<path d='M17.8901 13.5603C18.3049 13.5603 18.6411 13.8965 18.6411 14.3113V18.1227L19.9157 16.8481C20.209 16.5549 20.6845 16.5549 20.9778 16.8481C21.271 17.1414 21.271 17.6169 20.9778 17.9102L18.4212 20.4668C18.1279 20.7601 17.6524 20.7601 17.3591 20.4668L14.8025 17.9102C14.5092 17.6169 14.5092 17.1414 14.8025 16.8481C15.0958 16.5549 15.5713 16.5549 15.8646 16.8481L17.1391 18.1227V14.3113C17.1391 13.8965 17.4754 13.5603 17.8901 13.5603Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.5146 16.8679C11.5146 13.3468 14.369 10.4924 17.8901 10.4924C21.4112 10.4924 24.2656 13.3468 24.2656 16.8679C24.2656 20.3889 21.4112 23.2434 17.8901 23.2434C14.369 23.2434 11.5146 20.3889 11.5146 16.8679ZM17.8901 11.9944C15.1986 11.9944 13.0166 14.1763 13.0166 16.8679C13.0166 19.5594 15.1986 21.7414 17.8901 21.7414C20.5817 21.7414 22.7636 19.5594 22.7636 16.8679C22.7636 14.1763 20.5817 11.9944 17.8901 11.9944Z'
			/>
			<path d='M4.08453 6.40181C3.66977 6.40181 3.33353 6.73804 3.33353 7.15281C3.33353 7.56757 3.66977 7.90381 4.08453 7.90381H14.3109C14.7257 7.90381 15.0619 7.56757 15.0619 7.15281C15.0619 6.73804 14.7257 6.40181 14.3109 6.40181H4.08453Z' />
			<path d='M3.33353 11.2434C3.33353 10.8286 3.66977 10.4924 4.08453 10.4924H9.19772C9.61248 10.4924 9.94871 10.8286 9.94871 11.2434C9.94871 11.6581 9.61248 11.9944 9.19772 11.9944H4.08453C3.66977 11.9944 3.33353 11.6581 3.33353 11.2434Z' />
			<path d='M4.08453 14.5829C3.66977 14.5829 3.33353 14.9191 3.33353 15.3339C3.33353 15.7487 3.66977 16.0849 4.08453 16.0849H9.19772C9.61248 16.0849 9.94871 15.7487 9.94871 15.3339C9.94871 14.9191 9.61248 14.5829 9.19772 14.5829H4.08453Z' />
		</SvgIcon>
	);
}
