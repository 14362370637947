/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Instagram = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M17 0c3.866 0 7 3.134 7 7v10c0 3.866-3.134 7-7 7H7c-3.866 0-7-3.134-7-7V7c0-3.866 3.134-7 7-7h10zm0 1.5H7c-2.963 0-5.38 2.344-5.496 5.279L1.5 7v10c0 2.963 2.344 5.38 5.279 5.496L7 22.5h10c2.963 0 5.38-2.344 5.496-5.279L22.5 17V7c0-2.963-2.344-5.38-5.279-5.496L17 1.5zM12 6c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm0 1.5c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.015-4.5-4.5-4.5zM18.5 4c.828 0 1.5.672 1.5 1.5S19.328 7 18.5 7 17 6.328 17 5.5 17.672 4 18.5 4z'
			transform='translate(-264 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(120)'
		/>
	</svg>
);

export default Instagram;
