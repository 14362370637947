import React from 'react';
import P from '../../../elements/Paragraph';
import {
	isClient,
	linkObject,
	getDetailLink,
	isOrgUser,
} from '../../../lib/helpers/index';
import { ProposalsIcon } from '../../../mui/Icons/index';
import API from '../../../lib/API/index';
import { showError, showSuccess } from '../../Toast';
import { linkCustomer, LinkCustomerLinkType } from '../helpers';
import rpcShared from '@rockpapercoin/rpc-shared';
import { LinkObjectType } from '../../../lib/helpers/types';

const resendProposal = async function( id: string, refresh: () => void ) {
	// there is no bundling with resends
	const response = await API.sendProposal( id, null );

	if ( !( 'errors' in response ) ) {
		showSuccess();
		if ( refresh ) {
			return refresh();
		}
	} else {
		response.errors.map( ( error ) => showError( error ) );
		return;
	}
};

// Touble "props" in name because that's how Bilberry named the method
interface GetProposalPastDueEventPropsProps {
	defaultEventProps: {
		event: any;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	};
	user: {
		id: string;
		user?: {
			userType?: 'ClientUser' | 'OrgUser' | 'GuestUser' | 'SuperAdmin';
		};
		firstNameOne?: string;
		lastNameOne?: string;
		firstNameTwo?: string;
		lastNameTwo?: string;
	};
	targetUser: {
		[index: string]: any;
	};
	refresh: () => void;
}

export default function getProposalPastDueEventProps( {
	defaultEventProps,
	user,
	targetUser,
	refresh,
}: GetProposalPastDueEventPropsProps ) {
	const updatedEventProps = { ...defaultEventProps };
	const proposalLink = linkObject( updatedEventProps.event.proposal );
	const vendorLink = linkObject( updatedEventProps.event.vendor );
	const clientLink = linkCustomer(
		updatedEventProps.event,
		LinkCustomerLinkType.Contact
	);
	updatedEventProps.icon = <ProposalsIcon />;
	updatedEventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: updatedEventProps.event.proposal,
				type: LinkObjectType.Proposal,
			} ),
		},
	];

	const getResendOrViewLink = () => {
		return {
			type: updatedEventProps.event.resolvedBy ? 'secondary' : 'primary',
			text: updatedEventProps.event.resolvedBy ? 'View' : 'Resend',
			link: updatedEventProps.event.resolvedBy
				? getDetailLink( {
					object: updatedEventProps.event.contract,
					type: LinkObjectType.Contract,
				  } )
				: null,
			onClick: updatedEventProps.event.resolvedBy
				? null
				: () => resendProposal( updatedEventProps.event.proposal.id, refresh ),
		};
	};

	if ( isClient( user ) ) {
		updatedEventProps.message = (
			<P className='p--secondary'>
				{ proposalLink } from { vendorLink } is past due.
			</P>
		);
	} else if ( targetUser?.user.userType === 'ClientUser' ) {
		if ( updatedEventProps.event.orgUser.id !== user.id ) {
			updatedEventProps.message = (
				<P className='p--secondary'>
					{ proposalLink } from { vendorLink } is past due.
				</P>
			);
		} else {
			updatedEventProps.message = (
				<P className='p--secondary'>{ proposalLink } is past due. Resend?</P>
			);
			updatedEventProps.links = [ getResendOrViewLink() ];
		}
	} else if ( isOrgUser( user ) ) {
		// if the user is admin of the event's org then they can resend if the event is unresolved
		const isUserAdminOfSendingOrg = rpcShared.contacts.isAdminOfVendor(
			updatedEventProps.event.contact,
			user
		);
		const isAssignedPlanner = rpcShared.contacts.isAssignedPlanner(
			updatedEventProps.event.contact,
			user
		);
		if ( isUserAdminOfSendingOrg ) {
			updatedEventProps.message = (
				<P className='p--secondary'>{ proposalLink } is past due. Resend?</P>
			);
			updatedEventProps.links = [ getResendOrViewLink() ];
			// if the user is NOT admin of the event's org, all they can do is view
		} else if ( isAssignedPlanner ) {
			updatedEventProps.message = (
				<P className='p--secondary'>
					A proposal from { vendorLink } that was sent to { clientLink } is past
					due: { proposalLink }.
				</P>
			);
		} else {
			updatedEventProps.message = (
				<P className='p--secondary'>{ proposalLink } is past due.</P>
			);
		}
	}

	return updatedEventProps;
}
