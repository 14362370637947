import { GraphQLError } from 'graphql';

export enum LinkObjectType {
	Organization = 'org',
	Invoice = 'invoice',
	Contract = 'contract',
	Document = 'document',
	Proposal = 'proposal',
	Contact = 'contact',
}

export const isNumeric = ( n: any ): n is number =>
	!isNaN( parseFloat( n ) ) && isFinite( n );

export const isGraphQLError = ( obj: any ): obj is GraphQLError =>
	obj &&
	typeof obj == 'object' &&
	'message' in obj &&
	typeof obj.message === 'string' &&
	'path' in obj;
