import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';

// Markup
import ScreenCover from './ScreenCover';
import Icon from './icons';

// Utils
import withPortal from '../lib/withPortalHOC';

const ModalElement = styled( Div100vh )`
	&.modal {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		padding: 2.562rem 1rem 1rem 1rem;
		border-radius: 5px;
		background-color: ${ ( { theme } ) => theme.white };
		z-index: ${ ( { zindex } ) => ( zindex ? zindex : '11' ) };
		overflow-y: auto;

		@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
			width: auto;
			height: auto !important;
			max-height: 100vh;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 3.5rem 4.5rem;
		}

		.modal__h2 {
			width: 70%;
			font-size: 1.375rem;
			margin-bottom: 1.875rem;

			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				width: auto;
				font-size: 1.688rem;
			}
		}

		.modal-close-button {
			width: fit-content;
			cursor: pointer;
			position: absolute;
			right: 1.5rem;
			top: 2.662rem;

			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				position: absolute;
				right: 4.5rem;
				top: 3.5rem;
			}

			svg {
				width: 1.5rem;
				height: 1.5rem;
				fill: ${ ( { theme } ) => theme.darkBlue };
			}
		}
	}
`;

export const CloseButton = ( props ) => <Icon type='close-regular' { ...props } />;

const Modal = withPortal(
	( {
		closeModal,
		closable = true,
		children,
		className,
		removeCover,
		zIndex = 11,
		...props
	} ) => {
		const onClick = () => {
			if ( props.notClosableWithOutsideClick ) return;
			closeModal && closeModal();
			// this is a weird pattern, but it's hopefully going away when we implement MUI modals
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			window.removeEventListener( 'keydown', escToClose );
		};

		const escToClose = ( event ) => {
			if ( event && event.key === 'Escape' ) {
				onClick();
			}
		};

		useEffect( () => {
			window.addEventListener( 'keydown', escToClose );

			return () => {
				window.removeEventListener( 'keydown', escToClose );
			};
		}, [] );

		return (
			<>
				<ScreenCover
					className={ `screen-cover ${
						removeCover ? 'screen-cover__hidden' : ''
					}` }
					coverOpacity='0.5'
					onClick={ () => onClick() }
					zIndex={ zIndex - 1 }
				/>
				<ModalElement
					className={ `modal ${ className ? className : '' }` }
					zindex={ zIndex }
					{ ...props }
				>
					{ closable && (
						<div
							className='modal-close-button'
							onClick={ () => {
								closeModal && closeModal();
							} }
						>
							<Icon type='close-regular' />
						</div>
					) }
					{ children }
				</ModalElement>
			</>
		);
	}
);

Modal.propTypes = {
	className: PropTypes.string, // the name of the class to apply to this element
	closeModal: PropTypes.func, // function used when clicking the cover
	closable: PropTypes.bool, // renders a close icon, still requires "closeModal" to be effective
	removeCover: PropTypes.bool, //removes background cover
};

export default Modal;
