import React, { Component } from 'react';
import { theme } from '../blocks/Page';

export default function withWidth( WrappedComponent ) {
	return class extends Component {
		constructor( props ) {
			super( props );
			this.state = {
				width: 0,
			};
			this.updateWidth = this.updateWidth.bind( this );
		}

		static async getInitialProps() {
			let componentProps = {};
			if ( WrappedComponent.getInitialProps ) {
				componentProps = await WrappedComponent.getInitialProps( ...arguments );
			}

			return componentProps;
		}

		componentDidMount() {
			this.updateWidth();
			window.addEventListener( 'resize', () => this.updateWidth() );
		}

		componentWillUnmount() {
			window.removeEventListener( 'resize', () => this.updateWidth() );
		}

		updateWidth() {
			const width = window.innerWidth;
			this.setState( {
				width: width,
			} );
		}

		render() {
			const isMobile =
				typeof window !== 'undefined' && this.state.width < theme.mediumScreen;

			return (
				<WrappedComponent
					{ ...this.props }
					innerWidth={ this.state.width }
					isMobile={ isMobile }
				/>
			);
		}
	};
}
