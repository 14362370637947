/* eslint max-len: 0 */
const Draft = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M21.126 10.5c.76 0 1.49.302 2.026.84l.149.158c.45.52.7 1.185.7 1.876 0 .76-.303 1.49-.84 2.026l-7.63 7.63c-.105.105-.239.176-.384.205l-3.75.75c-.525.105-.987-.357-.882-.882l.75-3.75c.029-.145.1-.279.205-.383l7.63-7.63c.536-.538 1.266-.84 2.026-.84zm0 1.5c-.362 0-.71.144-.966.4l-7.469 7.469-.485 2.424 2.424-.485 7.47-7.469c.22-.22.357-.506.392-.81l.008-.155c0-.362-.144-.71-.4-.966l-.124-.11c-.24-.193-.54-.298-.85-.298zM17.25 0c1.19 0 2.166.925 2.245 2.096l.005.154V9c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L18 9V2.25c0-.38-.282-.693-.648-.743L17.25 1.5h-15c-.38 0-.693.282-.743.648L1.5 2.25v16.5c0 .38.282.693.648.743l.102.007h6c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L8.25 21h-6c-1.19 0-2.166-.925-2.245-2.096L0 18.75V2.25C0 1.06.925.084 2.096.005L2.25 0h15zM15 11.25c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L15 12.75H5.25v3.75h4.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L9.75 18h-4.5c-.78 0-1.42-.595-1.493-1.356L3.75 16.5v-3.75c0-.78.595-1.42 1.356-1.493l.144-.007H15zM15 3c.414 0 .75.336.75.75V9c0 .414-.336.75-.75.75H4.5c-.414 0-.75-.336-.75-.75V3.75c0-.414.336-.75.75-.75zm-.75 1.5h-9v3.75h9V4.5z'
			transform='translate(-344 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(200)'
		/>
	</svg>
);

export default Draft;
