export const stringToClipboard = async (
	callback: () => Promise<string | undefined>
) => {
	if ( typeof ClipboardItem !== 'undefined' ) {
		const text = new ClipboardItem( {
			'text/plain': callback().then( ( res ) => {
				if ( res ) {
					return new Blob( [ res ], { type: 'text/plain' } ); // can we also just return a string here I wonder?
				} else {
					return '';
				}
			} ),
		} );
		try {
			await navigator.clipboard.write( [ text ] );
		} catch ( error ) {
			console.log( 'Ignoring error:', error );
		}
	} else {
		callback().then( async ( res ) => {
			if ( res ) {
				try {
					await navigator.clipboard.writeText( res );
				} catch ( error ) {
					console.log( 'Ignoring error:', error );
				}
			}
		} );
	}
};
