import React from 'react';
import { Typography } from '@mui/material';
import {
	UserForContractSentEventFeed,
	userIsAssignedMemberOrVendorAdminOnContractEvent,
	DefaultEventProps,
	userIsCustomerOnContractEvent,
	userIsAPPAOnContractEvent,
} from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

const getContractSignedByCountersignerEventProps = (
	eventProps: DefaultEventProps,
	user: UserForContractSentEventFeed
) => {
	if (
		!eventProps.event.contract ||
		typeof eventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = eventProps;
	eventProps.icon = 'contract-signed';
	eventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: eventProps.event.contract,
				type: LinkObjectType.Contract,
			} ),
		},
	];
	if (
		( userIsAPPAOnContractEvent( event, user ) &&
			!userIsAssignedMemberOrVendorAdminOnContractEvent( event, user ) ) ||
		userIsCustomerOnContractEvent( event, user )
	) {
		// you are the planner or their customer viewing the event where the vendor countersigner
		eventProps.message = (
			<Typography>
				{ linkObject( eventProps.event.contract ) } has been countersigned by{ ' ' }
				{ linkObject( event.vendor ) }.
			</Typography>
		);
	} else {
		// you are someone at the vendor organization viewing the event where the vendor countersigned
		eventProps.message = (
			<Typography>
				{ linkObject( eventProps.event.contract ) } has been countersigned.
			</Typography>
		);
	}
	return eventProps;
};

export default getContractSignedByCountersignerEventProps;
