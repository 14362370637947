/* eslint max-len: 0 */
const CloseSmall = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			d='M8.696 7.647l.084.072L12 10.94l3.22-3.22c.293-.293.767-.293 1.06 0 .267.267.29.683.073.977l-.073.084L13.061 12l3.22 3.22c.292.292.292.767 0 1.06-.267.266-.684.29-.977.073l-.084-.073L12 13.06l-3.22 3.22c-.293.293-.767.293-1.06 0-.267-.266-.29-.683-.073-.977l.073-.084L10.939 12 7.719 8.78c-.292-.293-.292-.768 0-1.06.267-.267.684-.291.977-.073z'
			transform='translate(-1372 -110) translate(1372 110)'
		/>
	</svg>
);

export default CloseSmall;
