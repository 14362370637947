import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Team( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.51148 4.69057C5.51148 3.00673 6.87651 1.6417 8.56035 1.6417C10.2442 1.6417 11.6092 3.00673 11.6092 4.69057C11.6092 6.37442 10.2442 7.73945 8.56035 7.73945C6.87651 7.73945 5.51148 6.37442 5.51148 4.69057ZM8.56035 0C5.96982 0 3.86978 2.10004 3.86978 4.69057C3.86978 6.19616 4.57913 7.53607 5.68186 8.39433C4.49909 8.81665 3.41228 9.4981 2.50725 10.4031C0.901886 12.0085 0 14.1858 0 16.4562V18.176C0 18.6294 0.367507 18.9969 0.82085 18.9969H16.2997C16.7531 18.9969 17.1206 18.6294 17.1206 18.176V16.4562C17.1206 14.1858 16.2187 12.0085 14.6133 10.4031C13.7083 9.49812 12.6216 8.81668 11.4388 8.39436C12.5416 7.5361 13.2509 6.19618 13.2509 4.69057C13.2509 2.10004 11.1509 0 8.56035 0ZM3.66811 11.564C4.9656 10.2665 6.72537 9.53757 8.5603 9.53757C10.3952 9.53757 12.155 10.2665 13.4525 11.564C14.75 12.8615 15.4789 14.6212 15.4789 16.4562V17.3552H1.6417V16.4562C1.6417 14.6212 2.37062 12.8615 3.66811 11.564ZM14.6191 0.82085C14.6191 0.367507 14.9866 0 15.44 0C16.684 0 17.8771 0.494184 18.7567 1.37384C19.6364 2.25349 20.1306 3.44656 20.1306 4.69057C20.1306 5.93459 19.6364 7.12766 18.7567 8.00731C18.6856 8.07843 18.6124 8.14702 18.5374 8.21304C19.1382 8.51263 19.762 8.89748 20.3414 9.3226C21.1709 9.93134 21.9673 10.6651 22.484 11.4134C23.469 12.84 23.9977 14.5322 24 16.2659V18.1761C24 18.6294 23.6325 18.9969 23.1791 18.9969H20.5993C20.146 18.9969 19.7785 18.6294 19.7785 18.1761C19.7785 17.7227 20.146 17.3552 20.5993 17.3552H22.3583L22.3583 16.2681C22.3564 14.867 21.9291 13.4991 21.133 12.3461C20.7591 11.8046 20.1198 11.1963 19.3701 10.6462C18.6761 10.1369 17.9375 9.71405 17.316 9.45928H15.2833C14.8299 9.45928 14.4624 9.09178 14.4624 8.63843C14.4624 8.28544 14.6852 7.98449 14.9979 7.86855C15.1255 7.78683 15.2772 7.73945 15.44 7.73945C16.2486 7.73945 17.0241 7.41823 17.5959 6.84645C18.1676 6.27468 18.4888 5.49919 18.4888 4.69057C18.4888 3.88196 18.1676 3.10647 17.5959 2.53469C17.0241 1.96292 16.2486 1.6417 15.44 1.6417C14.9866 1.6417 14.6191 1.27419 14.6191 0.82085Z'
			/>
		</SvgIcon>
	);
}
