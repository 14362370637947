/* eslint max-len: 0 */
const Checked = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25zm0 1.5c-4.556 0-8.25 3.694-8.25 8.25s3.694 8.25 8.25 8.25 8.25-3.694 8.25-8.25S16.556 3.75 12 3.75zm5.567 4.759c.246.284.24.701.003.979l-.079.079-6.916 6c-.255.22-.62.242-.897.064l-.088-.066-3.084-2.696c-.312-.273-.343-.747-.07-1.059.247-.283.661-.335.969-.137l.089.066 2.591 2.266 6.424-5.572c.312-.271.786-.237 1.058.076z'
			transform='translate(-224 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241) translate(80)'
		/>
	</svg>
);

export default Checked;
