import { ApiResponseType } from '../../../../hooks/useQuery';
import Query from '../../../Query';
import { GetProposalsWhereReturnFields } from '../../returnFields/proposals';
import {
	GetProposalsWhereDataResponse,
	GetProposalsWhereInput,
} from '../types';

const getProposalsWhere = async function(
	this: any,
	paramsData: GetProposalsWhereInput
): Promise<ApiResponseType<GetProposalsWhereDataResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getProposalsWhere',
			params: paramsData,
			returnFields: [ '_count', { proposals: GetProposalsWhereReturnFields } ],
		} )
	);

	if ( errors ) return { errors };

	return {
		data: data.data.getProposalsWhere.proposals,
		count: data.data.getProposalsWhere._count,
	};
};

export default getProposalsWhere;
