/* eslint max-len: 0 */
const AddEvent = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
	>
		<g>
			<path
				d='M34.5 21C41.956 21 48 27.044 48 34.5S41.956 48 34.5 48 21 41.956 21 34.5 27.044 21 34.5 21zm0 3C28.701 24 24 28.701 24 34.5S28.701 45 34.5 45 45 40.299 45 34.5 40.299 24 34.5 24zm0 3c.77 0 1.403.58 1.49 1.325l.01.175V33h4.5c.828 0 1.5.672 1.5 1.5 0 .77-.58 1.403-1.325 1.49L40.5 36H36v4.5c0 .828-.672 1.5-1.5 1.5-.77 0-1.403-.58-1.49-1.325L33 40.5V36h-4.5c-.828 0-1.5-.672-1.5-1.5 0-.77.58-1.403 1.325-1.49L28.5 33H33v-4.5c0-.828.672-1.5 1.5-1.5zm-6-27c.77 0 1.403.58 1.49 1.325L30 1.5V3l4.554.001c2.45.088 4.386 2.072 4.447 4.447L39 16.5c0 .828-.672 1.5-1.5 1.5-.77 0-1.403-.58-1.49-1.325L36 16.5V7.665c.028-.83-.576-1.534-1.335-1.65L34.5 6 30 5.999V10.5c0 .828-.672 1.5-1.5 1.5-.77 0-1.403-.58-1.49-1.325L27 10.5V5.999h-6V10.5c0 .828-.672 1.5-1.5 1.5-.77 0-1.403-.58-1.49-1.325L18 10.5V5.999h-6V10.5c0 .828-.672 1.5-1.5 1.5-.77 0-1.403-.58-1.49-1.325L9 10.5V5.999H4.553c-.83.03-1.49.68-1.55 1.53L3 7.714v29.823c-.01.391.14.77.417 1.046.23.23.532.373.873.409L4.5 39h12c.828 0 1.5.672 1.5 1.5 0 .77-.58 1.403-1.325 1.49L16.5 42H4.537c-1.212.03-2.384-.439-3.241-1.296-.786-.786-1.245-1.836-1.293-2.91L0 7.763c-.08-2.451 1.765-4.519 4.223-4.746L4.5 3 9 2.999V1.5C9 .672 9.672 0 10.5 0c.77 0 1.403.58 1.49 1.325L12 1.5v1.499h6V1.5c0-.828.672-1.5 1.5-1.5.77 0 1.403.58 1.49 1.325L21 1.5v1.499h6V1.5c0-.828.672-1.5 1.5-1.5z'
				transform='translate(-275 -241) translate(170 154) translate(0 57) translate(65 30) translate(40)'
			/>
		</g>
	</svg>
);

export default AddEvent;
