/* eslint max-len: 0 */
const Save = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M18.023 0c.587 0 1.15.23 1.57.64l2.956 2.882c.434.424.678 1.005.678 1.611V21.75c0 1.243-1.007 2.25-2.25 2.25H3.023c-1.243 0-2.25-1.007-2.25-2.25V2.25C.773 1.007 1.78 0 3.023 0zm0 1.5h-5.25v9.75c0 .582-.625.928-1.11.658l-.09-.058-2.551-1.912-2.549 1.912c-.465.349-1.118.057-1.193-.494l-.007-.106V1.5h-2.25c-.38 0-.693.282-.743.648l-.007.102v19.5c0 .414.336.75.75.75h17.954c.414 0 .75-.336.75-.75V5.133c0-.203-.081-.396-.226-.537l-2.955-2.883c-.14-.137-.327-.213-.523-.213zm-6.75 0h-4.5v8.249l1.8-1.349c.237-.178.553-.198.808-.06l.092.06 1.8 1.351V1.5z'
			transform='translate(-264 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(120)'
		/>
	</svg>
);

export default Save;
