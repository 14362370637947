import React, { SVGProps } from 'react';

const ContractOverdue = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M18.107 12.214c3.255 0 5.893 2.639 5.893 5.893C24 21.362 21.362 24 18.107 24c-3.254 0-5.893-2.638-5.893-5.893 0-3.254 2.639-5.893 5.893-5.893zM12.612 0h.038l.06.004.097.004c.517.04 1.008.244 1.403.58l5.426 6.416c.415.416.667.965.713 1.546l.008.195v2.29c0 .415-.336.75-.75.75-.38 0-.693-.281-.743-.648l-.007-.101V9H14.15c-1.19 0-2.166-.925-2.245-2.096L11.9 6.75V1.5H2.464c-.494 0-.902.372-.958.852l-.006.112v18.857c0 .495.372.903.852.958l.112.007h6.857c.415 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H2.464c-1.306 0-2.375-1.017-2.459-2.303L0 21.321V2.464C0 1.158 1.017.09 2.302.005L2.464 0h10.148zm5.495 13.714c-2.426 0-4.393 1.967-4.393 4.393 0 2.426 1.967 4.393 4.393 4.393 2.426 0 4.393-1.967 4.393-4.393 0-2.426-1.967-4.393-4.393-4.393zM6.34 18.054c.356.087.657.315.86.63l.06.102.279-.189c.562-.377 1.27-.332 1.786.095l.101.09c.196.192.2.506.008.701-.172.176-.444.197-.64.06l-.062-.052c-.168-.164-.38-.202-.567-.113l-.07.04-.817.556c-.266.18-.624.064-.74-.222l-.022-.069-.069-.28c-.057-.208-.194-.35-.362-.392-.143-.041-.294-.012-.42.082l-.061.052-.752.767c-.191.196-.505.2-.702.009-.176-.172-.197-.444-.06-.639l.052-.062.759-.774c.395-.388.942-.536 1.44-.392zm11.768-3.268c.38 0 .694.282.743.648l.007.102v1.821h1.822c.414 0 .75.336.75.75 0 .38-.283.694-.649.743l-.101.007H18l-.083-.007h-.06c-.276 0-.5-.224-.5-.5v-2.814c0-.414.336-.75.75-.75zM11.2 12c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H4.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L4.75 12h6.45zm2.2-10.09v4.84c0 .38.282.693.648.743l.102.007h3.951L13.4 1.91zm-4 3.04c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L9.4 6.45H4.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007H9.4z'
			transform='translate(-184 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(40)'
		/>
	</svg>
);

export default ContractOverdue;
