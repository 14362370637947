import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import MuiModal, { MuiModalProps } from '../../mui/MuiModal/MuiModal';
import styles from './OrgOnboardingModal.module.scss';
import { gql } from '@apollo/client';
import {
	useOrgOnboardingModal_GetGlobalVariablesQuery,
	useOrgOnboardingModal_GetStripeConnectAccountQuery,
} from './__generated__';
import { showError } from '../Toast';
import ExtendedLink from '../ExtendedLink/ExtendedLink';
import { useSelector } from 'react-redux';
import { FlattenedUser } from '../../types/user';
import { UserType } from '../../types/generated';
import MuiSpinner from '../../mui/MuiSpinner';
import { LoadingButton } from '@mui/lab';
import Link from 'next/link';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query OrgOnboardingModal_getGlobalVariables {
		getGlobalVariables {
			WhiteGloveUrl
			WatchDemoUrl
			DemoCalendarUrl
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStripeConnectAccountQuery = gql`
	query OrgOnboardingModal_getStripeConnectAccount(
		$where: OrganizationWhereUniqueInput!
	) {
		getStripeConnectAccount(where: $where) {
			external_accounts {
				data {
					id
				}
			}
		}
	}
`;

export type OrgOnboardingModalProps = Pick<
MuiModalProps['muiModalProps'],
'open' | 'onClose'
>;

export const OrgOnboardingModal: React.FC<OrgOnboardingModalProps> = ( {
	open,
	onClose,
} ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state?.user );
	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;

	const {
		data: globalVariableData,
		loading: globalVariableLoading,
		error: globalVariableError,
	} = useOrgOnboardingModal_GetGlobalVariablesQuery();

	useEffect( () => {
		if ( globalVariableError ) {
			showError( globalVariableError );
		}
	}, [ globalVariableError ] );

	const {
		data: payToData,
		loading: payToLoading,
		error: payToError,
	} = useOrgOnboardingModal_GetStripeConnectAccountQuery( {
		variables: { where: { id: orgUser?.organization?.id || '' } },
		skip: !orgUser?.organization?.id || !orgUser.isAdmin,
	} );

	useEffect( () => {
		if ( payToError ) {
			showError( payToError );
		}
	}, [ payToError ] );

	const hasPayToAccount =
		payToData?.getStripeConnectAccount?.external_accounts?.data &&
		payToData.getStripeConnectAccount.external_accounts.data.length > 0
			? true
			: false;

	return (
		<MuiModal
			muiModalProps={ {
				open,
				onClose,
				className: styles.container,
				PaperProps: { className: styles.paper },
			} }
			title={
				<Typography component='span' className={ styles.hugeTitle }>
					Welcome { orgUser?.firstName }! 🎉
				</Typography>
			}
			description={ `We are thrilled to have you on RPC. Here are ${
				hasPayToAccount ? 'two' : 'three'
			} quick tips to get you started.` }
		>
			{ payToLoading ? (
				<Box className={ styles.centered }>
					<MuiSpinner />
				</Box>
			) : (
				<Box className={ hasPayToAccount ? styles.two : styles.three }>
					<Box className={ styles.item }>
						<Typography variant='h6'>See RPC in action</Typography>
						<Typography>
							Discover how RPC works and see all of our features in action!
							Watch a demo or to schedule a personalized demo with our team,
							click{ ' ' }
							<ExtendedLink
								target='_blank'
								rel='noreferrer'
								loading={ globalVariableLoading }
								href={ globalVariableData?.getGlobalVariables.DemoCalendarUrl }
								className='legacyLink'
							>
								here
							</ExtendedLink>
							.
						</Typography>
						<LoadingButton
							className={ styles.button }
							LinkComponent={ Link }
							variant='contained'
							target='_blank'
							rel='noreferrer'
							loading={ globalVariableLoading }
							href={ globalVariableData?.getGlobalVariables.WatchDemoUrl || '' }
						>
							Watch a Demo
						</LoadingButton>
					</Box>
					<Box className={ styles.item }>
						<Typography variant='h6'>Free account set up</Typography>
						<Typography>
							Take advantage of our free white glove onboarding service. Our
							team does the heavy lifting of getting your contracts & invoices
							set up, creating templates, shifting clients over, and more!
						</Typography>
						<LoadingButton
							LinkComponent={ Link }
							variant='contained'
							className={ styles.button }
							target='_blank'
							rel='noreferrer'
							loading={ globalVariableLoading }
							href={ globalVariableData?.getGlobalVariables.WhiteGloveUrl || '' }
						>
							Get Started
						</LoadingButton>
					</Box>
					{ !hasPayToAccount ? (
						<Box className={ styles.item }>
							<Typography variant='h6'>Set up your wallet</Typography>
							<Typography>
								Sending contracts and invoices is as easy as 1, 2, 3. Enter in
								your bank information so we can deposit funds into your bank
								account.
							</Typography>
							<LoadingButton
								LinkComponent={ Link }
								variant='contained'
								className={ styles.button }
								href='/wallet'
								onClick={ ( e ) => onClose?.( e, 'fromCode' ) }
							>
								Get Paid
							</LoadingButton>
						</Box>
					) : null }
				</Box>
			) }
		</MuiModal>
	);
};
