import React from 'react';
import { useRouter } from 'next/router';
import ErrorPage from './ErrorPage';
import { IncomingMessage } from 'http';
import Head from 'next/head';

/* there's a bug that an upgrade of NextJS might fix
https://github.com/vercel/next.js/issues/52266 */
const workAroundNextJsDoubleSetBug = ( value: string | string[] | undefined ) =>
	typeof value === 'string'
		? value.indexOf( ',' )
			? value.split( ',' )[ 0 ]
			: value
		: typeof value === 'undefined'
			? value
			: value[ 0 ];

/* to avoid circular reference, since we can't pass in the request, here's a helper to get what we
need from the request. Also, since most of these calls are getInitialProps, on the non-initial
page-view the req object is undefined, so we need to get hostname and protocol from another place */
export const getHostnameAndProtocolFromRequest = ( request?: IncomingMessage ) =>
	request
		? `${ workAroundNextJsDoubleSetBug(
			request.headers[ 'x-forwarded-proto' ]
		  ) }://${ workAroundNextJsDoubleSetBug( request.headers[ 'x-forwarded-host' ] ) }`
		: typeof window !== 'undefined'
			? window.location.origin
			: undefined;

export const capitalize = ( term: string | string[] ) =>
	[ ...( typeof term === 'string' ? [ term ] : term ) ]
		.map( ( term ) => `${ term.charAt( 0 ).toUpperCase() }${ term.slice( 1 ) }` )
		.join( ' ' );

export type PageHeadBaseProps = {
	hostnameAndProtocol?: string;
};

export type PageHeadProps = PageHeadBaseProps & {
	title: string;
	path: string;
};

export const PageHead: React.FC<PageHeadProps> = ( {
	hostnameAndProtocol,
	title,
	path,
} ) => (
	<Head>
		<title>{ title } - Rock Paper Coin</title>
		<link rel='canonical' href={ `${ hostnameAndProtocol }${ path }` } />
	</Head>
);

export type PreGetServerSidePropsType<T extends Record<string, any>> =
	| { notFound: true }
	| { notAuthorized: true }
	| { redirect: { destination: string; permanent: boolean } }
	| { props: T };

export const HandlePreGetServerSideProps = <T extends Record<string, any>>( {
	propsFromGetInitialProps,
	ComponentWithoutProps,
}: {
	propsFromGetInitialProps: PreGetServerSidePropsType<T>;
	ComponentWithoutProps: React.ComponentType<T>;
} ) => {
	const router = useRouter();
	if ( 'redirect' in propsFromGetInitialProps ) {
		router.push( propsFromGetInitialProps.redirect.destination );
		return <></>;
	}
	if (
		'notFound' in propsFromGetInitialProps ||
		'notAuthorized' in propsFromGetInitialProps
	) {
		return (
			<ErrorPage
				statusCode={ 'notAuthorized' in propsFromGetInitialProps ? 401 : 404 }
			/>
		);
	}

	return <ComponentWithoutProps { ...propsFromGetInitialProps.props } />;
};

//utility to strip the signed url params off of the url returned from api
export const stripSignedParamsFromUrl = ( name: string | null | undefined ) => {
	if ( typeof name === 'string' && name.indexOf( '?' ) !== -1 ) {
		return name.slice( 0, name.lastIndexOf( '?' ) );
	}
	return name;
};

export const getFileExtension = ( url: string | null | undefined ) => {
	if ( typeof url === 'string' ) {
		url = stripSignedParamsFromUrl( url );
		if ( url?.lastIndexOf( '.' ) !== -1 ) {
			return url?.slice( url.lastIndexOf( '.' ), url.length );
		}
	}
};
