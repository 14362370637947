import axios, { AxiosRequestConfig } from 'axios';
import {
	applyRequestLogInterceptorsToAxiosInstance,
	getVersion,
} from '../../helpers';
interface ServerlessError extends Error {
	response: {
		data: string;
	};
}
/**
 * This function essentially exists to implement product desired behavior
 * which is that when the API returns an error relating to version headers
 * that the app should attempt to do a serverside reload
 * assuming that the request was made with an older version of the app
 * or no header was submitted
 */
const rejectionHandler = ( error: any ) => {
	if ( error instanceof Error ) {
		const errorMessage = ( error as ServerlessError ).response.data;
		const badStrings = [ 'No RPC-AppVersion header submitted.', 'RPC-AppVersion header mismatch.', ];
		badStrings.forEach( ( errorString ) => {
			if ( errorMessage === errorString && typeof document !== 'undefined' ) {
				document.location.reload();
			}
		} );
	}
};

/**
 * this function should apply standard headers and interceptors
 * to axios headers
 *
 * @param { AxiosRequestConfig } options
 * @returns { AxiosInstance }
 */
export const createAxiosInstance = ( options: AxiosRequestConfig ) => {
	const axiosInstance = axios.create( {
		...options,
		headers: {
			...options?.headers,
			'Content-Type': 'application/json',
			'rpc-appversion': getVersion(),
		},
	} );
	axiosInstance.interceptors.response.use(
		( response ) => response,
		rejectionHandler
	);
	applyRequestLogInterceptorsToAxiosInstance( axiosInstance );
	return axiosInstance;
};
