/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const UnorderedList = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.85718 6.28585C7.85718 5.81247 8.24093 5.42871 8.71432 5.42871H21.1393C21.6127 5.42871 21.9965 5.81247 21.9965 6.28585C21.9965 6.75924 21.6127 7.143 21.1393 7.143H8.71432C8.24093 7.143 7.85718 6.75924 7.85718 6.28585Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.86078 12.0002C7.86078 11.5268 8.24453 11.1431 8.71792 11.1431H21.1429C21.6163 11.1431 22.0001 11.5268 22.0001 12.0002C22.0001 12.4736 21.6163 12.8574 21.1429 12.8574H8.71792C8.24453 12.8574 7.86078 12.4736 7.86078 12.0002Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.85718 17.7141C7.85718 17.2407 8.24093 16.8569 8.71432 16.8569H21.1393C21.6127 16.8569 21.9965 17.2407 21.9965 17.7141C21.9965 18.1875 21.6127 18.5712 21.1393 18.5712H8.71432C8.24093 18.5712 7.85718 18.1875 7.85718 17.7141Z'
		/>
		<path d='M4.0332 7.78564C4.86162 7.78564 5.5332 7.11406 5.5332 6.28564C5.5332 5.45722 4.86162 4.78564 4.0332 4.78564C3.20478 4.78564 2.5332 5.45722 2.5332 6.28564C2.5332 7.11406 3.20478 7.78564 4.0332 7.78564Z' />
		<path d='M4.0332 19.2144C4.86162 19.2144 5.5332 18.5428 5.5332 17.7144C5.5332 16.8859 4.86162 16.2144 4.0332 16.2144C3.20478 16.2144 2.5332 16.8859 2.5332 17.7144C2.5332 18.5428 3.20478 19.2144 4.0332 19.2144Z' />
		<path d='M4.0332 13.5C4.86162 13.5 5.5332 12.8284 5.5332 12C5.5332 11.1716 4.86162 10.5 4.0332 10.5C3.20478 10.5 2.5332 11.1716 2.5332 12C2.5332 12.8284 3.20478 13.5 4.0332 13.5Z' />
	</svg>
);
export default UnorderedList;
