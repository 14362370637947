import Link, { LinkProps } from 'next/link';
import React from 'react';
import styles from './ExtendedLink.module.scss';

type ExtendedLinkProps = {
	disabled?: boolean;
	loading?: boolean;
	href?: string | null;
} & Omit<LinkProps, 'href'> &
Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

const ExtendedLink: React.FC<ExtendedLinkProps> = ( props ) => {
	const { disabled, loading, className, children, href, ...spreadableProps } =
		props;
	if ( disabled || loading ) {
		return (
			<button
				className={ [
					className,
					styles.button,
					...( loading ? [ styles.loading ] : [] ),
				].join( ' ' ) }
				onClick={ ( e ) => {
					e.preventDefault();
					e.stopPropagation();
				} }
			>
				{ children }
			</button>
		);
	} else {
		if ( !href ) {
			return null;
		}
		return (
			<Link className={ className } href={ href } { ...spreadableProps }>
				{ children }
			</Link>
		);
	}
};

export default ExtendedLink;
