import React from 'react';
import { Typography } from '@mui/material';

import {
	DefaultEventProps,
	userIsActingUserOnEvent,
	UserForContractSentEventFeed,
} from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';
import { SignatureType } from '../../../types/generated';

const getContractResentToCountersignerEventProps = (
	defaultEventProps: DefaultEventProps,
	user: UserForContractSentEventFeed
) => {
	if (
		!defaultEventProps.event.contract ||
		typeof defaultEventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = defaultEventProps;
	defaultEventProps.icon = 'contract';
	if ( userIsActingUserOnEvent( event, user ) ) {
		// you are the resender
		const countersignature = event.contract?.signatures?.find(
			( signature ) => signature?.type === SignatureType.CounterSigner
		);

		if (
			!countersignature ||
			!countersignature.signer ||
			!countersignature.signer.orgUser
		) {
			throw new Error( 'No countersignature found' );
		}
		defaultEventProps.message = (
			<Typography>
				You resent { linkObject( defaultEventProps.event.contract ) } to{ ' ' }
				{ linkObject( countersignature.signer.orgUser ) }.
			</Typography>
		);
		defaultEventProps.links = [
			{
				type: 'secondary',
				text: 'View',
				link: getDetailLink( {
					object: defaultEventProps.event.contract,
					type: LinkObjectType.Contract,
				} ),
				className: 'eventRow-contract-resent__sign-btn',
			},
		];
	} else {
		// you are the countersigner
		defaultEventProps.message = (
			<Typography>
				{ linkObject( defaultEventProps.event.contract ) } has been resent by{ ' ' }
				{ linkObject( event.vendor ) }.
			</Typography>
		);
		defaultEventProps.links = [
			{
				type: event.resolvedBy ? 'secondary' : 'primary',
				text: event.resolvedBy ? 'View' : 'Sign',
				link: getDetailLink( {
					object: defaultEventProps.event.contract,
					type: LinkObjectType.Contract,
				} ),
				className: 'eventRow-contract-resent__sign-btn',
			},
		];
	}

	return defaultEventProps;
};

export default getContractResentToCountersignerEventProps;
