/* eslint max-len: 0 */
const Unchecked = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm0 1.5c4.556 0 8.25 3.694 8.25 8.25s-3.694 8.25-8.25 8.25S3.75 16.556 3.75 12 7.444 3.75 12 3.75z'
			transform='translate(-184 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241) translate(40)'
		/>
	</svg>
);

export default Unchecked;
