import React from 'react';
import { Typography } from '@mui/material';
import {
	UserForContractSentEventFeed,
	userIsAssignedMemberOrVendorAdminOnContractEvent,
	getCustomerLinksAsVendorOrAPPAForContractEvent,
	DefaultEventProps,
	userIsCustomerOnContractEvent,
	userIsAPPAOnContractEvent,
} from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

const getContractSignedByPlannerEventProps = (
	eventProps: DefaultEventProps,
	user: UserForContractSentEventFeed
) => {
	if (
		!eventProps.event.contract ||
		typeof eventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = eventProps;
	eventProps.icon = 'contract-signed';
	eventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: eventProps.event.contract,
				type: LinkObjectType.Contract,
			} ),
		},
	];
	if ( userIsCustomerOnContractEvent( event, user ) ) {
		// you are the customer viewing the event where your planner signed for you
		eventProps.message = (
			<Typography>
				Your planner has signed { linkObject( eventProps.event.contract ) } on your
				behalf.
			</Typography>
		);
	} else if ( userIsAssignedMemberOrVendorAdminOnContractEvent( event, user ) ) {
		// you are the person who sent the document
		// you are the assigned member to one of the customers on the contract
		// you are an admin of the vendor org
		eventProps.message = (
			<Typography>
				{ linkObject( eventProps.event.contract ) } has received a signature for{ ' ' }
				{ getCustomerLinksAsVendorOrAPPAForContractEvent( event, user, false ) }.
			</Typography>
		);
	} else if ( userIsAPPAOnContractEvent( event, user ) ) {
		// you are APPA and have signed for this customer
		eventProps.message = (
			<Typography>
				You signed { linkObject( eventProps.event.contract ) } on behalf of{ ' ' }
				{ getCustomerLinksAsVendorOrAPPAForContractEvent( event, user, false ) }.
			</Typography>
		);
	} else {
		//eslint-disable-next-line max-len
		throw new Error(
			`getContractResentEventProps unable to determine user role.\n event:\n ${ JSON.stringify(
				event,
				null,
				2
			) }\n user: \n ${ user } `
		);
	}
	return eventProps;
};

export default getContractSignedByPlannerEventProps;
