// Packages
import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

// Markup
import Button from '../../../elements/Button';
import Logo from '../../../elements/Logo2.jsx';
import { HelpButton } from '../../../mui/HelpButton/HelpButton';

import useIsLoggedIn from '../../../hooks/useIsLoggedIn';
import useIsOnBoardingRoute from '../../../hooks/useIsOnBoardingRoute';
import AvatarContainer from '../../../mui/Avatar/AvatarContainer';
import { BurgerIcon, CloseIcon } from '../../../mui/Icons';
import styles from './Header.module.scss';
import { useDeviceDetection } from '../../../hooks/useDeviceDetection';
import { EventNotifications } from './EventNotifications';
import { FlattenedUser } from '../../../types/user';
import { useSelector } from 'react-redux';
import { UserType } from '../../../types/generated';

export type HeaderProps = {
	isActiveMobileMenu: boolean;
	toggleMobileMenu: ( active: boolean ) => void;
};

const Header: React.FC<HeaderProps> = ( {
	toggleMobileMenu,
	isActiveMobileMenu,
} ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const { isDesktop } = useDeviceDetection();
	const isOnBoardingRoute = useIsOnBoardingRoute();
	const isLoggedIn = useIsLoggedIn();
	const router = useRouter();
	const { route: currentRoute } = router;

	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;

	return (
		<Box component='header' className={ styles.root }>
			<Link
				href='/'
				tabIndex={ 1 }
				className={ styles.logo }
				data-cy='headerLogo'
				passHref
				onClick={ () => toggleMobileMenu( false ) }
			>
				<Logo />
			</Link>
			<div className={ styles.rightControls }>
				{ !isLoggedIn && currentRoute === '/Login' && (
					<Button
						className={ classNames( [ 'button--blue', styles.button ] ) }
						text='Sign Up'
						link={ { href: '/SignUp', as: '/SignUp' } }
					/>
				) }
				{ !isLoggedIn && currentRoute === '/SignUp' && !isOnBoardingRoute && (
					<Button
						className={ classNames( [ 'button--blue', styles.button ] ) }
						text='Sign In'
						link={ { href: '/Login', as: '/Login' } }
					/>
				) }

				{ isLoggedIn && !isOnBoardingRoute && (
					<Stack direction='row' alignItems='center' spacing={ 1 }>
						{ orgUser ? <EventNotifications /> : null }
						{ isDesktop ? (
							<>
								<AvatarContainer />
								<HelpButton />
							</>
						) : (
							<div className={ styles.mobileToggleWrap }>
								{ isActiveMobileMenu ? (
									<IconButton
										className={ styles.mobileCloseButton }
										onClick={ () => toggleMobileMenu( false ) }
									>
										<CloseIcon />
									</IconButton>
								) : (
									<IconButton
										className={ styles.mobileOpenButton }
										onClick={ () => toggleMobileMenu( true ) }
									>
										<BurgerIcon />
									</IconButton>
								) }
							</div>
						) }
					</Stack>
				) }
			</div>
		</Box>
	);
};

export default Header;
