import { Box } from '@mui/material';
import { Editor } from '@tiptap/react';
import React, { useRef } from 'react';
import MuiTipTapMenuElements from '../MuiTipTapMenuElements';
import styles from './MuiTipTapMobileMenu.module.scss';
import useIntersection from '../../../../hooks/useIntersection';

export type MuiTipTapMobileMenuProps = {
	editor: Editor | null;
	onBlur: () => void;
	onFocus: () => void;
};

const MuiTipTapMobileMenu: React.FC<MuiTipTapMobileMenuProps> = ( {
	editor,
	onFocus,
	onBlur,
} ) => {
	const containerRef = useRef<HTMLDivElement>( null );
	const elementRef = useRef<HTMLDivElement>( null );
	const { intersects } = useIntersection(
		containerRef.current?.parentNode as HTMLElement | undefined | null,
		{ rootMargin: '0px 0px -72px' } // I hate that you get an error if 0 and not 0px!
	);
	if ( !editor ) {
		return null;
	}
	return (
		<Box
			className={ [ styles.container, ...( !intersects ? [ styles.freeze ] : [] ), ].join( ' ' ) }
			ref={ containerRef }
		>
			<Box className={ styles.alignment } ref={ elementRef }>
				<Box className={ styles.shadow }>
					<MuiTipTapMenuElements
						editor={ editor }
						onFocus={ onFocus }
						onBlur={ onBlur }
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default MuiTipTapMobileMenu;
