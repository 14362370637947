import Image from 'next/image';
import styled from 'styled-components';
import image from '../../public/icons/StripeLogo-slate.svg';

const StripeLogoImage = styled.span`
	&& {
		display: inline-block;
		vertical-align: top;
		margin: 2px -3px -2px;
	}
`;

const StripeLogo = () => (
	<StripeLogoImage>
		<Image src={ image.src } alt='Stripe Logo' height={ 24 } width={ 48 } />
	</StripeLogoImage>
);

export default StripeLogo;
