import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function InquiriesIcon( props: SvgIconProps ) {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{ ...props }
		>
			<path
				d='M18.1833 23C18.1833 22.5858 18.5191 22.25 18.9333 22.25H21.5833V19.6C21.5833 19.1858 21.9191 18.85 22.3333 18.85C22.7475 18.85 23.0833 19.1858 23.0833 19.6V22.25H25.7333C26.1475 22.25 26.4833 22.5858 26.4833 23C26.4833 23.4143 26.1475 23.75 25.7333 23.75H23.0833V26.4C23.0833 26.8143 22.7475 27.15 22.3333 27.15C21.9191 27.15 21.5833 26.8143 21.5833 26.4V23.75H18.9333C18.5191 23.75 18.1833 23.4143 18.1833 23Z'
				fill='#203A60'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.3334 30.6667C26.5675 30.6667 30 27.2342 30 23C30 18.7658 26.5675 15.3333 22.3334 15.3333C18.0992 15.3333 14.6667 18.7658 14.6667 23C14.6667 27.2342 18.0992 30.6667 22.3334 30.6667ZM22.3334 29.1667C25.7391 29.1667 28.5 26.4058 28.5 23C28.5 19.5943 25.7391 16.8333 22.3334 16.8333C18.9276 16.8333 16.1667 19.5943 16.1667 23C16.1667 26.4058 18.9276 29.1667 22.3334 29.1667Z'
				fill='#203A60'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.4066 1.5C9.40419 1.5 6.97028 3.93391 6.97028 6.93629C6.97028 9.93868 9.40419 12.3726 12.4066 12.3726C15.409 12.3726 17.8429 9.93868 17.8429 6.93629C17.8429 3.93391 15.409 1.5 12.4066 1.5ZM8.47028 6.93629C8.47028 4.76234 10.2326 3 12.4066 3C14.5805 3 16.3429 4.76234 16.3429 6.93629C16.3429 9.11025 14.5805 10.8726 12.4066 10.8726C10.2326 10.8726 8.47028 9.11025 8.47028 6.93629Z'
				fill='#203A60'
			/>
			<path
				d='M6.86589 17.0694C8.48294 15.4523 10.6761 14.5439 12.963 14.5439C13.6717 14.5439 14.1334 14.5594 14.5334 14.6334C14.9118 14.7034 15.2573 14.8306 15.7033 15.0897C16.0614 15.2978 16.5205 15.1761 16.7285 14.818C16.9366 14.4598 16.815 14.0008 16.4568 13.7927C15.8856 13.4609 15.3788 13.2644 14.8062 13.1584C14.2551 13.0565 13.6669 13.0439 12.963 13.0439C10.2783 13.0439 7.70358 14.1103 5.80523 16.0087C3.90688 17.9071 2.84039 20.4818 2.84039 23.1665C2.84039 23.5807 3.17618 23.9165 3.59039 23.9165C4.00461 23.9165 4.34039 23.5807 4.34039 23.1665C4.34039 20.8796 5.24884 18.6864 6.86589 17.0694Z'
				fill='#203A60'
			/>
		</svg>
	);
}
