import * as Sentry from '@sentry/nextjs';
import { isEmpty } from 'lodash';
import rpcShared from '@rockpapercoin/rpc-shared';

const errorReporting = {
	/**
	 * Captures an error in Sentry
	 *
	 * @param { unknown } error - The error to capture
	 * @param { (Object|Null) } [user] - The user object
	 * @param { Object } [extra] - The additional scope to set
	 * @return { Void }
	 */
	captureErrorInSentry: ( error, user, extra ) => {
		if (
			( error instanceof Error ||
				rpcShared.errorFiltering.isSomeKindOfStripeErrorTypeGuard( error ) ) &&
			rpcShared.errorFiltering.errorIsValidationError( error )
		) {
			return;
		} else {
			Sentry.withScope( ( scope ) => {
				if ( user ) {
					scope.setUser( { id: user.userID } );
					scope.setExtra( 'CurrentUser', user );
				}
				if ( extra && !isEmpty( extra ) ) {
					Object.keys( extra ).forEach( ( key ) => {
						scope.setExtra( key, extra[ key ] );
					} );
				}
				Sentry.captureException( error );
			} );
		}
	},
};

export default errorReporting;
