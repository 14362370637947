import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import styles from './MuiSpinner.module.scss';

const MuiSpinner: React.FC<CircularProgressProps> = ( props ) => {
	const { className, ...otherProps } = props;
	return (
		<Box className={ [ styles.container, className ].join( ' ' ) }>
			<CircularProgress
				variant='determinate'
				className={ styles.background }
				thickness={ 4 }
				{ ...otherProps }
				value={ 100 }
			/>
			<CircularProgress
				variant='indeterminate'
				disableShrink
				className={ styles.foreground }
				thickness={ 4 }
				{ ...otherProps }
			/>
		</Box>
	);
};

export default MuiSpinner;
