import createProposal from './mutations/createProposal';
import updateProposal from './mutations/updateProposal';
import getProposalWhere from './queries/getProposalWhere';
import getProposalsWhere from './queries/getProposalsWhere';
import searchProposals from './queries/searchProposals';
import voidProposal from './mutations/voidProposal';
import acceptProposal from './mutations/acceptProposal';
import sendProposal from './mutations/sendProposal';
import createProposalTemplate from './mutations/createProposalTemplate';
import updateProposalTemplate from './mutations/updateProposalTemplate';
import getProposalTemplatesWhere from './queries/getProposalTemplatesWhere';
import getProposalTemplateWhere from './queries/getProposalTemplateWhere';
import deleteProposalTemplate from './mutations/deleteProposalTemplate';

export {
	createProposal,
	updateProposal,
	getProposalWhere,
	getProposalsWhere,
	voidProposal,
	acceptProposal,
	searchProposals,
	sendProposal,
	createProposalTemplate,
	updateProposalTemplate,
	getProposalTemplatesWhere,
	getProposalTemplateWhere,
	deleteProposalTemplate,
};
