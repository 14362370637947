import React from 'react';
import P from '../../../elements/Paragraph';
import {
	linkObject,
	getDetailLink,
	toSimpleLink,
} from '../../../lib/helpers/index';
import { InvoicesIcon } from '../../../mui/Icons/index';
import { linkCustomer } from '../helpers';
import rpcShared from '@rockpapercoin/rpc-shared';
import { LinkObjectType } from '../../../lib/helpers/types';

export default function quickPaymentPaidEventProps(
	defaultEventProps: {
		event: any;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	},
	user: any
) {
	const updatedEventProps = { ...defaultEventProps };
	const { event } = updatedEventProps;

	const quickPaymentLink = toSimpleLink( {
		link: getDetailLink( { object: event.invoice } ),
		text: 'quick payment',
	} );

	const vendorOBOLink = toSimpleLink( {
		link: getDetailLink( {
			object: event.vendor,
			type: LinkObjectType.Organization,
			params: { client: event.contact.customer.clientUser?.id },
		} ),
		text: event.vendor.name,
	} );

	const customerLink = linkCustomer( event );
	const vendorLink = linkObject( event.vendor );

	updatedEventProps.icon = <InvoicesIcon />;

	updatedEventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: updatedEventProps.event.invoice,
				type: LinkObjectType.Invoice,
			} ),
		},
	];

	if (
		rpcShared.contacts.isAssignedPlannerOrAdminOfCustomersPlannerOrg(
			defaultEventProps.event.contact,
			user
		)
	) {
		updatedEventProps.message = (
			<P className='p--secondary'>
				{ customerLink } paid a { quickPaymentLink } to { vendorOBOLink }.
			</P>
		);
	}

	if (
		rpcShared.contacts.isMemberOfVendor( defaultEventProps.event.contact, user )
	) {
		updatedEventProps.message = (
			<P className='p--secondary'>
				{ customerLink } sent you a { quickPaymentLink }.
			</P>
		);
	}

	if (
		rpcShared.contacts.isCustomer( defaultEventProps.event.contact, user.user.id )
	) {
		updatedEventProps.message = (
			<P className='p--secondary'>
				You paid a { quickPaymentLink } to { vendorLink }.
			</P>
		);
	}

	return updatedEventProps;
}
