import { BundleStatusEnum } from '../../../types/generated';
import Globals from '../../Globals';
import { getCookie } from '../getCookie';
import { BundleCookie } from './bundleCookieType';

export const getContactIdFromBundleCookieByDocumentType = (
	cookies: string,
	documentStatusType:
	| 'proposalBundleStatus'
	| 'contractBundleStatus'
	| 'invoiceBundleStatus'
): string | undefined => {
	const documentCookie = getCookie( `${ Globals.DocumentBundle }=`, cookies );
	if ( documentCookie ) {
		const cookieData: BundleCookie = JSON.parse( documentCookie );
		if (
			cookieData.contactID &&
			cookieData[ documentStatusType ] === BundleStatusEnum.Awaiting
		) {
			return cookieData.contactID;
		}
	}
};
