/* eslint max-len: 0 */
const Void = () => (
	<svg height='24' viewBox='0 0 24 24'>
		<path
			d='M11.998 0c3.31 0 6.307 1.34 8.478 3.508l.006.007c2.175 2.177 3.516 5.174 3.516 8.485 0 3.31-1.34 6.308-3.509 8.48-.002 0-.004.003-.007.005C18.305 22.66 15.308 24 11.998 24c-3.307 0-6.302-1.338-8.472-3.502l-.013-.013-.012-.012C1.336 18.303-.002 15.308-.002 12c0-3.307 1.338-6.302 3.502-8.472l.013-.013.012-.012C5.695 1.338 8.69 0 11.998 0zm-.001 13.061l-6.875 6.875c1.842 1.597 4.246 2.564 6.876 2.564s5.033-.966 6.875-2.563l-6.876-6.876zM4.062 5.124C2.465 6.966 1.498 9.37 1.498 12s.967 5.034 2.564 6.876L10.936 12zm15.872 0L13.058 12l6.876 6.877c1.54-1.776 2.493-4.073 2.56-6.592l.004-.284c0-2.63-.967-5.034-2.564-6.876zM11.998 1.5c-2.63 0-5.034.967-6.876 2.564l6.875 6.874 6.876-6.875c-1.776-1.539-4.073-2.492-6.59-2.56z'
			transform='translate(-344 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(200)'
		/>
	</svg>
);

export default Void;
