/* eslint max-len: 0 */
const More = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='26'
			viewBox='0 0 24 26'
		>
			<g fill='none' fillRule='evenodd'>
				<g fill='#203A60' fillRule='nonzero'>
					<path
						d='M3.375 8.864c1.864 0 3.375 1.549 3.375 3.46 0 1.91-1.511 3.459-3.375 3.459S0 14.234 0 12.323c0-1.91 1.511-3.459 3.375-3.459zm15.563.464c1.044-.618 2.33-.618 3.375 0 1.044.618 1.687 1.76 1.687 2.996 0 1.235-.643 2.378-1.688 2.995-1.044.618-2.33.618-3.375 0-1.044-.617-1.687-1.76-1.687-2.995 0-1.236.643-2.378 1.688-2.996zM12 8.864c1.864 0 3.375 1.549 3.375 3.46 0 1.91-1.511 3.459-3.375 3.459s-3.375-1.549-3.375-3.46c0-1.91 1.511-3.459 3.375-3.459zm-8.625 1.538c-1.036 0-1.875.86-1.875 1.922 0 1.061.84 1.921 1.875 1.921 1.036 0 1.875-.86 1.875-1.921 0-1.062-.84-1.922-1.875-1.922zm18.188.257c-.58-.343-1.295-.343-1.875 0-.58.343-.938.978-.938 1.665 0 .686.357 1.32.938 1.664.58.343 1.294.343 1.875 0 .58-.343.937-.978.937-1.664 0-.687-.357-1.322-.938-1.665zM12 10.402c-1.036 0-1.875.86-1.875 1.922 0 1.061.84 1.921 1.875 1.921 1.036 0 1.875-.86 1.875-1.921 0-1.062-.84-1.922-1.875-1.922z'
						transform='translate(-1222 -1844) translate(170 258) translate(0 1253) translate(0 55) translate(0 250) rotate(90 523.65 552.674)'
					/>
				</g>
			</g>
		</svg>
	);
};

export default More;
