import React from 'react';
import MuiModal from '../../mui/MuiModal/MuiModal';
import Link from 'next/link';
import styles from './PremiumSubscriptionRequiredModal.module.scss';
import { Typography } from '@mui/material';

export type PremiumSubscriptionRequiredModalProps = {
	open: boolean;
	onClose: () => void;
};

export const PremiumSubscriptionRequiredModal: React.FC<
PremiumSubscriptionRequiredModalProps
> = ( { open, onClose } ) => (
	<MuiModal
		muiModalProps={ {
			open,
			onClose,
			PaperProps: { 'data-cy': 'premium-subscription-required' },
		} }
		title='Premium subscription required'
		description={
			<>
				<Typography component='span' className={ styles.paragraph }>
					A Premium subscription is required to access this feature.
				</Typography>
				<Typography component='span' className={ styles.paragraph }>
					You can purchase a Premium subscription on the{ ' ' }
					<Link
						className='legacyLink'
						href='/admin?tab=billing'
						onClick={ onClose }
					>
						Billing tab of your admin page
					</Link>
					, or get in touch with one of our team members for assistance.
				</Typography>
			</>
		}
	/>
);
