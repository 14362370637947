import routes, { Route } from '../Routes';

type RouteWithObjectId = Route & { objectId: string };

/**
 * Checks if the url part is an ind
 * */
const isId = ( str?: string ) => {
	if ( !str ) return false;

	if ( [ 'edit', 'template' ].includes( str ) ) {
		return false;
	}
	return /\d/.test( str ) && str.length >= 25;
};

const getSegmentRoute = (
	urlPathParts: string[],
	route?: Route
): Route | RouteWithObjectId | undefined => {
	if ( urlPathParts.length === 0 ) {
		throw new Error( 'getSegmentRoute called with insufficient arguments' );
	}
	const urlPart = urlPathParts[ 0 ];
	const searchableRoutes = route ? route.nested : routes;
	const newRoute = searchableRoutes?.find(
		( route ) => route.url === `/${ urlPart }`
	);
	const idInSearchableRoutes = searchableRoutes?.find(
		( route ) => route.url === '/id'
	);
	if ( newRoute ) {
		if ( urlPathParts.length === 1 ) {
			return newRoute;
		} else {
			return getSegmentRoute( urlPathParts.slice( 1 ), newRoute );
		}
	} else if ( isId( urlPart ) && idInSearchableRoutes ) {
		const idRoute: RouteWithObjectId = {
			...idInSearchableRoutes,
			objectId: urlPart,
		};
		return idRoute;
	}
};

/**
 * Gets the page route object for a provided url
 * */
export const getSegmentRouteFromUrl = ( url?: string ) => {
	if ( url ) {
		const urlWithoutQuery = url.indexOf( '?' ) ? url.split( '?' )[ 0 ] : url;
		const urlPathParts =
			urlWithoutQuery === '/' ? [ '/' ] : urlWithoutQuery.split( '/' ).slice( 1 );
		return getSegmentRoute( urlPathParts );
	}
};
