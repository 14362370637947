import React from 'react';
import { useSelector } from 'react-redux';

import useMenu from '../../hooks/useMenu';
import AvatarComponent from './AvatarComponent';
import useProfileLinks from '../../config/useProfileLinks';

export default function AvatarContainer() {
	const user = useSelector( ( state: any ) => state.user );
	const menu = useMenu();
	const items = useProfileLinks();

	return <AvatarComponent user={ user } menu={ menu } items={ items } />;
}
