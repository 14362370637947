import React, {
	MutableRefObject,
	useCallback,
	useEffect,
	useLayoutEffect,
} from 'react';

export const identifierAttribute = 'data-error-id';
const stickyHeaderOffsetPixels = 72;

type RefType = MutableRefObject<HTMLElement | null>;

type ErrorScrollContextProps = {
	add: ( ref: RefType ) => void;
	remove: ( ref: RefType ) => void;
};

type ErrorScrollContextProviderProps = {
	stickyHeaderOffset?: boolean;
	children?: React.ReactNode;
};

export const ErrorScrollContext =
	React.createContext<ErrorScrollContextProps | null>( null );

const ErrorScrollContextProvider: React.FC<ErrorScrollContextProviderProps> = ( {
	stickyHeaderOffset,
	children,
} ) => {
	// The state container for the list of error refs
	const [ refs, setRefs ] = React.useState<RefType[]>( [] );

	const absoluteOffsetTop = useCallback(
		( el: HTMLElement | null ) => {
			if ( el ) {
				const rect = el.getBoundingClientRect(),
					scrollTop = window.pageYOffset || document.body.scrollTop;
				return (
					rect.top +
					scrollTop -
					( stickyHeaderOffset ? stickyHeaderOffsetPixels : 0 )
				);
			} else {
				return 0;
			}
		},
		[ stickyHeaderOffset ]
	);

	useLayoutEffect( () => {
		if ( refs.length ) {
			const refsSortedByYDescending = [ ...refs ].sort(
				( ref1, ref2 ) =>
					absoluteOffsetTop( ref1.current ) - absoluteOffsetTop( ref2.current )
			);
			const topmostErrorPosition = refsSortedByYDescending[ 0 ].current;
			if ( topmostErrorPosition ) {
				const top = absoluteOffsetTop( topmostErrorPosition );
				document.body.scrollTo( {
					behavior: 'smooth',
					top,
				} );
			}
		}
	}, [ absoluteOffsetTop, refs ] );

	useEffect( () => {
		document.body.scrollTo( {
			behavior: 'smooth',
			top: 0,
		} );
	}, [] );

	const compare = useCallback(
		( ref1: RefType, ref2: RefType ) =>
			ref1.current?.getAttribute( identifierAttribute ) ===
			ref2.current?.getAttribute( identifierAttribute ),
		[]
	);

	const add = useCallback(
		( ref: RefType ) =>
			setRefs( ( prevState ) => {
				const index = prevState.findIndex( ( stateRef ) => compare( stateRef, ref ) );
				if ( index !== -1 ) {
					return prevState;
				} else {
					return [ ...prevState, ref ];
				}
			} ),
		[ compare ]
	);

	const remove = useCallback(
		( ref: RefType ) =>
			setRefs( ( prevState ) => {
				const index = prevState.findIndex( ( stateRef ) => compare( stateRef, ref ) );
				if ( index !== -1 ) {
					return prevState?.filter( ( stateRef ) => !compare( stateRef, ref ) );
				} else {
					return prevState;
				}
			} ),
		[ compare ]
	);

	const value: ErrorScrollContextProps = {
		add,
		remove,
	};
	return (
		<ErrorScrollContext.Provider value={ value }>
			{ children }
		</ErrorScrollContext.Provider>
	);
};

export default ErrorScrollContextProvider;
