import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function InvoicesIcon( props: SvgIconProps ) {
	return (
		<SvgIcon viewBox='0 0 21 24' { ...props }>
			<path d='M5.5982 6.15266C5.89183 5.94938 6.23282 5.80704 6.58824 5.72629V5.41176C6.58824 5.02192 6.90427 4.70588 7.29412 4.70588C7.68397 4.70588 8 5.02192 8 5.41176V5.72133C8.77979 5.8947 9.25992 6.33801 9.64052 6.68943L9.65526 6.70303C9.94172 6.96746 9.95958 7.41404 9.69516 7.70051C9.43073 7.98697 8.98415 8.00483 8.69769 7.7404C8.24217 7.31992 7.94661 7.05882 7.29412 7.05882C6.94845 7.05882 6.62222 7.1608 6.4018 7.3134C6.18977 7.46019 6.11765 7.61628 6.11765 7.76471C6.11765 7.80888 6.12302 7.90404 6.3843 8.07362C6.6583 8.25146 7.04794 8.40051 7.53918 8.58237L7.59051 8.60136C8.02833 8.76326 8.55358 8.95749 8.96714 9.22061C9.4071 9.50052 9.88235 9.96371 9.88235 10.6968C9.88235 11.4317 9.46793 11.9617 8.95819 12.2754C8.66843 12.4537 8.3382 12.5714 8 12.638V12.9412C8 13.331 7.68397 13.6471 7.29412 13.6471C6.90427 13.6471 6.58824 13.331 6.58824 12.9412V12.6203C5.76248 12.4166 5.20619 11.9024 4.95023 11.6658L4.93298 11.6499C4.64652 11.3855 4.62865 10.9389 4.89308 10.6524C5.15751 10.366 5.60409 10.3481 5.89055 10.6125C6.20676 10.9044 6.65273 11.2941 7.29412 11.2941C7.66984 11.2941 8.00356 11.2052 8.21828 11.073C8.41442 10.9523 8.47059 10.8307 8.47059 10.6968L8.47059 10.6955C8.47067 10.6668 8.47091 10.5782 8.20933 10.4117C7.93331 10.2361 7.54265 10.089 7.04906 9.90632L7.02166 9.89618C6.57688 9.73158 6.03885 9.53246 5.6157 9.25783C5.1711 8.96926 4.70588 8.49881 4.70588 7.76471C4.70588 7.04435 5.10434 6.49457 5.5982 6.15266Z' />
			<path d='M11.0588 12.2353C10.669 12.2353 10.3529 12.5513 10.3529 12.9412C10.3529 13.331 10.669 13.6471 11.0588 13.6471H15.7647C16.1546 13.6471 16.4706 13.331 16.4706 12.9412C16.4706 12.5513 16.1546 12.2353 15.7647 12.2353H11.0588Z' />
			<path d='M3.76471 15.7647C3.76471 15.3749 4.08074 15.0588 4.47059 15.0588H15.7647C16.1546 15.0588 16.4706 15.3749 16.4706 15.7647C16.4706 16.1546 16.1546 16.4706 15.7647 16.4706H4.47059C4.08074 16.4706 3.76471 16.1546 3.76471 15.7647Z' />
			<path d='M4.47059 17.8824C4.08074 17.8824 3.76471 18.1984 3.76471 18.5882C3.76471 18.9781 4.08074 19.2941 4.47059 19.2941H15.7647C16.1546 19.2941 16.4706 18.9781 16.4706 18.5882C16.4706 18.1984 16.1546 17.8824 15.7647 17.8824H4.47059Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.58824 0C1.15879 0 0 1.15879 0 2.58823V21.4118C0 22.8412 1.15879 24 2.58824 24H17.6471C19.0765 24 20.2353 22.8412 20.2353 21.4118V7.72015C20.2353 7.04301 19.9699 6.39284 19.4961 5.90908L14.4697 0.777172C13.9828 0.280118 13.3164 0 12.6206 0H2.58824ZM1.41176 2.58823C1.41176 1.93849 1.93849 1.41176 2.58824 1.41176H12.2755C12.2494 1.48536 12.2353 1.56456 12.2353 1.64706V5.41176C12.2353 6.84121 13.3941 8 14.8235 8H18.5882C18.6707 8 18.7499 7.98585 18.8235 7.95983V21.4118C18.8235 22.0615 18.2968 22.5882 17.6471 22.5882H2.58824C1.93849 22.5882 1.41176 22.0615 1.41176 21.4118V2.58823ZM18.1852 6.58824L13.6471 1.95491V5.41176C13.6471 6.06151 14.1738 6.58824 14.8235 6.58824H18.1852Z'
			/>
		</SvgIcon>
	);
}
