import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';

const ApolloContextProvider = ( {
	children,
}: {
	children?: React.ReactNode;
} ) => <ApolloProvider client={ client }>{ children }</ApolloProvider>;

export default ApolloContextProvider;
