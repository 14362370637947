import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ResourcesIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				xmlns='http://www.w3.org/2000/svg'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.59976 0C4.17032 0 3.01153 1.15879 3.01153 2.58824V4.94118C3.01153 5.33102 3.32756 5.64706 3.71741 5.64706C4.10726 5.64706 4.42329 5.33102 4.42329 4.94118V2.58824C4.42329 1.93849 4.95002 1.41176 5.59976 1.41176H15.2468V5.41176C15.2468 6.84121 16.4056 8 17.8351 8H21.8351V21.4118C21.8351 22.0615 21.3083 22.5882 20.6586 22.5882H5.59976C4.95002 22.5882 4.42329 22.0615 4.42329 21.4118V19.0588C4.42329 18.669 4.10726 18.3529 3.71741 18.3529C3.32756 18.3529 3.01153 18.669 3.01153 19.0588V21.4118C3.01153 22.8412 4.17032 24 5.59976 24H20.6586C22.088 24 23.2468 22.8412 23.2468 21.4118V7.72015C23.2468 7.04301 22.9815 6.39284 22.5076 5.90908L17.4812 0.777172C16.9943 0.280117 16.3279 0 15.6321 0H5.59976ZM21.1967 6.58824L16.6586 1.95491V5.41176C16.6586 6.06151 17.1853 6.58824 17.8351 6.58824H21.1967ZM6.95443 14.2958C8.22239 13.0279 8.22239 10.9721 6.95443 9.70415C5.68647 8.43619 3.6307 8.43619 2.36274 9.70415C1.09477 10.9721 1.09477 13.0279 2.36274 14.2958C3.6307 15.5638 5.68647 15.5638 6.95443 14.2958ZM8.29532 14.9121C9.76374 13.0829 9.64953 10.4027 7.95269 8.70589C6.13341 6.8866 3.18376 6.8866 1.36447 8.70589C-0.454822 10.5252 -0.454822 13.4748 1.36447 15.2941C2.99296 16.9226 5.52716 17.0934 7.34562 15.8065C7.37995 15.8972 7.43363 15.9822 7.50664 16.0552L10.3302 18.8787C10.6058 19.1544 11.0528 19.1544 11.3284 18.8787C11.6041 18.6031 11.6041 18.1561 11.3284 17.8805L8.50491 15.0569C8.4425 14.9945 8.37131 14.9462 8.29532 14.9121ZM13.3645 15.7647C13.3645 15.3749 13.6805 15.0588 14.0704 15.0588L18.7762 15.0588C19.1661 15.0588 19.4821 15.3749 19.4821 15.7647C19.4821 16.1546 19.1661 16.4706 18.7762 16.4706H14.0704C13.6805 16.4706 13.3645 16.1546 13.3645 15.7647ZM14.0704 12.2353C13.6805 12.2353 13.3645 12.5513 13.3645 12.9412C13.3645 13.331 13.6805 13.6471 14.0704 13.6471L18.7762 13.6471C19.1661 13.6471 19.4821 13.331 19.4821 12.9412C19.4821 12.5513 19.1661 12.2353 18.7762 12.2353L14.0704 12.2353Z'
			/>
		</SvgIcon>
	);
}
