/* eslint max-len: 0 */
const Star = ( props ) => (
	<svg { ...props } viewBox='0 0 20 20'>
		<path
			d='M10.0567 0L13.1715 6.18736L19.5659 7.4846L15.0966 12.6058L15.9337 19.5949L10.0567 16.5727L4.17967 19.5949L5.01681 12.6058L0.547471 7.4846L6.94188 6.18736L10.0567 0Z'
			fill='#F38E7D'
		/>
	</svg>
);

export default Star;
