/* eslint max-len: 0 */
const Warning = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			d='M12 .75c.82 0 1.573.445 1.972 1.168l9.748 17.995c.384.696.372 1.545-.032 2.23-.405.686-1.142 1.107-1.938 1.107H2.25c-.796 0-1.533-.42-1.938-1.107-.404-.685-.416-1.534-.034-2.225L10.03 1.912C10.428 1.195 11.18.75 12 .75zm0 1.5c-.273 0-.524.149-.654.382L1.594 20.637c-.129.233-.125.516.01.744.135.229.38.369.646.369h19.5c.265 0 .51-.14.646-.369.135-.228.139-.511.008-.749L12.656 2.638c-.132-.24-.383-.388-.656-.388zm0 15c.604 0 1.097.477 1.124 1.074l.001.051c0 .58-.439 1.057-1.002 1.118L12 19.5c-.621 0-1.125-.504-1.125-1.125S11.379 17.25 12 17.25zm0-9.75c.38 0 .693.282.743.648l.007.102V15c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L11.25 15V8.25c0-.414.336-.75.75-.75z'
			transform='translate(-384 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(240)'
		/>
	</svg>
);

export default Warning;
