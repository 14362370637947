/* eslint max-len: 0 */
const Text = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M18.045 0c.587 0 1.151.23 1.572.64l2.953 2.882c.435.423.68 1.004.68 1.611V21.75c0 1.243-1.007 2.25-2.25 2.25H3C1.757 24 .75 22.993.75 21.75V2.25C.75 1.007 1.757 0 3 0zm0 1.5H3c-.414 0-.75.336-.75.75v19.5c0 .414.336.75.75.75h18c.414 0 .75-.336.75-.75V5.133c0-.202-.082-.396-.227-.537L18.57 1.713c-.14-.137-.328-.213-.524-.213zm-6 15c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-6c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h6zm6-4.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-12c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L6.045 12h12zm0-4.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L18.045 9h-12c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h12z'
			transform='translate(-584 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(440)'
		/>
	</svg>
);

export default Text;
