/* eslint max-len: 0 */
const SmallArrow = ( props ) => (
	<svg { ...props } viewBox='0 0 7 4'>
		<path
			d='M.745.833l2.452 2.741c.184.206.5.223.706.04.016-.015.032-.031.046-.048L6.294.825C6.474.615 6.45.3 6.24.12 6.15.043 6.033 0 5.914 0H1.118c-.276 0-.5.224-.5.5 0 .123.045.242.127.333z'
			transform='translate(-771 -476) translate(144 183) translate(0 46) translate(0 161) translate(0 39) translate(379 30) translate(157) translate(91 17)'
		/>
	</svg>
);

export default SmallArrow;
