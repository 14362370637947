import 'react-international-phone/style.css';

import {
	TextFieldProps,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import React from 'react';
import {
	CountryData,
	CountryIso2,
	defaultCountries,
	FlagImage,
	parseCountry,
	usePhoneInput,
} from 'react-international-phone';
import styles from './MuiPhoneInput.module.scss';

export interface MuiPhoneProps
	extends Omit<TextFieldProps, 'error' | 'onChange'> {
	value: string;
	countries?: CountryData[1][];
	onChange: ( phone: string ) => void;
	errorText?: string;
}

/* CountryData is a tuple...which is kind of a bummer
country[ 1 ] is the "iso" country code, so we can use that for filtering */

const MuiPhoneInput: React.FC<MuiPhoneProps> = ( {
	value,
	onChange,
	countries,
	errorText,
	...restProps
} ) => {
	const allowedCountries = countries
		? defaultCountries.filter( ( country ) => countries.includes( country[ 1 ] ) )
		: defaultCountries;
	const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
		usePhoneInput( {
			defaultCountry: 'us',
			value,
			countries: allowedCountries,
			onChange: ( data ) => onChange?.( data.phone === '+1' ? '' : data.phone ),
		} );

	return (
		<TextField
			variant='outlined'
			label='Phone number'
			color='primary'
			placeholder='Phone number'
			value={ inputValue }
			onChange={ handlePhoneValueChange }
			type='tel'
			inputRef={ inputRef }
			error={ !!errorText }
			helperText={ errorText }
			slotProps={ {
				...restProps?.slotProps,
				input: {
					...restProps?.slotProps?.input,
					startAdornment: (
						<InputAdornment position='start' className={ styles.flagContainer }>
							<Select
								className={ styles.flagSelect }
								MenuProps={ {
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
									transformOrigin: {
										vertical: 'top',
										horizontal: 'left',
									},
								} }
								value={ country.iso2 }
								onChange={ ( e ) => setCountry( e.target.value as CountryIso2 ) }
								renderValue={ ( value ) => (
									<FlagImage iso2={ value } className={ styles.flag } />
								) }
							>
								{ allowedCountries.map( ( c ) => {
									const country = parseCountry( c );
									return (
										<MenuItem key={ country.iso2 } value={ country.iso2 }>
											<FlagImage
												iso2={ country.iso2 }
												className={ styles.flagImage }
											/>
											<Typography className={ styles.countryName }>
												{ country.name }
											</Typography>
											<Typography color='gray'>+{ country.dialCode }</Typography>
										</MenuItem>
									);
								} ) }
							</Select>
						</InputAdornment>
					),
				},
			} }
			{ ...restProps }
		/>
	);
};

export default MuiPhoneInput;
