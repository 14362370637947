import { ContactScalarFieldsNoSensitiveInformation } from './contact';
import { UserFieldsForGetContractWhere } from './user';
import { ClientUserFieldsForGetContractWhere } from './clientUser';
import { OrgUserFieldsForGetContractWhere } from './orgUser';
import { OrganizationFieldsForGetContractWhere } from './organization';
import { ServiceScalarFields } from './service';
import { GuestUserFieldsForGetContractWhere } from './guestUser';
import { PaymentInstallmentsForGetContractWhere } from './paymentInstallment';
import Query from '../../Query';

const ContractScalarFields = [
	'id',
	'archivedByVendor',
	'createdAt',
	'contractSourceFile',
	'eventDate',
	'executedContractUrl',
	'sentByOrganizationName',
	'isPastDue',
	'updatedAt',
	'unsignedContractFileUrl',
	'dueDate',
	'hsSignatureRequestId',
	'status',
	'sentAt',
	'title',
];

const GetContractWhereFields = [
	{
		contract: [
			...ContractScalarFields,
			{
				signatures: [
					{
						customerSignature: [
							{
								contact: [
									...ContactScalarFieldsNoSensitiveInformation,
									{
										customer: [
											...UserFieldsForGetContractWhere,
											{
												clientUser: [
													...ClientUserFieldsForGetContractWhere,
													{ user: UserFieldsForGetContractWhere },
													{
														assignedPlanner: [
															// eslint-disable-next-line array-element-newline
															...OrgUserFieldsForGetContractWhere,
															{
																organization:
																	OrganizationFieldsForGetContractWhere,
															},
														],
													},
												],
											},
											{
												guestUser: [ ...GuestUserFieldsForGetContractWhere, { user: UserFieldsForGetContractWhere }, ],
											},
										],
									},
									{
										assignedMember: [
											...OrgUserFieldsForGetContractWhere,
											{ user: UserFieldsForGetContractWhere },
											{
												organization: [ ...OrganizationFieldsForGetContractWhere, { services: ServiceScalarFields }, ],
											},
										],
									},
									{ vendor: OrganizationFieldsForGetContractWhere },
									new Query( {
										name: 'invoices',
										params: {
											where: { status: 'Sent' },
										},
										returnFields: [
											new Query( {
												name: 'paymentInstallments',
												params: {
													where: { status: 'Unpaid' },
													orderBy: { dueDate: 'desc' },
													take: 1,
												},
												returnFields: PaymentInstallmentsForGetContractWhere,
											} ),
										],
									} ),
								],
							},
						],
					},
				],
			},
		],
	},
];

export { ContractScalarFields, GetContractWhereFields };
