import React, { SVGProps } from 'react';

const SignCheck = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' fill='#000' { ...props }>
		<path
			d='M21.75 9.741c1.19 0 2.166.925 2.245 2.096l.005.154v9.759c0 1.19-.925 2.166-2.096 2.245L21.75 24H2.25c-1.19 0-2.166-.925-2.245-2.096L0 21.75v-9.759c0-.598.238-1.172.662-1.594.377-.375.873-.604 1.399-.648l.198-.008 2.244.009c.414.002.749.339.747.753-.002.38-.285.692-.651.74l-.102.007-2.244-.009c-.2 0-.391.078-.532.219-.113.112-.187.257-.212.413l-.009.118v9.759c0 .38.282.693.648.743l.102.007h19.5c.38 0 .693-.282.743-.648l.007-.102v-9.759c0-.38-.282-.694-.646-.743l-.102-.007-3 .009c-.414.001-.75-.334-.752-.748-.001-.38.28-.694.646-.745l.102-.007 3.002-.009zM7.478 18.066c.57.12 1.063.448 1.395.916l.109.169.575-.336c.877-.505 1.964-.445 2.776.14l.149.116c.317.266.359.74.093 1.057-.242.288-.655.349-.967.158l-.09-.065c-.307-.258-.73-.318-1.09-.167l-.117.058-1.34.783c-.392.23-.887.059-1.064-.344l-.035-.096-.11-.39c-.088-.27-.314-.473-.619-.537-.286-.071-.586-.018-.822.135l-.096.072-1.23 1.079c-.312.273-.786.242-1.059-.07-.248-.282-.245-.7-.009-.979l.078-.079 1.241-1.088c.625-.527 1.465-.723 2.232-.532zM20.25 12.75c.414 0 .75.336.75.75v3c0 .414-.336.75-.75.75H16.5c-.414 0-.75-.336-.75-.75v-3c0-.414.336-.75.75-.75zM21.53.22c.267.266.29.683.073.976l-.073.084-1.361 1.363c.679 1.15.524 2.657-.464 3.645l-8.782 8.781c-.096.096-.216.165-.348.198l-4.242 1.06c-.55.138-1.047-.36-.91-.909l1.06-4.242c.033-.132.102-.252.198-.348l5.827-5.829.007-.006.006-.007 2.645-2.645-.53-.53c-.266-.267-.683-.291-.977-.073l-.084.072-3.182 3.182c-.293.293-.767.293-1.06 0-.267-.266-.29-.683-.073-.976l.073-.084L12.515.75c.837-.837 2.168-.877 3.052-.12l.13.12.673.673c.875-.386 1.898-.336 2.737.159L20.47.22c.293-.293.767-.293 1.06 0zM19.5 14.25h-2.25v1.5h2.25v-1.5zm-6.455-7.666l-5.158 5.158-.706 2.827 2.827-.707 5.158-5.157-2.121-2.121zm5.391-3.652c-.587-.406-1.395-.343-1.913.174l-2.417 2.417 2.121 2.121 2.417-2.416c.513-.513.577-1.304.207-1.873l-.086-.118-.1-.11-.116-.108z'
			transform='translate(-504 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(360)'
		/>
	</svg>
);

export default SignCheck;
