/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const OrderedList = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.85718 6.28585C7.85718 5.81247 8.24093 5.42871 8.71432 5.42871H21.1393C21.6127 5.42871 21.9965 5.81247 21.9965 6.28585C21.9965 6.75924 21.6127 7.143 21.1393 7.143H8.71432C8.24093 7.143 7.85718 6.75924 7.85718 6.28585Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.86078 12.0002C7.86078 11.5268 8.24453 11.1431 8.71792 11.1431H21.1429C21.6163 11.1431 22.0001 11.5268 22.0001 12.0002C22.0001 12.4736 21.6163 12.8574 21.1429 12.8574H8.71792C8.24453 12.8574 7.86078 12.4736 7.86078 12.0002Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.85718 17.7141C7.85718 17.2407 8.24093 16.8569 8.71432 16.8569H21.1393C21.6127 16.8569 21.9965 17.2407 21.9965 17.7141C21.9965 18.1875 21.6127 18.5712 21.1393 18.5712H8.71432C8.24093 18.5712 7.85718 18.1875 7.85718 17.7141Z'
		/>
		<path d='M4.0414 8.09773V5.394L3.54468 5.50125C3.49388 5.51254 3.43743 5.47867 3.43179 5.42787L3.39792 5.15129C3.39228 5.10613 3.40357 5.07227 3.42615 5.04969L4.23896 4.3667H4.63408C4.69052 4.3667 4.73568 4.4175 4.73568 4.47395V8.09773C4.73568 8.15418 4.69052 8.20498 4.63408 8.20498H4.14865C4.08656 8.20498 4.0414 8.15418 4.0414 8.09773Z' />
		<path d='M3.09589 13.8402V13.7104C3.46843 13.2588 4.17964 12.3331 4.55782 11.8308C4.7328 11.6163 4.84569 11.3961 4.84569 11.2042C4.84569 10.9502 4.65378 10.6906 4.24737 10.6906C3.97643 10.6906 3.74501 10.8825 3.61518 11.0236C3.57003 11.0688 3.50794 11.0688 3.46278 11.0236L3.19184 10.7527C3.15798 10.7188 3.14669 10.6511 3.1862 10.6059C3.1862 10.6059 3.62647 10.0527 4.29817 10.0527C5.10534 10.0527 5.5569 10.5664 5.5569 11.176C5.5569 11.5429 5.39886 11.7743 5.17872 12.0904C4.936 12.4347 4.50702 12.9653 4.20786 13.3435H5.42708C5.48352 13.3435 5.53432 13.3943 5.53432 13.4507V13.8402C5.53432 13.8967 5.48352 13.9475 5.42708 13.9475H3.20313C3.14104 13.9475 3.09589 13.8967 3.09589 13.8402Z' />
		<path d='M3.09984 19.1876C3.06597 19.1481 3.07162 19.086 3.10549 19.0465L3.39336 18.7699C3.44416 18.7247 3.49496 18.7247 3.54011 18.7699C3.55705 18.7925 3.89008 19.0747 4.23439 19.0747C4.5392 19.0747 4.83271 18.8546 4.83271 18.5441C4.83271 18.2506 4.56177 17.9853 4.05377 17.9853H3.73767C3.67558 17.9853 3.63043 17.9401 3.63043 17.8724V17.5168C3.63043 17.4434 3.66994 17.4039 3.73767 17.4039H4.05377C4.51662 17.4039 4.75369 17.1725 4.75369 16.8846C4.75369 16.5742 4.49404 16.3653 4.20617 16.3653C3.91265 16.3653 3.73203 16.5234 3.63607 16.6363C3.59656 16.6814 3.53447 16.6871 3.48367 16.6419L3.20709 16.3822C3.16193 16.3427 3.16758 16.275 3.20709 16.2355C3.20709 16.2355 3.63607 15.7388 4.26826 15.7388C4.92302 15.7388 5.47054 16.1395 5.47054 16.7717C5.47054 17.2063 5.14316 17.5337 4.88916 17.6523V17.6692C5.15445 17.7821 5.57215 18.0869 5.57215 18.5893C5.57215 19.2271 5.05285 19.6899 4.2739 19.6899C3.56834 19.6899 3.22402 19.3343 3.09984 19.1876Z' />
	</svg>
);
export default OrderedList;
