/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Twitter = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M16.618 2.31l-.216.005c-2.795.12-5.026 2.42-5.032 5.243v.362l-.187.02c-2.692.23-5.275-1.242-7.997-4.372-.412-.473-1.188-.263-1.303.354l-.056.32c-.291 1.8-.141 3.345.46 4.61l.093.184-1.437-.217c-.49-.075-.917.338-.858.83l.033.23c.262 1.597 1.23 2.943 2.564 3.72l.038.021-.646.235c-.422.153-.615.64-.415 1.04l.1.194c.717 1.325 1.681 2.004 3.296 2.379l.14.031c-1.37.565-2.85.845-4.348.811-.82-.018-1.07 1.103-.322 1.435 6.123 2.72 11.562 2.507 15.628-.18l.293-.2c3.37-2.37 5.384-6.332 5.384-10.305l-.001-.447 1.79-1.295.083-.069c.378-.362.275-1.013-.227-1.228l-1.394-.598.78-1.7.037-.095c.177-.566-.365-1.125-.954-.93l-2.161.717-.048-.038c-.816-.621-1.799-.99-2.826-1.056l-.29-.011zm-.195 1.506l.192-.007c.925.01 1.815.367 2.494 1l.09.072c.192.13.434.165.657.09l.924-.307-.382.834-.036.097c-.109.36.067.752.423.904l.883.379-1.028.744c-.195.141-.31.367-.31.608v.83c0 3.59-1.883 7.191-5.003 9.253l-.25.16c-2.923 1.821-6.65 2.245-10.927 1.011l-.109-.033.342-.088c1.282-.358 2.506-.924 3.62-1.683.585-.4.342-1.313-.365-1.368l-.287-.023c-2.063-.18-3.142-.496-3.846-1.295l-.041-.049 1.872-.68c.766-.278.601-1.406-.212-1.454l-.195-.016c-1.358-.15-2.505-1.013-3.047-2.217l-.045-.107 2.17.33c.739.113 1.17-.805.611-1.302l-.156-.145c-.858-.843-1.29-1.986-1.28-3.464l.002-.122.175.174c2.874 2.812 5.778 3.999 8.912 3.353.349-.072.599-.38.599-.735v-1c.004-2.002 1.575-3.637 3.553-3.744z'
			transform='translate(-184 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(40)'
		/>
	</svg>
);

export default Twitter;
