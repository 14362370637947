import React from 'react';
import BalloonPops from '../../elements/BalloonPops';
import Logo2 from '../../elements/Logo2';
import { Typography, Box } from '@mui/material';
import styles from './ErrorPage.module.scss';

import Link from 'next/link';

export type ErrorPageProps = {
	statusCode: number;
};

const ErrorPage = ( { statusCode }: ErrorPageProps ) => {
	const copy =
		statusCode === 401
			? 'You do not have permission to view this document.'
			: 'The page you\'re looking for can\'t be found.';

	return (
		<main>
			<Logo2 />

			<Box
				data-cy='error-page'
				id={ statusCode === 404 ? '_404_page' : '_error_page' }
				className={ styles.container }
			>
				<Typography variant='h1' className={ styles.oops }>
					Oops
				</Typography>
				<Typography>{ copy }</Typography>
				<Typography>
					Click
					<Link href='/' className='legacyLink'>
						{ ' ' }
						here{ ' ' }
					</Link>
					to return home!
				</Typography>
				{ statusCode && (
					<Typography data-cy='error-page-status-code'>
						Error code: { statusCode }
					</Typography>
				) }
				<BalloonPops looping={ false } />
			</Box>
		</main>
	);
};

export default ErrorPage;
