import { ApiResponseType } from '../../../../hooks/useQuery.js';
import Query from '../../../Query.js';
import { GetProposalWhereReturnFields } from '../../returnFields/proposals';
import {
	GetProposalWhereData,
	GetProposalWhereDataResponse,
} from '../types.js';

const getProposalWhere = async function(
	this: any,
	paramsData: GetProposalWhereData
): Promise<ApiResponseType<GetProposalWhereDataResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getProposalWhere',
			params: paramsData,
			returnFields: GetProposalWhereReturnFields,
		} )
	);

	if ( errors ) return { errors };

	return data.data.getProposalWhere;
};

export default getProposalWhere;
