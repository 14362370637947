import React from 'react';
import styled from 'styled-components';
import withWidth from '../../lib/withWidthHOC';
import Icon from '../../elements/Icon';
import Button from '../../elements/Button';

const Container = styled.div`
	display: none;
	pointer-events: none;
	background-color: #fff;
	color: ${ ( { theme } ) => theme.pink };
	border-bottom: 1px solid #dddcdd;
	justify-content: center;
	align-items: center;

	&.acknowledged {
		display: none !important;
		pointer-events: none !important;
	}

	@media (max-width: ${ ( { theme } ) => theme.mediumScreen }px) {
		min-height: 7rem;
	}
`;

const Wrapper = withWidth( styled.div`
	max-width: 25%;
	font-size: 0.9rem;
	padding: 5px;
	text-align: center;

	@media (max-width: ${ ( { theme } ) => theme.mediumScreen }px) {
		max-width: initial;
		width: 100%;
	}
` );

const Span = styled.span`
	display: flex;
	margin: 5px;
`;

const A = styled.a`
	color: ${ ( { theme } ) => theme.pink };
	border-bottom: 1px solid ${ ( { theme } ) => theme.pink };
`;

const PinkIcon = styled( Icon )`
	fill: ${ ( { theme } ) => theme.pink };
`;

const NoBorderButton = styled( Button )`
	border: none;
	&:active,
	&:focus {
		background: none;
		outline: 0;
	}
	svg {
		fill: ${ ( props ) => props.theme.pink } !important;
		stroke: ${ ( props ) => props.theme.pink } !important;
	}
`;

const BrowserCompatibility = () => {
	//Native class assignment to avoid additional polyfill
	const dismissBrowserCompatibility = () =>
		( document.getElementById( 'BrowserCompatibility' ).className +=
			' acknowledged' );

	return (
		<Container id='BrowserCompatibility'>
			<Span>
				<PinkIcon icon='alertTriangle' />
			</Span>
			<Wrapper>
				<p>
					You are currently viewing Rock Paper Coin in an unsupported browser -
					your experience may be impacted. Please view list of supported
					browsers
					<A href='https://rockpapercoin.com/faq/' target='_blank'>
						{ ' ' }
						here
					</A>
					.
				</p>
			</Wrapper>
			<NoBorderButton
				onClick={ () => dismissBrowserCompatibility() }
				icon='xCircle'
				motif='ghostPink'
				active={ false }
			/>
		</Container>
	);
};

export default withWidth( BrowserCompatibility );
