import React, { SVGProps } from 'react';
/* eslint max-len: 0 */
const InvoiceTemplate = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 32 32' fill='none' fillRule='evenodd' { ...props }>
		<path
			d='M19.172.25c.729 0 1.428.29 1.944.805l7.83 7.83c.514.515.804 1.214.804 1.943V29c0 1.519-1.231 2.75-2.75 2.75H5c-1.519 0-2.75-1.231-2.75-2.75V3C2.25 1.481 3.481.25 5 .25zm-.922 1.5H5c-.69 0-1.25.56-1.25 1.25v26c0 .69.56 1.25 1.25 1.25h22c.69 0 1.25-.56 1.25-1.25V11.75H21c-1.463 0-2.658-1.142-2.745-2.582L18.25 9V1.75zM24 25.25c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L24 26.75H9c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L9 25.25h15zm0-6c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L24 20.75h-8c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L16 19.25h8zm-13.25-13c.379 0 .693.282.742.648L11.5 7v.676c.917.158 1.75.656 2.325 1.411.25.33.186.8-.143 1.051-.33.251-.8.187-1.051-.143-.438-.575-1.128-.9-1.88-.87-1.144 0-2 .642-2 1.312 0 .636.769 1.247 1.821 1.31l.178.004c1.893 0 3.496 1.204 3.496 2.812 0 1.394-1.209 2.485-2.745 2.75V18c0 .414-.336.75-.75.75-.38 0-.694-.282-.744-.648L10 18v-.676c-.918-.157-1.754-.655-2.33-1.411-.25-.33-.186-.8.143-1.051.33-.251.8-.187 1.051.142.438.575 1.129.9 1.881.871 1.144 0 2-.642 2-1.312 0-.635-.765-1.244-1.817-1.306l-.178-.006c-1.892 0-3.499-1.205-3.499-2.814 0-1.396 1.21-2.488 2.75-2.751V7c0-.414.335-.75.75-.75zm9.001-4.358L19.75 9c0 .647.492 1.18 1.122 1.244l.128.006h7.108c-.058-.112-.133-.214-.224-.305l-7.829-7.83c-.09-.09-.193-.164-.304-.223z'
			transform='translate(-384 -1434) translate(144 183) translate(0 1197) translate(0 54) translate(240)'
		/>
	</svg>
);

export default InvoiceTemplate;
