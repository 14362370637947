/* eslint max-len: 0 */
const Picture = () => (
	<svg viewBox='0 0 24 24'>
		<g fill='none' fillRule='evenodd'>
			<g fill='#203A60'>
				<path
					d='M18.867 2.273c.606 0 1.187.244 1.61.678l2.884 2.955c.41.42.639.984.639 1.571v12c0 1.243-1.007 2.25-2.25 2.25H2.25c-1.243 0-2.25-1.007-2.25-2.25V4.523c0-1.243 1.007-2.25 2.25-2.25zm0 1.5H2.25c-.414 0-.75.336-.75.75v14.954c0 .414.336.75.75.75h19.5c.414 0 .75-.336.75-.75v-12c0-.196-.076-.383-.213-.523l-2.883-2.955c-.14-.145-.334-.226-.537-.226zm-3.723 5.502c.473.005.917.22 1.214.583l.094.126 4.372 6.555c.23.344.137.81-.208 1.04-.313.209-.726.151-.972-.12l-.068-.088-4.371-6.554c-.018-.026-.047-.042-.077-.042-.021 0-.041.006-.057.018l-.021.021-2.245 3.215c-.228.327-.665.414-.997.216l-.088-.06-1.389-1.117c-.02-.016-.047-.023-.072-.019-.018.003-.033.01-.047.021l-.017.02-2.865 4.3c-.23.345-.695.438-1.04.208-.313-.209-.419-.612-.263-.943l.055-.097 2.865-4.3c.247-.372.64-.623 1.081-.691.387-.06.78.024 1.106.234l.136.097.764.615 1.787-2.558c.271-.388.7-.633 1.166-.674l.157-.006zm-8.064-3.4c1.45 0 2.625 1.175 2.625 2.625S8.53 11.125 7.08 11.125 4.455 9.95 4.455 8.5 5.63 5.875 7.08 5.875zm0 1.5c-.621 0-1.125.504-1.125 1.125s.504 1.125 1.125 1.125S8.205 9.121 8.205 8.5 7.701 7.375 7.08 7.375z'
					transform='translate(-584 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(440)'
				/>
			</g>
		</g>
	</svg>
);

export default Picture;
