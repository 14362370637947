import { ProposalTemplateDesign } from '../lib/API/proposals/types';

export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

export type SetSelectedTemplateType = {
	template: ProposalTemplateDesign | null;
	templateId: string | null;
};

export function setSelectedTemplate( payload: SetSelectedTemplateType ) {
	return {
		type: SET_SELECTED_TEMPLATE,
		...payload,
	};
}
