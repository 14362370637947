/* eslint max-len: 0 */
export default () => (
	<svg viewBox='0 0 24 24'>
		<g fill='none' fillRule='evenodd'>
			<g fill='#203A60' fillRule='nonzero'>
				<path
					d='M17.25 0c.587 0 1.15.23 1.567.635l3.003 2.887c.435.423.68 1.004.68 1.611V21.75c0 1.243-1.007 2.25-2.25 2.25h-18C1.007 24 0 22.993 0 21.75V2.25C0 1.007 1.007 0 2.25 0zm0 1.5h-15c-.414 0-.75.336-.75.75v19.5c0 .414.336.75.75.75h18c.414 0 .75-.336.75-.75V5.133c0-.202-.082-.396-.223-.533l-3.004-2.887c-.14-.137-.327-.213-.523-.213zM8.261 8.49c.473-.292 1.064-.32 1.562-.071l5.789 2.895c.544.272.888.828.888 1.436s-.344 1.164-.888 1.436l-5.79 2.895c-.497.248-1.088.221-1.561-.071-.473-.293-.761-.81-.761-1.366V9.856c0-.556.288-1.073.761-1.366zm.892 1.271c-.033-.016-.072-.014-.103.005-.031.02-.05.053-.05.09v5.788c0 .037.019.07.05.09.031.02.07.021.103.005l5.79-2.895c.035-.018.057-.054.057-.094s-.022-.076-.058-.094z'
					transform='translate(-194 -594) translate(170 316) translate(0 75) translate(0 175) translate(24.75 28)'
				/>
			</g>
		</g>
	</svg>
);
