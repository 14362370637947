import Axios from 'axios';
import Query from '../../Query.js';
import {
	OrgUserReturnFields,
	AuthedUserReturnFields,
	AuthedOrgUserReturnFields,
	SuperAdminUserReturnFields,
	ClientUserDetailReturnFields,
} from '../returnFields';

const setAuthToken = function( token ) {
	// set up for next request(s)
	this.request = this.requestWithToken.bind( this, token );
};

/**
 * Get a user object for the currently logged in user.
 *
 * @param {String} email - Email of the use to get.
 *
 * @returns { Promise<{ user: Record<string, any>, groups: Array<string> } | { errors: Array<Error> }> } See "AuthedUserReturnFields"
 */
const getAuthedUser = async function( email ) {
	if ( !email ) {
		return {
			errors: [ new Error( 'Validation Error: Insufficient arguments provided.' ) ],
		};
	}
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getAuthedUser',
			params: { where: { email } },
			returnFields: [
				'userType',
				{
					superAdmin: [ ...SuperAdminUserReturnFields, { user: AuthedUserReturnFields }, ],
				},
				{
					orgUser: [
						'isOwner',
						'isAdmin',
						...AuthedOrgUserReturnFields
					],
				},
				{
					clientUser: [
						...ClientUserDetailReturnFields,
						'weddingDate',
						'createdAt',
						{ assignedPlanner: OrgUserReturnFields },
						{ user: AuthedUserReturnFields },
					],
				},
				{ groups: [ 'id', 'name' ] },
				{
					impersonatingUser: [ 'id', { superAdminCapabilities: [ 'capabilities' ] }, ],
				},
			],
		} )
	);

	if ( errors ) return { errors };

	const user = data?.data?.getAuthedUser;

	if ( user?.userType ) {
		const { userType } = user;
		if ( userType === 'OrgUser' && user.orgUser ) {
			user.orgUser.impersonatingUser = user.impersonatingUser;
			return { user: user.orgUser, groups: user.groups };
		}
		if ( userType === 'ClientUser' && user.clientUser ) {
			user.clientUser.impersonatingUser = user.impersonatingUser;
			return { user: user.clientUser, groups: user.groups };
		}
		if ( userType === 'SuperAdmin' && user.superAdmin ) {
			user.superAdmin.impersonatingUser = user.impersonatingUser;
			return { user: user.superAdmin, groups: user.groups };
		}
	}

	// if no user is found, the credentials are invalid
	return {
		user: null,
		errors: { message: 'invalid credentials' },
	};
};

/** @typedef { { user: import('../../../lib/helpers').USER_FLATTENED } } USER */

/**
 * Get an auth token and a use object.
 *
 * @param { {
 * email: string
 * password: string
 * targetUserEmail: string
 * mfaToken: number
 * } } args
 *
 * @returns {Promise<{ { token: string, user: USER } | { errors: string[] } }>} - See "AuthedUserReturnFields"
 */
const login = async function( params ) {
	delete Axios.defaults.headers.common[ 'Authorization' ];

	const { data, errors: loginErrors } = await this.request(
		new Query( {
			type: 'query',
			name: 'login',
			params: { data: params },
			returnFields: [ 'token' ],
		} )
	);
	if ( loginErrors ) return { errors: loginErrors };

	const token = data.data.login.token;

	this.setAuthToken( token );

	const { user, errors: userErrors } = await this.getAuthedUser(
		params.targetUserEmail || params.email
	);
	if ( userErrors ) return { errors: userErrors };

	if ( !user ) {
		//If a user could not be retrieved, delete the token
		delete Axios.defaults.headers.common[ 'Authorization' ];
		return { errors: [ { message: 'invalid credentials' } ] };
	}

	return { user, token };
};

/**
 * Request a password reset email be sent.
 *
 * @param {String} email - Email to send the reset request to.
 *
 * @returns {Object} res - { errors, success }
 */
const requestPasswordReset = async function( email ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'requestReset',
			params: { data: { email } },
		} )
	);
	if ( errors ) return { errors };

	const success = data.data && data.data.requestReset;

	return { success };
};

/**
 * Reset a password.
 *
 * @param { {
 *   email: string,
 *   passwordResetToken: string,
 *   password: string,
 *   confirmedPassword: string
 * } } args
 * @returns {Object} res - { errors, success }
 */
const resetPassword = async function( {
	email,
	passwordResetToken,
	password,
	confirmedPassword,
} ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'resetPassword',
			params: {
				data: { email, passwordResetToken, password, confirmedPassword },
			},
		} )
	);
	if ( errors ) return { errors };

	const success = data.data && data.data.resetPassword;

	return { success };
};

/**
 * Update the currently logged in user's password.
 *
 * @param {string} currentPassword - Current password of the logged in user.
 * @param {string} newPassword - New password.
 * @param {string} confirmedPassword - must match newPassword.
 *
 * @returns {Object} res - { errors, success }
 */
const updatePassword = async function(
	currentPassword,
	newPassword,
	confirmedPassword
) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'changePassword',
			params: {
				data: { currentPassword, newPassword, confirmedPassword },
			},
		} )
	);

	if ( errors || data.errors ) return { errors: errors || data.errors };

	if ( data.data.changePassword ) {
		return { success: true };
	}
};

/**
 * Wrapper to request an MFAToken be generated.
 *
 * @param { {
 * email: string
 * password: string
 * targetUserEmail: string
 * } } args
 *
 * @returns {Promise<{ { boolean } | { errors: string[] } }>}
 */
const createMfaToken = async function( { email, password, targetUserEmail } ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createMfaToken',
			params: {
				data: {
					email,
					password,
					targetUserEmail,
				},
			},
		} )
	);
	if ( errors ) return { errors };

	const success = data.data && data.data.createMfaToken;
	return success;
};

export {
	setAuthToken,
	getAuthedUser,
	login,
	requestPasswordReset,
	resetPassword,
	updatePassword,
	createMfaToken,
};
