import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FilePresentation = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.25 3c0-.966.784-1.75 1.75-1.75h12.903c.463 0 .907.183 1.235.51l3.096 3.082c.33.328.516.775.516 1.24V21A1.75 1.75 0 0 1 20 22.75H4A1.75 1.75 0 0 1 2.25 21V3ZM4 2.75a.25.25 0 0 0-.25.25v18c0 .138.112.25.25.25h16a.25.25 0 0 0 .25-.25V6.082a.25.25 0 0 0-.074-.177L17.08 2.823a.25.25 0 0 0-.176-.073H4ZM14.25 16a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75ZM10 18.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8Zm-4.749-8.146a4.75 4.75 0 1 0 9.498-.208 4.75 4.75 0 0 0-9.498.208Zm1.601.709h2.801l1.789 2.1a3.251 3.251 0 0 1-4.59-2.101Zm5.744 1.143c.41-.544.654-1.222.654-1.956a3.245 3.245 0 0 0-1.312-2.609L10.87 9.928l1.726 2.028Zm-2.035-5.158a3.251 3.251 0 0 0-3.738 2.514h2.679l1.059-2.514Z'
		/>
	</svg>
);

export default FilePresentation;
