/* eslint max-len: 0 */
const Percentage = () => (
	<svg viewBox='0 0 11 14'>
		<path
			d='M33.485 22.062c1.332 0 2.43-1.08 2.43-2.43s-1.098-2.43-2.43-2.43c-1.35 0-2.43 1.08-2.43 2.43s1.08 2.43 2.43 2.43zM33.287 30c.162 0 .27-.108.306-.18l6.21-11.934c.126-.234-.036-.486-.306-.486h-.288c-.144 0-.234.054-.306.18l-6.174 11.934c-.126.234.036.486.306.486h.252zm.198-8.964c-.792 0-1.404-.612-1.404-1.404 0-.738.612-1.368 1.404-1.368.738 0 1.368.63 1.368 1.368 0 .792-.63 1.404-1.368 1.404zm5.688 9.162c1.332 0 2.43-1.08 2.43-2.43s-1.098-2.43-2.43-2.43c-1.35 0-2.43 1.08-2.43 2.43s1.08 2.43 2.43 2.43zm0-1.026c-.792 0-1.404-.612-1.404-1.404 0-.738.612-1.368 1.404-1.368.738 0 1.368.63 1.368 1.368 0 .792-.63 1.404-1.368 1.404z'
			transform='translate(-170 -1957) translate(139 186) translate(0 1701) translate(0 53)'
		/>
	</svg>
);

export default Percentage;
