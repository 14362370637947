import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { inputBaseClasses } from '@mui/material/InputBase';
import styles from './MuiThemeProvider.module.scss';
import { inputAdornmentClasses, outlinedInputClasses } from '@mui/material';

const theme = createTheme( {
	palette: {
		primary: {
			main: styles.primary,
		},
		secondary: {
			main: styles.secondary,
		},
	},
	typography: {
		fontFamily: '\'Brandon Grotesque\', sans-serif',
		h1: {
			fontWeight: 'bold',
			fontSize: '1.5rem',
			lineHeight: '2.25rem',
		},
		h2: {
			fontWeight: 'bold',
			fontSize: '1.6875rem',
			lineHeight: '2.5rem',
		},
		h5: {
			fontWeight: 'bold',
			fontSize: '1.5rem',
			lineHeight: '2.25rem',
		},
		h6: {
			fontWeight: 'bold',
			fontSize: '1.25rem',
			lineHeight: '2.25rem',
		},
		body1: {
			fontSize: '1.125rem',
			lineHeight: '1.5rem',
		},
		caption: {
			fontSize: '1.125rem',
			color: styles.readablegray,
		},
		button: {
			textTransform: 'unset',
			fontSize: '1.125rem',
		},
	},
	components: {
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: styles.darkgray,
					fontSize: '1rem',
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				variant: 'body1',
				color: styles.darkblue,
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: styles.darkblue,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: '1.125rem',
					color: styles.darkblue,
					// Change the background of disabled inputs, but only if they're outlined
					[ `&.${ outlinedInputClasses.root }.${ inputBaseClasses.disabled }` ]: {
						background: '#F5F5F5',
						[ `.${ inputAdornmentClasses.root }` ]: {
							color: styles.readablegray,
						},
					},
				},
			},
		},
		MuiInputAdornment: {
			styleOverrides: {
				root: {
					color: styles.darkblue,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					[ `&:not(.${ inputBaseClasses.disabled }):hover:not(:focus-within)` ]: {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: styles.darkblue,
						},
					},
					'& input::placeholder': {
						color: styles.readablegray,
						opacity: 1,
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					color: styles.darkblue,
					fontSize: '0.875rem',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				color: 'primary',
				disableElevation: true, // no dropshadow
			},
			styleOverrides: {
				root: ( { ownerState } ) => ( {
					lineHeight: '1.5rem',
					whiteSpace: 'nowrap',
					'.MuiButton-startIcon': {
						width: '1.125em',
						minWidth: '1.125em',
						height: '1.125em',
						fill: 'currentColor',
						' svg': {
							minWidth: '100%',
						},
					},
					...( [ 'outlined', 'contained' ].includes( ownerState.variant || '' )
						? ownerState.startIcon // icon button
							? {
								textTransform: 'uppercase',
								padding: '1rem 2.5rem',
								minWidth: '10.25rem',
								minHeight: '3.5rem',
								'&.empty': {
									minWidth: '3.5rem',
									maxWidth: '3.5rem',
									padding: '1rem 0',
									'.MuiButton-startIcon': {
										margin: 0,
									},
								},
							  }
							: {
								// non-icon, non-text button
								padding: '0.625rem 2.5rem',
								minWidth: '7.5rem',
								textTransform: 'capitalize',
							  }
						: {
							// everything else
							padding: '0.625rem 1rem',
							textTransform: 'uppercase',
						  } ),
				} ),
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontSize: '0.75rem',
					color: styles.readablegray, // needs work
				},
			},
		},
		// Some unfortunate style overrides below for DatePicker, because Mui doesn't want us to style those easily I guess...
		MuiPaper: {
			styleOverrides: {
				root: {
					'.MuiPickersCalendarHeader-labelContainer': {
						fontSize: '1rem',
					},
					'& .MuiAutocomplete-listbox': {
						color: styles.darkblue,
					},
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					color: styles.darkblue,
					'&.MuiPickersDay-root': {
						fontSize: '0.75rem',
					},
				},
			},
		},
		// So that the description of our MuiModal (really a Dialog) is like the typography body1
		MuiDialogContentText: {
			defaultProps: {
				color: styles.darkblue,
				fontSize: '1.125rem',
				lineHeight: '1.5rem',
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					fill: styles.darkblue,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					// make non-shrunken input labels grey
					[ `&:not(.${ inputLabelClasses.shrink })` ]: {
						color: styles.readablegray,
					},
				},
			},
		},
	},
} );

const MuiThemeProvider = ( { children }: { children?: React.ReactNode } ) => (
	<ThemeProvider theme={ theme }>{ children }</ThemeProvider>
);

export default MuiThemeProvider;
