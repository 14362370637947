import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const Lead = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 25 25' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.74306 5.75099C3.74306 3.23064 5.7862 1.1875 8.30655 1.1875C10.8269 1.1875 12.87 3.23064 12.87 5.75099C12.87 8.27134 10.8269 10.3145 8.30655 10.3145C5.7862 10.3145 3.74306 8.27134 3.74306 5.75099ZM11.7748 5.75099C11.7748 3.83553 10.222 2.28274 8.30655 2.28274C6.39108 2.28274 4.83829 3.83553 4.83829 5.75099C4.83829 7.66646 6.39108 9.21925 8.30655 9.21925C10.222 9.21925 11.7748 7.66646 11.7748 5.75099Z'
		/>
		<path d='M17.7986 14.3304C18.0758 14.3304 18.305 14.5364 18.3412 14.8037L18.3462 14.878V17.251H20.7192C21.0217 17.251 21.2669 17.4962 21.2669 17.7986C21.2669 18.0758 21.0608 18.305 20.7936 18.3412L20.7192 18.3462H18.3462V20.7192C18.3462 21.0217 18.1011 21.2669 17.7986 21.2669C17.5214 21.2669 17.2923 21.0608 17.256 20.7936L17.251 20.7192V18.3462H14.878C14.5755 18.3462 14.3304 18.1011 14.3304 17.7986C14.3304 17.5214 14.5364 17.2923 14.8037 17.256L14.878 17.251H17.251V14.878C17.251 14.5755 17.4962 14.3304 17.7986 14.3304Z' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.4097 17.7986C11.4097 14.2701 14.2701 11.4097 17.7986 11.4097C21.3271 11.4097 24.1875 14.2701 24.1875 17.7986C24.1875 21.3271 21.3271 24.1875 17.7986 24.1875C14.2701 24.1875 11.4097 21.3271 11.4097 17.7986ZM23.0923 17.7986C23.0923 14.875 20.7222 12.505 17.7986 12.505C14.875 12.505 12.505 14.875 12.505 17.7986C12.505 20.7222 14.875 23.0923 17.7986 23.0923C20.7222 23.0923 23.0923 20.7222 23.0923 17.7986Z'
		/>
		<path d='M11.1011 11.251C8.90192 10.314 6.37934 10.5428 4.38462 11.8602C2.38991 13.1776 1.18913 15.4078 1.1875 17.7982C1.18729 18.1007 1.4323 18.346 1.73475 18.3462C2.03719 18.3464 2.28253 18.1014 2.28274 17.799C2.28412 15.7761 3.30023 13.8889 4.9882 12.7741C6.67616 11.6593 8.81081 11.4657 10.6718 12.2586C10.95 12.3772 11.2717 12.2477 11.3902 11.9695C11.5088 11.6913 11.3793 11.3696 11.1011 11.251Z' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.74306 5.75099C3.74306 3.23064 5.7862 1.1875 8.30655 1.1875C10.8269 1.1875 12.87 3.23064 12.87 5.75099C12.87 8.27134 10.8269 10.3145 8.30655 10.3145C5.7862 10.3145 3.74306 8.27134 3.74306 5.75099ZM11.7748 5.75099C11.7748 3.83553 10.222 2.28274 8.30655 2.28274C6.39108 2.28274 4.83829 3.83553 4.83829 5.75099C4.83829 7.66646 6.39108 9.21925 8.30655 9.21925C10.222 9.21925 11.7748 7.66646 11.7748 5.75099Z'
			strokeWidth='0.4'
		/>
		<path
			d='M17.7986 14.3304C18.0758 14.3304 18.305 14.5364 18.3412 14.8037L18.3462 14.878V17.251H20.7192C21.0217 17.251 21.2669 17.4962 21.2669 17.7986C21.2669 18.0758 21.0608 18.305 20.7936 18.3412L20.7192 18.3462H18.3462V20.7192C18.3462 21.0217 18.1011 21.2669 17.7986 21.2669C17.5214 21.2669 17.2923 21.0608 17.256 20.7936L17.251 20.7192V18.3462H14.878C14.5755 18.3462 14.3304 18.1011 14.3304 17.7986C14.3304 17.5214 14.5364 17.2923 14.8037 17.256L14.878 17.251H17.251V14.878C17.251 14.5755 17.4962 14.3304 17.7986 14.3304Z'
			strokeWidth='0.4'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.4097 17.7986C11.4097 14.2701 14.2701 11.4097 17.7986 11.4097C21.3271 11.4097 24.1875 14.2701 24.1875 17.7986C24.1875 21.3271 21.3271 24.1875 17.7986 24.1875C14.2701 24.1875 11.4097 21.3271 11.4097 17.7986ZM23.0923 17.7986C23.0923 14.875 20.7222 12.505 17.7986 12.505C14.875 12.505 12.505 14.875 12.505 17.7986C12.505 20.7222 14.875 23.0923 17.7986 23.0923C20.7222 23.0923 23.0923 20.7222 23.0923 17.7986Z'
			strokeWidth='0.4'
		/>
		<path
			d='M11.1011 11.251C8.90192 10.314 6.37934 10.5428 4.38462 11.8602C2.38991 13.1776 1.18913 15.4078 1.1875 17.7982C1.18729 18.1007 1.4323 18.346 1.73475 18.3462C2.03719 18.3464 2.28253 18.1014 2.28274 17.799C2.28412 15.7761 3.30023 13.8889 4.9882 12.7741C6.67616 11.6593 8.81081 11.4657 10.6718 12.2586C10.95 12.3772 11.2717 12.2477 11.3902 11.9695C11.5088 11.6913 11.3793 11.3696 11.1011 11.251Z'
			strokeWidth='0.4'
		/>
	</svg>
);

export default Lead;
