import React, { SVGProps } from 'react';

const EditDetails = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11 4.625L3 4.625C2.65482 4.625 2.375 4.34518 2.375 4C2.375 3.65483 2.65482 3.375 3 3.375L11 3.375C11.3452 3.375 11.625 3.65482 11.625 4C11.625 4.34518 11.3452 4.625 11 4.625ZM19 11.8103L21 11.8103C21.3452 11.8103 21.625 12.0901 21.625 12.4353C21.625 12.7805 21.3452 13.0603 21 13.0603L19 13.0603C18.6548 13.0603 18.375 12.7805 18.375 12.4353C18.375 12.0901 18.6548 11.8103 19 11.8103ZM13 19.8103L21 19.8103C21.3452 19.8103 21.625 20.0901 21.625 20.4353C21.625 20.7805 21.3452 21.0603 21 21.0603L13 21.0603C12.6548 21.0603 12.375 20.7805 12.375 20.4353C12.375 20.0901 12.6548 19.8103 13 19.8103ZM21 15.8103L15 15.8103C14.6548 15.8103 14.375 16.0901 14.375 16.4353C14.375 16.7805 14.6548 17.0603 15 17.0603L21 17.0603C21.3452 17.0603 21.625 16.7805 21.625 16.4353C21.625 16.0901 21.3452 15.8103 21 15.8103ZM3 12.625L5 12.625C5.34518 12.625 5.625 12.3452 5.625 12C5.625 11.6548 5.34518 11.375 5 11.375L3 11.375C2.65482 11.375 2.375 11.6548 2.375 12C2.375 12.3452 2.65482 12.625 3 12.625ZM9 8.625L3 8.625C2.65482 8.625 2.375 8.34518 2.375 8C2.375 7.65483 2.65482 7.375 3 7.375L9 7.375C9.34518 7.375 9.625 7.65482 9.625 8C9.625 8.34518 9.34518 8.625 9 8.625ZM6.48539 18.1627L6.81405 16.2653L16.4084 6.671C16.8416 6.2378 17.5439 6.2378 17.9771 6.671C18.4103 7.10421 18.4103 7.80657 17.9771 8.23978L8.38282 17.8341L6.48539 18.1627ZM19.0378 9.30044C20.0568 8.28144 20.0568 6.62933 19.0378 5.61034C18.0188 4.59135 16.3667 4.59135 15.3477 5.61034L5.4172 15.5408L4.64412 20.004L9.10729 19.2309L19.0378 9.30044Z'
			fill='#203A60'
		/>
	</svg>
);

export default EditDetails;
