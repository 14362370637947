/* eslint max-len: 0 */
const ContractTemplate = () => (
	<svg viewBox='0 0 32 32'>
		<g fill='none' fillRule='evenodd'>
			<g fill='#000'>
				<g>
					<g>
						<g>
							<g>
								<path
									d='M19.172.25c.729 0 1.428.29 1.944.805l7.83 7.83c.514.515.804 1.214.804 1.943V29c0 1.519-1.231 2.75-2.75 2.75H5c-1.519 0-2.75-1.231-2.75-2.75V3C2.25 1.481 3.481.25 5 .25zm-.922 1.5H5c-.69 0-1.25.56-1.25 1.25v26c0 .69.56 1.25 1.25 1.25h22c.69 0 1.25-.56 1.25-1.25V11.75H21c-1.463 0-2.658-1.142-2.745-2.582L18.25 9V1.75zm2.978 22.479c.156-.345.582-.49.92-.292l1.25.73.115.057c.354.149.768.09 1.07-.163l.086-.06c.269-.16.622-.107.83.14.23.276.194.686-.081.917l-.145.112c-.741.528-1.73.582-2.528.122l-.581-.34-.028.052c-.299.504-.79.86-1.367.981-.702.175-1.469-.004-2.04-.485l-1.158-1.016-.073-.076c-.2-.241-.2-.598.013-.84.237-.27.648-.298.918-.06l1.148 1.006.095.071c.232.151.527.203.807.133.3-.063.525-.265.612-.535l.104-.364zm-10.815-.313c.466.114.86.413 1.126.828l.078.133.366-.248c.736-.495 1.663-.436 2.34.124l.131.12c.257.25.262.662.01.919-.225.23-.581.257-.837.077l-.081-.067c-.221-.216-.498-.266-.744-.149l-.09.052-1.071.73c-.348.238-.817.085-.969-.29l-.029-.091-.09-.367c-.075-.273-.254-.46-.475-.515-.186-.054-.384-.016-.55.107l-.08.069-.983 1.007c-.251.257-.662.262-.92.01-.23-.225-.257-.581-.078-.837l.068-.081.993-1.017c.518-.509 1.234-.703 1.885-.514zm12.587-8c.414 0 .75.336.75.75 0 .38-.282.694-.648.744l-.102.007H8c-.414 0-.75-.336-.75-.75 0-.38.282-.694.648-.743L8 15.917h15zM19.751 1.893L19.75 9c0 .647.492 1.18 1.122 1.244l.128.006h7.108c-.058-.112-.133-.214-.224-.305l-7.829-7.83c-.09-.09-.193-.164-.304-.223zM14 6.917c.414 0 .75.335.75.75 0 .38-.282.693-.648.743L14 8.417H8c-.414 0-.75-.336-.75-.75 0-.38.282-.694.648-.743L8 6.917h6z'
									transform='translate(-288 -1434) translate(144 183) translate(0 1197) translate(0 54) translate(144)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default ContractTemplate;
