import React from 'react';
import MuiCheckbox from '../../../mui/MuiCheckbox';
import { FormControlLabel, Box, Typography } from '@mui/material';
import MuiTipTap from '../../../mui/MuiTipTap';
import styles from './InquiriesConfirmationForm.module.scss';
import { useInquiriesConfiguration } from '../useInquiriesConfiguration';
import { useInquiriesConfigurationFields } from '../InquiriesConfigurationForm/useInquiriesConfigurationFields';
export type InquiriesConfirmationFormProps = Pick<
ReturnType<typeof useInquiriesConfiguration>,
| 'confirmationEmail'
| 'setConfirmationEmail'
| 'sendConfirmationEmail'
| 'setSendConfirmationEmail'
> &
Pick<
ReturnType<typeof useInquiriesConfigurationFields>,
'confirmationEmailValidation'
>;

export const InquiriesConfirmation: React.FC<
InquiriesConfirmationFormProps
> = ( {
	confirmationEmail,
	setConfirmationEmail,
	sendConfirmationEmail,
	setSendConfirmationEmail,
	confirmationEmailValidation,
} ) => {
	return (
		<>
			<Box className={ styles.container }>
				<Typography className={ styles.text } variant='h6'>
					Customize Confirmation Email
				</Typography>
				<Typography className={ styles.text } data-cy='helper-text'>
					This email will be sent to customers upon completion of filling out
					the form.
				</Typography>
				<MuiTipTap
					data-cy='confirmation-email-textarea'
					maxLength={ 500 }
					countCharacters
					onChange={ setConfirmationEmail }
					focused={ true }
					value={ confirmationEmail }
					className={ styles.textArea }
				/>
				{ confirmationEmailValidation ? (
					<Typography className={ styles.error }>
						{ confirmationEmailValidation }
					</Typography>
				) : null }
			</Box>
			<Box className={ styles.container }>
				<FormControlLabel
					className={ styles.text }
					control={
						<MuiCheckbox
							data-cy='send-confirmation-email-checkbox'
							onChange={ () => setSendConfirmationEmail( !sendConfirmationEmail ) }
							checked={ !sendConfirmationEmail }
						/>
					}
					label='Please do NOT send a confirmation email'
				/>
			</Box>
		</>
	);
};
