import React, { useEffect, useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import MuiButton from '../../../mui/MuiButton';
import styles from './InquiriesPublishForm.module.scss';
import { gql } from '@apollo/client';
import { useInquiriesPublishForm_GetGlobalVariablesQuery } from './__generated__';
import { showError } from '../../Toast';
import MuiSpinner from '../../../mui/MuiSpinner';
import ExtendedLink from '../../ExtendedLink/ExtendedLink';
import { CodeSnippetParts, useInquiriesPublish } from './useInquiriesPublish';
import Icon from '../../../elements/icons';
import { MuiTooltip } from '../../../mui/MuiTooltip';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query InquiriesPublishForm_getGlobalVariables {
		getGlobalVariables {
			InquiryFormSupportUrl
		}
	}
`;

export type InquiriesPublishFormProps = Pick<
ReturnType<typeof useInquiriesPublish>,
'onSave' | 'loading' | 'markup' | 'copyToClipboard'
>;

export const InquiriesPublishForm: React.FC<InquiriesPublishFormProps> = ( {
	onSave,
	loading,
	markup,
	copyToClipboard,
} ) => {
	const [ codeVisible, setCodeVisible ] = useState( false );

	const {
		data: urlData,
		error: urlError,
		loading: urlLoading,
	} = useInquiriesPublishForm_GetGlobalVariablesQuery();

	useEffect( () => {
		if ( urlError ) {
			showError( urlError );
		}
	}, [ urlError ] );

	return (
		<Box className={ styles.container }>
			{ loading ? (
				<Box className={ styles.loading }>
					<MuiSpinner />
				</Box>
			) : null }
			<Typography variant='h6'>Publish inquiry form</Typography>
			<Typography>Your inquiry form is ready to go.</Typography>
			<Typography>
				Copy the code and embed it in your website. You can find more detailed
				information and instructions{ ' ' }
				<ExtendedLink
					className='legacyLink'
					// the loading state will disable in the case of href being nothing
					href={ urlData?.getGlobalVariables.InquiryFormSupportUrl || '' }
					target='_blank'
					rel='noreferrer'
					loading={ urlLoading }
				>
					here
				</ExtendedLink>
				.
			</Typography>
			<Typography>
				Once the RPC form is live on your website all future updates will
				automatically populate on save.
			</Typography>
			<Box className={ styles.code } data-label='Inquiry form code'>
				<IconButton
					onClick={ () => setCodeVisible( ( prevState ) => !prevState ) }
					className={ styles.iconButton }
				>
					<Icon
						type={ codeVisible ? 'chevron-up' : 'chevron-down' }
						className={ styles.icon }
					/>
				</IconButton>
				<Collapse in={ codeVisible }>
					<br />
					{ markup.split( '\n' )[ 0 ] }
					<br />
					{ markup.split( '\n' )[ 1 ] }
				</Collapse>
			</Box>
			<Box className={ styles.buttons }>
				<MuiTooltip
					target={
						<MuiButton onClick={ () => onSave() } loading={ loading }>
							Save
						</MuiButton>
					}
				>
					Click here to save any edited work. Saving will automatically update
					any published forms.
				</MuiTooltip>
				<MuiTooltip
					target={
						<MuiButton
							onClick={ copyToClipboard( CodeSnippetParts.Markup ) }
							loading={ loading }
						>
							Copy HTML code
						</MuiButton>
					}
				>
					Click here to copy the HTML code to embed on your website.
				</MuiTooltip>
				<MuiTooltip
					target={
						<MuiButton
							onClick={ copyToClipboard( CodeSnippetParts.HostedFormURL ) }
							loading={ loading }
						>
							Copy web form URL
						</MuiButton>
					}
				>
					Click here to copy the URL for a published website page that will
					display your custom form.
				</MuiTooltip>
			</Box>
		</Box>
	);
};
