import SideNavContainer from '../SideNav/SideNavContainer';
import styles from './SideNavLayout.module.scss';

import React from 'react';

export default function SideNavLayoutComponent( {
	children,
	featureFlags,
	isShowingSideNav,
}: {
	children: React.ReactNode;
	featureFlags: any;
	isShowingSideNav: boolean;
} ) {
	return (
		<div { ...( isShowingSideNav ? { className: styles.root } : {} ) }>
			{ isShowingSideNav && (
				<div className={ styles.sideNavWrap }>
					<SideNavContainer featureFlags={ featureFlags } />
				</div>
			) }
			<div className={ styles.contentWrap }>{ children }</div>
		</div>
	);
}
