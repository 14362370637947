import { BundleStatusEnum } from '../../../types/generated';
import { createBundleObject } from './createBundleObject';

export enum DocumentType {
	Proposal,
	Contract,
	Invoice,
}

export const getIsLastDocumentInBundle = (
	documentType: DocumentType,
	contactId?: string
) => {
	if ( contactId ) {
		const bundle = createBundleObject( contactId );
		if ( !bundle ) {
			/* if contactId exists, meaning that we're creating a bundle, yet createBundleObject returns null,
			then that tells us this is a one-document bundle, like two NotAwaiting values would also indicate */
			return true;
		} else {
			const makingContract =
				bundle.contractBundleStatus === BundleStatusEnum.Awaiting;
			const makingInvoice =
				bundle.invoiceBundleStatus === BundleStatusEnum.Awaiting;
			if ( documentType === DocumentType.Proposal ) {
				return !makingContract && !makingInvoice;
			} else if ( documentType === DocumentType.Contract ) {
				return !makingInvoice;
			} else {
				// Invoice can't _not_ be the last document in a bundle
				return true;
			}
		}
	}
	// if there's no contactId then we can't have a bundle, so there is no "last document"
	return false;
};
