import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FileVideo = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.25 3c0-.966.784-1.75 1.75-1.75h12.903c.463 0 .907.183 1.235.51l3.096 3.082c.33.328.516.775.516 1.24V21A1.75 1.75 0 0 1 20 22.75H4A1.75 1.75 0 0 1 2.25 21V3ZM4 2.75a.25.25 0 0 0-.25.25v18c0 .138.112.25.25.25h16a.25.25 0 0 0 .25-.25V6.082a.25.25 0 0 0-.074-.177L17.08 2.823a.25.25 0 0 0-.176-.073H4ZM5.25 9A.75.75 0 0 1 6 8.25h12a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75v-4a.75.75 0 0 1 1.5 0v3.25H17.25v-7.5H6.75v.75a.75.75 0 0 1-1.5 0V9Zm9.189 5.108a.75.75 0 0 0 0-1.216l-3-2.165a.75.75 0 0 0-1.189.608v4.33a.75.75 0 0 0 1.189.608l3-2.165Zm-2.689-1.307.968.699-.968.699V12.8Z'
		/>
	</svg>
);

export default FileVideo;
