import { ApiResponseType } from '../../../../hooks/useMutation';
import Query from '../../../Query.js';
import { CreateProposalTemplateReturnFields } from '../../returnFields/proposals';
import {
	CreateProposalTemplateResponse,
	ProposalTemplateParams,
} from '../types';

const createProposalTemplate = async function(
	this: any,
	paramsData: Partial<ProposalTemplateParams>
): Promise<ApiResponseType<CreateProposalTemplateResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createProposalTemplate',
			params: {
				data: paramsData,
			},
			returnFields: CreateProposalTemplateReturnFields,
			excludeEnumParams: [ 'type' ],
			disableRemovingSemicolonsFromStringParams: true,
		} )
	);

	if ( errors ) return { errors };

	return data.data.createProposalTemplate;
};

export default createProposalTemplate;
