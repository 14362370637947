import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type YourBusiness_GetGlobalVariablesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type YourBusiness_GetGlobalVariablesQuery = { __typename?: 'Query', getGlobalVariables: { __typename?: 'FormattedGlobalVariables', StripeLegalUrl?: string | null, TermsOfServiceUrl?: string | null } };


export const YourBusiness_GetGlobalVariablesDocument = gql`
    query YourBusiness_getGlobalVariables {
  getGlobalVariables {
    StripeLegalUrl
    TermsOfServiceUrl
  }
}
    `;

/**
 * __useYourBusiness_GetGlobalVariablesQuery__
 *
 * To run a query within a React component, call `useYourBusiness_GetGlobalVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useYourBusiness_GetGlobalVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYourBusiness_GetGlobalVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useYourBusiness_GetGlobalVariablesQuery(baseOptions?: Apollo.QueryHookOptions<YourBusiness_GetGlobalVariablesQuery, YourBusiness_GetGlobalVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YourBusiness_GetGlobalVariablesQuery, YourBusiness_GetGlobalVariablesQueryVariables>(YourBusiness_GetGlobalVariablesDocument, options);
      }
export function useYourBusiness_GetGlobalVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YourBusiness_GetGlobalVariablesQuery, YourBusiness_GetGlobalVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YourBusiness_GetGlobalVariablesQuery, YourBusiness_GetGlobalVariablesQueryVariables>(YourBusiness_GetGlobalVariablesDocument, options);
        }
export type YourBusiness_GetGlobalVariablesQueryHookResult = ReturnType<typeof useYourBusiness_GetGlobalVariablesQuery>;
export type YourBusiness_GetGlobalVariablesLazyQueryHookResult = ReturnType<typeof useYourBusiness_GetGlobalVariablesLazyQuery>;
export type YourBusiness_GetGlobalVariablesQueryResult = Apollo.QueryResult<YourBusiness_GetGlobalVariablesQuery, YourBusiness_GetGlobalVariablesQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    