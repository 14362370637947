import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import Icon from './icons/index';
import P from './Paragraph';
import Spinner from './Spinner';
import React from 'react';

const ButtonBase = styled.button`
	&.button {
		display: flex;
		cursor: pointer;
		padding: 0.5rem 1rem;
		min-width: fit-content;
		width: 11.975rem;
		height: 3rem;
		border-radius: 5px;
		background-color: ${ ( props ) => props.theme.green };
		border: none;

		&&--with-icon:not(.button--collapse):not(.button--round) {
			width: 10.125rem;
			height: 3.625rem;
			.button__content-container .button-text {
				margin-top: 0.1rem;
				font-size: 0.875rem;
				line-height: 1.43;
			}
		}

		&&--round {
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 100%;

			svg {
				margin: 0;
			}
		}

		&&--pill {
			width: fit-content;
			height: fit-content;
			padding: 0.25rem 1rem;
			border-radius: 16.5px;
		}

		&--collapse {
			width: auto;
			height: auto;
			min-width: 0;
		}

		:hover {
			background-color: ${ ( props ) => props.theme.pink };
		}

		&:focus {
			outline: none;
		}

		.button__content-container {
			display: flex;
			margin: auto;

			.button-text {
				margin: 0;
				font-size: 1.125rem;
				line-height: normal;
				color: ${ ( props ) => props.theme.white };
				opacity: 1;
				&.button-text--capitalize {
					text-transform: uppercase;
				}
			}

			svg {
				height: 1.3rem;
				width: 1.3rem;
				object-fit: contain;
				margin: 0 0.5rem 0 0;

				* {
					fill: ${ ( props ) =>
		props.disabled ? props.theme.darkerShade : 'white' };
				}
			}
		}

		&&--ghost {
			background-color: #fff;
			border: 1.5px solid ${ ( props ) => props.theme.green };

			.button__content-container {
				.button-text {
					color: ${ ( props ) => props.theme.green };
				}
			}

			&:hover {
				border: 1px solid ${ ( props ) => props.theme.pink };

				.button__content-container {
					.button-text {
						color: ${ ( props ) => props.theme.pink };
					}
				}
			}
		}

		&.button--disabled {
			pointer-events: none;
		}

		&.button--disabled,
		.button--disabled:hover,
		&--gray {
			background-color: ${ ( props ) => props.theme.lightGray };

			.button__content-container {
				.button-text {
					color: ${ ( props ) => props.theme.black };
					opacity: 0.3;
				}
			}

			svg {
				* {
					fill: ${ ( props ) => props.theme.black };
					opacity: 0.3;
				}
			}
		}

		&--gray:hover {
			.button__content-container {
				p {
					color: #fff;
					opacity: 1;
				}
			}
		}

		&--green-outline {
			background-color: transparent;
			border: 1px solid ${ ( props ) => props.theme.green };

			.button__content-container {
				svg {
					* {
						fill: ${ ( props ) => props.theme.green };
					}
				}

				p.button-text {
					color: ${ ( props ) => props.theme.green };
				}
			}

			&:hover {
				background-color: ${ ( props ) => props.theme.green };
				svg {
					* {
						fill: ${ ( props ) => props.theme.green };
					}
				}

				p.button-text {
					color: #fff;
				}
			}
		}

		&--blue {
			background-color: ${ ( props ) => props.theme.darkBlue };
		}

		&--back,
		&&--with-icon.button--back {
			width: auto;
			height: auto;
			padding: 0.5rem 1rem;
			p,
			.button-text {
				margin: 0;
				font-size: 1rem;
			}

			svg {
				width: 1.25rem;
				height: 1.25rem;
				margin: 0 0.25rem 00;
			}
		}

		&--loading {
			pointer-events: none;

			.spinner {
				transform: scale(0.3);
				margin-top: -1.4rem;
				div {
					background-color: ${ ( props ) => props.theme.white };
				}
			}
		}
	}
`;

/**
 *
 * @param {{
 *   capitalize?: boolean,
 *   className?: string,
 *   disabled?: boolean,
 *   icon?: string,
 *   id?: string,
 *   link?: object,
 *   loading?: boolean,
 *   onClick?: ( event: React.ChangeEvent<HTMLButtonElement>) => void,
 *   pill?: boolean,
 *   ghost?: boolean,
 *   round?: boolean,
 *   text?: string,
 *   type?: string,
 *   withIcon?: boolean,
 *	 dataCy?: string,
 * }} props
 * @returns { JSX.Element }
 */
const Button = ( props ) => {
	const {
		capitalize,
		className,
		disabled,
		icon,
		id,
		link,
		loading,
		onClick,
		pill,
		ghost,
		round,
		text,
		type,
		withIcon,
		dataCy,
	} = props;

	const inner = text || props.children;
	const wrap = ( content ) =>
		link ? (
			<Link { ...link } className='legacyLink'>
				{ content }
			</Link>
		) : (
			content
		);

	let buttonBaseClass = 'button';
	if ( disabled ) buttonBaseClass += ' button--disabled';
	if ( className ) buttonBaseClass += ` ${ className }`;
	if ( withIcon ) buttonBaseClass += ' button--with-icon';
	if ( round ) buttonBaseClass += ' button--round';
	if ( pill ) buttonBaseClass += ' button--pill';
	if ( ghost ) buttonBaseClass += ' button--ghost';
	if ( loading ) buttonBaseClass += ' button--loading';

	return wrap(
		<ButtonBase
			capitalize={ capitalize }
			className={ buttonBaseClass }
			disabled={ disabled }
			data-cy={ dataCy }
			id={ id }
			onClick={ ( e ) => {
				if ( !!onClick && !loading ) {
					onClick( e );
					return;
				}

				return {};
			} }
			type={ type }
		>
			<div
				className={ `button__content-container ${
					withIcon ? 'button__content-container--with-icon' : ''
				}` }
			>
				{ withIcon && !loading && <Icon className='button-icon' type={ icon } /> }
				{ inner && !loading && (
					<P className={ `button-text ${ capitalize ? 'p--caps' : '' }` }>
						{ inner }
					</P>
				) }
				{ loading && <Spinner /> }
			</div>
		</ButtonBase>
	);
};

Button.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	id: PropTypes.string,
	round: PropTypes.bool,
	text: PropTypes.string,
	type: PropTypes.string,
	withIcon: PropTypes.bool,
	link: PropTypes.shape( {
		href: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
		as: PropTypes.string,
	} ),
	onClick: PropTypes.func,
	capitalize: PropTypes.bool,
	loading: PropTypes.bool,
};

export default Button;
