/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CheckCircleIndeterminate = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.40759 12C2.40759 6.61522 6.77282 2.25 12.1576 2.25C17.5424 2.25 21.9076 6.61522 21.9076 12C21.9076 17.3848 17.5424 21.75 12.1576 21.75C6.77282 21.75 2.40759 17.3848 2.40759 12ZM5.90759 11.25C5.49338 11.25 5.15759 11.5858 5.15759 12C5.15759 12.4142 5.49338 12.75 5.90759 12.75H18.4076C18.8218 12.75 19.1576 12.4142 19.1576 12C19.1576 11.5858 18.8218 11.25 18.4076 11.25H5.90759Z'
		/>
	</svg>
);

export default CheckCircleIndeterminate;
