import React, { ReactNode } from 'react';
import SideNavLayoutComponent from './SideNavLayoutComponent';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import useIsOnBoardingRoute from '../../hooks/useIsOnBoardingRoute';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';

export default function SideNavLayoutContainer( {
	children,
	featureFlags,
	hideSideNav,
}: {
	children: ReactNode;
	featureFlags: any;
	hideSideNav?: boolean;
} ) {
	const isLoggedIn = useIsLoggedIn();
	const { isDesktop } = useDeviceDetection();
	const isOnBoardingRoute = useIsOnBoardingRoute();

	const isShowingSideNav =
		isLoggedIn && isDesktop && !isOnBoardingRoute && !hideSideNav;

	return (
		<SideNavLayoutComponent
			featureFlags={ featureFlags }
			isShowingSideNav={ isShowingSideNav }
		>
			{ children }
		</SideNavLayoutComponent>
	);
}
