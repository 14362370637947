import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import styles from './MobileMenuHelpAccordion.module.scss';
import QuestionMarkIcon from '../../../../mui/Icons/QuestionMarkIcon';
import { gql } from '@apollo/client';
import MuiSpinner from '../../../../mui/MuiSpinner';
import { useMobileMenuHelpAccordion_GetGlobalVariablesQuery } from './__generated__';
import { useOrgOnboardingModal } from '../../../../hooks/useOrgOnboardingModal';
import { OrgOnboardingModal } from '../../../OrgOnboardingModal';
import CustomMenuItemComponent from '../../../../mui/CustomMenuItem/CustomMenuItemComponent';
import { ChevronDownIcon } from '../../../../mui/Icons';
import CustomMenuItemList from '../../../../mui/CustomMenuItemList/CustomMenuItemList';
import classNames from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query MobileMenuHelpAccordion_getGlobalVariables {
		getGlobalVariables {
			HelpUrl
		}
	}
`;

export type MobileMenuHelpAccordionProps = {
	onClick: () => void;
};

export const MobileMenuHelpAccordion: React.FC<
MobileMenuHelpAccordionProps
> = ( { onClick } ) => {
	const [ open, setOpen ] = useState( false );

	const ref = useRef<HTMLLIElement>( null );
	const timeout = useRef<NodeJS.Timeout>();

	const { data: globalVariableData, loading: globalVariableLoading } =
		useMobileMenuHelpAccordion_GetGlobalVariablesQuery();
	const href = globalVariableData?.getGlobalVariables.HelpUrl;

	const {
		setOpen: setOrgOnboardingModalOpen,
		isOrgOwner,
		...orgOnboardingModalProps
	} = useOrgOnboardingModal();

	const handleClose = useCallback( () => setOpen( false ), [] );

	// close this menu if the org onboarding modal opens
	useEffect( () => {
		if ( orgOnboardingModalProps.open ) {
			setOpen( false );
		}
	}, [ orgOnboardingModalProps ] );

	// Lets make sure the accordion, when open, has viewable menu items despite not being fixed
	useEffect( () => {
		if ( isOrgOwner ) {
			clearTimeout( timeout.current );
			if ( open ) {
				timeout.current = setTimeout(
					() => ref.current?.scrollIntoView( { behavior: 'smooth' } ),
					250
				);
			}
		}
	}, [ open, isOrgOwner ] );

	if ( globalVariableLoading || !href ) {
		return <MuiSpinner />;
	}

	if ( isOrgOwner ) {
		return (
			<>
				<Accordion expanded={ open }>
					<AccordionSummary
						aria-controls='panel1bh-content'
						onClick={ () => setOpen( ( prevState ) => !prevState ) }
						className={ classNames( styles.accordionToggle, {
							[ styles.accordionToggleActive ]: open,
						} ) }
						expandIcon={ <ChevronDownIcon /> }
					>
						<QuestionMarkIcon className={ styles.icon } />
						<Typography className={ styles.copy }>RPC Help Center</Typography>
					</AccordionSummary>
					<div>
						<CustomMenuItemList
							items={ [
								{
									id: 'helpLink',
									href: href,
									target: '_blank',
									rel: 'noreferrer',
									label: 'Help Center',
									onClick: () => {
										handleClose();
										onClick();
									},
								},
								{
									id: 'orgOnboardingModal',
									label: 'Getting Started',
									onClick: () => {
										setOrgOnboardingModalOpen( true );
										handleClose();
										onClick();
									},
									ref,
								},
							] }
						/>
					</div>
				</Accordion>
				<OrgOnboardingModal { ...orgOnboardingModalProps } />
			</>
		);
	}

	return (
		<CustomMenuItemComponent
			menuItem={ {
				id: 'rpcHelpCenter',
				className: styles.rpcHelpCenterLink,
				href: href,
				label: 'RPC Help Center',
				icon: <QuestionMarkIcon />,
				target: '_blank',
				rel: 'noreferrer',
				onClick,
			} }
		/>
	);
};
