import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FileFolder = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			d='M3.452 4C2.65 4 2 4.65 2 5.452V17.9a2.282 2.282 0 0 0 2.282 2.282h14.936a2.282 2.282 0 0 0 2.282-2.282v-7.883c0-.802-.65-1.452-1.452-1.452H19.01V7.112c0-.802-.65-1.452-1.452-1.452h-5.424l-.429-.857A1.452 1.452 0 0 0 10.407 4H3.452Zm14.314 3.112v1.452H5.941c-.801 0-1.452.65-1.452 1.452v8.92h-.207A1.037 1.037 0 0 1 3.245 17.9V5.452c0-.114.093-.207.207-.207h6.955c.079 0 .15.044.186.114l.6 1.201c.106.211.321.344.557.344h5.809c.114 0 .207.093.207.208Zm1.452 11.824H5.734v-8.92c0-.115.093-.207.207-.207h14.107c.114 0 .207.092.207.207v7.883c0 .573-.464 1.037-1.037 1.037Z'
		/>
	</svg>
);

export default FileFolder;
