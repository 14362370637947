import Query from '../../../Query.js';
import {
	ClientUserReturnFields,
	ClientUserDetailReturnFields,
	ContactWithIdentifiersReturnFields,
} from '../../returnFields';

/**
 * Create a unique ClientUser object
 * @param {object} data - "input ClientUserCreateInput"
 * @param {string} data.email - Email associated with the account. Required.
 * @param {string} data.password - Password associated with the account. Required.
 * @param {string} data.firstNameOne - First partner's first name. Required.
 * @param {string} data.firstNameOne - First partner's first name. Required.
 * @param {string} data.lastNameOne - First partner's last name. Required.
 * @param {string} data.firstNameTwo - Second partner's first name. Required.
 * @param {string} data.lastNameTwo - Second partner's last name. Required.
 * @param {string} data.phone - Couple's phone number. Required.
 *
 * @returns {object} res - {user, errors}
 */
const createClientUser = async function( data ) {
	return await this.request(
		new Query( {
			type: 'mutation',
			name: 'createClientUser',
			params: { data },
			returnFields: ClientUserDetailReturnFields,
		} )
	).then( ( res ) => {
		return {
			user: res?.data?.data?.createClientUser,
			errors: res?.data?.errors,
		};
	} );
};

/**
 * Update a single OrgUser.
 *
 * @param {object} data - "input OrgUserUpdateInput"
 * @param {object} id - OrgUser's ID.
 *
 * @returns {object} res - {user, errors}
 */
const updateClientUser = async function( data, id ) {
	const where = { id };
	return await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateClientUser',
			params: { data, where },
			returnFields: ClientUserDetailReturnFields,
		} )
	).then( ( res ) => {
		if ( !res.errors ) {
			return {
				user: res.data.data.updateClientUser,
				errors: res.data.errors,
			};
		}

		return { errors: res.errors };
	} );
};

const getClientUsers = async function( params ) {
	return await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUsersWhere',
			params,
			returnFields: [ { clientUsers: ClientUserReturnFields } ],
		} )
	);
};

/**
 * Gets a client user given a unique identifier.
 *
 * @param { Object } where - "input ClientUserWhereUniqueInput" from the API
 *
 * @returns { Promise<{ user | errors }> }
 */
const getClientUserWhere = async function( where ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUserWhere',
			params: { where },
			returnFields: ClientUserDetailReturnFields,
		} )
	);
	if ( errors ) return { errors };
	const user = data.data.getClientUserWhere;

	return {
		user,
	};
};

/**
 * @param {File} clientID - Client User ID.
 *
 * @returns {Promise<Object>} res - { errors, address }
 */
const getClientAddress = async function( clientID ) {
	const where = { id: clientID };

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUserWhere',
			params: { where },
			returnFields: [
				'id',
				'addressLine1',
				'addressLine2',
				'country',
				'state',
				'city',
				'postalCode',
			],
		} )
	);
	if ( errors ) return { errors };

	return {
		address: data.data.getClientUserWhere,
	};
};

/**
 * Search for client users with an array of strings.
 * "id" is the vendor id
 * @param { {
 *   queries: Array<string>,
 *   id: string,
 *   limit?: number,
 *   skip?: number
 * } } arg
 * @returns {Promise<Object[]>}
 */
const searchClientUsers = async function( { queries, id, limit, skip } ) {
	const results = [];
	const clientQueryReducer = ( constraints, query ) => {
		return [
			...constraints,
			{ firstNameOne_contains: query },
			{ lastNameOne_contains: query },
			{ firstNameTwo_contains: query },
			{ lastNameTwo_contains: query },
			{ venue_contains: query },
			{ city_contains: query },
			{ facebook_contains: query },
			{ twitter_contains: query },
			{ pinterest_contains: query },
			{ instagram_contains: query },
		];
	};

	const connectedClientsWhere = {
		OR: queries.reduce( clientQueryReducer, [] ),
		user: { contacts: { some: { vendor: { id } } } },
	};

	// get connected clients first so they appear first in results
	const { data, errors } = await this.getClientUsers( {
		where: connectedClientsWhere,
		take: limit - results.length, // only get as many as we need
		skip,
	} );
	if ( errors ) return results;

	const connectedClients = data.data.getClientUsersWhere.clientUsers;

	const IDs = [];

	if ( connectedClients && connectedClients.length ) {
		connectedClients.forEach( ( client ) => {
			IDs.push( client.id );
			client.contactStatus = client.status;
			results.push( client );
		} );

		if ( results.length >= limit ) return results;
	}

	const clientWhere = {
		NOT: [],
		OR: queries.reduce( clientQueryReducer, [] ),
	};

	for ( const ID of IDs ) {
		clientWhere.NOT.push( { id: ID } );
	}

	// get unconnected clients next, so vendors/planner can seek out new clients
	const { data: clientData, errors: clientErrors } = await this.getClientUsers( {
		where: clientWhere,
		take: limit - results.length, // only get as many as we need
		skip,
	} );

	if ( clientErrors ) return results;

	const clientUsers = clientData.data.getClientUsersWhere.clientUsers;

	clientUsers.forEach( ( clientUser ) => results.push( clientUser ) );

	return results;
};

/**
 * Gets a client user given a unique identifier.
 *
 * @param { Record<string, any> } where - "input ClientUserWhereUniqueInput" from the API
 *
 * @returns { Promise<{ contacts: Array<Record<string, any>> } | { errors: Array<Error> }> }
 */
const getContactsWhere = async function( where ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getContactsWhere',
			params: { where },
			returnFields: ContactWithIdentifiersReturnFields,
		} )
	);
	if ( errors ) return { errors };
	const contacts = data.data.getContactsWhere.contacts;

	return {
		contacts,
	};
};

/**
 * Search for customers on contacts with an array of strings.
 * "id" is the vendor id
 * @param { {
 *   queries: Array<string>,
 *   orgUserId: string,
 *   isAdmin: boolean,
 *   limit?: number,
 *   skip?: number
 * } } arg
 * @returns {Promise<Object[]>}
 */
const searchContactCustomers = async function( {
	queries,
	orgUserId,
	isAdmin,
	limit,
	skip,
} ) {
	const results = [];
	const contactQueryReducer = ( constraints, query ) => {
		return [
			...constraints,
			{ firstName_contains: query },
			{ lastName_contains: query },
			{
				customer: {
					clientUser: {
						OR: [
							{ venue_contains: query },
							{ city_contains: query },
							{ facebook_contains: query },
							{ twitter_contains: query },
							{ pinterest_contains: query },
							{ instagram_contains: query },
						],
					},
				},
			},
		];
	};

	const contactsWhere = {
		OR: queries.reduce( contactQueryReducer, [] ),
		...( isAdmin
			? { vendor: { users: { some: { id: orgUserId } } } }
			: { assignedMember: { id: orgUserId } } ),
	};

	// get connected clients first so they appear first in results
	const { contacts } = await this.getContactsWhere( {
		where: contactsWhere,
		take: limit - results.length, // only get as many as we need
		skip,
		orderBy: [ { firstName: 'asc' }, { lastName: 'asc' } ],
	} );
	// just ignoring errors intentionally. When Toast is included in this file Cypress breaks

	return contacts;
};

/**
 * Gets a client's upcoming payments (within 30 days) and all active contacts to display
 * in the dashboard infographic containers.
 *
 * @param { String } clientUserID - Client User ID.
 *
 * @returns {Promise<{errors}|{upcomingPayments, activeContacts }>}
 */
const getDashboardStatsForClient = async function( clientUserID ) {
	const where = { id: clientUserID };

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getDashboardStatsForClient',
			params: { where },
			returnFields: [ 'upcomingPayments', 'activeContacts' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		return data.data.getDashboardStatsForClient;
	}
};

/**
 * Gets an org user's month in review to display in the dashboard infographic containers: the number
 * of client contacts made, invoices paid, contracts signed, and the value of incoming payments.
 *
 * @param { String } orgUserID - Org User ID.
 *
 * @returns {Promise<{errors: Error[]}|{clientContacts: number, completedPaymentsAmount: number, signedContracts: number, outstandingPaymentsAmount: number}>}
 */
const getDashboardStatsForOrg = async function( orgUserID ) {
	const where = { id: orgUserID };

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getDashboardStatsForOrg',
			params: { where },
			returnFields: [
				'clientContacts',
				'signedContracts',
				'completedPaymentsAmount',
				'outstandingPaymentsAmount',
			],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		return data.data.getDashboardStatsForOrg;
	}
};

export {
	updateClientUser,
	createClientUser,
	getClientUsers,
	getClientUserWhere,
	getClientAddress,
	searchClientUsers,
	getContactsWhere,
	searchContactCustomers,
	getDashboardStatsForClient,
	getDashboardStatsForOrg,
};
