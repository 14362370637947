/* eslint max-len: 0 */
const PaperClip = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M12.342.25c2.971 0 5.78 2.724 5.904 6.369l.004.25v8.249c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.649l-.007-.101v-8.25c0-2.938-2.195-5.118-4.408-5.118-2.202 0-4.466 2.094-4.587 4.885l-.005.234v11.25c0 2.343.907 3.586 2.75 3.586 1.78 0 2.686-1.159 2.747-3.348l.003-.238V6.869c0-1.08-.298-1.567-.908-1.567-.639 0-1.042.509-1.088 1.396l-.004.17v9.878c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648l-.007-.102V6.87c0-1.735.959-3.067 2.592-3.067 1.573 0 2.35 1.174 2.405 2.869l.003.198v11.25c0 3.088-1.458 5.086-4.25 5.086-2.714 0-4.168-1.889-4.247-4.832l-.003-.254V6.869C6.25 3.115 9.267.25 12.342.25z'
			transform='translate(-464 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(320)'
		/>
	</svg>
);

export default PaperClip;
