import React, { useId } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
} from '@mui/material';

export type FontSizePickerProps = Pick<
SelectProps<string>,
'value' | 'onChange' | 'onBlur' | 'onSelect'
>;

export const FontSizePicker: React.FC<FontSizePickerProps> = ( {
	value,
	onChange,
	onBlur,
	onSelect,
} ) => {
	const labelId = useId();
	return (
		<FormControl fullWidth>
			<InputLabel id={ labelId }>Font size</InputLabel>
			<Select<string>
				labelId={ labelId }
				value={ value }
				label='Font size'
				onChange={ onChange }
				onBlur={ onBlur }
				onSelect={ onSelect }
			>
				<MenuItem value='0.85rem'>Small</MenuItem>
				<MenuItem value='1rem'>Medium</MenuItem>
				<MenuItem value='1.125rem'>Large</MenuItem>
			</Select>
		</FormControl>
	);
};
