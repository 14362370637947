import classNames from 'classnames';
import React from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';

import styles from './CustomMenu.module.scss';

interface CustomMenuProps extends MenuProps {
	arrowPosition?: 'left' | 'top';
}

export default function CustomMenu( {
	children,
	arrowPosition = 'top',
	...rest
}: CustomMenuProps ) {
	const arrowProps: Pick<MenuProps, 'transformOrigin' | 'anchorOrigin'> =
		arrowPosition === 'left'
			? {
				transformOrigin: { horizontal: 'left', vertical: 'bottom' },
				anchorOrigin: { horizontal: 'right', vertical: 'center' },
			  }
			: {
				transformOrigin: { horizontal: 'right', vertical: 'top' },
				anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
			  };

	return (
		<Menu
			{ ...arrowProps }
			disableScrollLock={ true }
			classes={ {
				paper: classNames( styles.paper, {
					[ styles.paperArrowTop ]: arrowPosition === 'top',
					[ styles.paperArrowLeft ]: arrowPosition === 'left',
				} ),
			} }
			{ ...rest }
		>
			{ children }
		</Menu>
	);
}
