import * as mathjs from 'mathjs';

/**
 * Multiplies an two inputs as BigNumbers.
 *
 * @param {String | Int | Float} number1
 * @param {String | Int | Float} number2
 *
 * @returns {Int | Float} result
 */
export const multiply = function( number1, number2 ) {
	const BN = mathjs.multiply(
		mathjs.bignumber( number1 ),
		mathjs.bignumber( number2 )
	);
	const result = mathjs.number( BN );
	return result;
};

/**
 * Divides number by number 2 as BigNumbers.
 *
 * @param {String | Int | Float} number1
 * @param {String | Int | Float} number2
 *
 * @returns {Int | Float} result
 */
export const divide = function( number1, number2 ) {
	const BN = mathjs.divide(
		mathjs.bignumber( number1 ),
		mathjs.bignumber( number2 )
	);
	const result = mathjs.number( BN );
	return result;
};

/**
 *
 * @param{ String | Integer | Float } number
 *
 * @return { Integer }
 */
export const round = function( number ) {
	const returnable = mathjs.round( mathjs.bignumber( number ) );
	return mathjs.number( returnable );
};

/**
 * Adds an array of numbers together as BigNumbers
 *
 * @param { Array } numbers
 *
 * @return { Float }
 */
export const add = function( numbers ) {
	const wholes = [];
	for ( const number of numbers ) {
		wholes.push( mathjs.number( number ) );
	}
	const BN = mathjs.add( ...wholes );
	return mathjs.number( BN );
};

/**
 * Subtracts subtrahend from minuend as Bignumbers
 *
 * @param{ String | Integer | Float } minuend
 * @param{ String | Integer | Float } subtrahend
 *
 * @return { Float }
 */
export const subtract = function( minuend, subtrahend ) {
	const difference = mathjs.subtract(
		mathjs.bignumber( minuend ),
		mathjs.bignumber( subtrahend )
	);
	return mathjs.number( difference );
};

/**
 *
 * @param { String | Integer | Float } number
 *
 * @return { Integer }
 */
export const floor = function( number ) {
	const returnable = mathjs.floor( mathjs.bignumber( number ) );
	return mathjs.number( returnable );
};

/**
 *
 *
 * @param { String | Integer | Float } dividend
 * @param { String | Integer | Float } divisor
 *
 * @return { Integer }
 */
export const mod = function( dividend, divisor ) {
	const returnable = mathjs.mod(
		mathjs.bignumber( dividend ),
		mathjs.bignumber( divisor )
	);
	return mathjs.number( returnable );
};
