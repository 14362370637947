import { Divider, IconButton } from '@mui/material';
import { Editor } from '@tiptap/react';
import React from 'react';
import Bold from '../../../../elements/icons/Bold';
import Italic from '../../../../elements/icons/Italic';
import OrderedList from '../../../../elements/icons/OrderedList';
import Underline from '../../../../elements/icons/Underline';
import UnorderedList from '../../../../elements/icons/UnorderedList';
import styles from './MuiTipTapMenuElements.module.scss';

export type MuiTipTapMenuElementsProps = {
	editor: Editor;
	onBlur: () => void;
	onFocus: () => void;
};

const MuiTipTapMenuElements: React.FC<MuiTipTapMenuElementsProps> = ( {
	editor,
	onBlur,
	onFocus,
} ) => (
	<>
		<IconButton
			size='small'
			onClick={ () => editor.chain().focus().toggleBold().run() }
			className={ [ styles.button, editor.isActive( 'bold' ) ? styles.active : undefined, ].join( ' ' ) }
			onFocus={ onFocus }
			onBlur={ onBlur }
		>
			<Bold className={ styles.icon } />
		</IconButton>
		<IconButton
			size='small'
			onClick={ () => editor.chain().focus().toggleItalic().run() }
			className={ [ styles.button, editor.isActive( 'italic' ) ? styles.active : undefined, ].join( ' ' ) }
			onFocus={ onFocus }
			onBlur={ onBlur }
		>
			<Italic className={ styles.icon } />
		</IconButton>
		<IconButton
			size='small'
			onClick={ () => editor.chain().focus().toggleUnderline().run() }
			className={ [ styles.button, editor.isActive( 'underline' ) ? styles.active : undefined, ].join( ' ' ) }
			onFocus={ onFocus }
			onBlur={ onBlur }
		>
			<Underline className={ styles.icon } />
		</IconButton>
		<Divider orientation='vertical' />
		<IconButton
			size='small'
			onClick={ () => editor.chain().focus().toggleBulletList().run() }
			className={ [ styles.button, editor.isActive( 'bulletList' ) ? styles.active : undefined, ].join( ' ' ) }
			onFocus={ onFocus }
			onBlur={ onBlur }
		>
			<UnorderedList className={ styles.icon } />
		</IconButton>
		<IconButton
			size='small'
			onClick={ () => editor.chain().focus().toggleOrderedList().run() }
			className={ [ styles.button, editor.isActive( 'orderedList' ) ? styles.active : undefined, ].join( ' ' ) }
			onFocus={ onFocus }
			onBlur={ onBlur }
		>
			<OrderedList className={ styles.icon } />
		</IconButton>
	</>
);

export default MuiTipTapMenuElements;
