/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Website = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12 0c6.46 0 11.727 5.104 11.99 11.498l.004.11c.004.13.006.26.006.392l-.003-.284c.007.283.004.567-.01.85l-.007.133c-.003.068-.008.136-.013.203-.012.156-.027.311-.044.466l-.016.133C23.166 19.422 18.117 24 12 24 5.373 24 0 18.627 0 12l.003.27c-.006-.272-.003-.545.009-.819L.02 11.3l.01-.147.014-.193.011-.117C.637 4.758 5.763 0 12 0zm0 1.5C7.243 1.5 3.226 4.663 1.935 9h4.394c1.032 0 1.932.703 2.183 1.704l.75 3c.168.672.017 1.384-.41 1.93-.426.547-1.08.866-1.773.866h-.465l-.798 3.987C7.551 21.753 9.688 22.5 12 22.5c3.566 0 6.718-1.778 8.615-4.496l-.594-.001c-1.1 0-2.039-.795-2.22-1.88L17.114 12h-.943c-.635 0-1.238-.268-1.662-.734l-.111-.132c-.427-.546-.578-1.258-.41-1.93l.75-3c.25-1.001 1.15-1.704 2.183-1.704h2.427c-1.894-1.856-4.487-3-7.348-3zm-5.671 9H1.606c-.07.49-.106.99-.106 1.5v-.07.141l.002.157-.002-.157c0 .078.002.156.004.234l-.002-.077c.003.1.006.2.011.3l-.009-.223c.075 2.527 1.063 4.957 2.795 6.824l.215.225.75-3.751c.064-.316.32-.552.63-.596L6 15h1.079c.23 0 .449-.106.591-.289.142-.182.192-.419.136-.643l-.75-3c-.083-.334-.383-.568-.727-.568zM20.603 6h-3.682c-.306 0-.577.185-.692.46l-.035.108-.75 3c-.056.224-.006.461.136.643.142.183.36.289.591.289h1.579c.367 0 .68.265.74.627l.79 4.75c.061.361.374.626.741.626h1.461l.09-.19c.41-.904.682-1.85.82-2.807.071-.492.108-.995.108-1.506v-.032-.039.039c0-.081-.001-.162-.004-.242l.004.203c0-.114-.003-.229-.008-.343l.004.14-.009-.246.005.106c-.076-1.878-.657-3.731-1.727-5.348L20.603 6z'
			transform='translate(-584 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(440)'
		/>
	</svg>
);

export default Website;
