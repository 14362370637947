/* eslint max-len: 0 */
const Pencil = ( props ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M16.4625 1.93246C17.2469 1.12996 18.3195 0.674197 19.4417 0.666504L19.7466 0.67551C20.7578 0.742135 21.7151 1.17237 22.4398 1.894C23.2319 2.68891 23.6733 3.76754 23.6658 4.88986C23.6581 6.01206 23.2022 7.08462 22.4054 7.86279L22.0077 8.26047L8.51634 21.7518C8.51613 21.752 8.51591 21.7523 8.5157 21.7525C8.51518 21.753 8.51467 21.7535 8.51415 21.754L8.51046 21.7577C8.41941 21.8488 8.30556 21.9137 8.18082 21.9457L1.56396 23.6428C1.03471 23.7785 0.553546 23.2974 0.689216 22.7681L2.38535 16.1513C2.41734 16.0265 2.48227 15.9126 2.57335 15.8215L2.57386 15.821L16.4625 1.93246ZM3.46494 17.7261L2.38297 21.9476L6.60866 20.8643L3.46494 17.7261ZM8.00552 20.2277L4.09881 16.3278L16.5792 3.8484L20.4831 7.75223L8.00552 20.2277ZM22.2159 5.12485C22.1586 5.72427 21.9069 6.28988 21.498 6.73435L17.5975 2.83385C18.0454 2.42387 18.6095 2.17359 19.2067 2.1164L19.4514 2.10388C20.1901 2.09894 20.9002 2.38937 21.4236 2.91057C21.9429 3.43172 22.2334 4.14158 22.2285 4.88013L22.2159 5.12485Z'
		/>
	</svg>
);

export default Pencil;
