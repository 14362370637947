import { useMediaQuery } from '@mui/material';

import variables from '../styles/_variables.module.scss';

export const useDeviceDetection = () => {
	const isDesktop = useMediaQuery(
		`(min-width:${ variables[ 'breakpoint-desktop' ] })`
	);

	const isTouch = useMediaQuery( '(pointer: coarse)' );

	return {
		isMobile: !isDesktop,
		isDesktop,
		isTouch,
	};
};
