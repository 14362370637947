/* eslint max-len: 0 */
const Contract = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			d='M14.379 0c.596 0 1.168.237 1.59.659l5.873 5.872c.421.422.658.994.658 1.59V21.75c0 1.243-1.007 2.25-2.25 2.25H3.75c-1.243 0-2.25-1.007-2.25-2.25V2.25C1.5 1.007 2.507 0 3.75 0zM13.5 1.5H3.75c-.414 0-.75.336-.75.75v19.5c0 .414.336.75.75.75h16.5c.414 0 .75-.336.75-.75V9h-5.25c-1.19 0-2.166-.925-2.245-2.096L13.5 6.75V1.5zM8.234 18.031c.355.087.657.315.86.63l.059.102.28-.189c.562-.377 1.269-.332 1.786.095l.1.09c.197.192.2.506.009.701-.173.176-.445.197-.64.06l-.062-.052c-.169-.164-.38-.202-.568-.113l-.069.04-.818.556c-.266.181-.624.064-.74-.222l-.022-.069-.068-.28c-.058-.208-.194-.35-.363-.392-.143-.041-.293-.012-.42.082l-.06.052-.752.768c-.192.195-.506.199-.702.008-.176-.172-.197-.444-.06-.639l.052-.062.758-.774c.395-.388.942-.536 1.44-.392zm5.42.115c.18-.205.495-.226.7-.045l.877.767.073.054c.177.115.402.154.616.101.23-.048.402-.202.468-.407l.08-.278.025-.069c.118-.262.444-.373.702-.222l.954.557.088.043c.27.113.587.068.817-.124l.066-.047c.205-.121.475-.08.633.108.176.21.149.522-.061.698l-.111.085c-.566.403-1.32.444-1.93.093l-.444-.259-.022.04c-.228.384-.603.656-1.043.748-.536.133-1.122-.003-1.558-.37l-.884-.774-.056-.058c-.152-.184-.153-.456.01-.64zM17.8 11.95c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H6.35c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007H17.8zm2.889-4.45L15 1.811V6.75c0 .38.282.693.648.743l.102.007h4.939zM11 4.95c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L11 6.45H6.35c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007H11z'
			transform='translate(-144 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49)'
		/>
	</svg>
);

export default Contract;
