import { useContext, useEffect, useId, useRef } from 'react';
import {
	ErrorScrollContext,
	identifierAttribute,
} from '../context/ErrorScrollContextProvider';

const useErrorScrollContext = ( error: boolean ) => {
	const ref = useRef<HTMLDivElement | null>( null );
	const id = useId();
	const errorScrollContext = useContext( ErrorScrollContext );
	useEffect( () => {
		if ( ref.current && !ref.current.getAttribute( identifierAttribute ) ) {
			ref.current.setAttribute( 'data-error-id', id );
		}
		if ( error ) {
			errorScrollContext?.add( ref );
		} else {
			errorScrollContext?.remove( ref );
		}
	}, [
		errorScrollContext,
		error,
		id
	] );
	return ref;
};

export default useErrorScrollContext;
