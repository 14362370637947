import React from 'react';
import { Badge, ButtonBase, Typography } from '@mui/material';
import styles from './SideNavButtonItem.module.scss';
import classNames from 'classnames';
import { CustomMenuButtonItemType } from '../../CustomMenuItem/CustomMenuItemComponent';

export default function SideNavButtonItem( {
	menuItem: { label, icon, isActive = false, onClick, className },
	badgeContent,
}: {
	menuItem: CustomMenuButtonItemType;
	badgeContent?: number;
} ) {
	return (
		<Badge badgeContent={ badgeContent } color='primary' className={ styles.badge }>
			<ButtonBase
				onClick={ onClick }
				className={ classNames(
					styles.button,
					{ [ styles.buttonActive ]: isActive },
					className
				) }
			>
				{ icon }
				{ label ? <Typography>{ label }</Typography> : null }
			</ButtonBase>
		</Badge>
	);
}
