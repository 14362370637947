import styled from 'styled-components';
import Input from './Input';
import React from 'react';

const Container = styled.div`
	width: 100%;
	:hover {
		opacity: 1;
	}
	padding: 2px;

	input[type='range']::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: ${ ( props ) => props.theme.white };
		border: 1px solid ${ ( props ) => props.theme.pink };
		cursor: pointer;
	}

	input[type='range'] {
		-webkit-appearance: none;
		width: 100%;
		height: 4px;
		padding: 0;
		border-radius: 2px;
		background: #d3d3d3;
		outline: none;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;

		&:focus-within,
		&:hover {
			border: 1px solid ${ ( props ) => props.theme.lightGray };
		}
	}

	input[type='range']::-moz-range-thumb {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: ${ ( props ) => props.theme.white };
		border: 1px ${ ( props ) => props.theme.pink };
		cursor: pointer;
	}

	input[type='range']::-ms-thumb {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: ${ ( props ) => props.theme.white };
		border: 1px ${ ( props ) => props.theme.pink };
		cursor: pointer;
	}
`;

class Slider extends React.Component {
	constructor( props ) {
		super( props );
		this.onChange = this.onChange.bind( this );
	}

	onChange( e ) {
		this.props.onChange( this.props.mapToState, e.target.value );
	}

	render() {
		const minVal = this.props.min || 1;
		const maxVal = this.props.max || 100;

		return (
			<Container>
				<Input
					className={ this.props.className }
					type='range'
					name={ this.props.mapToState }
					min={ minVal }
					max={ maxVal }
					{ ...this.props }
					onChange={ this.onChange }
				/>
			</Container>
		);
	}
}

export default Slider;
