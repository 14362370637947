import * as Types from '../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewLeadsSubscription_GetInquiriesForOrganizationQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.InquiriesForOrganizationWhereInput>;
}>;


export type NewLeadsSubscription_GetInquiriesForOrganizationQuery = { __typename?: 'Query', getInquiriesForOrganization: { __typename?: 'InquiriesForOrganization', _count: number } };

export type NewLeadsSubscription_InquiryChangedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type NewLeadsSubscription_InquiryChangedSubscription = { __typename?: 'Subscription', inquiryChanged: { __typename?: 'Inquiry', organization: { __typename?: 'Organization', inquiries: Array<{ __typename?: 'Inquiry', status: Types.InquiryStatus }> } } };


export const NewLeadsSubscription_GetInquiriesForOrganizationDocument = gql`
    query newLeadsSubscription_GetInquiriesForOrganization($where: InquiriesForOrganizationWhereInput) {
  getInquiriesForOrganization(where: $where) {
    _count
  }
}
    `;

/**
 * __useNewLeadsSubscription_GetInquiriesForOrganizationQuery__
 *
 * To run a query within a React component, call `useNewLeadsSubscription_GetInquiriesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewLeadsSubscription_GetInquiriesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewLeadsSubscription_GetInquiriesForOrganizationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNewLeadsSubscription_GetInquiriesForOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<NewLeadsSubscription_GetInquiriesForOrganizationQuery, NewLeadsSubscription_GetInquiriesForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewLeadsSubscription_GetInquiriesForOrganizationQuery, NewLeadsSubscription_GetInquiriesForOrganizationQueryVariables>(NewLeadsSubscription_GetInquiriesForOrganizationDocument, options);
      }
export function useNewLeadsSubscription_GetInquiriesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewLeadsSubscription_GetInquiriesForOrganizationQuery, NewLeadsSubscription_GetInquiriesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewLeadsSubscription_GetInquiriesForOrganizationQuery, NewLeadsSubscription_GetInquiriesForOrganizationQueryVariables>(NewLeadsSubscription_GetInquiriesForOrganizationDocument, options);
        }
export type NewLeadsSubscription_GetInquiriesForOrganizationQueryHookResult = ReturnType<typeof useNewLeadsSubscription_GetInquiriesForOrganizationQuery>;
export type NewLeadsSubscription_GetInquiriesForOrganizationLazyQueryHookResult = ReturnType<typeof useNewLeadsSubscription_GetInquiriesForOrganizationLazyQuery>;
export type NewLeadsSubscription_GetInquiriesForOrganizationQueryResult = Apollo.QueryResult<NewLeadsSubscription_GetInquiriesForOrganizationQuery, NewLeadsSubscription_GetInquiriesForOrganizationQueryVariables>;
export const NewLeadsSubscription_InquiryChangedDocument = gql`
    subscription newLeadsSubscription_InquiryChanged {
  inquiryChanged {
    organization {
      inquiries {
        status
      }
    }
  }
}
    `;

/**
 * __useNewLeadsSubscription_InquiryChangedSubscription__
 *
 * To run a query within a React component, call `useNewLeadsSubscription_InquiryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewLeadsSubscription_InquiryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewLeadsSubscription_InquiryChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewLeadsSubscription_InquiryChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewLeadsSubscription_InquiryChangedSubscription, NewLeadsSubscription_InquiryChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewLeadsSubscription_InquiryChangedSubscription, NewLeadsSubscription_InquiryChangedSubscriptionVariables>(NewLeadsSubscription_InquiryChangedDocument, options);
      }
export type NewLeadsSubscription_InquiryChangedSubscriptionHookResult = ReturnType<typeof useNewLeadsSubscription_InquiryChangedSubscription>;
export type NewLeadsSubscription_InquiryChangedSubscriptionResult = Apollo.SubscriptionResult<NewLeadsSubscription_InquiryChangedSubscription>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    