import { Box } from '@mui/material';
import React from 'react';
import MuiSpinner from '../../mui/MuiSpinner';
import styles from './FullScreenSpinner.module.scss';

export const FullScreenSpinner: React.FC = () => (
	<Box className={ styles.container }>
		<MuiSpinner />
	</Box>
);
