import API from './API';
import { Title as InquiriesConfigurationTitle } from '../blocks/InquiriesConfiguration';

export type Route = {
	url: string;
	name?: string;
	text?: string;
	nested?: Route[];
	getObjectName?: ( id: string, token?: string ) => Promise<string>;
};

const routes: Route[] = [
	{
		url: '/',
		name: 'Dashboard',
		text: 'Dashboard',
	},
	{
		url: '/Login',
		name: 'Log In',
		text: 'Log In',
	},
	{
		url: '/ResetPassword',
		name: 'Reset Password',
		text: 'Reset Password',
	},
	{
		url: '/SignUp',
		name: 'Sign Up',
		text: 'Sign Up',
		nested: [
			{
				url: '/id',
				name: 'Invitation',
			},
		],
	},
	{
		url: '/orgOnboarding', // we might pull the names from below here in this block as set those from the page itself
		nested: [
			{
				url: '/your-info',
				name: 'Org Sign Up 1',
			},
			{
				url: '/your-business',
				name: 'Org Sign Up 2',
			},
			{
				url: '/finish-up',
				name: 'Org Sign Up 3',
			},
		],
	},
	{
		url: '/ClientSignUp',
		nested: [
			{
				url: '/1',
				name: 'Client Sign Up 1',
			},
			{
				url: '/2',
				name: 'Client Sign Up 2',
			},
			{
				url: '/3',
				name: 'Client Sign Up 3',
			},
			{
				url: '/4',
				name: 'Client Sign Up 4',
			},
			{
				url: '/5',
				name: 'Client Sign Up 5',
			},
		],
	},
	{
		url: '/OrgMemberSignUp',
		nested: [
			{
				url: '/1',
				name: 'Org Member Sign Up 1',
			},
			{
				url: '/2',
				name: 'Org Member Sign Up 2',
			},
		],
	},
	{
		url: '/contracts',
		name: 'Contract Index',
		text: 'Contracts',
	},
	{
		url: '/contract',
		nested: [
			{
				url: '/id',
				name: 'Contract Detail',
				getObjectName: async ( id, token ) => {
					return await API.getContractNameWhere( id, token );
				},
			},
			{
				url: '/edit',
				name: 'Contract Edit',
			},
		],
	},
	{
		url: '/contractTemplates',
		name: 'Contract Template Index',
		text: 'Templates',
	},
	{
		url: '/contractTemplate/edit',
		name: 'Contract Template Edit',
		nested: [
			{
				url: '/id',
				name: 'Contract Template Edit Detail',
				getObjectName: async ( id ) =>
					await API.getOrganizationContractNameTemplate( id ),
			},
		],
	},
	{
		url: '/invoices',
		name: 'Invoice Index',
		text: 'Invoices',
	},
	{
		url: '/invoice',
		nested: [
			{
				url: '/id',
				name: 'Invoice Detail',
				getObjectName: async ( id, token ) => {
					return await API.getInvoiceNameWhere( id, token );
				},
			},
			{
				url: '/edit',
				name: 'Invoice Edit',
			},
		],
	},
	{
		url: '/invoiceTemplate',
		name: 'Invoice Template',
		nested: [
			{
				url: '/edit',
				name: 'Invoice Template Edit',
			},
		],
	},
	{
		url: '/invoiceTemplates',
		name: 'Invoice Template Index',
	},
	{
		url: '/vendors',
		name: 'Organization Index',
		text: 'Vendors',
	},
	{
		url: '/org',
		nested: [
			{
				url: '/id',
				name: 'Organization Detail',
				getObjectName: async ( id ) => {
					return await API.getOrganizationNameWhere( id );
				},
			},
		],
	},
	{
		url: '/admin?tab=team',
		name: 'Members Index',
		text: 'Planners',
	},
	{
		url: '/Clients',
		name: 'Client Index',
		text: 'Clients',
	},
	{
		url: '/contact',
		nested: [
			{
				url: '/id',
				name: 'Contact Detail',
			},
			{
				url: '/contracts',
				name: 'Contract Index',
				text: 'Contracts',
			},
			{
				url: '/contract',
				name: 'Contract Detail OBO',
				getObjectName: async ( id ) => {
					return await API.getContractNameWhere( id );
				},
			},
			{
				url: '/invoices',
				name: 'Invoice Index',
				text: 'Invoices',
			},
			{
				url: '/invoice',
				name: 'Invoice Detail OBO',
				getObjectName: async ( id ) => {
					return await API.getInvoiceNameWhere( id );
				},
			},
			{
				url: '/proposal',
				name: 'Proposal Detail OBO',
			},
		],
	},
	{
		url: '/customerFolders',
		name: 'Client Folder Index',
		text: 'Folders',
		nested: [
			{
				url: '/id',
				name: 'Client Folder Detail',
			},
		],
	},
	{
		url: '/myFolder',
		name: 'My Folder Index',
		text: 'Folder',
	},
	{
		url: '/companyFolders',
		name: 'Company Folder Index',
		nested: [
			{
				url: '/id',
				name: 'Company Folder Detail',
				getObjectName: async ( id ) => {
					return await API.getFolderNameWhere( id );
				},
			},
		],
	},
	{
		url: '/document',
		nested: [
			{
				url: '/id',
				name: 'Document Detail',
				getObjectName: async ( id ) => {
					return await API.getDocumentNameWhere( id );
				},
			},
		],
	},
	{
		url: '/resources',
		name: 'Resource Index',
		text: 'Resources',
	},
	{
		url: '/projects',
		name: 'All Projects',
		text: 'Projects',
	},
	{
		url: '/resource',
		nested: [
			{
				url: '/id',
				name: 'Resource Detail',
				getObjectName: async ( id ) => {
					return await API.getResourceNameWhere( id );
				},
			},
		],
	},
	{
		url: '/wallet',
		name: 'Wallet',
		text: 'My Wallet',
	},
	{
		url: '/profile',
		name: 'Profile',
	},
	{
		url: '/admin',
		name: 'Admin',
		nested: [
			{
				url: '/inquiries',
				nested: [
					{
						url: '/setup',
						name: InquiriesConfigurationTitle.setup,
					},
					{
						url: '/style',
						name: InquiriesConfigurationTitle.style,
					},
					{
						url: '/publish',
						name: InquiriesConfigurationTitle.publish,
					},
				],
			},
		],
	},
	{
		url: '/ClientUsers',
		name: 'Client User Index',
		text: 'Client Users',
	},
	{
		url: '/OrgUsers',
		name: 'Org User Index',
		text: 'Org Users',
	},
	{
		url: '/Organizations',
		name: 'Organization Index',
		text: 'Organizations',
	},
	{
		url: '/timelines',
		name: 'Timelines',
		text: 'Timelines',
	},
	{
		url: '/quickPayment',
		nested: [
			{
				url: '/id',
				name: 'Quick Payment',
			},
		],
	},
	{
		url: '/proposals',
		name: 'Proposal Index',
		text: 'Proposals',
	},
	{
		url: '/proposal',
		nested: [
			{
				url: '/id',
				name: 'Proposal Detail',
				getObjectName: async ( id, token = undefined ) => {
					return await API.getProposalNameWhere( id, token );
				},
			},
			{
				url: '/edit',
				name: 'Proposal Edit',
			},
		],
	},
	{
		url: '/proposalTemplates',
		name: 'Proposal Templates Index',
	},
	{
		url: '/proposalTemplate',
		name: 'Proposal Template',
		nested: [
			{
				url: '/id',
				name: 'Proposal Template Detail',
				getObjectName: async ( id, token = undefined ) => {
					return await API.getProposalTemplateNameWhere( id );
				},
			},
			{
				url: '/edit',
				name: 'Proposal Template Edit',
			},
		],
	},
	{
		url: '/inquiries',
		name: 'Inquiries Index',
	},
];

export default routes;
