import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CompanyFoldersIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				xmlns='http://www.w3.org/2000/svg'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.78723 0C0.800171 0 0 0.800173 0 1.78723V17.1064C0 18.6575 1.25741 19.9149 2.80851 19.9149H21.1915C22.7426 19.9149 24 18.6575 24 17.1064V7.40426C24 6.41719 23.1998 5.61702 22.2128 5.61702H20.9362V3.82979C20.9362 2.84273 20.136 2.04255 19.1489 2.04255H12.4734L11.9461 0.987959C11.6433 0.382472 11.0245 0 10.3475 0H1.78723ZM19.4043 3.82979V5.61702H4.85106C3.864 5.61702 3.06383 6.41719 3.06383 7.40426V18.383H2.80851C2.10347 18.383 1.53191 17.8114 1.53191 17.1064V1.78723C1.53191 1.64623 1.64623 1.53191 1.78723 1.53191H10.3475C10.4442 1.53191 10.5327 1.58655 10.5759 1.67305L11.3149 3.15106C11.4447 3.41055 11.7099 3.57447 12 3.57447H19.1489C19.2899 3.57447 19.4043 3.68878 19.4043 3.82979ZM21.1915 18.383H4.59574V7.40426C4.59574 7.26325 4.71005 7.14894 4.85106 7.14894H22.2128C22.3538 7.14894 22.4681 7.26325 22.4681 7.40426V17.1064C22.4681 17.8114 21.8965 18.383 21.1915 18.383ZM6.12766 12C6.12766 11.577 6.47059 11.234 6.89362 11.234H13.0213C13.4443 11.234 13.7872 11.577 13.7872 12C13.7872 12.423 13.4443 12.766 13.0213 12.766L6.89362 12.766C6.47059 12.766 6.12766 12.423 6.12766 12ZM6.12766 15.0638C6.12766 14.6408 6.47059 14.2979 6.89362 14.2979H13.0213C13.4443 14.2979 13.7872 14.6408 13.7872 15.0638C13.7872 15.4869 13.4443 15.8298 13.0213 15.8298L6.89362 15.8298C6.47059 15.8298 6.12766 15.4869 6.12766 15.0638Z'
			/>
		</SvgIcon>
	);
}
