const MicroTransaction = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
	>
		{ /* eslint-disable-next-line max-len */ }
		<path
			d='M34.667 21.333C42.03 21.333 48 27.303 48 34.667 48 42.03 42.03 48 34.667 48c-7.364 0-13.334-5.97-13.334-13.333 0-7.364 5.97-13.334 13.334-13.334zm0 2.286c-6.102 0-11.048 4.946-11.048 11.048 0 6.101 4.946 11.047 11.048 11.047 6.101 0 11.047-4.946 11.047-11.047 0-6.102-4.946-11.048-11.047-11.048zm0 2.286c.578 0 1.056.43 1.132.988l.01.155v.38h1.905c.631 0 1.143.512 1.143 1.143 0 .579-.43 1.057-.988 1.133l-.155.01h-4.13c-.758 0-1.41.539-1.553 1.283-.132.683.196 1.364.792 1.69l.17.08 4.195 1.679c1.719.691 2.706 2.504 2.355 4.324-.335 1.732-1.795 3.007-3.535 3.126l-.199.006v.384c0 .63-.511 1.143-1.142 1.143-.579 0-1.057-.43-1.133-.988l-.01-.155-.001-.382h-1.904c-.631 0-1.143-.51-1.143-1.142 0-.579.43-1.057.988-1.133l.155-.01h4.126c.758 0 1.41-.539 1.554-1.283.132-.683-.197-1.363-.793-1.69l-.17-.08-4.195-1.678c-1.719-.692-2.706-2.505-2.355-4.324.335-1.733 1.795-3.008 3.535-3.126l.202-.008v-.382c0-.632.512-1.143 1.144-1.143zM37.714 0c2.229 0 4.051 1.74 4.183 3.935l.008.255v12.191c0 .631-.512 1.143-1.143 1.143-.579 0-1.057-.43-1.133-.988l-.01-.155v-4.953H2.285v17.143c0 .987.75 1.798 1.71 1.895l.195.01h12.191c.631 0 1.143.512 1.143 1.143 0 .579-.43 1.057-.988 1.132l-.155.01H4.191c-2.23 0-4.051-1.739-4.183-3.934L0 28.57V4.191C0 1.96 1.74.14 3.935.007L4.19 0h33.524zm-24.38 22.857c.63 0 1.142.512 1.142 1.143 0 .579-.43 1.057-.988 1.132l-.155.01H7.238c-.631 0-1.143-.51-1.143-1.142 0-.579.43-1.057.988-1.132l.155-.01h6.095zm6.095-6.095c.63 0 1.142.512 1.142 1.143 0 .578-.43 1.057-.987 1.132l-.155.01H7.239c-.632 0-1.144-.511-1.144-1.142 0-.579.43-1.057.988-1.133l.155-.01h12.19zM37.714 2.286H4.19c-.986 0-1.797.75-1.894 1.71l-.01.194-.001 4.952h37.334V4.19c0-.986-.75-1.797-1.71-1.894l-.195-.01z'
			transform='translate(-872 -578) translate(500 531) translate(288) translate(36 47) translate(48)'
		/>
	</svg>
);

export default MicroTransaction;
