import { combineReducers } from 'redux';
import user from './user';
import {
	ALERT,
	BEGIN_USER_REGISTRATION,
	END_USER_REGISTRATION,
	TOGGLE_LOADER,
} from '../actions';
import { loadingOverlay, stripeNeedsModal, confirm } from './modals';

import { SET_SELECTED_TEMPLATE } from '../tsActions';

function userRegistration( state = {}, action ) {
	switch ( action.type ) {
		case BEGIN_USER_REGISTRATION:
			return {
				coreUserFields: action.data.coreUserFields,
				invitation: action.data.invitation,
				promoCode: action.data.promoCode,
				verificationToken: action.data.verificationToken,
				inProgress: true,
			};

		case END_USER_REGISTRATION:
			return {
				coreUserFields: {},
				invitation: {},
				inProgress: false,
			};
		default:
			return state;
	}
}

const defaultAlertState = {
	id: '',
	message: '',
	routes: null,
	time: '',
};

function alert( state = defaultAlertState, action ) {
	switch ( action.type ) {
		case ALERT:
			return {
				alert: {
					id: action.alert.id,
					message: action.alert.message,
					routes: action.alert.routes,
					time: new Date(),
				},
			};
		default:
			return state;
	}
}

const defaultLoaderState = {
	loading: false,
};

function loader( state = defaultLoaderState, action ) {
	if ( action.type === TOGGLE_LOADER ) {
		return {
			loading: action.loading,
		};
	} else return state;
}

const defaultProposalsState = {
	selectedTemplate: null,
};

function proposals( state = defaultProposalsState, action ) {
	switch ( action.type ) {
		case SET_SELECTED_TEMPLATE:
			return {
				...state,
				selectedTemplate: action.template,
				selectedTemplateId: action.templateId,
			};
		default:
			return state;
	}
}

const Reducers = combineReducers( {
	alert,
	loadingOverlay,
	stripeNeedsModal,
	confirm,
	loader,
	user,
	userRegistration,
	proposals,
} );

export default Reducers;
