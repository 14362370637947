import Globals from '../../Globals';
import Query from '../../Query';
import {
	ResourceDetailReturnFields,
	ResourcesReturnFields,
	ResourceDownloadLinkReturnFields,
} from '../returnFields';

/**
 * Get resources
 * @param { {
 *   skip?: number,
 *   orderBy?: Record<string, any> | null,
 *   stringQuery?: Array<string>,
 *   take?: number
 * } } arg
 * @returns { errors: string[] }
 */

const getResources = async function( {
	skip = 0,
	orderBy = { description: 'asc' },
	stringQuery = [],
	take,
} ) {
	let whereClause;
	if ( stringQuery.length > 0 ) {
		const queryReducer = ( constraints, query ) => {
			return [
				...constraints,
				{ categoryName_contains: query },
				{ sponsorName_contains: query },
			];
		};
		whereClause = {
			OR: stringQuery.reduce( queryReducer, [] ),
		};
	}

	let orderByParam;
	if ( Array.isArray( orderBy ) ) {
		orderByParam = orderBy;
	} else {
		orderByParam = [ orderBy ];
	}

	const params = {
		take: take || Globals.itemReturnLimit + 1,
		orderBy: orderByParam,
		skip,
	};

	if ( whereClause ) {
		params.where = whereClause;
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getResourcesWhere',
			params,
			returnFields: [ '_count', { resources: ResourcesReturnFields } ],
		} )
	);

	if ( errors ) {
		return errors;
	}

	if ( data ) {
		const resources = data.data.getResourcesWhere.resources;
		const count = data.data.getResourcesWhere._count;

		let moreToLoad = false;
		if ( resources.length === Globals.itemReturnLimit + 1 ) {
			resources.pop();
			moreToLoad = true;
		}
		return { resources, moreToLoad, count };
	}
};

const searchResources = async function( queries, limit, skip ) {
	const queryReducer = ( constraints, query ) => {
		return [
			...constraints,
			{ categoryName_contains: query },
			{ sponsorName_contains: query },
		];
	};

	const whereClause = {
		OR: queries.reduce( queryReducer, [] ),
	};

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getResourcesWhere',
			params: {
				where: whereClause,
				take: limit + 1,
				skip,
			},
			returnFields: [ { resources: ResourcesReturnFields } ],
		} )
	);
	if ( errors ) return [];

	if ( data ) {
		const resources = data.data.getResourcesWhere.resources;
		let moreToLoad = false;
		if ( resources.length === Globals.itemReturnLimit + 1 ) {
			resources.pop();
			moreToLoad = true;
		}
		return { resources, moreToLoad };
	}
};

const getResourceWhere = async function( id ) {
	const where = { id };
	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getResourceWhere',
			params: { where },
			returnFields: ResourceDetailReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		resource: data.data.getResourceWhere,
		errors: data.data.errors,
	};
};

const getResourceDownload = async function( id ) {
	const where = { id };
	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'downloadResourceWhere',
			params: { where },
			returnFields: ResourceDownloadLinkReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		downloadLink: data.data.downloadResourceWhere,
		errors: data.data.errors,
	};
};

export { getResources, searchResources, getResourceWhere, getResourceDownload };
