import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgOnboarding_GetServicesWhereQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgOnboarding_GetServicesWhereQuery = { __typename?: 'Query', getServicesWhere: Array<{ __typename?: 'Service', id: string, name: string }> };

export type OrgOnboardingAddressOrganizationFragment = { __typename?: 'Organization', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: Types.State | null, postalCode?: string | null, country?: Types.Country | null };

export type OrgOnboardingOrganizationFragment = { __typename?: 'Organization', id: string, name: string, phone?: string | null, image?: string | null, canReceivePayments: boolean, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null };

export type OrgOnboarding_RequestOrgProfileImageUploadUrlQueryVariables = Types.Exact<{
  data: Types.RequestImageUploadUrlInput;
}>;


export type OrgOnboarding_RequestOrgProfileImageUploadUrlQuery = { __typename?: 'Query', requestOrgProfileImageUploadUrl: { __typename?: 'UploadUrl', uploadURL?: string | null } };

export type OrgOnboarding_CreateOrganizationMutationVariables = Types.Exact<{
  data: Types.OrganizationCustomCreateInput;
}>;


export type OrgOnboarding_CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', id: string, name: string, phone?: string | null, image?: string | null, canReceivePayments: boolean, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } };

export type OrgOnboarding_UpdateOrganizationMutationVariables = Types.Exact<{
  data: Types.OrganizationCustomUpdateInput;
  where: Types.OrganizationWhereUniqueInput;
}>;


export type OrgOnboarding_UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: string, name: string, phone?: string | null, image?: string | null, canReceivePayments: boolean, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } | null };

export type OrgOnboarding_GetOrgUserWhereQueryVariables = Types.Exact<{
  where: Types.OrgUserWhereUniqueInput;
}>;


export type OrgOnboarding_GetOrgUserWhereQuery = { __typename?: 'Query', getOrgUserWhere?: { __typename?: 'OrgUser', id: string, organization?: { __typename?: 'Organization', image?: string | null } | null, user: { __typename?: 'User', id: string, acceptedTerms?: Date | string | null } } | null };

export type OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationVariables = Types.Exact<{
  data: Types.OrgUserUpdateCustomInput;
  where: Types.OrgUserWhereUniqueInput;
}>;


export type OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation = { __typename?: 'Mutation', updateOrgUser?: { __typename?: 'OrgUser', id: string } | null };

export type OrgOnboarding_GetStripeConnectAccountQueryVariables = Types.Exact<{
  where: Types.OrganizationWhereUniqueInput;
}>;


export type OrgOnboarding_GetStripeConnectAccountQuery = { __typename?: 'Query', getStripeConnectAccount?: { __typename?: 'StripeConnectAccount', id?: string | null } | null };

export type OrgOnboarding_CreateStripeConnectAccountMutationVariables = Types.Exact<{
  data: Types.StripeConnectAccountCreationInput;
  where: Types.OrganizationWhereUniqueInput;
}>;


export type OrgOnboarding_CreateStripeConnectAccountMutation = { __typename?: 'Mutation', createStripeConnectAccount?: { __typename?: 'StripeConnectAccount', id?: string | null } | null };

export const OrgOnboardingAddressOrganizationFragmentDoc = gql`
    fragment orgOnboardingAddressOrganization on Organization {
  addressLine1
  addressLine2
  city
  state
  postalCode
  country
}
    `;
export const OrgOnboardingOrganizationFragmentDoc = gql`
    fragment orgOnboardingOrganization on Organization {
  id
  name
  phone
  image
  canReceivePayments
  services {
    id
    name
  }
}
    `;
export const OrgOnboarding_GetServicesWhereDocument = gql`
    query OrgOnboarding_getServicesWhere {
  getServicesWhere {
    id
    name
  }
}
    `;

/**
 * __useOrgOnboarding_GetServicesWhereQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_GetServicesWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_GetServicesWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_GetServicesWhereQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgOnboarding_GetServicesWhereQuery(baseOptions?: Apollo.QueryHookOptions<OrgOnboarding_GetServicesWhereQuery, OrgOnboarding_GetServicesWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_GetServicesWhereQuery, OrgOnboarding_GetServicesWhereQueryVariables>(OrgOnboarding_GetServicesWhereDocument, options);
      }
export function useOrgOnboarding_GetServicesWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_GetServicesWhereQuery, OrgOnboarding_GetServicesWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_GetServicesWhereQuery, OrgOnboarding_GetServicesWhereQueryVariables>(OrgOnboarding_GetServicesWhereDocument, options);
        }
export type OrgOnboarding_GetServicesWhereQueryHookResult = ReturnType<typeof useOrgOnboarding_GetServicesWhereQuery>;
export type OrgOnboarding_GetServicesWhereLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_GetServicesWhereLazyQuery>;
export type OrgOnboarding_GetServicesWhereQueryResult = Apollo.QueryResult<OrgOnboarding_GetServicesWhereQuery, OrgOnboarding_GetServicesWhereQueryVariables>;
export const OrgOnboarding_RequestOrgProfileImageUploadUrlDocument = gql`
    query OrgOnboarding_requestOrgProfileImageUploadUrl($data: RequestImageUploadUrlInput!) {
  requestOrgProfileImageUploadUrl(data: $data) {
    uploadURL
  }
}
    `;

/**
 * __useOrgOnboarding_RequestOrgProfileImageUploadUrlQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_RequestOrgProfileImageUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_RequestOrgProfileImageUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_RequestOrgProfileImageUploadUrlQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrgOnboarding_RequestOrgProfileImageUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_RequestOrgProfileImageUploadUrlQuery, OrgOnboarding_RequestOrgProfileImageUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_RequestOrgProfileImageUploadUrlQuery, OrgOnboarding_RequestOrgProfileImageUploadUrlQueryVariables>(OrgOnboarding_RequestOrgProfileImageUploadUrlDocument, options);
      }
export function useOrgOnboarding_RequestOrgProfileImageUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_RequestOrgProfileImageUploadUrlQuery, OrgOnboarding_RequestOrgProfileImageUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_RequestOrgProfileImageUploadUrlQuery, OrgOnboarding_RequestOrgProfileImageUploadUrlQueryVariables>(OrgOnboarding_RequestOrgProfileImageUploadUrlDocument, options);
        }
export type OrgOnboarding_RequestOrgProfileImageUploadUrlQueryHookResult = ReturnType<typeof useOrgOnboarding_RequestOrgProfileImageUploadUrlQuery>;
export type OrgOnboarding_RequestOrgProfileImageUploadUrlLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_RequestOrgProfileImageUploadUrlLazyQuery>;
export type OrgOnboarding_RequestOrgProfileImageUploadUrlQueryResult = Apollo.QueryResult<OrgOnboarding_RequestOrgProfileImageUploadUrlQuery, OrgOnboarding_RequestOrgProfileImageUploadUrlQueryVariables>;
export const OrgOnboarding_CreateOrganizationDocument = gql`
    mutation OrgOnboarding_createOrganization($data: OrganizationCustomCreateInput!) {
  createOrganization(data: $data) {
    ...orgOnboardingOrganization
  }
}
    ${OrgOnboardingOrganizationFragmentDoc}`;
export type OrgOnboarding_CreateOrganizationMutationFn = Apollo.MutationFunction<OrgOnboarding_CreateOrganizationMutation, OrgOnboarding_CreateOrganizationMutationVariables>;

/**
 * __useOrgOnboarding_CreateOrganizationMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_CreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_CreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingCreateOrganizationMutation, { data, loading, error }] = useOrgOnboarding_CreateOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrgOnboarding_CreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_CreateOrganizationMutation, OrgOnboarding_CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_CreateOrganizationMutation, OrgOnboarding_CreateOrganizationMutationVariables>(OrgOnboarding_CreateOrganizationDocument, options);
      }
export type OrgOnboarding_CreateOrganizationMutationHookResult = ReturnType<typeof useOrgOnboarding_CreateOrganizationMutation>;
export type OrgOnboarding_CreateOrganizationMutationResult = Apollo.MutationResult<OrgOnboarding_CreateOrganizationMutation>;
export type OrgOnboarding_CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_CreateOrganizationMutation, OrgOnboarding_CreateOrganizationMutationVariables>;
export const OrgOnboarding_UpdateOrganizationDocument = gql`
    mutation OrgOnboarding_updateOrganization($data: OrganizationCustomUpdateInput!, $where: OrganizationWhereUniqueInput!) {
  updateOrganization(data: $data, where: $where) {
    ...orgOnboardingOrganization
  }
}
    ${OrgOnboardingOrganizationFragmentDoc}`;
export type OrgOnboarding_UpdateOrganizationMutationFn = Apollo.MutationFunction<OrgOnboarding_UpdateOrganizationMutation, OrgOnboarding_UpdateOrganizationMutationVariables>;

/**
 * __useOrgOnboarding_UpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_UpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_UpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingUpdateOrganizationMutation, { data, loading, error }] = useOrgOnboarding_UpdateOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_UpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_UpdateOrganizationMutation, OrgOnboarding_UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_UpdateOrganizationMutation, OrgOnboarding_UpdateOrganizationMutationVariables>(OrgOnboarding_UpdateOrganizationDocument, options);
      }
export type OrgOnboarding_UpdateOrganizationMutationHookResult = ReturnType<typeof useOrgOnboarding_UpdateOrganizationMutation>;
export type OrgOnboarding_UpdateOrganizationMutationResult = Apollo.MutationResult<OrgOnboarding_UpdateOrganizationMutation>;
export type OrgOnboarding_UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_UpdateOrganizationMutation, OrgOnboarding_UpdateOrganizationMutationVariables>;
export const OrgOnboarding_GetOrgUserWhereDocument = gql`
    query OrgOnboarding_getOrgUserWhere($where: OrgUserWhereUniqueInput!) {
  getOrgUserWhere(where: $where) {
    id
    organization {
      image
    }
    user {
      id
      acceptedTerms
    }
  }
}
    `;

/**
 * __useOrgOnboarding_GetOrgUserWhereQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_GetOrgUserWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_GetOrgUserWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_GetOrgUserWhereQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_GetOrgUserWhereQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_GetOrgUserWhereQuery, OrgOnboarding_GetOrgUserWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_GetOrgUserWhereQuery, OrgOnboarding_GetOrgUserWhereQueryVariables>(OrgOnboarding_GetOrgUserWhereDocument, options);
      }
export function useOrgOnboarding_GetOrgUserWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_GetOrgUserWhereQuery, OrgOnboarding_GetOrgUserWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_GetOrgUserWhereQuery, OrgOnboarding_GetOrgUserWhereQueryVariables>(OrgOnboarding_GetOrgUserWhereDocument, options);
        }
export type OrgOnboarding_GetOrgUserWhereQueryHookResult = ReturnType<typeof useOrgOnboarding_GetOrgUserWhereQuery>;
export type OrgOnboarding_GetOrgUserWhereLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_GetOrgUserWhereLazyQuery>;
export type OrgOnboarding_GetOrgUserWhereQueryResult = Apollo.QueryResult<OrgOnboarding_GetOrgUserWhereQuery, OrgOnboarding_GetOrgUserWhereQueryVariables>;
export const OrgOnboarding_UpdateOrgUserTermsOfAcceptanceDocument = gql`
    mutation OrgOnboarding_updateOrgUserTermsOfAcceptance($data: OrgUserUpdateCustomInput!, $where: OrgUserWhereUniqueInput!) {
  updateOrgUser(data: $data, where: $where) {
    id
  }
}
    `;
export type OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationFn = Apollo.MutationFunction<OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation, OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationVariables>;

/**
 * __useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingUpdateOrgUserTermsOfAcceptanceMutation, { data, loading, error }] = useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation, OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation, OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationVariables>(OrgOnboarding_UpdateOrgUserTermsOfAcceptanceDocument, options);
      }
export type OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationHookResult = ReturnType<typeof useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation>;
export type OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationResult = Apollo.MutationResult<OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation>;
export type OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation, OrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutationVariables>;
export const OrgOnboarding_GetStripeConnectAccountDocument = gql`
    query OrgOnboarding_getStripeConnectAccount($where: OrganizationWhereUniqueInput!) {
  getStripeConnectAccount(where: $where) {
    id
  }
}
    `;

/**
 * __useOrgOnboarding_GetStripeConnectAccountQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_GetStripeConnectAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_GetStripeConnectAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_GetStripeConnectAccountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_GetStripeConnectAccountQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_GetStripeConnectAccountQuery, OrgOnboarding_GetStripeConnectAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_GetStripeConnectAccountQuery, OrgOnboarding_GetStripeConnectAccountQueryVariables>(OrgOnboarding_GetStripeConnectAccountDocument, options);
      }
export function useOrgOnboarding_GetStripeConnectAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_GetStripeConnectAccountQuery, OrgOnboarding_GetStripeConnectAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_GetStripeConnectAccountQuery, OrgOnboarding_GetStripeConnectAccountQueryVariables>(OrgOnboarding_GetStripeConnectAccountDocument, options);
        }
export type OrgOnboarding_GetStripeConnectAccountQueryHookResult = ReturnType<typeof useOrgOnboarding_GetStripeConnectAccountQuery>;
export type OrgOnboarding_GetStripeConnectAccountLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_GetStripeConnectAccountLazyQuery>;
export type OrgOnboarding_GetStripeConnectAccountQueryResult = Apollo.QueryResult<OrgOnboarding_GetStripeConnectAccountQuery, OrgOnboarding_GetStripeConnectAccountQueryVariables>;
export const OrgOnboarding_CreateStripeConnectAccountDocument = gql`
    mutation OrgOnboarding_createStripeConnectAccount($data: StripeConnectAccountCreationInput!, $where: OrganizationWhereUniqueInput!) {
  createStripeConnectAccount(data: $data, where: $where) {
    id
  }
}
    `;
export type OrgOnboarding_CreateStripeConnectAccountMutationFn = Apollo.MutationFunction<OrgOnboarding_CreateStripeConnectAccountMutation, OrgOnboarding_CreateStripeConnectAccountMutationVariables>;

/**
 * __useOrgOnboarding_CreateStripeConnectAccountMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_CreateStripeConnectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_CreateStripeConnectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingCreateStripeConnectAccountMutation, { data, loading, error }] = useOrgOnboarding_CreateStripeConnectAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_CreateStripeConnectAccountMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_CreateStripeConnectAccountMutation, OrgOnboarding_CreateStripeConnectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_CreateStripeConnectAccountMutation, OrgOnboarding_CreateStripeConnectAccountMutationVariables>(OrgOnboarding_CreateStripeConnectAccountDocument, options);
      }
export type OrgOnboarding_CreateStripeConnectAccountMutationHookResult = ReturnType<typeof useOrgOnboarding_CreateStripeConnectAccountMutation>;
export type OrgOnboarding_CreateStripeConnectAccountMutationResult = Apollo.MutationResult<OrgOnboarding_CreateStripeConnectAccountMutation>;
export type OrgOnboarding_CreateStripeConnectAccountMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_CreateStripeConnectAccountMutation, OrgOnboarding_CreateStripeConnectAccountMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    