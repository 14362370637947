import { LinkProps } from 'next/link';
import { constructURLParams, getObjectType } from './index';
import { LinkObjectType } from './types';

export interface LinkPropsType {
	href: LinkProps['href'];
	as: LinkProps['as'];
}

type GetDetailLinkProps = {
	params?: Record<string, any>;
	baseUserId?: string;
	type?: LinkObjectType;
	object: Record<string, any>;
};

/**
 * Generates the link props to pass into a `next/link` component.
 */
export const getDetailLink = ( {
	type,
	params,
	baseUserId,
	object,
}: GetDetailLinkProps ) => {
	if ( !object ) {
		// eslint-disable-next-line
		console.error('GetDetailLink failure. `obj` not defined.');
		return;
	}

	const objectLinkType = type || getObjectType( object );
	if ( !objectLinkType ) return;

	const linkProps: LinkPropsType =
		type === LinkObjectType.Contact
			? {
				href: '/client/[userId]', // this needs to match the folder name
				as: `/client/${
					// First try for object.customer = contact
					'customer' in object
						? object.customer.id
						: // Then try for objecty.user = clientUser | GuestUser | OrgUser
						'user' in object
							? object.user.id
							: // Finally, use the object itself = base user
							  object.id
				}`,
			  }
			: {
				href: `/${ objectLinkType }/[id]`,
				as: `/${ objectLinkType }/${ object.id }`,
			  };

	if ( objectLinkType === 'user' && 'user' in object ) {
		linkProps.href = '/client/[userId]';
		linkProps.as = `/client/${ object.user.id }`;
	}

	if ( baseUserId ) {
		linkProps.href = `/client/[userId]${ linkProps.href }`;
		linkProps.as = `/client/${ baseUserId }${ linkProps.as }`;
	}
	if ( params ) {
		const paramString = constructURLParams( params );
		linkProps.as += paramString;
		linkProps.href = {
			pathname: linkProps.href?.toString(),
			query: {
				...params,
			},
		};
	}

	return linkProps;
};
