/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const View = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props } xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.013 4.501l.34.001c3.78.084 7.943 2.489 11.028 5.881.827.918.827 2.312-.002 3.232-3.184 3.505-7.482 5.949-11.366 5.883h-.336c-3.819-.075-7.963-2.48-11.055-5.885-.827-.918-.827-2.312.002-3.232C3.81 6.873 8.134 4.436 12.013 4.5zm.321 1.5h-.655c-3.31.079-7.11 2.267-9.943 5.386-.313.347-.313.875-.002 1.22 2.919 3.214 6.847 5.448 10.279 5.391l.31-.001c3.333-.078 7.113-2.273 9.944-5.388.313-.347.313-.875.002-1.22-2.831-3.112-6.642-5.313-9.935-5.387zM12 7.5l.238.006c1.107.059 2.157.524 2.945 1.312.844.844 1.318 1.99 1.317 3.183 0 2.485-2.016 4.5-4.5 4.499-2.486 0-4.5-2.015-4.5-4.5 0-2.486 2.015-4.5 4.5-4.5zm0 1.5c-1.657 0-3 1.343-3 3 0 1.656 1.343 3 3 3 1.656 0 3-1.342 3-3 0-.795-.315-1.559-.878-2.121-.563-.563-1.326-.88-2.122-.879z'
			transform='translate(-304 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(160)'
		/>
	</svg>
);

export default View;
