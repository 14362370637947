import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FileAudio = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.25 3c0-.966.784-1.75 1.75-1.75h12.903c.463 0 .907.183 1.235.51l3.096 3.082c.33.328.516.775.516 1.24V21A1.75 1.75 0 0 1 20 22.75H4A1.75 1.75 0 0 1 2.25 21V3ZM4 2.75a.25.25 0 0 0-.25.25v18c0 .138.112.25.25.25h16a.25.25 0 0 0 .25-.25V6.082a.25.25 0 0 0-.074-.177L17.08 2.823a.25.25 0 0 0-.176-.073H4ZM8.947 9a.75.75 0 0 1 .588-.732l6.804-1.5a.75.75 0 0 1 .227-.015.75.75 0 0 1 .881.738v7.654c.003.075.003.149-.001.223-.054.945-.703 1.79-1.625 2.185-1.205.515-2.736.156-3.28-1.116-.544-1.272.253-2.627 1.458-3.142a2.776 2.776 0 0 1 1.948-.093V8.429l-5.5 1.24v6.324c.003.075.003.15-.001.223-.054.945-.703 1.79-1.625 2.185-1.205.516-2.736.156-3.28-1.116-.544-1.271.253-2.627 1.458-3.142a2.777 2.777 0 0 1 1.948-.093V9Zm0 7.038a.61.61 0 0 0-.047-.19c-.136-.318-.66-.604-1.311-.326-.65.278-.805.855-.67 1.173.137.319.661.605 1.312.327.458-.196.691-.572.716-.873v-.111ZM15.9 15a.61.61 0 0 1 .047.19v.11c-.025.302-.258.678-.716.874-.65.278-1.175-.008-1.311-.327-.136-.318.019-.895.669-1.173s1.175.008 1.31.326Z'
		/>
	</svg>
);

export default FileAudio;
