import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ContractTemplatesIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path d='M17.1115 4.40099H18.0682V7.6539C18.0682 7.97093 18.3252 8.22794 18.6423 8.22794C18.9593 8.22794 19.2163 7.97093 19.2163 7.6539V4.40099H20.1731C20.4901 4.40099 20.7471 4.14398 20.7471 3.82695C20.7471 3.50991 20.4901 3.25291 20.1731 3.25291L17.1115 3.25291C16.7945 3.25291 16.5374 3.50991 16.5374 3.82695C16.5374 4.14398 16.7945 4.40099 17.1115 4.40099Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24 5.35773C24 8.31672 21.6013 10.7155 18.6423 10.7155C15.6833 10.7155 13.2845 8.31672 13.2845 5.35773C13.2845 2.39874 15.6833 0 18.6423 0C21.6013 0 24 2.39874 24 5.35773ZM22.8519 5.35773C22.8519 7.68265 20.9672 9.56737 18.6423 9.56737C16.3173 9.56737 14.4326 7.68265 14.4326 5.35773C14.4326 3.03281 16.3173 1.14808 18.6423 1.14808C20.9672 1.14808 22.8519 3.03281 22.8519 5.35773Z'
			/>
			<path d='M3.90852 4.59234C3.90852 4.06395 4.33686 3.6356 4.86525 3.6356H11.3711C11.6881 3.6356 11.9451 3.37859 11.9451 3.06156C11.9451 2.74452 11.6881 2.48752 11.3711 2.48752H4.86525C3.70279 2.48752 2.76043 3.42988 2.76043 4.59234V11.0982C2.76043 11.4152 3.01744 11.6722 3.33447 11.6722C3.65151 11.6722 3.90852 11.4152 3.90852 11.0982V4.59234Z' />
			<path d='M19.2163 12.6289C19.2163 12.3119 18.9593 12.0549 18.6423 12.0549C18.3252 12.0549 18.0682 12.3119 18.0682 12.6289V19.9001C18.0682 20.4285 17.6399 20.8569 17.1115 20.8569H4.86525C4.33686 20.8569 3.90852 20.4285 3.90852 19.9001C3.90852 19.5831 3.65151 19.3261 3.33447 19.3261C3.01744 19.3261 2.76043 19.5831 2.76043 19.9001C2.76043 21.0626 3.70279 22.005 4.86525 22.005H17.1115C18.274 22.005 19.2163 21.0626 19.2163 19.9001V12.6289Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.16193 17.4646C5.26592 17.6113 5.43709 17.7071 5.63064 17.7071H14.4678C15.6303 17.7071 16.5726 16.7648 16.5726 15.6023C16.5726 14.5342 15.777 13.6519 14.7461 13.5157V13.4263C14.7461 12.2638 13.8037 11.3215 12.6412 11.3215H9.195C8.87797 11.3215 8.62096 11.5785 8.62096 11.8955C8.62096 12.2125 8.87797 12.4695 9.195 12.4695H12.6412C13.1696 12.4695 13.598 12.8979 13.598 13.4263V13.4975H5.63064C5.43708 13.4975 5.26589 13.5933 5.16191 13.7401C4.4736 13.265 3.53139 13.1667 2.70977 13.6576L0.279619 15.1095C0.106193 15.2132 0 15.4003 0 15.6023C0 15.8043 0.106193 15.9915 0.279619 16.0951L2.70976 17.547C3.5314 18.0379 4.47362 17.9396 5.16193 17.4646ZM14.4678 16.559H13.0932V14.6456H14.4678C14.9962 14.6456 15.4245 15.0739 15.4245 15.6023C15.4245 16.1307 14.9962 16.559 14.4678 16.559ZM11.9451 14.6456V16.559H6.20468V14.6456H11.9451ZM3.29861 16.5615L1.69326 15.6023L3.29861 14.6432C4.04334 14.1982 4.98895 14.7348 4.98895 15.6023C4.98895 16.4699 4.04334 17.0064 3.29861 16.5615Z'
			/>
			<path d='M6.39603 6.31447C6.079 6.31447 5.82199 6.57147 5.82199 6.88851C5.82199 7.20554 6.079 7.46255 6.39603 7.46255L10.9884 7.46255C11.3054 7.46255 11.5624 7.20554 11.5624 6.88851C11.5624 6.57147 11.3054 6.31447 10.9884 6.31447L6.39603 6.31447Z' />
			<path d='M5.82199 9.18468C5.82199 8.86764 6.079 8.61064 6.39603 8.61064L10.9884 8.61063C11.3054 8.61063 11.5624 8.86764 11.5624 9.18468C11.5624 9.50171 11.3054 9.75872 10.9884 9.75872H6.39603C6.079 9.75872 5.82199 9.50171 5.82199 9.18468Z' />
		</SvgIcon>
	);
}
