import React, { useEffect } from 'react';
import {
	Autocomplete,
	FormControl,
	FormControlProps,
	FormHelperText,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { Service as OrganizationService } from '../../types/generated';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import { gql } from '@apollo/client';
import { useSelectServices_GetServicesWhereQuery } from './__generated__';
import { showError } from '../Toast';
import MuiSpinner from '../../mui/MuiSpinner';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getServicesWhere = gql`
	query SelectServices_GetServicesWhere {
		getServicesWhere {
			id
			name
		}
	}
`;

type Service = Pick<OrganizationService, 'id' | 'name'>;

export type SelectServicesProps = {
	value?: Service[] | null;
	onChange: ( services: Service[] ) => void;
	errorText?: string;
	dataCy?: string;
	chipLimitDesktop?: number;
	chipLimitMobile?: number;
} & Omit<FormControlProps, 'error' | 'onChange' | 'onBlur'> &
Pick<TextFieldProps, 'onBlur'>;

const SelectServices: React.FC<SelectServicesProps> = ( {
	value,
	onChange,
	errorText,
	onBlur,
	dataCy,
	chipLimitDesktop,
	chipLimitMobile,
	...formControlProps
} ) => {
	const { isDesktop } = useDeviceDetection();
	const { data, error, loading } = useSelectServices_GetServicesWhereQuery();

	useEffect( () => {
		if ( error ) {
			showError( error );
		}
	}, [ error ] );

	if ( loading ) {
		return <MuiSpinner />;
	}

	return (
		<FormControl { ...formControlProps } error={ !!errorText }>
			<Autocomplete<Service, true, false>
				multiple
				filterSelectedOptions
				limitTags={ isDesktop ? chipLimitDesktop : chipLimitMobile }
				value={ value || [] }
				onChange={ ( _, value ) => onChange( value ) }
				options={ data?.getServicesWhere || [] }
				getOptionLabel={ ( option ) => option.name }
				isOptionEqualToValue={ ( option, value ) => option.id === value.id }
				autoHighlight
				forcePopupIcon={ false } // to hide the option to display the list without typing anything
				renderInput={ ( params ) => (
					<TextField
						{ ...params }
						label='What services do you provide?'
						placeholder='Services'
						onBlur={ onBlur }
						error={ !!errorText }
						inputProps={ {
							...params?.inputProps,
							'data-cy': dataCy,
						} }
					/>
				) }
			/>
			{ errorText ? <FormHelperText>{ errorText }</FormHelperText> : null }
		</FormControl>
	);
};

export default SelectServices;
