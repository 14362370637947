import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function QuestionMarkIconThin( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props } viewBox='0 0 40 40'>
			<path d='M19.7134 11.0339C22.2724 10.7329 24.6421 12.4677 25.2204 15.0655C25.7988 17.6633 24.4016 20.2968 21.9734 21.1854C21.3775 21.4035 20.9669 21.964 20.9182 22.6085L20.9133 22.7385V24.2784C20.9133 24.6375 20.6321 24.9286 20.2852 24.9286C19.965 24.9286 19.7008 24.6805 19.662 24.36L19.6571 24.2784V22.7388C19.6565 21.544 20.3522 20.473 21.4085 20.0177L21.5547 19.9595C23.3806 19.2913 24.4312 17.311 23.9963 15.3575C23.5614 13.404 21.7795 12.0995 19.8552 12.3258C17.9309 12.5522 16.4772 14.2373 16.4768 16.242C16.4768 16.6011 16.1955 16.8921 15.8486 16.892C15.5018 16.892 15.2206 16.6008 15.2207 16.2418C15.2212 13.5759 17.1544 11.3349 19.7134 11.0339Z' />
			<path d='M20.2856 27.25L20.392 27.2548C20.9877 27.3081 21.4545 27.8053 21.4545 28.4107C21.4545 29.0518 20.9312 29.5714 20.2856 29.5714C19.6401 29.5714 19.1168 29.0518 19.1168 28.4107C19.1168 27.8053 19.5836 27.3081 20.1792 27.2548L20.2856 27.25Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM20 38.8C9.61705 38.8 1.2 30.383 1.2 20C1.2 9.61705 9.61705 1.2 20 1.2C30.383 1.2 38.8 9.61705 38.8 20C38.8 30.383 30.383 38.8 20 38.8Z'
			/>
		</SvgIcon>
	);
}
