import { Box, TextFieldProps, Typography } from '@mui/material';
import React from 'react';
import MuiTextFieldText from '../../../mui/MuiTextField/MuiTextFieldText/MuiTextFieldText';
import styles from './YourInfo.module.scss';

export type YourInfoProps = {
	firstName?: string;
	setFirstName: React.Dispatch<React.SetStateAction<string | undefined>>;
	firstNameValidation?: string;
	lastName?: string;
	setLastName: React.Dispatch<React.SetStateAction<string | undefined>>;
	lastNameValidation?: string;
	onBlur?: TextFieldProps['onBlur'];
};

const YourInfo: React.FC<YourInfoProps> = ( {
	firstName,
	setFirstName,
	firstNameValidation,
	lastName,
	setLastName,
	lastNameValidation,
	onBlur,
} ) => (
	<>
		<Typography variant='h1' component='h1'>
			Nice to meet you!
		</Typography>
		<Box className={ styles.row }>
			<MuiTextFieldText
				label='First name'
				value={ firstName || '' }
				errorText={ firstNameValidation }
				onChange={ ( e ) => setFirstName( e.target.value ) }
				autoComplete='given-name'
				onBlur={ onBlur }
				inputProps={ {
					'data-cy': 'firstName',
				} }
				autoFocus
			/>
			<MuiTextFieldText
				label='Last name'
				value={ lastName || '' }
				errorText={ lastNameValidation }
				onChange={ ( e ) => setLastName( e.target.value ) }
				autoComplete='family-name'
				onBlur={ onBlur }
				inputProps={ {
					'data-cy': 'lastName',
				} }
			/>
		</Box>
	</>
);

export default YourInfo;
