import { LOGIN, UPDATE_USER, LOGOUT } from '../actions';
import {
	Redirect,
	setCookie,
	unsetCookie,
	flattenUser,
} from '../../lib/helpers';
import { cleanSegmentTraitAndId } from '../../lib/helpers/segment';

/**
 * combines two user objects
 *
 * @param { Object } oldUser
 * @param { Object } newUser
 *
 * @returns { Object } returnableUser
 */
const combineUsers = ( oldUser, newUser ) => {
	const returnableUser = {
		...oldUser,
		...newUser,
		user: {
			...oldUser.user,
			...newUser.user,
		},
		...( oldUser?.organization && newUser?.organization
			? { organization: { ...oldUser.organization, ...newUser.organization } }
			: {} ),
		isLoggedIn: true,
		updatedAt: new Date().getTime(),
	};

	return flattenUser( returnableUser );
};

/**
 * Extracts/calculates values from
 *
 * @param { Object } user - A logged in user object.
 *
 * @returns { Object }
 */
export const getCookieFromUser = ( user ) => {
	const cookieObject = {
		id: user.id,
		email: user.user.email,
		userID: user.user.id,
		userType: user.user.userType,
		isAdmin: user.user.userType === 'OrgUser' && user.isAdmin === true,
		isSuperAdmin: user.user.userType === 'SuperAdmin',
		isOwner: user.user.userType === 'OrgUser' && user.isOwner,
		isLoggedIn: true,
		groups: user.groups || [],
		impersonatingUser: user.impersonatingUser,
	};

	return cookieObject;
};

/**
 * Sets the appropriate cookie for a given user object.
 *
 * @param { Object } user - An object to set to the CurrentUser cookie
 * @param { Object } ctx - Nextjs context object. Only required for server-side cookie setting.
 */
const setCookieFromUser = ( user, ctx ) => {
	const cookieObject = getCookieFromUser( user );
	setCookie( {
		cookieName: 'CurrentUser',
		cookieString: JSON.stringify( cookieObject ),
		options: {},
		ctx,
	} );
};

export const defaultUserState = {
	isLoggedIn: false,
};

export default function user( state = defaultUserState, action ) {
	switch ( action.type ) {
		case LOGIN:
			if ( !action.user ) return defaultUserState;
			const user = flattenUser( action.user );

			setCookieFromUser( action.user, action.ctx );
			if ( action.token ) {
				setCookie( {
					cookieName: 'Authorization',
					cookieString: action.token,
					options: {},
					ctx: action.ctx,
				} );
			}

			return user;

		case LOGOUT:
			unsetCookie( 'CurrentUser', action.ctx );
			unsetCookie( 'Authorization', action.ctx );
			cleanSegmentTraitAndId();

			// force a non-clientside route change to the login screen
			if ( action.redirect ) {
				if ( action.ctx ) {
					Redirect( action.ctx.res, '/Login' );
				} else {
					window.location.pathname = '/Login';
				}
			}

			return {
				isLoggedIn: false,
			};

		case UPDATE_USER: // does not overwrite computed fields
			const updatedUser = combineUsers( state, action.user );

			if ( updatedUser ) {
				setCookieFromUser( updatedUser, action.ctx );
			}

			return updatedUser;

		default:
			return state;
	}
}
