/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronRightSquare = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<rect
			x='0.5'
			y='0.5'
			width='23'
			height='23'
			rx='2.5'
			strokeLinejoin='round'
		/>
		<path d='M8.23427 5.2261C7.9508 5.50064 7.92452 5.93075 8.15576 6.23415L8.23286 6.32108L14.0751 12.0077L8.23366 17.6782C7.9505 17.953 7.92469 18.3831 8.15628 18.6863L8.23347 18.7731C8.51654 19.0481 8.95955 19.0731 9.27178 18.8483L9.36123 18.7733L15.7663 12.5563C16.0493 12.2817 16.0753 11.852 15.8442 11.5488L15.7671 11.4619L9.36202 5.22746C9.05099 4.92471 8.54607 4.9241 8.23427 5.2261Z' />
	</svg>
);
export default ChevronRightSquare;
