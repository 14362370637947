import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { showError } from '../blocks/Toast';
import { InquiryStatus } from '../types/generated';
import {
	useNewLeadsSubscription_GetInquiriesForOrganizationQuery,
	useNewLeadsSubscription_InquiryChangedSubscription,
} from './__generated__/useNewLeadsSubscription';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newLeadsSubscription_GetInquiriesForOrganization = gql`
	query newLeadsSubscription_GetInquiriesForOrganization(
		$where: InquiriesForOrganizationWhereInput
	) {
		getInquiriesForOrganization(where: $where) {
			_count
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const newLeadsSubscription_InquiryReceived = gql`
	subscription newLeadsSubscription_InquiryChanged {
		inquiryChanged {
			organization {
				inquiries {
					status
				}
			}
		}
	}
`;

export const useNewLeadsSubscription = ( skip: boolean ) => {
	const [ badgeContent, setBadgeContent ] = useState<number>();

	const { data: query, error: queryError } =
		useNewLeadsSubscription_GetInquiriesForOrganizationQuery( {
			variables: { where: { status: { in: [ InquiryStatus.New ] } } },
			skip,
		} );

	useEffect( () => {
		const queryValue = query?.getInquiriesForOrganization._count;
		if ( typeof queryValue === 'number' ) {
			setBadgeContent( queryValue );
		}
	}, [ query ] );

	const { data: subscription, error: subscriptionError } =
		useNewLeadsSubscription_InquiryChangedSubscription( {
			skip,
		} );

	useEffect( () => {
		const subscriptionValue =
			subscription?.inquiryChanged.organization.inquiries.filter(
				( inquiry ) => inquiry.status === InquiryStatus.New
			).length;
		if ( typeof subscriptionValue === 'number' ) {
			setBadgeContent( subscriptionValue );
		}
	}, [ subscription ] );

	useEffect( () => {
		if ( queryError ) {
			showError( queryError );
		}
		if ( subscriptionError ) {
			showError( subscriptionError );
		}
	}, [ queryError, subscriptionError ] );

	return {
		badgeContent,
	};
};
