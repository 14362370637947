import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FileImage = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.25 3c0-.966.784-1.75 1.75-1.75h12.903c.463 0 .907.183 1.235.51l3.096 3.082c.33.328.516.775.516 1.24V21A1.75 1.75 0 0 1 20 22.75H4A1.75 1.75 0 0 1 2.25 21V3ZM4 2.75a.25.25 0 0 0-.25.25v18c0 .138.112.25.25.25h16a.25.25 0 0 0 .25-.25V6.082a.25.25 0 0 0-.074-.177L17.08 2.823a.25.25 0 0 0-.176-.073H4ZM5.25 9A.75.75 0 0 1 6 8.25h12a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75v-2.495a.69.69 0 0 1 0-.01V9Zm1.5 6.845v1.405h10.5v-1.39l-1.75-1.4-2.031 1.626a.75.75 0 0 1-.957-.017L9.5 13.488l-2.75 2.357Zm10.5-1.905-1.281-1.026a.75.75 0 0 0-.938 0l-2.014 1.612-3.029-2.595a.75.75 0 0 0-.976 0L6.75 13.869V9.75h10.5v4.19Z'
		/>
	</svg>
);

export default FileImage;
