import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import {
	Box,
	FormControlLabel,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import React from 'react';
import MuiCheckbox from '../../../../../mui/MuiCheckbox';
import MuiModal from '../../../../../mui/MuiModal/MuiModal';
import MuiTextFieldText from '../../../../../mui/MuiTextField/MuiTextFieldText/MuiTextFieldText';
import { useInquiriesFormField } from '../useInquiriesFormField';
import styles from './InquiriesConfigurationFormStandardField.module.scss';
import coalesceClassNames from 'classnames';
import { commonFieldDataIsFirstNameTwo } from '../../types';
import { useInquiriesConfigurationFields } from '../../useInquiriesConfigurationFields';
import { ArrayElement } from '../../../../../lib/helpers/apollo';

export type InquiriesConfigurationFormStandardFieldProps = {
	index: number;
} & ArrayElement<
ReturnType<typeof useInquiriesConfigurationFields>['usedItems']
>;

export const itemIsStandardField = (
	item: Record<string, any> | InquiriesConfigurationFormStandardFieldProps
): item is InquiriesConfigurationFormStandardFieldProps =>
	[
		'text',
		'tel',
		'url',
		'email',
		'numeric',
		'textarea',
		'date'
	].includes(
		item.inputMode
	);

const Item: React.FC<
InquiriesConfigurationFormStandardFieldProps & {
	className?: string;
	provided?: DraggableProvided;
	dragHandleProps?: DraggableProvided['dragHandleProps'];
}
> = ( { className, provided, dragHandleProps, ...props } ) => {
	const { forceRequired } = props;

	const {
		containerProps,
		classNames,
		label,
		DragHandle,
		setLabel,
		required,
		setRequired,
		modalProps,
	} = useInquiriesFormField( {
		...props,
		onUpdate: ( { label, required } ) => {
			props.setLabel( label );
			props.setRequired( required );
		},
	} );
	return (
		<ListItem
			{ ...provided?.draggableProps }
			{ ...containerProps }
			ref={ provided?.innerRef }
			className={ coalesceClassNames( containerProps.className, className ) }
		>
			{ dragHandleProps || provided?.dragHandleProps ? (
				<DragHandle { ...( dragHandleProps || provided?.dragHandleProps ) } />
			) : null }
			<ListItemText
				className={ classNames.text }
				primary={ props.label }
				secondary={
					props.validation ? (
						<Typography color='error'>{ props.validation }</Typography>
					) : undefined
				}
			/>
			<MuiModal { ...modalProps }>
				<Box className={ classNames.modal }>
					<MuiTextFieldText
						fullWidth
						label='Label'
						value={ label }
						onChange={ ( e ) => setLabel( e.target.value ) }
						errorText={ props.validation }
						onBlur={ () => props.onBlur() }
					/>
					<FormControlLabel
						label='Required Field'
						disabled={ forceRequired }
						control={
							<MuiCheckbox
								checked={ forceRequired || required }
								onChange={ ( _, checked ) => setRequired( checked ) }
							/>
						}
					/>
				</Box>
			</MuiModal>
		</ListItem>
	);
};

export const InquiriesConfigurationFormStandardField: React.FC<
InquiriesConfigurationFormStandardFieldProps
> = ( props ) => {
	const { type, index } = props;

	/* we're using forceRequired as in indicator of non-draggable fields. Because I couldn't
	get @hello-pangea/dnd to let me mix single and double column items we have the narrow
	items either not draggable at all, or they drag as a single item */
	if ( props.forceRequired || typeof index !== 'number' ) {
		return <Item { ...props } />;
	}

	if ( commonFieldDataIsFirstNameTwo( props ) ) {
		return (
			<Draggable key={ type } draggableId={ type } index={ index }>
				{ ( provided ) => (
					<ListItem
						{ ...provided.draggableProps }
						ref={ provided.innerRef }
						className={ styles.container }
					>
						<List className={ styles.list } disablePadding>
							<Item { ...props } dragHandleProps={ provided.dragHandleProps } />
							<Item
								{ ...( props.lastNameTwo as InquiriesConfigurationFormStandardFieldProps ) }
								className={ styles.pairedItem }
							/>
						</List>
					</ListItem>
				) }
			</Draggable>
		);
	}

	return (
		<Draggable key={ type } draggableId={ type } index={ index }>
			{ ( provided ) => <Item { ...props } provided={ provided } /> }
		</Draggable>
	);
};
