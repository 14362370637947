import { useRouter } from 'next/router';
import React from 'react';
import P from '../../../elements/Paragraph';
import {
	linkObject,
	getDetailLink,
	isAssignedPlannerOrAdmin,
} from '../../../lib/helpers/index';
import { LinkObjectType } from '../../../lib/helpers/types';
import { ProposalsIcon } from '../../../mui/Icons/index';
import { linkCustomer, LinkCustomerLinkType } from '../helpers';

export default function getProposalAcceptedByPlannerEventProps(
	defaultEventProps: {
		event: any;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	},
	user: any,
	targetUser: any
) {
	// if a file isn't a component and it uses a hook then its name should start with `use`
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const router = useRouter();
	const updatedEventProps = { ...defaultEventProps };
	const proposalLink = linkObject( updatedEventProps.event.proposal );
	const customerLink = linkCustomer(
		updatedEventProps.event,
		LinkCustomerLinkType.Contact
	);
	const vendorLink = linkObject( updatedEventProps.event.vendor );
	const assignedPlannerOrAdmin = isAssignedPlannerOrAdmin(
		user,
		updatedEventProps.event.clientUser
	);
	const isVendorDetail =
		router.pathname === '/org/[id]' && targetUser?.user.userType === 'OrgUser';

	updatedEventProps.icon = <ProposalsIcon />;
	updatedEventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: updatedEventProps.event.proposal,
				type: LinkObjectType.Proposal,
			} ),
		},
	];

	if ( defaultEventProps.event.category === 'ProposalAcceptedByPlanner' ) {
		if ( isVendorDetail ) {
			updatedEventProps.message = (
				<P className='p--secondary'>{ proposalLink } has been accepted.</P>
			);
		} else {
			updatedEventProps.message = (
				<P className='p--secondary'>
					{ customerLink } has accepted a proposal from { vendorLink }
				</P>
			);
		}
	} else if (
		defaultEventProps.event.category === 'ProposalAcceptedByCustomer'
	) {
		if ( assignedPlannerOrAdmin ) {
			updatedEventProps.message = (
				<P className='p--secondary'>
					{ customerLink } has accepted a proposal from { vendorLink }
				</P>
			);
		} else {
			updatedEventProps.message = (
				<P className='p--secondary'>{ proposalLink } has been accepted.</P>
			);
		}
	}

	return updatedEventProps;
}
