import { FormControl, FormLabel } from '@mui/material';
import React, { useId, useState } from 'react';
import MuiButton from '../../../../mui/MuiButton';
import MuiModal from '../../../../mui/MuiModal/MuiModal';
import ProfileImagePicker from '../../../forms/inputs/ProfileImagePicker';
import styles from './OrganizationLogoSelector.module.scss';

export type ProfileImageSelection = {
	imageFile: File;
	imagePositionX: number;
	imagePositionY: number;
	imageRotation: number;
	imageScale: number;
	originalImage: File;
};

export type OrganizationLogoSelectorProps = {
	errorText?: string;
	onSelect?: ( file: ProfileImageSelection ) => void;
};

const OrganizationLogoSelector: React.FC<OrganizationLogoSelectorProps> = ( {
	onSelect,
	errorText,
} ) => {
	const [ open, setOpen ] = useState( false );
	const buttonId = useId();
	return (
		<>
			<FormControl error={ !!errorText } className={ styles.container }>
				<FormLabel htmlFor={ buttonId }>
					Business logo or image (optional)
				</FormLabel>
				<MuiButton
					id={ buttonId }
					variant='text'
					onClick={ () => setOpen( true ) }
					data-cy='profileImageSelector'
				>
					Add
				</MuiButton>
			</FormControl>
			<MuiModal
				muiModalProps={ {
					open,
					onClose: () => setOpen( false ),
				} }
			>
				<ProfileImagePicker
					closeModal={ () => setOpen( false ) }
					values={ {} }
					onChange={ (
						event: React.ChangeEvent<{ value: ProfileImageSelection }>
					) => onSelect?.( event.target.value ) }
					onBack={ () => setOpen( false ) }
				/>
			</MuiModal>
		</>
	);
};

export default OrganizationLogoSelector;
