import Image from 'next/image';
import styled from 'styled-components';
import Logo from '../../public/logo2.jpg';

const Logo2 = styled.div`
	max-width: 12rem;
	height: 165px;
	width: auto;
	display: block;
	margin: 0 auto;
	position: relative;
	img {
		object-fit: contain;
	}
`;

export default ( props ) => (
	<Logo2 { ...props }>
		<Image src={ Logo } fill={ true } alt='Rock Paper Coin logo' />
	</Logo2>
);
