import React from 'react';
import styled from 'styled-components';
import Router from 'next/router';

// Markup
import Button from '../../elements/Button.jsx';
import Modal from '../../elements/Modal';
import P from '../../elements/Paragraph.jsx';
import { H2 } from '../../elements/Headers';

// Utils
import { isOrgAdmin } from '../../lib/helpers';

const ModalContainer = styled( Modal )`
	@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
		min-width: 43rem;
	}

	h3 + p,
	p + div {
		margin-top: 1rem;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
			flex-direction: row;
		}
		button {
			& + button {
				margin-top: 1rem;
				@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
					margin-top: 0;
					margin-left: 1rem;
				}
			}
		}
	}
`;

const SubscriptionChargeFailedModal = ( props ) => {
	/**
	 * Closes modal
	 */
	const closeModal = () => {
		props.closeModal( false );
	};

	return (
		<ModalContainer
			id='subscription-charge-failed-modal'
			closable={ true }
			closeModal={ closeModal }
		>
			{ isOrgAdmin( props.user ) && (
				<>
					<H2 className='modal__h2 h2--secondary h2--bold'>
						Subscription payment failure
					</H2>
					<P>
						There was an issue processing your subscription payment. Please go
						to the wallet page and update the card on file for the Charge
						Account.
					</P>
					<div>
						<Button
							className='button--ghost'
							id='close-subscription-charge-failed-btn'
							onClick={ closeModal }
							text='Later'
						/>
						<Button
							id='continue-subscription-charge-failed-btn'
							text='Let’s go!'
							onClick={ () => Router.push( '/wallet?chargeAccount=true' ) }
						/>
					</div>
				</>
			) }
			{ !isOrgAdmin( props.user ) && (
				<>
					<H2 className='modal__h2 h2--secondary h2--bold'>
						Subscription payment failure
					</H2>
					<P>
						There was an issue processing your subscription payment. Please let
						your admin know.
					</P>
					<div>
						<Button
							id='close-subscription-charge-failed-btn'
							text='Got it'
							onClick={ closeModal }
						/>
					</div>
				</>
			) }
		</ModalContainer>
	);
};

export default SubscriptionChargeFailedModal;
