import React, { SVGProps } from 'react';
/* eslint max-len: 0 */
const Computer = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<g>
			<path
				d='M6 22.5c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L6 21h1.614l.5-3H.75c-.38 0-.693-.282-.743-.648L0 17.25v-15c0-.414.336-.75.75-.75h22.5c.414 0 .75.336.75.75v15c0 .414-.336.75-.75.75h-7.365l.5 3H18c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L18 22.5H6zm8.364-4.5H9.635l-.5 3h5.729l-.5-3zM22.5 3h-21v13.5h21V3z'
				transform='translate(-1079 -237) translate(1046 212) translate(32.997 24) translate(0 1)'
			/>
		</g>
	</svg>
);

export default Computer;
