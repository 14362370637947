import {
	FormControl,
	FormControlProps,
	FormHelperText,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import styles from './MuiTextArea.module.scss';

export type MuiTextAreaProps = {
	countCharacters?: boolean | number; // if this is a number we'll use it as the character count
	errorText?: string;
	maxLength?: number;
} & Omit<
TextFieldProps,
'className' | 'fullWidth' | 'type' | 'error' | 'variant'
> &
Pick<FormControlProps, 'className' | 'fullWidth' | 'variant'>;

const MuiTextArea = forwardRef<HTMLDivElement | null, MuiTextAreaProps>(
	( props, ref ) => {
		const {
			countCharacters,
			onChange,
			errorText,
			maxLength,
			variant,
			className,
			focused,
			...propsToSpread
		} = props;
		const [ characterCount, setCharacterCount ] = useState( 0 );
		const [ focus, setFocus ] = useState( focused );
		useEffect( () => {
			setFocus( focused );
		}, [ focused ] );
		const focusClassName = focus ? 'Mui-focused' : undefined;
		const colorClassName = `Mui-${ propsToSpread.color || 'primary' }`;
		const onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> =
			useCallback(
				( e: React.ChangeEvent<HTMLTextAreaElement> ) => {
					setCharacterCount( e.target.value.length );
					onChange?.( e );
				},
				[ onChange ]
			);
		useEffect( () => {
			setCharacterCount( ( prevState ) =>
				typeof propsToSpread.value === 'string'
					? propsToSpread.value.length
					: prevState
			);
		}, [ propsToSpread.value ] );
		return (
			<FormControl
				variant={ variant }
				error={ !!errorText }
				fullWidth={ propsToSpread.fullWidth }
				className={ [
					className,
					styles[ colorClassName ],
					focusClassName ? styles[ focusClassName ] : undefined,
				].join( ' ' ) }
			>
				<TextField
					{ ...propsToSpread } // also including fulWidth from FormControlProps here
					ref={ ref }
					focused={ focused }
					multiline={ true }
					onChange={ onChangeHandler }
					className={ styles.textarea }
					inputProps={ { ...propsToSpread.inputProps, maxLength } }
					onFocus={ ( e ) => {
						setFocus( true );
						propsToSpread.onFocus?.( e );
					} }
					onBlur={ ( e ) => {
						setFocus( false );
						propsToSpread.onBlur?.( e );
					} }
					error={ !!errorText }
				/>
				{ countCharacters !== undefined ? (
					<Typography
						className={ [
							styles.characterCount,
							...( propsToSpread.disabled
								? [ styles.characterCountDisabled ]
								: [] ),
						].join( ' ' ) }
					>
						{ typeof countCharacters === 'number'
							? countCharacters
							: characterCount }
						{ maxLength ? ` / ${ maxLength }` : null }
					</Typography>
				) : null }
				{ errorText ? (
					<FormHelperText className={ styles.errorText }>
						{ errorText }
					</FormHelperText>
				) : null }
			</FormControl>
		);
	}
);

export default MuiTextArea;
