import React from 'react';
import { Typography } from '@mui/material';

import {
	EventForContractEventFeedHelpers,
	UserForContractSentEventFeed,
} from './helpers';
import { LinkCustomerEvent } from './../helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

type DefaultEventProps = {
	event: EventForContractApprovedEventFeed;
	message: JSX.Element;
	links: { type: string; text: string; link: any; className?: string }[];
	icon: string;
	className: string;
};

type EventForContractApprovedEventFeed = EventForContractEventFeedHelpers &
LinkCustomerEvent & {
	resolvedBy?: { id: string };
	vendor: { name: string; id: string };
	contacts: {
		customer: {
			id: string;
		};
	};
	actingUser: {
		orgUser: {
			organization: {
				id: string;
				name: string;
				services: {
					name: string;
				};
			};
		};
	};
	contract: {
		id: string;
	};
};

const getContractApprovedEventProps = (
	defaultEventProps: DefaultEventProps,
	user: UserForContractSentEventFeed
) => {
	if (
		!defaultEventProps.event.contract ||
		typeof defaultEventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = defaultEventProps;
	defaultEventProps.icon = 'contract';

	defaultEventProps.message = (
		<Typography>
			{ linkObject( event.actingUser.orgUser.organization ) } approved a contract:{ ' ' }
			{ linkObject( defaultEventProps.event.contract ) }
		</Typography>
	);
	defaultEventProps.links = [
		{
			type: event.resolvedBy ? 'secondary' : 'primary',
			text: event.resolvedBy ? 'View' : 'Sign',
			link: getDetailLink( {
				object: event.contract,
				type: LinkObjectType.Contract,
			} ),
		},
	];

	return defaultEventProps;
};

export default getContractApprovedEventProps;
