import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ExportIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.280996 23.719C0.0427221 23.4807 -0.0530645 23.1349 0.028663 22.808L1.82945 15.6048C1.86667 15.4296 1.95332 15.2629 2.08941 15.1268L16.5606 0.65561C17.4347 -0.218537 18.852 -0.218537 19.7261 0.65561L23.3439 4.2734C24.2181 5.14755 24.2181 6.56482 23.3439 7.43897L8.89086 21.892C8.82438 21.9621 8.74742 22.022 8.66242 22.0695C8.58525 22.1126 8.50338 22.1443 8.41931 22.1645L1.192 23.9713C0.86509 24.0531 0.519271 23.9573 0.280996 23.719ZM18.5956 9.47397L14.5256 5.40395L4.12466 15.8049L8.19468 19.8749L18.5956 9.47397ZM19.9522 8.1173L21.9873 6.0823C22.1121 5.95742 22.1121 5.75495 21.9873 5.63007L18.3695 2.01228C18.2446 1.8874 18.0421 1.8874 17.9172 2.01228L15.8822 4.04729L19.9522 8.1173ZM2.27778 21.7222L3.28786 17.6819L6.31811 20.7121L2.27778 21.7222Z'
			/>
		</SvgIcon>
	);
}
