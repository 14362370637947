import ProposalsIcon from './ProposalsIcon';
import ContractsIcon from './ContractsIcon';
import ContactsIcon from './ContactsIcon';
import InvoicesIcon from './InvoicesIcon';
import DashboardIcon from './DashboardIcon';
import MenuIcon from './IconMenuIcon';
import ClientFoldersIcon from './ClientFoldersIcon';
import CompanyFoldersIcon from './CompanyFoldersIcon';
import ProposalTemplatesIcon from './ProposalTemplatesIcon';
import ContractTemplatesIcon from './ContractTemplatesIcon';
import InvoiceTemplatesIcon from './InvoiceTemplatesIcon';
import ResourcesIcon from './ResourcesIcon';
import QuestionMarkIcon from './QuestionMarkIcon';
import TeamIcon from './TeamIcon';
import BurgerIcon from './BurgerIcon';
import CloseIcon from './CloseIcon';
import ChevronDownIcon from './ChevronDownIcon';
import VendorsIcon from './VendorsIcon';
import ExportIcon from './ExportIcon';
import UpdateIcon from './UpdateIcon';
import InquiryIcon from './InquiryIcon';

export {
	ProposalsIcon,
	ContractsIcon,
	ContactsIcon,
	InvoicesIcon,
	DashboardIcon,
	MenuIcon,
	ClientFoldersIcon,
	CompanyFoldersIcon,
	ProposalTemplatesIcon,
	ContractTemplatesIcon,
	InvoiceTemplatesIcon,
	InquiryIcon,
	ResourcesIcon,
	QuestionMarkIcon,
	TeamIcon,
	CloseIcon,
	BurgerIcon,
	ChevronDownIcon,
	VendorsIcon,
	ExportIcon,
	UpdateIcon,
};
