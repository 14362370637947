import React from 'react';
import { Typography } from '@mui/material';
import rpcShared from '@rockpapercoin/rpc-shared';

import {
	UserForContractSentEventFeed,
	userIsActingUserOnEvent,
	userIsAssignedMemberOrVendorAdminOnContractEvent,
	userIsCustomerOnContractEvent,
	userIsAPPAOnContractEvent,
	getCustomerLinksAsVendorOrAPPAForContractEvent,
	DefaultEventProps,
} from './helpers';
// Lib
import { getDetailLink, toSimpleLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

const getContractSentEventProps = (
	defaultEventProps: DefaultEventProps,
	user: UserForContractSentEventFeed
) => {
	if (
		!defaultEventProps.event.contract ||
		typeof defaultEventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = defaultEventProps;
	defaultEventProps.icon = 'contract';
	if (
		userIsAPPAOnContractEvent( event, user ) &&
		!userIsAssignedMemberOrVendorAdminOnContractEvent( event, user )
	) {
		// you are the planner viewing the event where one or more of your customers
		// is a signer on this contract
		// but you are NOT also the vendor on the contract
		// meaning you are acting in a planner capacity
		const signaturesPlannerCanSign =
			rpcShared.contracts.getSignableSignaturesForCustomerAsAPPA(
				defaultEventProps.event.contract,
				user
			);
		const hasSigningPermission = !!signaturesPlannerCanSign?.length;

		defaultEventProps.message = (
			<Typography>
				{ event.vendor.name } sent a{ ' ' }
				{ toSimpleLink( {
					link: getDetailLink( {
						object: defaultEventProps.event.contract,
					} ),
					text: 'contract',
				} ) }{ ' ' }
				to { getCustomerLinksAsVendorOrAPPAForContractEvent( event, user, true ) }
				{ '.' }
			</Typography>
		);
		defaultEventProps.links = [
			{
				type:
					event.resolvedBy || !hasSigningPermission ? 'secondary' : 'primary',
				text: event.resolvedBy || !hasSigningPermission ? 'View' : 'Sign',
				link: getDetailLink( {
					object: defaultEventProps.event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
	} else if (
		userIsActingUserOnEvent( event, user ) ||
		userIsAssignedMemberOrVendorAdminOnContractEvent( event, user )
	) {
		// you are the person who sent the document
		// you are the assigned member to one of the customers on the contract
		// you are an admin of the vendor org
		defaultEventProps.message = (
			<Typography>
				You sent{ ' ' }
				{ getCustomerLinksAsVendorOrAPPAForContractEvent( event, user, false ) } a
				contract: { linkObject( defaultEventProps.event.contract ) }.
			</Typography>
		);
		defaultEventProps.links = [
			{
				type: 'secondary',
				text: 'View',
				link: getDetailLink( {
					object: defaultEventProps.event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
	} else if ( userIsCustomerOnContractEvent( event, user ) ) {
		// you are a customer
		defaultEventProps.message = (
			<Typography>
				New{ ' ' }
				{ toSimpleLink( {
					link: getDetailLink( {
						object: defaultEventProps.event.contract,
						type: LinkObjectType.Contract,
					} ),
					text: 'contract',
				} ) }{ ' ' }
				from { linkObject( event.vendor ) }.
			</Typography>
		);
		defaultEventProps.links = [
			{
				type: event.resolvedBy ? 'secondary' : 'primary',
				text: event.resolvedBy ? 'View' : 'Sign',
				link: getDetailLink( {
					object: defaultEventProps.event.contract,
					type: LinkObjectType.Contract,
				} ),
				className: 'eventRow-contract-resent__sign-btn',
			},
		];
	} else {
		//eslint-disable-next-line max-len
		throw new Error(
			`getContractSentEventProps unable to determine user role.\n event:\n ${ JSON.stringify(
				event,
				null,
				2
			) }\n user: \n ${ user } `
		);
	}
	return defaultEventProps;
};

export default getContractSentEventProps;
