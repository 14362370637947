/* eslint max-len: 0 */
const Update = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M19.1 4.498h4.15c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-2.634c2.462 3.532 2.542 8.288.046 11.933-2.81 4.104-8.113 5.674-12.704 3.761-.383-.159-.564-.598-.404-.98.159-.383.598-.564.98-.404 3.936 1.64 8.482.293 10.89-3.225 2.1-3.066 2.073-7.049.077-10.058L19.5 9.748c0 .38-.282.693-.648.743l-.102.007c-.38 0-.693-.282-.743-.648L18 9.748v-4.5c0-.38.282-.693.648-.743l.102-.007.228-.001c.04-.003.081-.003.122.001zm-18.35 15c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h2.635C.93 14.467.852 9.715 3.347 6.073c2.808-4.1 8.105-5.671 12.695-3.765.382.16.564.598.405.98-.16.383-.598.565-.98.406C11.531 2.058 6.99 3.405 4.583 6.92 2.487 9.982 2.51 13.96 4.5 16.968l.001-2.72c0-.38.282-.693.648-.743l.102-.007c.38 0 .693.282.743.648l.007.102v4.5c0 .38-.282.693-.648.743l-.102.007-.23.001c-.04.003-.08.003-.122-.001H.75z'
			transform='translate(-224 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(80)'
		/>
	</svg>
);

export default Update;
