import * as nookies from 'nookies';

/**
 * Sets a cookie according to the current environment.
 */
export const setCookie = ( {
	cookieName,
	cookieString,
	options = {},
	ctx,
}: {
	cookieName: string;
	cookieString: string;
	options?: Record<string, any>;
	ctx?: import( 'next' ).NextPageContext;
} ) => {
	if ( typeof window !== 'undefined' ) {
		let stringOptions = '';
		for ( const key in options ) {
			if ( Object.prototype.hasOwnProperty.call( options, key ) ) {
				stringOptions += `${ key }=${ options[ key ] }; `;
			}
		}
		if ( !options.path ) {
			stringOptions += 'path=/;';
		}
		if (
			!Object.prototype.hasOwnProperty.call( options, 'max-age' ) &&
			!Object.prototype.hasOwnProperty.call( options, 'expires' )
		) {
			stringOptions += 'max-age=86400;';
		}
		const cookie = `${ cookieName }=${ cookieString }; ${ stringOptions }`;
		document.cookie = cookie;
	} else if ( ctx ) {
		nookies.setCookie( ctx, cookieName, cookieString, {
			// set max age of cookie to 1 day, or 86400 seconds
			maxAge: 86400,
			path: '/',
			...options,
		} );
	}
};
