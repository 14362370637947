import {
	Box,
	FormControl,
	FormLabel,
	IconButton,
	Typography,
} from '@mui/material';
import React, { useId } from 'react';
import { CloseIcon } from '../../../../mui/Icons';
import styles from './OrganizationLogoSelected.module.scss';

export type ProfileImageSelection = {
	imageFile: File;
	imagePositionX: number;
	imagePositionY: number;
	imageRotation: number;
	imageScale: number;
	originalImage: File;
};

export type OrganizationLogoSelectedProps = {
	value: string;
	onRemove?: () => void;
};

const OrganizationLogoSelected: React.FC<OrganizationLogoSelectedProps> = ( {
	value,
	onRemove,
} ) => {
	const buttonId = useId();
	return (
		<FormControl className={ styles.container } data-cy='profileImageSelected'>
			<FormLabel htmlFor={ buttonId }>
				Business logo or image (optional)
			</FormLabel>
			<Box className={ styles.row }>
				<Typography className={ styles.filename }>{ value }</Typography>
				<IconButton id={ buttonId } onClick={ onRemove } className={ styles.icon }>
					<CloseIcon />
				</IconButton>
			</Box>
		</FormControl>
	);
};

export default OrganizationLogoSelected;
