import * as Types from '../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectServices_GetServicesWhereQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectServices_GetServicesWhereQuery = { __typename?: 'Query', getServicesWhere: Array<{ __typename?: 'Service', id: string, name: string }> };


export const SelectServices_GetServicesWhereDocument = gql`
    query SelectServices_GetServicesWhere {
  getServicesWhere {
    id
    name
  }
}
    `;

/**
 * __useSelectServices_GetServicesWhereQuery__
 *
 * To run a query within a React component, call `useSelectServices_GetServicesWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectServices_GetServicesWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectServices_GetServicesWhereQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectServices_GetServicesWhereQuery(baseOptions?: Apollo.QueryHookOptions<SelectServices_GetServicesWhereQuery, SelectServices_GetServicesWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectServices_GetServicesWhereQuery, SelectServices_GetServicesWhereQueryVariables>(SelectServices_GetServicesWhereDocument, options);
      }
export function useSelectServices_GetServicesWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectServices_GetServicesWhereQuery, SelectServices_GetServicesWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectServices_GetServicesWhereQuery, SelectServices_GetServicesWhereQueryVariables>(SelectServices_GetServicesWhereDocument, options);
        }
export type SelectServices_GetServicesWhereQueryHookResult = ReturnType<typeof useSelectServices_GetServicesWhereQuery>;
export type SelectServices_GetServicesWhereLazyQueryHookResult = ReturnType<typeof useSelectServices_GetServicesWhereLazyQuery>;
export type SelectServices_GetServicesWhereQueryResult = Apollo.QueryResult<SelectServices_GetServicesWhereQuery, SelectServices_GetServicesWhereQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    