/* eslint max-len: 0 */
const Pinterest = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<g fillRule='evenodd'>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path
									d='M12.53.05c-5.929 0-9.99 4.104-9.99 8.82-.142 1.952.71 3.9 2.282 5.156.39.312.97.162 1.161-.3l1.31-3.17c.065-.157.075-.332.027-.496-.089-.304-.132-.618-.13-.934-.015-1.307.5-2.548 1.427-3.448.865-.84 2.016-1.315 3.215-1.332l.258.003c2.597.001 4.08 1.44 4.08 3.671 0 3.085-1.317 5.38-2.91 5.38-.61 0-.817-.358-.619-1.222l.035-.136c.071-.25.182-.524.461-1.164l.255-.59c.427-1.01.617-1.693.563-2.36-.08-.982-.734-1.656-1.883-1.91-1.6-.355-2.773.376-3.431 1.809l-.122.287c-.306.782-.466 1.694-.522 2.651l-.013.278-1.644 6.934c-.275 1.595-.3 3.184-.082 4.743l.073.467c.027.328.302.651.678.748.335.087.686-.022.913-.273L8 23.56c1.013-1.257 1.83-2.733 2.378-4.315l.425-1.576.152.11c.766.526 1.69.807 2.645.78 4.476 0 7.859-4.735 7.859-10.36 0-4.477-3.827-8.15-8.93-8.15zm0 1.5c4.288 0 7.43 3.016 7.43 6.65 0 4.884-2.84 8.86-6.38 8.86-1.025.029-1.974-.455-2.54-1.283-.342-.499-1.085-.404-1.31.126l-.042.132-.741 2.766-.15.41c-.265.682-.592 1.348-.975 1.987l-.181.287-.02-.4c-.034-.947.03-1.884.188-2.808l1.65-6.954.02-.149c.034-1.026.197-2.006.525-2.721.385-.838.898-1.158 1.744-.97.535.118.688.276.712.567.026.317-.09.768-.363 1.444l-.162.387-.435 1.01c-.172.415-.247.642-.32.944-.394 1.722.383 3.065 2.08 3.065 2.645 0 4.41-3.077 4.41-6.88 0-2.992-2.057-5.064-5.298-5.166l-.252-.004c-1.672-.068-3.327.565-4.548 1.752C6.35 5.79 5.67 7.426 5.69 9.13l.006.27c.014.27.052.544.115.815l-.786 1.902-.086-.11c-.653-.876-.98-1.967-.9-3.082C4.04 4.979 7.432 1.55 12.53 1.55z'
									transform='translate(-184 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(40)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Pinterest;
