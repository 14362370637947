'use client';
import { LicenseInfo } from '@mui/x-license';

/* since this repo is private, and the value is not specific to an environment, we can commit this to source
This is license key is good for two developers */
LicenseInfo.setLicenseKey(
	'cc8ca6ac4e3fbc1b959d617d1b76ce5bTz05OTQxOSxFPTE3NTk2OTczOTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);

export default function MuiXLicense() {
	return null;
}
