import * as Types from '../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewEventsSubscription_GetAuthedUserQueryVariables = Types.Exact<{
  where: Types.UserWhereUniqueInput;
}>;


export type NewEventsSubscription_GetAuthedUserQuery = { __typename?: 'Query', getAuthedUser?: { __typename?: 'AuthedUser', id: string, userType: string, eventsLastViewedAt: Date | string } | null };

export type NewEventsSubscription_UpdateSelfMutationVariables = Types.Exact<{
  data: Types.UserUpdateSelfInput;
}>;


export type NewEventsSubscription_UpdateSelfMutation = { __typename?: 'Mutation', updateSelf: { __typename?: 'User', id: string, eventsLastViewedAt: Date | string } };

export type NewEventsSubscription_GetEventsWhereQueryVariables = Types.Exact<{
  where: Types.EventWhereInput;
}>;


export type NewEventsSubscription_GetEventsWhereQuery = { __typename?: 'Query', getEventsWhere?: Array<{ __typename?: 'Event', id: string, category: Types.EventCategory, createdAt: Date | string, consumingUsers?: Array<{ __typename?: 'User', id: string }> | null, dismissingUsers?: Array<{ __typename?: 'User', id: string }> | null }> | null };

export type NewEventsSubscription_EventCreatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type NewEventsSubscription_EventCreatedSubscription = { __typename?: 'Subscription', eventCreated: { __typename?: 'Event', id: string } };


export const NewEventsSubscription_GetAuthedUserDocument = gql`
    query newEventsSubscription_GetAuthedUser($where: UserWhereUniqueInput!) {
  getAuthedUser(where: $where) {
    id
    userType
    eventsLastViewedAt
  }
}
    `;

/**
 * __useNewEventsSubscription_GetAuthedUserQuery__
 *
 * To run a query within a React component, call `useNewEventsSubscription_GetAuthedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewEventsSubscription_GetAuthedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEventsSubscription_GetAuthedUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNewEventsSubscription_GetAuthedUserQuery(baseOptions: Apollo.QueryHookOptions<NewEventsSubscription_GetAuthedUserQuery, NewEventsSubscription_GetAuthedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewEventsSubscription_GetAuthedUserQuery, NewEventsSubscription_GetAuthedUserQueryVariables>(NewEventsSubscription_GetAuthedUserDocument, options);
      }
export function useNewEventsSubscription_GetAuthedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewEventsSubscription_GetAuthedUserQuery, NewEventsSubscription_GetAuthedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewEventsSubscription_GetAuthedUserQuery, NewEventsSubscription_GetAuthedUserQueryVariables>(NewEventsSubscription_GetAuthedUserDocument, options);
        }
export type NewEventsSubscription_GetAuthedUserQueryHookResult = ReturnType<typeof useNewEventsSubscription_GetAuthedUserQuery>;
export type NewEventsSubscription_GetAuthedUserLazyQueryHookResult = ReturnType<typeof useNewEventsSubscription_GetAuthedUserLazyQuery>;
export type NewEventsSubscription_GetAuthedUserQueryResult = Apollo.QueryResult<NewEventsSubscription_GetAuthedUserQuery, NewEventsSubscription_GetAuthedUserQueryVariables>;
export const NewEventsSubscription_UpdateSelfDocument = gql`
    mutation newEventsSubscription_UpdateSelf($data: UserUpdateSelfInput!) {
  updateSelf(data: $data) {
    id
    eventsLastViewedAt
  }
}
    `;
export type NewEventsSubscription_UpdateSelfMutationFn = Apollo.MutationFunction<NewEventsSubscription_UpdateSelfMutation, NewEventsSubscription_UpdateSelfMutationVariables>;

/**
 * __useNewEventsSubscription_UpdateSelfMutation__
 *
 * To run a mutation, you first call `useNewEventsSubscription_UpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewEventsSubscription_UpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newEventsSubscriptionUpdateSelfMutation, { data, loading, error }] = useNewEventsSubscription_UpdateSelfMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewEventsSubscription_UpdateSelfMutation(baseOptions?: Apollo.MutationHookOptions<NewEventsSubscription_UpdateSelfMutation, NewEventsSubscription_UpdateSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewEventsSubscription_UpdateSelfMutation, NewEventsSubscription_UpdateSelfMutationVariables>(NewEventsSubscription_UpdateSelfDocument, options);
      }
export type NewEventsSubscription_UpdateSelfMutationHookResult = ReturnType<typeof useNewEventsSubscription_UpdateSelfMutation>;
export type NewEventsSubscription_UpdateSelfMutationResult = Apollo.MutationResult<NewEventsSubscription_UpdateSelfMutation>;
export type NewEventsSubscription_UpdateSelfMutationOptions = Apollo.BaseMutationOptions<NewEventsSubscription_UpdateSelfMutation, NewEventsSubscription_UpdateSelfMutationVariables>;
export const NewEventsSubscription_GetEventsWhereDocument = gql`
    query newEventsSubscription_GetEventsWhere($where: EventWhereInput!) {
  getEventsWhere(where: $where) {
    id
    category
    createdAt
    consumingUsers {
      id
    }
    dismissingUsers {
      id
    }
  }
}
    `;

/**
 * __useNewEventsSubscription_GetEventsWhereQuery__
 *
 * To run a query within a React component, call `useNewEventsSubscription_GetEventsWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewEventsSubscription_GetEventsWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEventsSubscription_GetEventsWhereQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNewEventsSubscription_GetEventsWhereQuery(baseOptions: Apollo.QueryHookOptions<NewEventsSubscription_GetEventsWhereQuery, NewEventsSubscription_GetEventsWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewEventsSubscription_GetEventsWhereQuery, NewEventsSubscription_GetEventsWhereQueryVariables>(NewEventsSubscription_GetEventsWhereDocument, options);
      }
export function useNewEventsSubscription_GetEventsWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewEventsSubscription_GetEventsWhereQuery, NewEventsSubscription_GetEventsWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewEventsSubscription_GetEventsWhereQuery, NewEventsSubscription_GetEventsWhereQueryVariables>(NewEventsSubscription_GetEventsWhereDocument, options);
        }
export type NewEventsSubscription_GetEventsWhereQueryHookResult = ReturnType<typeof useNewEventsSubscription_GetEventsWhereQuery>;
export type NewEventsSubscription_GetEventsWhereLazyQueryHookResult = ReturnType<typeof useNewEventsSubscription_GetEventsWhereLazyQuery>;
export type NewEventsSubscription_GetEventsWhereQueryResult = Apollo.QueryResult<NewEventsSubscription_GetEventsWhereQuery, NewEventsSubscription_GetEventsWhereQueryVariables>;
export const NewEventsSubscription_EventCreatedDocument = gql`
    subscription newEventsSubscription_EventCreated {
  eventCreated {
    id
  }
}
    `;

/**
 * __useNewEventsSubscription_EventCreatedSubscription__
 *
 * To run a query within a React component, call `useNewEventsSubscription_EventCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewEventsSubscription_EventCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEventsSubscription_EventCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewEventsSubscription_EventCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewEventsSubscription_EventCreatedSubscription, NewEventsSubscription_EventCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewEventsSubscription_EventCreatedSubscription, NewEventsSubscription_EventCreatedSubscriptionVariables>(NewEventsSubscription_EventCreatedDocument, options);
      }
export type NewEventsSubscription_EventCreatedSubscriptionHookResult = ReturnType<typeof useNewEventsSubscription_EventCreatedSubscription>;
export type NewEventsSubscription_EventCreatedSubscriptionResult = Apollo.SubscriptionResult<NewEventsSubscription_EventCreatedSubscription>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    