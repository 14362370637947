import {
	FormControl,
	FormControlProps,
	FormHelperText,
	TextField,
	TextFieldProps,
} from '@mui/material';
import React from 'react';
import useErrorScrollContext from '../../../hooks/useErrorScrollContext';

export type MuiTextFieldTextProps = {
	errorText?: string;
	'data-cy'?: string;
} & Omit<
TextFieldProps,
'className' | 'fullWidth' | 'type' | 'error' | 'variant'
> &
Pick<FormControlProps, 'className' | 'fullWidth' | 'variant'>;

const MuiTextFieldText: React.FC<MuiTextFieldTextProps> = ( {
	errorText,
	className,
	variant,
	...propsToSpread
} ) => {
	const ref = useErrorScrollContext( !!errorText );
	return (
		<FormControl
			variant={ variant }
			error={ !!errorText }
			fullWidth={ propsToSpread.fullWidth }
			className={ className }
		>
			<TextField
				{ ...propsToSpread } // also including fulWidth from FormControlProps here
				error={ !!errorText }
				ref={ ref }
				variant={ variant }
				inputProps={ propsToSpread.inputProps }
			/>
			{ errorText ? <FormHelperText>{ errorText }</FormHelperText> : null }
		</FormControl>
	);
};

export default MuiTextFieldText;
