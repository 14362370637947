import {
	Badge,
	Box,
	IconButton,
	Paper,
	Popover,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../../elements/icons';
import { useNewEventsSubscription } from '../../../../hooks/useNewEventsSubscription';
import { FlattenedUser } from '../../../../types/user';
import { Events } from '../../../Events';
import styles from './EventNotifications.module.scss';

export const EventNotifications: React.FC = () => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const [ open, setOpen ] = useState( false );
	const ref = useRef<HTMLDivElement>( null );

	const { badgeContent, reset } = useNewEventsSubscription();
	useEffect( () => {
		if ( open ) {
			reset();
		}
	}, [ open, reset ] );

	if ( !user?.isLoggedIn ) {
		return null;
	}

	return (
		<Box ref={ ref } className={ styles.container }>
			<IconButton onClick={ () => setOpen( true ) }>
				<Badge
					badgeContent={ badgeContent }
					color='primary'
					className={ styles.badge }
					anchorOrigin={ { vertical: 'top', horizontal: 'left' } }
				>
					<Icon type='bell-off' className={ styles.icon } />
				</Badge>
			</IconButton>
			<Popover
				open={ open }
				onClose={ () => setOpen( false ) }
				anchorEl={ ref.current }
				anchorOrigin={ {
					vertical: 'bottom',
					horizontal: 'right',
				} }
				transformOrigin={ {
					vertical: 'top',
					horizontal: 'right',
				} }
				keepMounted
			>
				<Paper className={ styles.paper }>
					<Typography variant='h6'>Activity Feed</Typography>
					<Events hideActionButtons hideDismissButtons open={ open } />
				</Paper>
			</Popover>
		</Box>
	);
};
