/* eslint max-len: 0 */
const Mail = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M22.5 4c.414 0 .75.336.75.75v15c0 .414-.336.75-.75.75h-21c-.414 0-.75-.336-.75-.75v-15c0-.414.336-.75.75-.75zm-.75 2.562l-7.276 5.596c-1.4 1.078-3.328 1.12-4.77.13l-.178-.13L2.25 6.562V19h19.5V6.562zM20.669 5.5H3.33l7.11 5.47c.869.668 2.059.705 2.963.11l.157-.11 7.109-5.47z'
			transform='translate(-424 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(280)'
		/>
	</svg>
);

export default Mail;
