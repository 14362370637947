/* eslint max-len: 0 */
const Send = ( props ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M20.008 2.449c.219-.072.448-.079.663-.027l.074.022c.226-.104.502-.062.688.123.185.186.227.462.123.688l.02.068c.053.215.047.445-.024.666l-5.336 16.376c-.253.76-.974 1.266-1.775 1.246-.801-.02-1.496-.56-1.717-1.353l-1.744-7.244-7.262-1.742c-.72-.202-1.237-.82-1.318-1.552l-.011-.159c-.02-.8.485-1.52 1.249-1.775zm-.04 2.448l-7.535 7.536c-.084.084-.187.138-.295.163l1.77 7.354c.07.254.3.432.563.438.264.007.501-.16.583-.406l4.913-15.085zm-.862-.869L4.022 8.948c-.25.083-.417.32-.41.583.006.264.184.492.414.558l7.379 1.769c.017-.073.048-.142.092-.206l.07-.085 7.54-7.539z'
			transform='translate(-344 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(200)'
		/>
	</svg>
);

export default Send;
