import React from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import styles from './StateSelect.module.scss';

type Option = {
	label: string;
	value: string;
};

const options = [
	{ label: 'Alabama', value: 'AL' },
	{ label: 'Alaska', value: 'AK' },
	{ label: 'Arizona', value: 'AZ' },
	{ label: 'Arkansas', value: 'AR' },
	{ label: 'California', value: 'CA' },
	{ label: 'Colorado', value: 'CO' },
	{ label: 'Connecticut', value: 'CT' },
	{ label: 'Delaware', value: 'DE' },
	{ label: 'District of Columbia', value: 'DC' },
	{ label: 'Florida', value: 'FL' },
	{ label: 'Georgia', value: 'GA' },
	{ label: 'Hawaii', value: 'HI' },
	{ label: 'Idaho', value: 'ID' },
	{ label: 'Illinois', value: 'IL' },
	{ label: 'Indiana', value: 'IN' },
	{ label: 'Iowa', value: 'IA' },
	{ label: 'Kansas', value: 'KS' },
	{ label: 'Kentucky', value: 'KY' },
	{ label: 'Louisiana', value: 'LA' },
	{ label: 'Maine', value: 'ME' },
	{ label: 'Maryland', value: 'MD' },
	{ label: 'Massachusetts', value: 'MA' },
	{ label: 'Michigan', value: 'MI' },
	{ label: 'Minnesota', value: 'MN' },
	{ label: 'Mississippi', value: 'MS' },
	{ label: 'Missouri', value: 'MO' },
	{ label: 'Montana', value: 'MT' },
	{ label: 'Nebraska', value: 'NE' },
	{ label: 'Nevada', value: 'NV' },
	{ label: 'New Hampshire', value: 'NH' },
	{ label: 'New Jersey', value: 'NJ' },
	{ label: 'New Mexico', value: 'NM' },
	{ label: 'New York', value: 'NY' },
	{ label: 'North Carolina', value: 'NC' },
	{ label: 'North Dakota', value: 'ND' },
	{ label: 'Ohio', value: 'OH' },
	{ label: 'Oklahoma', value: 'OK' },
	{ label: 'Oregon', value: 'OR' },
	{ label: 'Pennsylvania', value: 'PA' },
	{ label: 'Puerto Rico', value: 'PR' },
	{ label: 'Rhode Island', value: 'RI' },
	{ label: 'South Carolina', value: 'SC' },
	{ label: 'South Dakota', value: 'SD' },
	{ label: 'Tennessee', value: 'TN' },
	{ label: 'Texas', value: 'TX' },
	{ label: 'Utah', value: 'UT' },
	{ label: 'Vermont', value: 'VT' },
	{ label: 'Virginia', value: 'VA' },
	{ label: 'Washington', value: 'WA' },
	{ label: 'West Virginia', value: 'WV' },
	{ label: 'Wisconsin', value: 'WI' },
	{ label: 'Wyoming', value: 'WY' },
];

type StateSelectProps = {
	id?: string;
	className?: string;
	label: string;
	onChange: (
		event: React.SyntheticEvent<Element, Event>,
		value: string | undefined
	) => void;
	value?: string;
	error: boolean;
	helperText?: string;
	onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	inputProps?: TextFieldProps['inputProps'];
};

const StateSelect: React.FC<StateSelectProps> = ( {
	id,
	className,
	label,
	onChange,
	value,
	error,
	helperText,
	onBlur,
	inputProps,
} ) => {
	const selectedValue = options.find( ( option ) => option.value === value );
	return (
		// using freeSolo + forcePopupIcon to allow for passing in '' as the value when none is provided, to avoid warning
		<Autocomplete<Option, undefined, true, true>
			id={ id }
			className={ className }
			onChange={ ( e, o ) => onChange?.( e, typeof o === 'string' ? o : o.value ) }
			options={ options }
			value={ selectedValue || '' }
			forcePopupIcon={ true }
			disableClearable={ true }
			renderInput={ ( params ) => (
				<TextField
					{ ...params }
					className={ styles.input }
					fullWidth={ true }
					variant='outlined'
					label={ label }
					error={ error }
					helperText={ helperText }
					onBlur={ onBlur }
					inputProps={ {
						...params?.inputProps,
						...inputProps,
					} }
				/>
			) }
		/>
	);
};

export default StateSelect;
