import React from 'react';
import { Avatar, IconButton } from '@mui/material';

import getInitials from '../../lib/helpers/getInitials';
import useMenu from '../../hooks/useMenu';
import CustomMenuItemList from '../CustomMenuItemList/CustomMenuItemList';
import { CustomMenuItemType } from '../CustomMenuItem/CustomMenuItemComponent';
import CustomMenu from '../CustomMenu/CustomMenu';

export default function AvatarComponent( {
	user,
	menu,
	items,
}: {
	user: { [index: string]: any } | undefined;
	menu: ReturnType<typeof useMenu>;
	items: CustomMenuItemType[];
} ) {
	return (
		<>
			<IconButton
				onClick={ menu.handleClick }
				data-cy={ 'profileBtn' }
				size='small'
				aria-controls={ menu.open ? 'account-menu' : undefined }
				aria-haspopup='true'
				aria-expanded={ menu.open ? 'true' : undefined }
			>
				<Avatar src={ user?.image }>{ getInitials( user as any ) }</Avatar>
			</IconButton>

			<CustomMenu
				data-cy={ 'profileMenu' }
				anchorEl={ menu.anchorEl }
				open={ menu.open }
				onClose={ menu.handleClose }
				onClick={ menu.handleClose }
				PaperProps={ {
					elevation: 0,
				} }
			>
				<CustomMenuItemList items={ items } />
			</CustomMenu>
		</>
	);
}
