import { BubbleMenuOptions } from '@tiptap/extension-bubble-menu';
import { BubbleMenu, Editor } from '@tiptap/react';
import React from 'react';
import MuiTipTapMenuElements from '../MuiTipTapMenuElements';
import styles from './MuiTipTapBubbleMenu.module.scss';

type MuiTipTapBubbleMenuProps = {
	editor: Editor | null;
	shouldShow?: BubbleMenuOptions['shouldShow'];
	onBlur: () => void;
	onFocus: () => void;
};

const MuiTipTapBubbleMenu: React.FC<MuiTipTapBubbleMenuProps> = ( {
	editor,
	shouldShow,
	onBlur,
	onFocus,
} ) =>
	editor ? (
		<BubbleMenu
			editor={ editor }
			tippyOptions={ { duration: 100 } }
			className={ styles.container }
			shouldShow={ shouldShow }
		>
			<MuiTipTapMenuElements
				editor={ editor }
				onBlur={ onBlur }
				onFocus={ onFocus }
			/>
		</BubbleMenu>
	) : null;

export default MuiTipTapBubbleMenu;
