/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Pin = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12 .05c5.385 0 9.75 4.365 9.75 9.75 0 3.715-1.427 6.966-3.864 9.744-1.626 1.853-3.462 3.248-5.144 4.208-.46.262-1.025.262-1.486 0-1.678-.958-3.516-2.355-5.142-4.208C3.677 16.766 2.25 13.514 2.25 9.8 2.25 4.415 6.615.05 12 .05zm0 1.5c-4.556 0-8.25 3.694-8.25 8.25 0 3.319 1.279 6.233 3.491 8.755 1.503 1.712 3.214 3.012 4.758 3.893l.36-.21c1.45-.875 3.012-2.103 4.4-3.684C18.97 16.033 20.25 13.12 20.25 9.8c0-4.556-3.694-8.25-8.25-8.25zm0 3.648c2.485 0 4.5 2.015 4.5 4.5s-2.015 4.5-4.5 4.5-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5zm0 1.5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z'
			transform='translate(-144 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145)'
		/>
	</svg>
);

export default Pin;
