/* eslint max-len: 0 */
const Faq = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M12 20.25l.126.006c.706.063 1.259.652 1.259 1.369 0 .76-.62 1.375-1.385 1.375s-1.385-.616-1.385-1.375c0-.717.553-1.306 1.259-1.37L12 20.25zm5.846-14.434c.685 3.077-.97 6.197-3.847 7.25-.706.258-1.192.922-1.25 1.686l-.005.154v1.824c0 .425-.334.77-.744.77-.38 0-.693-.294-.739-.674l-.005-.096v-1.824c-.001-1.415.823-2.684 2.074-3.223l.173-.07c2.163-.791 3.408-3.137 2.893-5.451-.515-2.314-2.626-3.86-4.906-3.591-2.28.268-4.001 2.264-4.002 4.639 0 .425-.333.77-.744.77S6 7.635 6 7.21c0-3.159 2.29-5.813 5.322-6.17 3.032-.356 5.839 1.699 6.524 4.776z'
			transform='translate(-504 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(360)'
		/>
	</svg>
);

export default Faq;
