import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CloseIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M23.5884 2.39803C24.137 1.84945 24.137 0.960019 23.5884 0.411437C23.0398 -0.137146 22.1504 -0.137146 21.6018 0.411437L11.9998 10.0134L2.39803 0.411649C1.84945 -0.136933 0.960019 -0.136933 0.411437 0.411649C-0.137146 0.960232 -0.137146 1.84966 0.411437 2.39824L10.0132 12L0.411473 21.6018C-0.137109 22.1503 -0.13711 23.0398 0.411473 23.5884C0.960055 24.1369 1.84948 24.1369 2.39807 23.5884L11.9998 13.9866L21.6018 23.5886C22.1503 24.1371 23.0398 24.1371 23.5884 23.5886C24.1369 23.04 24.1369 22.1506 23.5884 21.602L13.9864 12L23.5884 2.39803Z'
			/>
		</SvgIcon>
	);
}
