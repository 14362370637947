/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Email = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M14.758.322c5.317 1.256 9.102 5.939 9.238 11.375L24 12v2.25c0 2.071-1.679 3.75-3.75 3.75-1.579 0-2.93-.976-3.483-2.357C15.672 17.076 13.944 18 12 18c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6v2.25c0 1.243 1.007 2.25 2.25 2.25 1.19 0 2.166-.925 2.245-2.096l.005-.154V12c0-4.87-3.348-9.1-8.087-10.218C9.674.664 4.788 2.95 2.61 7.305.433 11.66 1.535 16.941 5.274 20.061c3.738 3.12 9.13 3.26 13.026.339.331-.249.802-.181 1.05.15.249.331.181.802-.15 1.05-4.452 3.339-10.615 3.178-14.888-.387C.04 17.647-1.22 11.612 1.27 6.634 3.757 1.657 9.342-.956 14.758.322zM12 7.5c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.015-4.5-4.5-4.5z'
			transform='translate(-384 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(240)'
		/>
	</svg>
);

export default Email;
