import { Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { MuiColorInput } from 'mui-color-input';
import dynamic from 'next/dynamic';
const FontPicker = dynamic( () => import( './FontPicker' ), {
	ssr: false,
} );
import rpcShared, { RPCShared } from '@rockpapercoin/rpc-shared';
import styles from './InquiriesStylingForm.module.scss';
import { CornerSelector } from './CornerSelector';
import { InquiryFormStyleType } from '../../../types/generated';

import MuiSpinner from '../../../mui/MuiSpinner';
import { getLoadInquiryFormJavaScript } from '../helpers';
import Script from 'next/script';
import { FontSizePicker } from './FontSizePicker';
import { useInquiriesConfiguration } from '../useInquiriesConfiguration';

const families = [
	'Barlow',
	'Baskervville SC',
	'Comic Neue',
	'Cormorant Garamond',
	'Dancing Script',
	'Darker Grotesque',
	'EB Garamond',
	'Gentium Plus',
	'Great Vibes',
	'Gruppo',
	'Italianno',
	'Libre Baskerville',
	'Lora',
	'Maven Pro',
	'Merriweather',
	'Open Sans',
	'Parisienne',
	'Patua One',
	'Playfair Display',
	'Playwrite US Modern',
	'Poiret One',
	'Poppins',
	'PT Sans',
	'PT Serif',
	'Public Sans',
	'Quicksand',
	'Raleway',
	'Reenie Beanie',
	'Roboto',
	'Roboto Serif',
	'Sacramento',
	'Sanchez',
	'Shadows Into Light',
	'Tenor Sans',
	'The Nautigal',
];

type Styles = RPCShared.Inquiries.Styles;
type Colors = RPCShared.Inquiries.Colors;

const getLabelById = ( type: keyof Styles ) => {
	if ( type === InquiryFormStyleType.FontFamily ) {
		return 'Font';
	} else if ( type === InquiryFormStyleType.Instructions ) {
		return 'Instructions';
	} else if ( type === InquiryFormStyleType.FieldLabelAndBorder ) {
		return 'Field label and border';
	} else if ( type === InquiryFormStyleType.BackgroundColor ) {
		return 'Background color';
	} else if ( type === InquiryFormStyleType.FieldInputText ) {
		return 'Field Input text';
	} else if ( type === InquiryFormStyleType.ButtonFill ) {
		return 'Button fill';
	} else if ( type === InquiryFormStyleType.ButtonText ) {
		return 'Button text';
	} else if ( type === InquiryFormStyleType.Corners ) {
		return 'Corners';
	}
};

export type InquiriesStylingFormProps = {
	googleFontsApiKey: string;
	loading: boolean;
} & Pick<
ReturnType<typeof useInquiriesConfiguration>,
'instructions' | 'fields' | 'styles' | 'setStyles'
>;

export const InquiriesStylingForm: React.FC<InquiriesStylingFormProps> = ( {
	googleFontsApiKey,
	instructions,
	fields,
	styles: propsStyles,
	setStyles,
} ) => {
	const [ fontFamily, setFontFamily ] = useState( 'Open Sans' );
	const [ fontSize, setFontSize ] = useState( '1.125rem' );
	const [ colors, setColors ] = useState<Colors | undefined>();
	const [ corners, setCorners ] = useState<Styles['corners']>( 'rounded' );

	useEffect( () => {
		const styles =
			rpcShared.inquiries.stylesSupplementedWithDefaultsAsDictionary(
				propsStyles
			);
		setFontFamily( styles[ InquiryFormStyleType.FontFamily ] );
		setFontSize( styles[ InquiryFormStyleType.FontSize ] );
		setColors( rpcShared.inquiries.getColorsFromStylesDictionary( styles ) );
		setCorners( styles[ InquiryFormStyleType.Corners ] );
	}, [ propsStyles ] );

	// but only set colors on blur
	const handleBlur = useCallback(
		() =>
			setStyles( [
				{ type: InquiryFormStyleType.FontFamily, value: fontFamily },
				{ type: InquiryFormStyleType.FontSize, value: fontSize },
				{ type: InquiryFormStyleType.Corners, value: corners },
				...( colors
					? Object.entries( colors ).reduce(
						( acc, [ type, value ] ) => [
							...acc,
							{ type, value } as {
								type: InquiryFormStyleType;
								value: string;
							},
						],
						[] as { type: InquiryFormStyleType; value: string }[]
					  )
					: [] ),
			] ),
		[
			setStyles,
			colors,
			fontFamily,
			fontSize,
			corners
		]
	);

	if ( !colors || !fields ) {
		return (
			<Box className={ styles.container }>
				<Box className={ styles.loading }>
					<MuiSpinner />
				</Box>
			</Box>
		);
	}

	const stringifiedConfiguration = JSON.stringify(
		rpcShared.inquiries.inquiryFormToConfiguration( {
			instructions,
			fields,
			styles: propsStyles,
		} )
	);

	return (
		<Box className={ styles.container }>
			<Box className={ styles.column } data-cy='style'>
				<Typography variant='h6'>Style</Typography>
				<FontPicker
					apiKey={ googleFontsApiKey }
					value={ fontFamily }
					onChange={ ( value ) => setFontFamily( value.family ) }
					onBlur={ handleBlur }
					families={ families }
					sort='alphabet'
					data-cy='fontFamily'
				/>
				<FontSizePicker
					value={ fontSize }
					onChange={ ( e ) => setFontSize( e.target.value ) }
					onBlur={ handleBlur }
				/>
				{ Object.entries( colors ).map( ( [ key, value ] ) => (
					<MuiColorInput
						key={ key }
						label={ getLabelById( key as keyof typeof colors ) }
						format='hex'
						value={ value }
						slotProps={ { htmlInput: { 'data-cy': key } } }
						onBlur={ handleBlur }
						onChange={ ( value ) =>
							setColors( ( prevState ) =>
								prevState ? { ...prevState, [ key ]: value } : prevState
							)
						}
						isAlphaHidden
					/>
				) ) }
				<CornerSelector
					value={ corners }
					onChange={ ( e ) => setCorners( e.target.value as typeof corners ) }
					onBlur={ handleBlur }
					data-cy='corners'
				/>
			</Box>
			<Divider orientation='vertical' />
			<Box className={ styles.column } data-cy='preview'>
				<Typography variant='h6'>Preview</Typography>
				<Box id='inquiry-form' data-configuration={ stringifiedConfiguration } />
				<Script
					id='formMarkup'
					dangerouslySetInnerHTML={ { __html: getLoadInquiryFormJavaScript() } }
				></Script>
			</Box>
		</Box>
	);
};
