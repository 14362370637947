import { ApiResponseType } from '../../../../hooks/useMutation.js';
import Query from '../../../Query.js';
import { VoidProposalReturnFields } from '../../returnFields/proposals';
import { VoidProposalResponse } from '../types.js';

const voidProposal = async function(
	this: any,
	id: string
): Promise<ApiResponseType<VoidProposalResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'voidProposal',
			params: {
				where: { id },
			},
			returnFields: VoidProposalReturnFields,
		} )
	);

	if ( errors ) return { errors };

	return data.data.voidProposal;
};

export default voidProposal;
