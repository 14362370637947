import { MenuConfigType } from '../../../mui/SideNav/SideNavContainer';

export default function addClickHandler(
	config: MenuConfigType,
	toggleMobileMenu: ( flag: boolean ) => void
) {
	return config.map( ( item ) => {
		return {
			...item,
			onClick: () => {
				if ( 'onClick' in item ) {
					item.onClick?.();
				}
				toggleMobileMenu( false );
			},
		};
	} );
}
