/*
 * action types
 */

export const LOGIN = 'LOGIN';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT = 'LOGOUT';
export const BEGIN_USER_REGISTRATION = 'BEGIN_USER_REGISTRATION';
export const END_USER_REGISTRATION = 'END_USER_REGISTRATION';
export const DISPLAY_CUSTOM = 'DISPLAY_CUSTOM';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const TOGGLE_LOADING_OVERLAY = 'TOGGLE_LOADING_OVERLAY';
export const DISPLAY_STRIPE_NEEDS_MODAL = 'DISPLAY_STRIPE_NEEDS_MODAL';
export const CONFIRM = 'CONFIRM';
export const ALERT = 'ALERT';

/*
 * action creators
 */

export function login( user, token, ctx ) {
	return {
		type: LOGIN,
		user,
		token,
		ctx,
	};
}

export function updateUser( user, ctx ) {
	return {
		type: UPDATE_USER,
		user,
		ctx,
	};
}

export function logout( redirect = true, ctx ) {
	return {
		type: LOGOUT,
		redirect,
		ctx,
	};
}

export function beginUserRegistration( data ) {
	return {
		type: BEGIN_USER_REGISTRATION,
		data,
	};
}
export function endUserRegistration() {
	return {
		type: END_USER_REGISTRATION,
	};
}

export function displayCustom( custom ) {
	return {
		type: DISPLAY_CUSTOM,
		custom,
	};
}

/**
 * Controls the confirmation dialog/modal
 * @param {boolean} loading
 * @returns {{ type: 'TOGGLE_LOADER', loading: boolean }}
 */
export function toggleLoader( loading ) {
	return {
		type: TOGGLE_LOADER,
		loading,
	};
}

export function toggleLoadingOverlay( overlay ) {
	return {
		type: TOGGLE_LOADING_OVERLAY,
		overlay,
	};
}

export function displayStripeNeedsModal( { active, props } ) {
	return {
		type: DISPLAY_STRIPE_NEEDS_MODAL,
		props,
		active,
	};
}

// eslint-disable-next-line max-len
/** @typedef {{ title?: string, message?: string, options: { text: string, className?: string, callback: () => void, loading?: boolean, dataCy?: string }[], defaultCallback?: () => void }} DISPLAY_CONFIRM_PROPS_PROP */
/** @typedef {{ active: true, props: DISPLAY_CONFIRM_PROPS_PROP } | { active: false }} DISPLAY_CONFIRM_PROPS */
/**
 * Controls the confirmation dialog/modal
 * @param {DISPLAY_CONFIRM_PROPS} args
 * @returns {{ type: 'CONFIRM', props: DISPLAY_CONFIRM_PROPS_PROP }}
 */
export function displayConfirm( { active, props } ) {
	return {
		type: CONFIRM,
		props,
		active,
	};
}

/** @typedef {{ id: string, message: string | JSX.Element, routes: Array< string > }} ALERT */
/**
 * Controls the alert dialog/modal
 * @param {ALERT} alert
 * @returns {{ type: 'ALERT', alert: ALERT }}
 */
export function createAlert( alert ) {
	return {
		type: ALERT,
		alert,
	};
}
