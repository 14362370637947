import React from 'react';
import { CustomMenuItemType } from '../CustomMenuItem/CustomMenuItemComponent';
import CustomMenuItemContainer from '../CustomMenuItem/CustomMenuItemContainer';

export default function CustomMenuItemList( {
	items,
}: {
	items: CustomMenuItemType[];
} ) {
	return (
		<>
			{ items.map( ( menuItem ) => (
				<CustomMenuItemContainer key={ menuItem.id } menuItem={ menuItem } />
			) ) }
		</>
	);
}
