import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InquiriesPublish_GetInquiryFormApiKeyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InquiriesPublish_GetInquiryFormApiKeyQuery = { __typename?: 'Query', getInquiryFormApiKey: string };


export const InquiriesPublish_GetInquiryFormApiKeyDocument = gql`
    query InquiriesPublish_GetInquiryFormApiKey {
  getInquiryFormApiKey
}
    `;

/**
 * __useInquiriesPublish_GetInquiryFormApiKeyQuery__
 *
 * To run a query within a React component, call `useInquiriesPublish_GetInquiryFormApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiriesPublish_GetInquiryFormApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiriesPublish_GetInquiryFormApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useInquiriesPublish_GetInquiryFormApiKeyQuery(baseOptions?: Apollo.QueryHookOptions<InquiriesPublish_GetInquiryFormApiKeyQuery, InquiriesPublish_GetInquiryFormApiKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InquiriesPublish_GetInquiryFormApiKeyQuery, InquiriesPublish_GetInquiryFormApiKeyQueryVariables>(InquiriesPublish_GetInquiryFormApiKeyDocument, options);
      }
export function useInquiriesPublish_GetInquiryFormApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InquiriesPublish_GetInquiryFormApiKeyQuery, InquiriesPublish_GetInquiryFormApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InquiriesPublish_GetInquiryFormApiKeyQuery, InquiriesPublish_GetInquiryFormApiKeyQueryVariables>(InquiriesPublish_GetInquiryFormApiKeyDocument, options);
        }
export type InquiriesPublish_GetInquiryFormApiKeyQueryHookResult = ReturnType<typeof useInquiriesPublish_GetInquiryFormApiKeyQuery>;
export type InquiriesPublish_GetInquiryFormApiKeyLazyQueryHookResult = ReturnType<typeof useInquiriesPublish_GetInquiryFormApiKeyLazyQuery>;
export type InquiriesPublish_GetInquiryFormApiKeyQueryResult = Apollo.QueryResult<InquiriesPublish_GetInquiryFormApiKeyQuery, InquiriesPublish_GetInquiryFormApiKeyQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    