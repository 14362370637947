import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Icon from '../../elements/icons';

const AlertItem = styled.div`
	&.alert {
		width: calc(100% - 2rem);
		max-width: 68.75rem;
		position: relative;
		top: 1rem;
		left: 50%;
		transform: translate(-50%, -25%);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		padding: 1.25rem 1.5rem;
		border: 1px solid ${ ( { theme } ) => theme.darkBlue };
		border-radius: 5px;
		background-color: #fff;
		opacity: 0;

		&--slide-in {
			animation: fadeIn 250ms ease-in 250ms forwards;
			@keyframes fadeIn {
				from {
					transform: translate(-50%, -25%);
					opacity: 0;
				}
				to {
					transform: translate(-50%, 0%);
					opacity: 1;
				}
			}
		}

		&--persist {
			transform: translate(-50%, 0%);
			opacity: 1;
		}
	}

	.alert {
		&__message {
			width: calc(100% - 2rem);
		}

		&__dismiss {
			width: 2rem;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			a {
				width: 2rem;
				height: 2rem;

				svg {
					width: 2rem;
					height: 2rem;

					* {
						fill: ${ ( { theme } ) => theme.darkBlue };
					}
				}
			}
		}
	}
`;

const Alert = ( props ) => {
	const [ alerts, setAlerts ] = useState( [] );

	useEffect( () => {
		if ( props.alert ) {
			setAlerts( [ props.alert ] );
		}
	}, [ props.alert ] );

	useEffect( () => {
		alerts.forEach( ( alert ) => {
			if ( !alert.persist ) {
				setTimeout( () => {
					const alertEl = document.getElementById( alert.id );
					if ( alertEl ) {
						document.getElementById( alert.id ).classList.add( 'alert--slide-in' );
					}
				}, 500 );
			}
		} );
	}, [ alerts ] );

	const dismiss = ( id ) => {
		const index = alerts.findIndex( ( alert ) => alert.id === id );

		if ( index > -1 ) {
			const alertsArr = alerts.filter( ( alert ) => alert.id !== id );
			setAlerts( alertsArr );
		}
	};

	return (
		<>
			{ alerts.map( ( alert, index ) => {
				let className = 'alert';

				if ( alert.persist ) className += ' alert--persist';

				if (
					props.currentRoute &&
					alert.routes &&
					!alert.routes.includes( props.currentRoute )
				) {
					return null;
				}

				return (
					<AlertItem
						key={ index }
						id={ alert.id }
						className={ className }
						style={ { zIndex: alerts.length - index } }
					>
						<div className='alert__message'>{ alert.message }</div>
						<div className='alert__dismiss'>
							<a onClick={ () => dismiss( alert.id ) }>
								<Icon type='close-small' />
							</a>
						</div>
					</AlertItem>
				);
			} ) }
		</>
	);
};

const mapStateToProps = function( state ) {
	return {
		alert: state.alert.alert,
	};
};

export default connect( mapStateToProps )( Alert );
