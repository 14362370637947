import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

const CoverWrapper = styled.div`
	width: 100vw !important;
	position: fixed;
	top: 0% !important;
	left: 0% !important;
	opacity: 0;
	background-color: ${ ( { theme } ) => theme.black };
	z-index: ${ ( { zIndex } ) => ( zIndex ? zIndex : '10' ) };

	&.screen-cover {
		&__hidden {
			display: none;
		}
	}

	@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
		display: block;
		opacity: ${ ( props ) => ( props.coverOpacity ? props.coverOpacity : '0.7' ) };
	}
`;
const CoverElement = ( props ) => {
	const {
		coverOpacity,
		coverColor,
		onClick,
		zIndex = 10,
		...childProps
	} = props;
	return (
		<CoverWrapper
			coverOpacity={ coverOpacity }
			coverColor={ coverColor }
			onClick={ onClick }
			zIndex={ zIndex }
		>
			<Div100vh { ...childProps } />
		</CoverWrapper>
	);
};

export default CoverElement;
