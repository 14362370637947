// This file contains all API wrappers for folder queries and mutations:
// createFolder
// getFolderContents

import Query from '../../Query.js';
import {
	FolderContentsReturnFields,
	FolderReturnFields,
} from '../returnFields';

/**
 * creates a new folder record
 *
 * @params { object } a hash of arguments, including
 * @param { object } folder - folder data
 *
 * @returns { Promise< object || Error[] > } either the folder or an array of errors
 */
const createFolder = async function( { folder } ) {
	const folderData = {
		name: folder.name,
	};

	if ( folder.parentFolder ) {
		folderData.parentFolder = { connect: { id: folder.parentFolder.id } };
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createFolder',
			params: { data: folderData },
			returnFields: FolderReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		return data.data.createFolder;
	}
};

/**
 * move folder record
 *
 * @params { Array } foldersToBeMoved - Array of IDs of folders to be moved
 * @params { object } newParentFolder - New parent folder to put folders in
 * @returns { Promise< object || Error[] > } Array of moved folders
 */
const moveFolders = async function( foldersToBeMoved, newParentFolder ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'moveFolders',
			params: {
				where: {
					OR: foldersToBeMoved,
				},
				data: {
					parentFolder: { connect: { id: newParentFolder.id } },
				},
			},
			returnFields: FolderReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		const folder = data.data.moveFolders;
		return { folder };
	}
};

/**
 * Given a folder ID, returns the folder including its direct children
 *
 * @param { Object } - Series of parameters that informs how many child folders and
 * documents to retrieve
 *
 * params.where 								- includes the ID of the parent folder
 * params.orderby 							- informs how to sort child folders and documents
 * params.skipFolder 						- how many folders to skip before grabbing more child folder records
 * params.skipDocument					- how many documents to skip before grabbing more child document records
 * params.take									- comparison value to determine if more records are available
 * params.folderTakeOverride		- overrides take value to return a set number of child folders
 * params.documentsTakeOverride	- overrides take value to return a set number of child documents
 * params.batchSort							- Gets set number of folders and documents based on override values
 *
 * @returns { Promise< object || Error[] > } a folder record, including its direct children
 */
const getFolderContents = async function( params ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getFolderContents',
			params,
			returnFields: FolderContentsReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		return data.data.getFolderContents;
	}
};

/**
 * Given an array of folder IDs, returns boolean if successful
 *
 * @param { String } folderID - A folder ID\
 *
 * @returns { Promise< object || Error[] > } a folder record
 */
const deleteFolders = async function( folderIDs ) {
	const { errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'deleteFolders',
			params: { where: { OR: folderIDs } },
		} )
	);

	if ( errors ) {
		return errors;
	}

	return true;
};

/**
 * Rename a folder
 *
 * @param { String } folderID - A folder ID\
 * @param { String } newName - the name to change to
 *
 * @returns { Promise< object || Error[] > } a folder record
 */
const renameFolder = async function( folderID, newName ) {
	const { errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'renameFolder',
			params: {
				where: { id: folderID },
				data: {
					name: newName,
				},
			},
			returnFields: FolderReturnFields,
		} )
	);

	if ( errors ) {
		return errors;
	}

	return true;
};

export {
	createFolder,
	deleteFolders,
	getFolderContents,
	moveFolders,
	renameFolder,
};
