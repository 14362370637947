import {
	CONFIRM,
	DISPLAY_STRIPE_NEEDS_MODAL,
	TOGGLE_LOADING_OVERLAY,
} from '../actions';

const defaultLoadingOverlayState = {
	overlay: false,
};

export function loadingOverlay(
	state = defaultLoadingOverlayState,
	action: { type: typeof TOGGLE_LOADING_OVERLAY; overlay: boolean }
) {
	switch ( action.type ) {
		case TOGGLE_LOADING_OVERLAY:
			return {
				overlay: action.overlay,
			};
		default:
			return state;
	}
}

const defaultModalState = {
	props: null,
	active: false,
};

export function stripeNeedsModal(
	state = defaultModalState,
	action: {
		type: typeof DISPLAY_STRIPE_NEEDS_MODAL;
		props: Record<string, any>;
		active: boolean;
	}
) {
	switch ( action.type ) {
		case DISPLAY_STRIPE_NEEDS_MODAL:
			return {
				props: action.props,
				active: action.active,
			};
		default:
			return state;
	}
}

const defaultConfirmState = {
	props: null,
	active: false,
};

export function confirm(
	state = defaultConfirmState,
	action: { type: typeof CONFIRM; props: Record<string, any>; active: boolean }
) {
	switch ( action.type ) {
		case CONFIRM:
			return {
				props: action.props,
				active: action.active,
			};
		default:
			return state;
	}
}
