import styled from 'styled-components';

const A = styled.a`
	font-size: 1.125rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${ ( { theme } ) => theme.pink };
	text-decoration: none;

	&:visited {
		color: ${ ( { theme } ) => theme.pink };
		text-decoration: none;
	}

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	&:focus,
	&:active,
	&:visited,
	&:hover {
		outline: none;
	}

	&.a {
		&--dark-blue,
		&--dark-blue:visited {
			color: ${ ( { theme } ) => theme.darkBlue };
		}

		&--dark-blue:hover,
		&--dark-blue:focus {
			color: ${ ( { theme } ) => theme.pink };
		}

		&--primary {
			color: ${ ( { theme } ) => theme.green };
		}

		&--red {
			color: ${ ( { theme } ) => theme.error };
		}

		&--bold {
			font-weight: bold;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--pink {
			color: ${ ( { theme } ) => theme.pink };
		}

		&--underlined {
			text-decoration: underline;
		}
		&--disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	&.a {
		&--green,
		&--green:visited {
			color: ${ ( { theme } ) => theme.green };
		}
	}
`;

export default A;
