import React, { useMemo, useState } from 'react';
import { Fade } from '@mui/material';
import useGetNavigationItems, {
	MenuItemsIds,
} from '../../../config/useGetNavigationItems';
import { MenuConfigType } from '../../../mui/SideNav/SideNavContainer';
import CustomMenuItemList from '../../../mui/CustomMenuItemList/CustomMenuItemList';
import styles from './MobileMenu.module.scss';
import ProfileAccordionContainer from './ProfileAccordion/ProfileAccordionContainer';
import classNames from 'classnames';
import addClickHandler from './utils';
import { use100vh } from 'react-div-100vh';
import { PX_BODY_FONT_SIZE, REM_HEADER_HEIGHT } from './config';
import { useDeviceDetection } from '../../../hooks/useDeviceDetection';
import { MobileMenuHelpAccordion } from './MobileMenuHelpAccordion';
import { PremiumSubscriptionRequiredModal } from '../../PremiumSubscriptionRequiredModal';

const MobileMenuContainer = ( {
	active,
	featureFlags,
	toggleMobileMenu,
}: {
	active: boolean;
	featureFlags: any;
	toggleMobileMenu: ( flag: boolean ) => void;
} ) => {
	const {
		items: navigationItems,
		subscriptionNeededModalOpen: open,
		closeSubscriptionNeededModal: onClose,
	} = useGetNavigationItems( featureFlags );
	const openState = useState<boolean>( false );
	const [ isOpen, setIsOpen ] = openState;
	const height = use100vh();
	const menuHeightRem = height
		? height / PX_BODY_FONT_SIZE - REM_HEADER_HEIGHT
		: 0;

	const config: MenuConfigType = useMemo(
		() => [
			navigationItems[ MenuItemsIds.Dashboard ],
			navigationItems[ MenuItemsIds.Leads ],
			navigationItems[ MenuItemsIds.Contacts ],
			navigationItems[ MenuItemsIds.Proposals ],
			navigationItems[ MenuItemsIds.Contracts ],
			navigationItems[ MenuItemsIds.Invoices ],
			navigationItems[ MenuItemsIds.Vendors ],
			navigationItems[ MenuItemsIds.ClientUsers ],
			navigationItems[ MenuItemsIds.OrgUsers ],
			navigationItems[ MenuItemsIds.Organizations ],
			navigationItems[ MenuItemsIds.CustomerFolders ],
			navigationItems[ MenuItemsIds.CompanyFolders ],
			navigationItems[ MenuItemsIds.MyFolder ],
			navigationItems[ MenuItemsIds.ProposalTemplates ],
			navigationItems[ MenuItemsIds.ContractTemplates ],
			navigationItems[ MenuItemsIds.InvoiceTemplates ],
			navigationItems[ MenuItemsIds.Resources ],
			{ ...navigationItems[ MenuItemsIds.Timelines ], label: 'Timeline Genius' },
		],
		[ navigationItems ]
	);

	const configWithClickHandler = useMemo(
		() => addClickHandler( config, toggleMobileMenu ),
		[ config, toggleMobileMenu ]
	);

	const { isDesktop } = useDeviceDetection();
	if ( isDesktop ) return null;

	return (
		<>
			<Fade in={ active }>
				<div
					className={ classNames( styles.root, {
						[ styles.rootProfileIsOpen ]: isOpen,
					} ) }
					style={ { height: menuHeightRem + 'rem' } }
					onClick={ () => {
						if ( isOpen ) {
							setIsOpen( false );
						}
					} }
				>
					<div className={ styles.menuWrap }>
						<CustomMenuItemList items={ configWithClickHandler } />
						<MobileMenuHelpAccordion onClick={ () => toggleMobileMenu( false ) } />
					</div>
					<div className={ styles.profileWrap }>
						<ProfileAccordionContainer
							openState={ openState }
							toggleMobileMenu={ toggleMobileMenu }
						/>
					</div>
				</div>
			</Fade>
			<PremiumSubscriptionRequiredModal open={ open } onClose={ onClose } />
		</>
	);
};

export default MobileMenuContainer;
