const PlaidLogo = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='18'
		viewBox='0 0 48 18'
	>
		{ /* eslint-disable-next-line max-len */ }
		<path
			d='M7.1 0l1.91 1.96L10.95.032l5.519 1.541 1.437 5.598-1.94 1.929 1.909 1.96-1.527 5.574-5.542 1.451-1.91-1.96-1.941 1.928-5.518-1.541L0 10.914l1.941-1.928-1.91-1.962 1.527-5.573L7.1 0zm4.773 13.166l-1.862 1.85 1.277 1.31 2.918-.764-2.333-2.396zm-5.906-.048l-2.372 2.357 2.907.813 1.297-1.29-1.832-1.88zm8.883-2.91l-1.862 1.85 2.334 2.396.804-2.936-1.276-1.31zm-11.812-.097l-1.297 1.29.757 2.948 2.372-2.357-1.832-1.88zm5.906.049l-1.862 1.85 1.832 1.88 1.862-1.85-1.832-1.88zm14.231-4.47c.972 0 1.66.169 2.062.507.523.44.785 1.04.785 1.799 0 .731-.236 1.317-.704 1.757-.416.393-1.07.59-1.961.59h-.735v2.123h-1.64V5.69zm5.653 0v5.242h1.982v1.534h-3.692V5.689h1.71zm6.689 0l2.666 6.776h-1.861L36 11.48h-2.334l-.352.986h-1.84l2.685-6.777h1.358zm5.23 0v6.776h-1.71V5.689h1.71zm3.712 0c.892 0 1.596.162 2.112.487.322.203.6.469.816.782.408.576.613 1.274.613 2.093 0 1.03-.288 1.856-.865 2.479s-1.341.935-2.293.935h-2.636V5.69zm.202 1.533h-.745v3.708h.734c1.094 0 1.64-.61 1.64-1.828 0-.6-.14-1.063-.423-1.39-.281-.326-.683-.49-1.206-.49zm-32.74-.021l-1.862 1.85 1.832 1.88 1.861-1.85-1.831-1.88zm-5.906-.049l-1.861 1.85 1.831 1.88 1.862-1.85-1.832-1.88zm28.828.64l-.704 2.316h1.398l-.694-2.317zm-11.598-.57h-.623v1.586h.694c.71 0 1.066-.263 1.066-.788 0-.532-.379-.798-1.137-.798zm-7.837-3.487l-2.372 2.357 1.832 1.88 1.297-1.289-.757-2.948zm-6.416.459L7.13 6.045l1.832 1.88 1.861-1.85-1.831-1.88zm-6.408-.563L1.78 6.566l1.276 1.311 1.861-1.85-2.333-2.395zm8.82-1.834l-1.297 1.288 1.832 1.88 2.372-2.356-2.906-.812zm-4.786-.04L3.7 2.523 6.033 4.92l1.862-1.85-1.277-1.31z'
			transform='translate(-920 -1241) translate(144 183) translate(0 783) translate(776) translate(0 275)'
		/>
	</svg>
);

export default PlaidLogo;
