/* eslint max-len: 0 */
const Invoice = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			d='M14.379 0c.596 0 1.168.237 1.59.659l5.873 5.872c.421.422.658.994.658 1.59V21.75c0 1.243-1.007 2.25-2.25 2.25H3.75c-1.243 0-2.25-1.007-2.25-2.25V2.25C1.5 1.007 2.507 0 3.75 0zM13.5 1.5H3.75c-.414 0-.75.336-.75.75v19.5c0 .414.336.75.75.75h16.5c.414 0 .75-.336.75-.75V9h-5.25c-1.19 0-2.166-.925-2.245-2.096L13.5 6.75V1.5zM18 18.75c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L18 20.25H6.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007H18zm0-4.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L18 15.75h-6c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L12 14.25h6zM8.062 4.5c.38 0 .693.282.743.648l.007.102v.353c.672.154 1.278.538 1.705 1.1.25.329.187.8-.143 1.05-.3.228-.716.196-.977-.06l-.074-.083c-.29-.383-.75-.6-1.261-.579-.764 0-1.312.411-1.312.797 0 .36.478.743 1.162.793l.15.005c1.513 0 2.809.973 2.809 2.296 0 1.102-.9 1.961-2.06 2.218l.001.36c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648l-.007-.102v-.353c-.673-.153-1.28-.537-1.709-1.099-.25-.33-.187-.8.143-1.05.3-.229.715-.197.977.058l.074.084c.291.382.75.599 1.261.579.764 0 1.313-.412 1.313-.797 0-.36-.476-.741-1.16-.79l-.149-.006c-1.513 0-2.812-.975-2.812-2.298 0-1.103.902-1.963 2.061-2.219l.001-.359c0-.414.336-.75.75-.75zM15 1.811V6.75c0 .38.282.693.648.743l.102.007h4.939L15 1.811z'
			transform='translate(-304 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(160)'
		/>
	</svg>
);

export default Invoice;
