/* eslint max-len: 0 */
const Signature = ( props ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M3.747 18c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H3c-.828 0-1.5.672-1.5 1.5 0 .78.595 1.42 1.356 1.493L3 22.5h15.75c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L18.75 24H3c-1.657 0-3-1.343-3-3 0-1.598 1.249-2.904 2.824-2.995L3 18h.747zM21.087.54l.13.119 1.06 1.06c.693.693.84 1.725.44 2.561l.62.622c.837.837.877 2.168.12 3.052l-.12.13-4.773 4.772c-.293.293-.767.293-1.06 0-.267-.266-.29-.683-.073-.976l.073-.084 4.773-4.773c.266-.266.29-.683.072-.977l-.073-.084-.53-.53-9.545 9.547c-.216.215-.53.272-.796.17-.124.52-.39 1.014-.796 1.42-.473.474-1.287.769-2.403.988-.512.1-1.055.176-1.598.23-.163.017-.308.03-.431.04l-.3.02c-.445.025-.813-.343-.789-.789l.012-.183.048-.547c.055-.543.13-1.086.231-1.598.219-1.116.514-1.93.988-2.403.406-.406.9-.672 1.42-.796-.102-.266-.045-.58.17-.795l6.346-6.346.019-.02L18.035.659c.837-.837 2.168-.877 3.052-.12zM9.435 13.283c-.59-.48-1.458-.446-2.008.103-.193.194-.413.8-.576 1.632-.09.46-.16.96-.21 1.46l.022-.207.168-.018c.124-.015.248-.031.37-.049l.364-.056.352-.064c.833-.163 1.438-.383 1.632-.576.585-.586.585-1.536 0-2.122zm5.417-7.321l-5.303 5.303 1.044 1.045.016.016.016.015 1.045 1.046 5.303-5.304-2.121-2.121zm4.327-4.316l-.084.072-3.184 3.182 2.122 2.122 3.184-3.182c.266-.266.29-.683.072-.977l-.072-.084-1.061-1.06c-.266-.267-.683-.291-.977-.073z'
			transform='translate(-464 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(320)'
		/>
	</svg>
);

export default Signature;
