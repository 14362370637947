import Query from '../../../Query.js';
import {
	UserDetailReturnFields,
	ClientUserReturnFields,
	OrgUserReturnFields,
	AuthedUserReturnFields,
	ClientUserDetailReturnFields,
	OrgUserDetailReturnFields,
	OrgUserWithClientsReturnFields,
} from '../../returnFields';

/**
 * Check if an org user is an admin of their organization.
 *
 * @param user - OrgUser object.
 * @param user.id - OrgUser ID.
 * @param user.organization - OrgUser's organization.
 * @param user.organization.id - OrgUser's organization ID.
 *
 * @returns {Object} res - {errors, isAdmin}
 */
const isUserAnAdmin = async function( { id: userID, organization } ) {
	// no org, no admin
	if ( !organization ) return { isAdmin: false };

	const { id: orgID } = organization;

	const { adminUsers } = await this.getOrganizationUsers( orgID );

	let isAdmin = false;
	if ( Array.isArray( adminUsers ) ) {
		isAdmin = !!adminUsers.filter( ( { id } ) => id === userID ).length;
	}

	return { isAdmin };
};

const getUserByEmail = async function( email ) {
	// first, try to find a client user
	return await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUserByEmail',
			params: { where: { email } },
			returnFields: [ ...ClientUserReturnFields, { user: [ 'email' ] } ],
		} )
	).then( ( res ) => {
		if ( res.data.data.getClientUserByEmail ) {
			return {
				user: res.data.data.getClientUserByEmail,
				errors: res.data.errors,
			};
		}

		// if no client user is found, look for an org user
		return this.request(
			new Query( {
				type: 'query',
				name: 'getOrgUserByEmail',
				params: { where: { email } },
				returnFields: [ ...OrgUserReturnFields, { user: [ 'email' ] } ],
			} )
		).then( ( res ) => {
			const user = res.data.data.getOrgUserByEmail;
			if ( user ) {
				return isUserAnAdmin( user ).then( ( { isAdmin } ) => {
					return {
						user: {
							...user,
							isAdmin,
						},
						errors: res.data.errors,
					};
				} );
			}

			// if no org user is found, the credentials are invalid
			return {
				user: null,
				errors: { message: 'invalid credentials' },
			};
		} );
	} );
};

/**
 * Get a user's full profile information.
 *
 * @param user.id - The user's id.
 * @param user.userType - "ClientUser" | "OrgUser"
 *
 * @returns res - {profile, errors}
 */
const getUserProfile = async function( id, userType ) {
	const [ mutationName, returnFields ] =
		userType === 'ClientUser'
			? [ 'getClientUserWhere', ClientUserDetailReturnFields ]
			: [ 'getOrgUserWhere', OrgUserDetailReturnFields ];
	let errors = [];
	let profile = {};
	const { data: detailData, errors: detailErrors } = await this.request(
		new Query( {
			type: 'query',
			name: mutationName,
			params: { where: { id } },
			returnFields: [ ...returnFields, 'createdAt' ],
		} )
	);

	if ( detailErrors ) errors = detailErrors;
	if ( detailData ) profile = detailData.data[ mutationName ];

	return { profile, errors };
};

/**
 * Get a user's preferences.
 *
 * @param user - A logged in user object.
 * @param user.id - The user's id.
 * @param user.user.userType - The user's type.
 *
 * @returns res - {preferences, errors}
 */
const getUserPreferences = async function( { user, id } ) {
	const mutationName =
		user.userType === 'ClientUser' ? 'getClientUserWhere' : 'getOrgUserWhere';

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: mutationName,
			params: { where: { id } },
			returnFields: [ { user: [ 'notificationPreference' ] } ],
		} )
	);

	if ( errors || data.errors ) return { errors: errors || data.errors };

	const preferences = {};
	preferences.notificationPreference =
		data.data[ mutationName ].user.notificationPreference;

	return { preferences };
};

/**
 * Gets a mostly complete user object for a given ClientUser or OrgUser.
 *
 * @param user - A user object.
 * @param user.id - A ClientUser/OrgUser ID.
 * @param user.userType - "ClientUser" | "OrgUser".
 *
 * @returns res - {user, errors}
 */
const getUserObject = async function( id, userType ) {
	const queryName =
		userType === 'ClientUser' ? 'getClientUserWhere' : 'getOrgUserWhere';
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: queryName,
			params: { where: { id } },
			returnFields: [ { user: [ ...UserDetailReturnFields, ...AuthedUserReturnFields ] }, ],
		} )
	);

	if ( errors || data.errors ) return { errors: errors || data.errors };

	const { user } = data.data[ queryName ];

	return { user };
};

// eslint-disable-next-line max-len
/** @typedef {{user: import('../../../helpers/index.js').ORGUSER | import('../../../helpers/index.js').CLIENTUSER}} ORG_OR_CLIENT_USER */
/**
 * Query for a unique ClientUser or OrgUser.
 * Throws an error if multiple users are returned.
 *
 * @returns {Promise<ORG_OR_CLIENT_USER>}
 */
const getUserWhere = async function( where ) {
	const TooManyUsersError =
		'UserWhere search is insufficiently specific. Multiple users were found.';

	const clientUsersQuery = new Query( {
		name: 'getClientUsersWhere',
		params: { where },
		returnFields: [ { clientUsers: ClientUserDetailReturnFields } ],
	} );
	const orgUsersQuery = new Query( {
		name: 'getOrgUsersWhere',
		params: { where },
		returnFields: [ { orgUsers: OrgUserWithClientsReturnFields } ],
	} );

	const { data, errors } = await this.request(
		`{ clientUsers: ${ clientUsersQuery } orgUsers: ${ orgUsersQuery } }`
	);
	if ( errors ) return errors;

	const { clientUsers, orgUsers } = data.data;
	if ( clientUsers.clientUsers.length > 1 || orgUsers.orgUsers.length > 1 ) {
		throw new Error( TooManyUsersError );
	}

	const clientUser = clientUsers.clientUsers[ 0 ];
	const orgUser = orgUsers.orgUsers[ 0 ];

	if ( clientUser ) {
		return { user: clientUser };
	} else if ( orgUser ) {
		return { user: orgUser };
	} else {
		return { user: null };
	}
};

/**
 * Update a user. (ClientUser | OrgUser)
 *
 * @param user - A user object.
 * @param user.userType - "ClientUser" | "OrgUser"
 * @param user.id - The user's id.
 * @param data - Data to use to update the user.
 *
 * @returns res - {user, errors}
 */
const updateUser = async function( { userType, id }, data ) {
	const [ mutationName, returnFields ] =
		userType === 'ClientUser'
			? [ 'updateClientUser', ClientUserDetailReturnFields ]
			: [ 'updateOrgUser', OrgUserDetailReturnFields ];

	const { data: responseData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: mutationName,
			params: { data, where: { id } },
			returnFields: [ ...returnFields, { user: [ 'notificationPreference' ] } ],
		} )
	);

	if ( errors || responseData.errors )
		return { errors: errors || responseData.errors };

	const user = responseData.data[ mutationName ];

	return { user };
};

/**
 * Resend API interaction
 * @returns { Promise< { errors: Array< Error > } | true }
 */
const resendVerificationEmail = async function() {
	const { errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'resendVerificationEmail',
		} )
	);

	if ( errors ) return { errors };

	return true;
};

export {
	getUserByEmail,
	getUserObject,
	getUserPreferences,
	getUserProfile,
	getUserWhere,
	isUserAnAdmin,
	resendVerificationEmail,
	updateUser,
};
