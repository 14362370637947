import React from 'react';
import Cleave from 'cleave.js/react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import store from '../../../redux/store';
import { toggleLoader } from '../../../redux/actions';
import Icon from '../../../elements/icons';
import P from '../../../elements/Paragraph';

const BaseHeight = '2rem';

const Container = styled.div`
	&.input {
		& {
			width: 100%;
			input[type='number']::-webkit-inner-spin-button {
				display: none;
			}

			.input-label {
				font-size: 1.125rem;
				margin: 0;
				display: flex;
				justify-content: space-between;
			}

			.input-description {
				font-size: 1.125rem;
				margin: 0.25rem 0 0 0;
			}
			.input-warning,
			.input-validation {
				p {
					font-size: 1.125rem;
					margin: 0;
				}

				margin-top: 0.25rem;
				${ ( props ) => props.type === 'textarea' && 'margin-top: -0.1rem;' }
				display: flex;
				svg {
					margin: 0.2rem 0.2rem 0 0;
					height: 1.2rem;
					width: 1.2rem;
					min-width: 1.2rem;
					fill: ${ ( props ) => props.theme.error };
				}
			}

			.FileInput,
			input,
			textarea {
				color: ${ ( props ) => props.theme.darkBlue };
				${ ( props ) => ( props.type === 'file' ? 'display: none;' : '' ) }
				width: 100%;
				height: 3rem;
				border-radius: 5px;
				border: ${ ( props ) => props.theme.lightBorder };
				margin: 0.5rem 0 0 0;
				font-size: 1.125rem;
				padding: 0.6rem 1rem;

				&--placeholder {
					color: ${ ( props ) => props.theme.gray };
				}

				&::placeholder {
					margin-left: 0.688rem 1.625rem 0.688rem 1rem;
					opacity: 0.2;
					font-size: 1.125rem;
					color: ${ ( props ) => props.theme.darkBlue };
				}

				&:hover {
					border: solid 1px ${ ( props ) => props.theme.pink };
				}

				&:focus {
					outline: none;
				}

				&:focus-within {
					border: solid 1px ${ ( props ) => props.theme.green };
				}

				&.FileInput {
					display: block;
					align-items: center;
					text-transform: uppercase;
					line-height: ${ BaseHeight };
					padding: 0 3rem 0 0.4rem;
					/* white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden; */
					cursor: pointer;

					span {
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						max-width: 11rem;
					}

					background: url(../../../public/icons/downArrow.svg) no-repeat 97%
						center/6%;
				}
			}

			input:disabled {
				pointer-events: none;
				background-color: ${ ( props ) => props.theme.lightGray };

				& + button {
					display: none;
				}
			}

			.input--error {
				border: solid 1px ${ ( props ) => props.theme.error };
			}
		}

		&--plain {
			input,
			textarea {
				height: auto;
				margin: 0;
				padding: 0;
				border: none;

				&--placeholder {
					color: ${ ( props ) => props.theme.gray };
				}

				&::placeholder {
					font-size: 1.125rem;
				}

				&:hover,
				&:focus,
				&:focus-within {
					border: none;
				}
			}

			&:hover,
			&:focus,
			&:focus-within {
				input {
					border: none;
				}
			}
		}
	}
`;

const TextAreaElement = styled.textarea`
	resize: ${ ( { resize } ) => ( resize ? resize : 'vertical' ) };
`;

const InputElement = styled.input`
	line-height: ${ BaseHeight };
`;

class Input extends React.Component {
	constructor( props ) {
		super( props );

		this.state = {
			isOpen: false,
			doPassValue: true,
		};

		this.inputRef = props.innerRef || React.createRef();
		this.inputChangeHandler = this.inputChangeHandler.bind( this );
		this.onFocus = this.onFocus.bind( this );
		this.onBlur = this.onBlur.bind( this );
	}

	componentDidMount() {
		if ( this.inputRef && this.inputRef.current ) {
			setTimeout( () => {
				this.forceUpdate();
			}, 200 );
		}

		if ( this.props.type === 'phone' ) {
			this.setState( { doPassValue: false } );
		}
	}

	onBlur( e ) {
		if ( e?.type === 'blur' ) {
			e.persist();
		}
		this.setState( { isOpen: false }, () => {
			if ( this.props.onBlur ) {
				this.props.onBlur( e );
			}
		} );
	}

	inputChangeHandler( e ) {
		if ( e.target.type == 'file' ) {
			const { files } = e.target;
			const file = files[ 0 ];
			if ( !file ) return;
			store.dispatch( toggleLoader( true ) );

			var reader = new FileReader();

			reader.addEventListener(
				'load',
				() => {
					store.dispatch( toggleLoader( false ) );
				},
				false
			);

			if ( file ) {
				reader.readAsDataURL( file );
			}
		}

		if ( this.props.type == 'phone' ) {
			e.target.value = e.target.rawValue;
		}

		if ( this.props.textArea && this.props.maxLength ) {
			if ( e.target.value.length > this.props.maxLength ) {
				return;
			}
		}

		this.props.onChange( e );
	}

	onFocus( e ) {
		if ( this.props.type == 'phone' ) {
			this.phoneRef.focus();
		} else {
			this.inputRef.current.focus();
		}
		if ( this.props.onFocus ) {
			this.props.onFocus( e );
		}
	}

	preventNonNumericalInput( e ) {
		e = e || window.event;
		if ( !/^[0-9]*\.?[0-9]*$/i.test( e.key ) ) {
			e.preventDefault();
		}
	}

	blurOnEnter( e ) {
		e = e || window.event;
		if ( e.charCode === 13 ) {
			this.onBlur( e );
		}
	}

	preventPasteNonNumericalInput( e ) {
		e = e || window.event;
		const clipboardData = e.clipboardData || window.clipboardData;

		if ( !/^[0-9]*\.?[0-9]*$/i.test( clipboardData.getData( 'Text' ) ) ) {
			e.preventDefault();
		}
	}

	render() {
		const {
			capitalize,
			className,
			description,
			disabled,
			error = false,
			id,
			label,
			mapToState,
			maxLength,
			numericInputOnly,
			placeholder,
			rows,
			step,
			textArea,
			type = 'text',
			validate,
			value,
			blurOnEnter,
			warning,
		} = this.props;

		const inputProps = {
			disabled,
			ref: this.inputRef,
			type: type,
			name: mapToState,
			style: {},
			placeholder: placeholder,
			value,
			step: step,
			autoComplete: 'on',
			rows: rows,
			onChange: this.inputChangeHandler,
			onBlur: this.onBlur,
			onFocus: this.onFocus,
			onKeyPress: null,
			id,
		};

		if ( type !== 'phone' ) {
			inputProps.mapToState = mapToState;
		}

		const isHTMLElement = !!this.inputRef.current;
		if ( isHTMLElement && numericInputOnly ) {
			inputProps.onKeyPress = ( e ) => this.preventNonNumericalInput( e );
			inputProps.onPaste = ( e ) => this.preventPasteNonNumericalInput( e );
		}

		if ( isHTMLElement && blurOnEnter ) {
			inputProps.onKeyPress = ( e ) => this.blurOnEnter( e );
		}

		let DisplayElement = InputElement;
		if ( textArea ) {
			DisplayElement = TextAreaElement;
			inputProps.rows = inputProps.rows || '5';
		}

		if ( type == 'phone' ) {
			DisplayElement = Cleave;
			inputProps.htmlRef = ( phoneRef ) => ( this.phoneRef = phoneRef );
		}

		return (
			<Container className={ `input ${ className || '' }` }>
				{ label && (
					<div className='input-label'>
						<P
							className={ `p--black p--opacity-50 ${
								capitalize ? 'p--caps' : ''
							}` }
						>
							{ label }
						</P>
						{ textArea && maxLength && (
							<P className={ `p--opacity-50 ${ capitalize ? 'p--caps' : '' }` }>
								{ `${ value.length } / ${ maxLength }` }
							</P>
						) }
					</div>
				) }
				{ description && (
					<P className='input-description p--opacity-20'>{ description }</P>
				) }
				{ ( () => {
					switch ( type ) {
						case 'phone':
							return (
								<DisplayElement
									options={ {
										numericOnly: true,
										blocks: [
											0,
											3,
											0,
											3,
											4
										],
										delimiters: [
											'(',
											')',
											' ',
											'-'
										],
									} }
									{ ...inputProps }
								/>
							);
						case 'file':
							return <DisplayElement { ...inputProps } />;
						default:
							return (
								<DisplayElement
									className={ `input ${ error ? 'input--error' : '' }` }
									{ ...inputProps }
								/>
							);
					}
				} )() }
				{ validate && (
					<div className='input-validation field-validation'>
						<Icon type='warning-2' />
						<P className='p--red'>{ validate }</P>
					</div>
				) }
				{ warning && (
					<div className='input-warning field-warning'>
						<P className='p--red'>{ warning }</P>
					</div>
				) }
			</Container>
		);
	}
}
Input.propTypes = {
	capitalize: PropTypes.bool,
	className: PropTypes.string,
	description: PropTypes.string,
	error: PropTypes.bool,
	fileName: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	mapToState: PropTypes.string,
	max: PropTypes.number,
	maxLength: PropTypes.number,
	min: PropTypes.number,
	minDate: PropTypes.object,
	numericInputOnly: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	rows: PropTypes.number,
	step: PropTypes.number,
	textArea: PropTypes.bool, // render a text area
	type: PropTypes.string,
	validate: PropTypes.string,
	value: PropTypes.any,
	resize: PropTypes.string, // resize property, only matters when textArea is true
};

export default Input;
