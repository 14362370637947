import errorReporting from '../lib/ErrorReporting';
import { connect } from 'react-redux';
import React from 'react';

class ErrorBoundary extends React.Component {
	constructor( props ) {
		super( props );
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch( error ) {
		errorReporting.captureErrorInSentry(
			error,
			this.props.user,
			this.props.extraScope
		);
	}

	render() {
		if ( this.state.hasError ) {
			// You can render any custom fallback UI
			return <></>;
		}

		return this.props.children;
	}
}

const mapStateToProps = ( state ) => ( {
	user: state.user,
} );

export default connect( mapStateToProps )( ErrorBoundary );
