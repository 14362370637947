import {
	Box,
	FormControl,
	FormHelperText,
	FormLabel,
	InputLabel,
	MenuItem,
	Select,
	TextFieldProps,
	Typography,
} from '@mui/material';
import React from 'react';
import MuiTextFieldText from '../../../mui/MuiTextField/MuiTextFieldText';
import AddressFieldSet, {
	AddressFieldsProps,
} from '../../../mui/AddressFieldSet';
import styles from './YourBusiness.module.scss';
import OrganizationLogoSelector, {
	ProfileImageSelection,
} from './OrganizationLogoSelector';
import OrganizationLogoSelected from './OrganizationLogoSelected';
import MuiCheckbox, { MuiCheckboxProps } from '../../../mui/MuiCheckbox';
import ExtendedLink from '../../ExtendedLink/ExtendedLink';
import { BusinessTypes } from './helpers';
import MuiPhoneInput from '../../../mui/MuiPhoneInput';
import SelectServices from '../../SelectServices';
import { gql } from '@apollo/client';
import { useYourBusiness_GetGlobalVariablesQuery } from './__generated__/YourBusiness';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query YourBusiness_getGlobalVariables {
		getGlobalVariables {
			StripeLegalUrl
			TermsOfServiceUrl
		}
	}
`;

export type YourBusinessProps = {
	businessName?: string;
	setBusinessName: React.Dispatch<React.SetStateAction<string | undefined>>;
	businessNameValidation?: string;
	businessType?: BusinessTypes;
	setBusinessType: React.Dispatch<
	React.SetStateAction<BusinessTypes | undefined>
	>;
	businessTypeValidation?: string;
	selectedServices?: { name: string; id: string }[] | undefined | null;
	setSelectedServices: React.Dispatch<
	React.SetStateAction<{ name: string; id: string }[] | undefined | null>
	>;
	selectedServicesValidation?: string;
	address?: AddressFieldsProps['address'];
	setAddress: React.Dispatch<AddressFieldsProps['address']>;
	addressValidation?: AddressFieldsProps['validate'];
	phone?: string;
	setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
	phoneValidation?: string;
	image?: ProfileImageSelection | string;
	setImage: React.Dispatch<
	React.SetStateAction<ProfileImageSelection | string | undefined>
	>;
	termsOfServiceAccepted?: boolean | undefined;
	setTermsOfServiceAccepted: React.Dispatch<
	React.SetStateAction<boolean | undefined>
	>;
	termsOfServiceAcceptedValidation?: string;
	onBlur?: TextFieldProps['onBlur'] & MuiCheckboxProps['onBlur'];
};

const YourBusiness: React.FC<YourBusinessProps> = ( {
	businessName,
	setBusinessName,
	businessNameValidation,
	businessType,
	setBusinessType,
	businessTypeValidation,
	selectedServices,
	setSelectedServices,
	selectedServicesValidation,
	address,
	setAddress,
	addressValidation,
	phone,
	setPhone,
	phoneValidation,
	image,
	setImage,
	termsOfServiceAccepted,
	setTermsOfServiceAccepted,
	termsOfServiceAcceptedValidation,
	onBlur,
} ) => {
	const { data: globalVariableData, loading: globalVariableLoading } =
		useYourBusiness_GetGlobalVariablesQuery();
	const legalHref = globalVariableData?.getGlobalVariables.StripeLegalUrl;
	const termsOfServiceHref =
		globalVariableData?.getGlobalVariables.TermsOfServiceUrl;
	return (
		<>
			<Typography variant='h1' component='h1'>
				Let's create your business profile
			</Typography>
			<Box className={ styles.form }>
				<Box className={ styles.row }>
					<MuiTextFieldText
						fullWidth
						label='Business name'
						autoComplete='organization'
						value={ businessName || '' }
						onChange={ ( e ) => setBusinessName( e.target.value ) }
						errorText={ businessNameValidation }
						onBlur={ onBlur }
						inputProps={ {
							'data-cy': 'businessName',
						} }
						autoFocus
					/>
					<FormControl
						fullWidth
						error={ !!businessTypeValidation }
						variant='outlined'
					>
						<InputLabel htmlFor={ 'businessType' }>Business structure</InputLabel>
						<Select<BusinessTypes>
							id='businessType'
							label='Business structure'
							value={ businessType || '' }
							onChange={ ( e ) => setBusinessType( e.target.value as BusinessTypes ) }
							onBlur={ onBlur }
							inputProps={ {
								'data-cy': 'businessType',
							} }
						>
							{ Object.entries( BusinessTypes ).map( ( [ value, label ] ) => (
								<MenuItem key={ value } value={ value }>
									{ label }
								</MenuItem>
							) ) }
						</Select>
						{ businessTypeValidation ? (
							<FormHelperText>{ businessTypeValidation }</FormHelperText>
						) : null }
					</FormControl>
				</Box>
				<SelectServices
					errorText={ selectedServicesValidation }
					variant='outlined'
					fullWidth
					value={ selectedServices }
					onChange={ ( selectedServices ) => setSelectedServices( selectedServices ) }
					onBlur={ onBlur }
					dataCy='selectedServices'
					chipLimitDesktop={ 3 }
					chipLimitMobile={ 1 }
				/>
				<AddressFieldSet
					address={ address || {} }
					onChange={ ( values ) => setAddress( values ) }
					validate={ addressValidation }
					onBlur={ onBlur }
				/>
				<Box className={ styles.row }>
					<MuiPhoneInput
						fullWidth
						label='Phone number'
						autoComplete='tel'
						value={ phone || '' }
						onChange={ setPhone }
						errorText={ phoneValidation }
						onBlur={ onBlur }
						inputProps={ {
							'data-cy': 'phone',
						} }
					/>
					{ image ? (
						<OrganizationLogoSelected
							value={
								typeof image === 'string' ? image : image.originalImage.name
							}
							onRemove={ () => setImage( undefined ) }
						/>
					) : (
						<OrganizationLogoSelector
							onSelect={ ( selection ) => setImage( selection ) }
						/>
					) }
				</Box>
				<FormControl
					error={ !!termsOfServiceAcceptedValidation }
					className={ styles.termsOfService }
				>
					<Box className={ styles.termsOfServiceBody }>
						<MuiCheckbox
							id='termsOfService'
							checked={ termsOfServiceAccepted || false }
							onChange={ ( _, checked ) => setTermsOfServiceAccepted( checked ) }
							onBlur={ onBlur }
							inputProps={ {
								'data-cy': 'termsOfServiceAccepted',
							} }
						/>
						<FormLabel htmlFor='termsOfService'>
							I have reviewed and agree to Rock Paper Coin's{ ' ' }
							<ExtendedLink
								className='legacyLink'
								target='_blank'
								href={ termsOfServiceHref }
								rel='noreferrer'
								loading={ globalVariableLoading }
							>
								Terms and Conditions
							</ExtendedLink>{ ' ' }
							and Stripe Connect's{ ' ' }
							<ExtendedLink
								className='legacyLink'
								target='_blank'
								href={ legalHref }
								rel='noreferrer'
								loading={ globalVariableLoading }
							>
								Account Agreement
							</ExtendedLink>
						</FormLabel>
					</Box>
					{ termsOfServiceAcceptedValidation ? (
						<FormHelperText>{ termsOfServiceAcceptedValidation }</FormHelperText>
					) : null }
				</FormControl>
			</Box>
		</>
	);
};

export default YourBusiness;
