import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FilePDF = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			d='M7.47 14.436c.394 0 .713.32.713.713v1.784a.713.713 0 1 1-1.427 0v-1.784c0-.394.32-.713.714-.713Z'
		/>
		<path
			fillRule='evenodd'
			d='M15.938 1c.44 0 .863.174 1.174.485l2.946 2.932c.314.312.49.737.49 1.18v3.726a.713.713 0 1 1-1.426 0V5.596a.238.238 0 0 0-.07-.168l-2.945-2.932a.238.238 0 0 0-.168-.07H3.665a.238.238 0 0 0-.238.239v17.122c0 .13.106.237.238.237h15.22a.238.238 0 0 0 .237-.237v-.476a.713.713 0 0 1 1.427 0v.476c0 .919-.746 1.664-1.665 1.664H3.664c-.919 0-1.664-.745-1.664-1.664V2.665C2 1.745 2.745 1 3.665 1h12.273Z'
		/>
		<path
			fillRule='evenodd'
			d='M9.006 11.463H7.47a.713.713 0 0 0-.714.714v2.972c0 .394.32.714.714.714h1.61c.51 0 1.002-.178 1.393-.504l.132-.12c.875-.885.858-2.283-.025-3.123l-.04-.039a2.223 2.223 0 0 0-1.534-.614Zm-.823 2.973V12.89h.823a.8.8 0 0 1 .55.22l.04.039a.747.747 0 0 1 .019 1.063l-.082.074a.734.734 0 0 1-.452.15h-.898Zm4.043-2.973a.713.713 0 0 0-.714.714v4.756c0 .394.32.713.714.713h1.601a3.177 3.177 0 0 0 1.43-.363c.919-.476 1.487-1.356 1.487-2.625 0-1.254-.55-2.158-1.443-2.702a3.374 3.374 0 0 0-1.407-.49l-1.668-.003Zm.713 4.757v-3.33h.888c.11.014.426.097.73.284.484.295.76.748.76 1.484 0 .72-.258 1.12-.715 1.357a1.825 1.825 0 0 1-.775.205h-.888Zm7.848-4.757a.713.713 0 0 1 0 1.427h-2.14v.951h1.92a.713.713 0 0 1 0 1.427h-1.92v1.665a.713.713 0 1 1-1.427 0v-4.756c0-.394.319-.714.713-.714h2.854Z'
		/>
	</svg>
);

export default FilePDF;
