import styled from 'styled-components';

const P = styled.p`
	margin: 0;
	font-size: 1.125rem;
	color: ${ ( { theme } ) => theme.darkBlue };

	&.p {
		&--primary {
			color: ${ ( { theme } ) => theme.green };
		}

		&--secondary {
			color: ${ ( { theme } ) => theme.darkBlue };
		}

		&--red {
			color: ${ ( { theme } ) => theme.error };
		}

		&--pink {
			color: ${ ( { theme } ) => theme.pink };
		}

		&--white {
			color: ${ ( { theme } ) => theme.white };
		}

		&--gray {
			color: ${ ( { theme } ) => theme.gray };	
		}

		&--light-gray {
			color: ${ ( { theme } ) => theme.lightGray };	
		}

		&--black {
			color: ${ ( { theme } ) => theme.black };
		}

		&--bold {
			font-weight: bold;
		}

		&--centered {
			text-align: center;
		}

		&--centered-index {
			max-width: 80%;
			margin: 0 auto;
			&-margins {
				margin: 3.5rem;
			}
		}
			
		&-centered-v-space {
			max-width: 80%
			margin: 3.5rem auto;
		}

		&--right {
			text-align: right;
		}

		&--caps {
			text-transform: uppercase;
		}

		&--opacity-20 {
			opacity: 0.2;
		}

		&--opacity-30 {
			opacity: 0.3;
		}

		&--opacity-50 {
			opacity: 0.5;
		}

		&--large {
			font-size: 1.688rem;
		}
	}
`;

export default P;
