/* eslint max-len: 0 */
const Cash = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M6.97 8.57c.164.381-.012.822-.393.986-2.786 1.198-4.43 4.107-4.022 7.112.41 3.005 2.773 5.368 5.778 5.777 3.005.41 5.914-1.236 7.112-4.022.164-.38.605-.557.985-.393.38.164.557.605.393.985-1.464 3.406-5.02 5.417-8.692 4.917-3.673-.5-6.561-3.389-7.062-7.061-.5-3.673 1.51-7.229 4.916-8.693.38-.164.821.012.985.393zm.405 4.772c.38 0 .693.282.743.648l.007.102c0 .211.113.404.291.51l.08.039 2.066.826c.93.374 1.463 1.354 1.273 2.338-.176.911-.928 1.589-1.835 1.684v.761c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L8.5 20.25v-.751l-.75.001c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L7.75 18h2.031c.284 0 .528-.201.581-.48.048-.247-.066-.494-.276-.62l-.083-.04-2.064-.827c-.793-.317-1.314-1.086-1.314-1.94 0-.415.336-.751.75-.751zM16.75 0C21.306 0 25 3.694 25 8.25s-3.694 8.25-8.25 8.25S8.5 12.806 8.5 8.25 12.194 0 16.75 0zm0 1.5C13.022 1.5 10 4.522 10 8.25S13.022 15 16.75 15s6.75-3.022 6.75-6.75-3.022-6.75-6.75-6.75zm0 1.5c.38 0 .693.282.743.648l.007.102v.75h.75c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L18.25 6h-2.032c-.284 0-.528.201-.581.48-.048.247.066.494.276.62l.083.04 2.065.827c.93.374 1.463 1.354 1.273 2.338-.176.911-.927 1.588-1.834 1.683v.762c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L16 12.75V12h-.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h2.03c.284 0 .528-.201.581-.48.048-.247-.066-.494-.276-.62l-.083-.04-2.065-.827c-.93-.374-1.463-1.354-1.273-2.338.176-.911.928-1.589 1.835-1.684L16 3.75c0-.414.336-.75.75-.75z'
			transform='translate(-264 -1020) translate(144 183) translate(0 783) translate(0 53) translate(119 1)'
		/>
	</svg>
);

export default Cash;
