import { ApiResponseType } from '../../../hooks/useQuery';
import { Invitation } from '../../../types/generated';
import Query from '../../Query';
import { Pagination } from './../proposals/types';
import { InvitationReturnFields } from './../returnFields/index';

export type DataResponseType = Array<Invitation>;

const getPendingInvitations = async function(
	this: any,
	paramsData: Pagination & { where: Partial<Invitation> }
): Promise<ApiResponseType<DataResponseType>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getPendingInvitations',
			params: paramsData,
			returnFields: [ '_count', { pendingInvitations: InvitationReturnFields } ],
		} )
	);

	if ( errors ) return { errors };

	return {
		data: data.data.getPendingInvitations.pendingInvitations,
		count: data.data.getPendingInvitations._count,
	};
};

export default getPendingInvitations;
