import React from 'react';
import P from '../../../elements/Paragraph';
import { linkObject, getDetailLink } from '../../../lib/helpers/index';
import { ProposalsIcon } from '../../../mui/Icons/index';
import { linkCustomer } from '../helpers';
import rpcShared from '@rockpapercoin/rpc-shared';
import { LinkObjectType } from '../../../lib/helpers/types';

export default function getProposalViewedEventProps(
	defaultEventProps: {
		event: any;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	},
	user: any
) {
	const updatedEventProps = { ...defaultEventProps };
	const proposalLink = linkObject( updatedEventProps.event.proposal );
	const customerLink = linkCustomer( updatedEventProps.event );
	const vendorLink = linkObject( updatedEventProps.event.vendor );

	updatedEventProps.icon = <ProposalsIcon />;

	updatedEventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: updatedEventProps.event.proposal,
				type: LinkObjectType.Proposal,
			} ),
		},
	];

	updatedEventProps.message = (
		<P className='p--secondary'>
			{ proposalLink } has been viewed by { customerLink }
		</P>
	);

	if (
		rpcShared.contacts.isCustomer( defaultEventProps.event.contact, user.user.id )
	) {
		updatedEventProps.message = (
			<P className='p--secondary'>
				You viewed { proposalLink } from { vendorLink }
			</P>
		);
	}

	return updatedEventProps;
}
