import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ContactsIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				xmlns='http://www.w3.org/2000/svg'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.80851 0C1.25741 0 0 1.25741 0 2.80851V21.1915C0 22.7426 1.25741 24 2.80851 24H21.1915C21.6145 24 21.9574 23.6571 21.9574 23.234V0.765957C21.9574 0.342931 21.6145 0 21.1915 0H2.80851ZM4.08511 1.53191H2.80851C2.10347 1.53191 1.53191 2.10347 1.53191 2.80851V21.1915C1.53191 21.8965 2.10347 22.4681 2.80851 22.4681H4.08511L4.08511 1.53191ZM5.61702 22.4681L5.61702 1.53191H20.4255V22.4681H5.61702ZM11.3001 10.4918C11.3001 9.43146 12.1382 8.59627 13.1424 8.59627C14.1465 8.59627 14.9847 9.43146 14.9847 10.4918C14.9847 11.5522 14.1465 12.3874 13.1424 12.3874C12.1382 12.3874 11.3001 11.5522 11.3001 10.4918ZM13.1424 7.23346C11.3589 7.23346 9.93725 8.70575 9.93725 10.4918C9.93725 11.7058 10.594 12.7748 11.5748 13.3351C10.8937 13.5324 10.2618 13.8587 9.73124 14.302C8.80549 15.0754 8.25474 16.1534 8.25474 17.3097V18.0906C8.25474 18.4669 8.55981 18.772 8.93614 18.772H17.3486C17.7249 18.772 18.03 18.4669 18.03 18.0906V17.3097C18.03 16.1534 17.4792 15.0754 16.5535 14.302C16.0229 13.8587 15.391 13.5324 14.7099 13.3351C15.6907 12.7749 16.3475 11.7058 16.3475 10.4918C16.3475 8.70575 14.9259 7.23346 13.1424 7.23346ZM13.1424 14.4771C12.1719 14.4771 11.2605 14.8002 10.605 15.3478C9.9531 15.8925 9.61755 16.602 9.61755 17.3097V17.4092H16.6672V17.3097C16.6672 16.602 16.3316 15.8925 15.6797 15.3478C15.0242 14.8002 14.1128 14.4771 13.1424 14.4771Z'
			/>
		</SvgIcon>
	);
}
