import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const MoneyBank = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.999 0.27004C11.3029 0.0930415 11.6485 0 12 0C12.3515 0 12.6971 0.0930415 13.001 0.27004C13.0113 0.276057 13.0215 0.282319 13.0315 0.288823L23.2657 6.92432C23.946 7.36824 24.1527 8.13363 23.8895 8.78945C23.6368 9.41931 22.9993 9.8452 22.2323 9.8452H21.4V17.7738H22.3846C23.27 17.7738 24 18.4866 24 19.3809V21.9524C24 22.8467 23.27 23.5595 22.3846 23.5595H1.61539C0.730021 23.5595 6.09894e-06 22.8467 6.09894e-06 21.9524V19.3809C6.09894e-06 18.4866 0.730021 17.7738 1.61539 17.7738H2.59617V9.8452H1.76768C1.00072 9.8452 0.363235 9.41931 0.110468 8.78945C-0.152718 8.13363 0.0540404 7.36824 0.734273 6.92432L0.736139 6.92311L10.9685 0.288823C10.9785 0.282319 10.9887 0.276057 10.999 0.27004ZM11.7664 1.55931L1.55452 8.18037C1.52228 8.2015 1.50721 8.21819 1.50146 8.22639L1.50309 8.23096C1.51003 8.24824 1.56509 8.3452 1.76768 8.3452H22.2323C22.4349 8.3452 22.49 8.24824 22.4969 8.23096L22.4985 8.22639C22.4928 8.2182 22.4784 8.20195 22.4462 8.18082L12.2336 1.55931C12.1625 1.5206 12.0821 1.5 12 1.5C11.9179 1.5 11.8375 1.5206 11.7664 1.55931ZM4.09617 17.7738H6.9231V9.8452H4.09617V17.7738ZM8.4231 17.7738H11.25V9.8452H8.4231V17.7738ZM12.75 17.7738H15.5769V9.8452H12.75V17.7738ZM17.0769 17.7738H19.9V9.8452H17.0769V17.7738ZM1.50001 19.3809C1.50001 19.3285 1.54488 19.2738 1.61539 19.2738H22.3846C22.4551 19.2738 22.5 19.3285 22.5 19.3809V21.9524C22.5 22.0048 22.4551 22.0595 22.3846 22.0595H1.61539C1.54488 22.0595 1.50001 22.0048 1.50001 21.9524V19.3809Z'
		/>
	</svg>
);
export default MoneyBank;
