import React, { SVGProps } from 'react';
const Dollar = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12.105 2.006c-.388.052-.688.4-.688.826V3.96l-.05.005C9.049 4.21 7.5 5.81 7.5 8.074c0 1.703.618 2.652 2.12 3.574l.425.248c.183.103.388.212.709.382l2.122 1.112c.483.258.726.4.98.568.807.538 1.073 1.01 1.073 1.977 0 1.59-1.182 2.435-2.929 2.435-1.747 0-2.928-.844-2.928-2.435 0-.463-.351-.832-.786-.832-.435 0-.786.37-.786.832l.004.204c.088 2.16 1.571 3.623 3.832 3.86l.081.007v1.162c0 .462.352.832.786.832l.099-.006c.388-.051.687-.4.687-.826v-1.213l.065-.01c2.114-.369 3.446-1.877 3.446-4.01 0-1.709-.622-2.662-2.133-3.588l-.194-.116c-.257-.15-.482-.273-.914-.5l-1.503-.786c-2.344-1.217-2.684-1.552-2.684-2.87 0-1.603 1.224-2.478 2.928-2.478 1.717 0 2.877.854 2.877 2.477 0 .455.353.832.786.832.433 0 .786-.376.786-.832l-.004-.205c-.082-2.038-1.378-3.48-3.396-3.845l-.06-.01V2.831c0-.46-.351-.831-.786-.831l-.098.006z'
			transform='translate(-188 -756) translate(144 183) translate(0 54) translate(0 39) translate(0 452) translate(24 8) translate(20 20)'
		/>
	</svg>
);

export default Dollar;
