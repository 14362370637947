/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CheckCircleFilled = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM17.5665 8.50855C17.2951 8.19566 16.8214 8.16205 16.5085 8.43347L10.085 14.005L7.49368 11.7394L7.40489 11.6725C7.09737 11.4748 6.68326 11.5268 6.43539 11.8103C6.16274 12.1221 6.1945 12.5959 6.50633 12.8686L9.58974 15.5646L9.67818 15.6312C9.95432 15.8088 10.3203 15.7874 10.5749 15.5666L17.4915 9.56655L17.5699 9.48778C17.8079 9.21033 17.8133 8.793 17.5665 8.50855Z'
		/>
	</svg>
);

export default CheckCircleFilled;
