import * as Types from '../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgOnboardingModal_GetGlobalVariablesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgOnboardingModal_GetGlobalVariablesQuery = { __typename?: 'Query', getGlobalVariables: { __typename?: 'FormattedGlobalVariables', WhiteGloveUrl?: string | null, WatchDemoUrl?: string | null, DemoCalendarUrl?: string | null } };

export type OrgOnboardingModal_GetStripeConnectAccountQueryVariables = Types.Exact<{
  where: Types.OrganizationWhereUniqueInput;
}>;


export type OrgOnboardingModal_GetStripeConnectAccountQuery = { __typename?: 'Query', getStripeConnectAccount?: { __typename?: 'StripeConnectAccount', external_accounts?: { __typename?: 'Sources', data?: Array<{ __typename?: 'Source', id?: string | null } | null> | null } | null } | null };


export const OrgOnboardingModal_GetGlobalVariablesDocument = gql`
    query OrgOnboardingModal_getGlobalVariables {
  getGlobalVariables {
    WhiteGloveUrl
    WatchDemoUrl
    DemoCalendarUrl
  }
}
    `;

/**
 * __useOrgOnboardingModal_GetGlobalVariablesQuery__
 *
 * To run a query within a React component, call `useOrgOnboardingModal_GetGlobalVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboardingModal_GetGlobalVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboardingModal_GetGlobalVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgOnboardingModal_GetGlobalVariablesQuery(baseOptions?: Apollo.QueryHookOptions<OrgOnboardingModal_GetGlobalVariablesQuery, OrgOnboardingModal_GetGlobalVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboardingModal_GetGlobalVariablesQuery, OrgOnboardingModal_GetGlobalVariablesQueryVariables>(OrgOnboardingModal_GetGlobalVariablesDocument, options);
      }
export function useOrgOnboardingModal_GetGlobalVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboardingModal_GetGlobalVariablesQuery, OrgOnboardingModal_GetGlobalVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboardingModal_GetGlobalVariablesQuery, OrgOnboardingModal_GetGlobalVariablesQueryVariables>(OrgOnboardingModal_GetGlobalVariablesDocument, options);
        }
export type OrgOnboardingModal_GetGlobalVariablesQueryHookResult = ReturnType<typeof useOrgOnboardingModal_GetGlobalVariablesQuery>;
export type OrgOnboardingModal_GetGlobalVariablesLazyQueryHookResult = ReturnType<typeof useOrgOnboardingModal_GetGlobalVariablesLazyQuery>;
export type OrgOnboardingModal_GetGlobalVariablesQueryResult = Apollo.QueryResult<OrgOnboardingModal_GetGlobalVariablesQuery, OrgOnboardingModal_GetGlobalVariablesQueryVariables>;
export const OrgOnboardingModal_GetStripeConnectAccountDocument = gql`
    query OrgOnboardingModal_getStripeConnectAccount($where: OrganizationWhereUniqueInput!) {
  getStripeConnectAccount(where: $where) {
    external_accounts {
      data {
        id
      }
    }
  }
}
    `;

/**
 * __useOrgOnboardingModal_GetStripeConnectAccountQuery__
 *
 * To run a query within a React component, call `useOrgOnboardingModal_GetStripeConnectAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboardingModal_GetStripeConnectAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboardingModal_GetStripeConnectAccountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboardingModal_GetStripeConnectAccountQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboardingModal_GetStripeConnectAccountQuery, OrgOnboardingModal_GetStripeConnectAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboardingModal_GetStripeConnectAccountQuery, OrgOnboardingModal_GetStripeConnectAccountQueryVariables>(OrgOnboardingModal_GetStripeConnectAccountDocument, options);
      }
export function useOrgOnboardingModal_GetStripeConnectAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboardingModal_GetStripeConnectAccountQuery, OrgOnboardingModal_GetStripeConnectAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboardingModal_GetStripeConnectAccountQuery, OrgOnboardingModal_GetStripeConnectAccountQueryVariables>(OrgOnboardingModal_GetStripeConnectAccountDocument, options);
        }
export type OrgOnboardingModal_GetStripeConnectAccountQueryHookResult = ReturnType<typeof useOrgOnboardingModal_GetStripeConnectAccountQuery>;
export type OrgOnboardingModal_GetStripeConnectAccountLazyQueryHookResult = ReturnType<typeof useOrgOnboardingModal_GetStripeConnectAccountLazyQuery>;
export type OrgOnboardingModal_GetStripeConnectAccountQueryResult = Apollo.QueryResult<OrgOnboardingModal_GetStripeConnectAccountQuery, OrgOnboardingModal_GetStripeConnectAccountQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    