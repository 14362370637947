import { getEnumKeyByValue } from '../../../lib/helpers/apollo';

export enum BusinessTypes {
	SoleProprietorship = 'Sole Proprietorship',
	Company = 'Company, LLC, or Partnership',
	NonProfit = 'Non Profit',
	GovernmentEntity = 'Government Entity',
}

export const businessTypeFromString = (
	businessType?: string
): BusinessTypes | undefined => {
	if (
		typeof businessType === 'string' &&
		businessType &&
		Object.values<string>( BusinessTypes ).includes( businessType )
	) {
		return getEnumKeyByValue( BusinessTypes, businessType ) as BusinessTypes;
	}
};

/* Not using the pre-exiasting validators helpers here because we're using an enum here,
and I didn't want to possibly break anything pre-existing behavior elsewhere */
export const validateBusinessType = ( businessType?: BusinessTypes ) => {
	if ( !businessType ) {
		return 'We need your business structure';
	}

	if ( !Object.keys( BusinessTypes ).includes( businessType ) ) {
		return `${ businessType } is not a valid option`;
	}
};

const stripeBusinessTypes = {
	SoleProprietorship: 'individual',
	Company: 'company',
	NonProfit: 'non_profit',
	GovernmentEntity: 'government_entity',
};

export const businessTypeEnumToCorrespondingStripeValue = (
	businessType: BusinessTypes
) => stripeBusinessTypes[ businessType as unknown as keyof typeof BusinessTypes ];
