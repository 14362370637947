import React, { SVGProps } from 'react';
const Decline = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12 0c3.307 0 6.302 1.338 8.472 3.502l.013.013.012.012C22.662 5.697 24 8.692 24 12c0 6.627-5.373 12-12 12-3.307 0-6.302-1.338-8.472-3.502l-.013-.013-.012-.012C1.338 18.303 0 15.308 0 12 0 5.373 5.373 0 12 0zm7.936 5.124L5.124 19.936C6.966 21.533 9.37 22.5 12 22.5c5.799 0 10.5-4.701 10.5-10.5 0-2.63-.967-5.034-2.564-6.876zM12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 2.63.967 5.034 2.564 6.876L18.876 4.064C17.034 2.467 14.63 1.5 12 1.5z'
			transform='translate(-264 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(120)'
		/>
	</svg>
);

export default Decline;
