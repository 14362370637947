/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Italic = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path d='M12.7092 6.26011C11.9885 6.26011 11.4995 5.66809 11.4995 4.94737C11.4995 4.02073 12.2717 3.24854 13.1468 3.24854C13.8933 3.24854 14.4338 3.84056 14.4338 4.58701C14.4338 5.51366 13.6101 6.26011 12.7092 6.26011ZM9.56896 20.2627L10.856 10.9448C10.8817 10.6874 11.1391 10.4557 11.3708 10.4557H12.9666C13.1983 10.4557 13.3527 10.6874 13.327 10.9448L12.0143 20.2627C11.9885 20.5201 11.7311 20.7517 11.4995 20.7517H9.92932C9.69766 20.7517 9.54322 20.5201 9.56896 20.2627Z' />
	</svg>
);
export default Italic;
