/* eslint max-len: 0 */
const Slide = () => (
	<svg viewBox='0 0 24 24'>
		<g fill='none' fillRule='evenodd'>
			<g fill='#000'>
				<g>
					<g>
						<g>
							<g>
								<path
									d='M18-.002c.587 0 1.15.23 1.57.64l2.954 2.882c.434.424.678 1.005.678 1.61l.002 16.618c0 1.243-1.007 2.25-2.25 2.25H3c-1.243 0-2.25-1.007-2.25-2.25v-19.5C.75 1.005 1.757-.002 3-.002zm0 1.5H3c-.414 0-.75.336-.75.75v19.5c0 .414.336.75.75.75h17.954c.414 0 .75-.336.75-.75l-.002-16.617c0-.203-.081-.396-.226-.537L18.523 1.71c-.14-.137-.327-.213-.523-.213zm.704 17.25c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-6.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h6.75zm0-3c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-3c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h3zm-8.25-12c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm-.62 6.75H6.016c.358 2.129 2.209 3.75 4.438 3.75.073 0 .146-.002.218-.005l-.838-3.745zm4.287-3.36l-2.865 2.849.88 3.936c1.59-.641 2.73-2.164 2.813-3.963l.005-.212c0-.973-.309-1.873-.833-2.61zm-3.667-1.89c-2.23 0-4.08 1.622-4.438 3.75h4.109l2.935-2.919c-.681-.485-1.504-.785-2.394-.826z'
									transform='translate(-544 -1164) translate(144 183) translate(0 783) translate(0 53) translate(0 145) translate(400)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Slide;
