import React, { useState } from 'react';

import Popover, { PopoverProps } from '@mui/material/Popover';

import styles from './MuiTooltip.module.scss';
import { Typography } from '@mui/material';

export type MuiTooltipProps = {
	target: React.ReactElement | undefined;
	children: React.ReactNode;
} & Omit<PopoverProps, 'open' | 'anchorEl' | 'children'>;

export const MuiTooltip: React.FC<MuiTooltipProps> = ( {
	target,
	children,
	disablePortal = true,
	...popperProps
} ) => {
	const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>( null );

	const handlePopoverOpen = ( e: React.MouseEvent<HTMLElement> ) =>
		setAnchorEl( e.currentTarget );
	const handlePopoverClose = () => setAnchorEl( null );

	/* Using React.cloneElement so we don't have to wrap a thing in a thing.
	Some MUI elements rely on their children being specific things.
	AvatarGroup needs to have Avatar components inside of it, so if we want
	tooltips on those Avatar components but keep them Avatar and not some
	other component we can use cloneElement */

	return (
		<>
			{ target
				? React.cloneElement( target, {
					'aria-haspopup': 'true',
					onMouseEnter: handlePopoverOpen,
					onMouseLeave: handlePopoverClose,
				  } )
				: null }

			<Popover
				open={ !!anchorEl }
				anchorEl={ anchorEl }
				anchorOrigin={ {
					vertical: 'bottom',
					horizontal: 'center',
				} }
				transformOrigin={ {
					vertical: 'bottom',
					horizontal: 'center',
				} }
				className={ styles.container }
				slotProps={ {
					paper: {
						className: styles[ 'mui-tooltip-paper' ],
					},
				} }
				sx={ { pointerEvents: 'none' } }
				disableScrollLock
				disablePortal={ disablePortal }
				{ ...popperProps }
			>
				{ typeof children === 'string' ? (
					<Typography className={ styles.text }>{ children }</Typography>
				) : (
					children
				) }
			</Popover>
		</>
	);
};
