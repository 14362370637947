/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronLeftSquare = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<rect
			x='23.5'
			y='23.5'
			width='23'
			height='23'
			rx='2.5'
			transform='rotate(-180 23.5 23.5)'
			strokeLinejoin='round'
		/>
		<path d='M15.7657 18.7739C16.0492 18.4994 16.0755 18.0692 15.8442 17.7659L15.7671 17.6789L9.92492 11.9923L15.7663 6.32185C16.0495 6.04701 16.0753 5.61687 15.8437 5.31372L15.7665 5.22687C15.4835 4.95194 15.0404 4.92688 14.7282 5.15173L14.6388 5.22669L8.23366 11.4437C7.95072 11.7183 7.9247 12.148 8.15582 12.4512L8.23287 12.5381L14.638 18.7725C14.949 19.0753 15.4539 19.0759 15.7657 18.7739Z' />
	</svg>
);
export default ChevronLeftSquare;
