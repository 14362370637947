import { useEffect, useCallback, useState } from 'react';

export type UseLimitScrollOptions = {
	maxTop?: number;
	minTop?: number;
};

const useIntersection = (
	container: HTMLElement | null | undefined,
	options: IntersectionObserverInit
) => {
	const [ intersects, setIntersects ] = useState( false );
	const callback: IntersectionObserverCallback = useCallback( ( entries ) => {
		if ( entries.length ) {
			const entry = entries[ 0 ];
			setIntersects( ( prevState ) => {
				if ( entry.isIntersecting && !prevState ) {
					return true;
				} else if ( !entry.isIntersecting && prevState ) {
					return false;
				} else {
					return prevState;
				}
			} );
		}
	}, [] );
	useEffect( () => {
		if ( container ) {
			const observer = new IntersectionObserver( callback, options );
			observer.observe( container );
			return () => observer.disconnect();
		}
	}, [
		container,
		callback,
		options
	] );
	return {
		intersects,
	};
};

export default useIntersection;
