import { BundleStatusEnum } from '../../../types/generated';
import Globals from '../../Globals';
import { getCookie } from '../getCookie';
import { BundleCookie } from './bundleCookieType';
import { setDocumentOptionCookie } from './setDocumentOptionCookie';

/**
 * Updates a bundle object based on users cookies, the contactID passed, and the action taken.
 */
export const updateBundleCookieAfterSending = ( {
	contactID,
	contractSent,
	invoiceSent,
	proposalSent,
	eventDate,
	contractCustomers,
}: Omit<
BundleCookie,
'proposalBundleStatus' | 'contractBundleStatus' | 'invoiceBundleStatus'
> & {
	contractSent?: true;
	invoiceSent?: true;
	proposalSent?: true;
} ) => {
	// were we expecting a bundle
	const documentCookie = getCookie(
		`${ Globals.DocumentBundle }=`,
		document.cookie
	);
	if ( documentCookie ) {
		const cookieData: BundleCookie = JSON.parse( documentCookie );
		if ( cookieData && cookieData.contactID === contactID ) {
			setDocumentOptionCookie( {
				contactID,
				bundle: cookieData.bundle,
				contractBundleStatus: contractSent
					? BundleStatusEnum.Sent
					: cookieData.contractBundleStatus,
				invoiceBundleStatus: invoiceSent
					? BundleStatusEnum.Sent
					: cookieData.invoiceBundleStatus,
				proposalBundleStatus: proposalSent
					? BundleStatusEnum.Sent
					: cookieData.proposalBundleStatus,
				// if there is no eventDate yet in the cookie, but one was added
				...( !cookieData.eventDate && !!eventDate ? { eventDate } : {} ),
				...( contractCustomers ? { contractCustomers } : {} ),
			} );
		} else {
			/* We can only have one bundle cookie at a time, and sending a document on a
			different contact invalidates the bundle cookie */
			setDocumentOptionCookie();
		}
	}
};
