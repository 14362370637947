/* eslint max-len: 0 */
const AcceptConnection = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.8125 4.875C2.8125 2.28617 4.91117 0.1875 7.5 0.1875C10.0888 0.1875 12.1875 2.28617 12.1875 4.875C12.1875 7.46383 10.0888 9.5625 7.5 9.5625C4.91117 9.5625 2.8125 7.46383 2.8125 4.875ZM11.0625 4.875C11.0625 2.90749 9.46751 1.3125 7.5 1.3125C5.53249 1.3125 3.9375 2.90749 3.9375 4.875C3.9375 6.84251 5.53249 8.4375 7.5 8.4375C9.46751 8.4375 11.0625 6.84251 11.0625 4.875ZM10.6875 17.25C10.6875 13.6256 13.6256 10.6875 17.25 10.6875C20.8744 10.6875 23.8125 13.6256 23.8125 17.25C23.8125 20.8744 20.8744 23.8125 17.25 23.8125C13.6256 23.8125 10.6875 20.8744 10.6875 17.25ZM22.6875 17.25C22.6875 14.247 20.253 11.8125 17.25 11.8125C14.247 11.8125 11.8125 14.247 11.8125 17.25C11.8125 20.253 14.247 22.6875 17.25 22.6875C20.253 22.6875 22.6875 20.253 22.6875 17.25ZM17.25 13.6875C17.5348 13.6875 17.7701 13.8991 17.8074 14.1737L17.8125 14.25V16.6875H20.25C20.5607 16.6875 20.8125 16.9393 20.8125 17.25C20.8125 17.5348 20.6009 17.7701 20.3263 17.8074L20.25 17.8125H17.8125V20.25C17.8125 20.5607 17.5607 20.8125 17.25 20.8125C16.9652 20.8125 16.7299 20.6009 16.6926 20.3263L16.6875 20.25V17.8125H14.25C13.9393 17.8125 13.6875 17.5607 13.6875 17.25C13.6875 16.9652 13.8991 16.7299 14.1737 16.6926L14.25 16.6875H16.6875V14.25C16.6875 13.9393 16.9393 13.6875 17.25 13.6875ZM10.3705 10.5245C8.11155 9.56205 5.52043 9.79705 3.4715 11.1502C1.42258 12.5034 0.189173 14.7942 0.1875 17.2496C0.187289 17.5603 0.438957 17.8123 0.749617 17.8125C1.06028 17.8127 1.31229 17.561 1.3125 17.2504C1.31392 15.1726 2.35765 13.234 4.09148 12.089C5.82531 10.9439 8.01797 10.745 9.92951 11.5595C10.2153 11.6813 10.5457 11.5483 10.6675 11.2625C10.7893 10.9767 10.6563 10.6463 10.3705 10.5245Z'
		/>
	</svg>
);

export default AcceptConnection;
