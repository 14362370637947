/* eslint max-len: 0 */
const CircleClose = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M12 0c6.627 0 12 5.37 12 12 0 6.631-5.373 12-12 12S0 18.631 0 12C0 5.37 5.373 0 12 0zm0 1C5.934 1 1 5.94 1 12c0 6.071 4.934 11 11 11 6.065 0 11-4.929 11-11 0-6.06-4.935-11-11-11zM7.853 7.151l4.146 4.145 4.147-4.145c.196-.2.512-.2.707 0 .196.189.196.509 0 .699L12.703 12l4.15 4.151c.196.19.196.509 0 .699-.097.1-.225.15-.353.15-.128 0-.256-.05-.354-.15l-4.147-4.147-4.146 4.147c-.097.1-.225.15-.353.15-.128 0-.256-.05-.354-.15-.195-.19-.195-.509 0-.699L11.296 12l-4.15-4.15c-.195-.19-.195-.51 0-.699.196-.2.512-.2.707 0z'
			transform='translate(-144 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241)'
		/>
		<path
			d='M11.998 0c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zm0 1.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5 10.5-4.701 10.5-10.5-4.701-10.5-10.5-10.5zM7.943 6.897l.084.073 3.97 3.969 3.97-3.97c.293-.292.767-.292 1.06 0 .267.267.29.684.073.977l-.073.084L13.058 12l3.97 3.97c.293.294.293.768 0 1.061-.266.267-.683.29-.976.073l-.084-.073-3.971-3.969-3.969 3.97c-.293.292-.767.292-1.06 0-.267-.267-.29-.684-.073-.977l.073-.084L10.936 12l-3.97-3.97c-.292-.293-.292-.767 0-1.06.267-.267.684-.29.977-.073z'
			transform='translate(-144 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241)'
		/>
	</svg>
);

export default CircleClose;
