/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CheckCircleOutline = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2 11.75C2 6.36522 6.36522 2 11.75 2C17.1348 2 21.5 6.36522 21.5 11.75C21.5 17.1348 17.1348 21.5 11.75 21.5C6.36522 21.5 2 17.1348 2 11.75ZM20 11.75C20 7.19365 16.3063 3.5 11.75 3.5C7.19365 3.5 3.5 7.19365 3.5 11.75C3.5 16.3063 7.19365 20 11.75 20C16.3063 20 20 16.3063 20 11.75Z'
		/>
	</svg>
);

export default CheckCircleOutline;
