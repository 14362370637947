import { useEffect, useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { showError } from '../blocks/Toast';
import { FlattenedUser } from '../types/user';
import { useSelector } from 'react-redux';
import { UserType } from '../types/generated';
import {
	useOrgOnboardingModal_GetOrgUserQuery,
	useOrgOnboardingModal_UpdateOrgUserMutation,
} from './__generated__/useOrgOnboardingModal';
import { OrgOnboardingModalProps } from '../blocks/OrgOnboardingModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const orgOnboardingModal_GetOrgUser = gql`
	query orgOnboardingModal_GetOrgUser($where: OrgUserWhereUniqueInput!) {
		getOrgUserWhere(where: $where) {
			id
			seenOnboardingModalAt
			organization {
				id
				defaultOrgUser {
					id
				}
			}
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const orgOnboardingModal_UpdateOrgUser = gql`
	mutation orgOnboardingModal_UpdateOrgUser(
		$where: OrgUserWhereUniqueInput!
		$data: OrgUserUpdateCustomInput!
	) {
		updateOrgUser(where: $where, data: $data) {
			id
			seenOnboardingModalAt
		}
	}
`;

export const useOrgOnboardingModal = (): OrgOnboardingModalProps & {
	setOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
	isOrgOwner: boolean;
} => {
	const user: FlattenedUser = useSelector( ( state: any ) => state?.user );
	const [ open, setOpen ] = useState<undefined | boolean>();
	const [ isOrgOwner, setIsOrgOwner ] = useState( false );

	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;

	const [ updateOrgUser ] = useOrgOnboardingModal_UpdateOrgUserMutation();

	const apiResponse = useOrgOnboardingModal_GetOrgUserQuery( {
		variables: { where: { id: orgUser?.id } },
		skip: !orgUser?.id,
	} );

	useEffect( () => {
		const fetchAndUpdate = async () => {
			if ( apiResponse.error ) {
				showError( apiResponse.error );
			} else if ( apiResponse.data?.getOrgUserWhere?.organization ) {
				/* open the modal if
				- the org user hasn't seen it yet
				- and they are the org "owner", as defined as they are the defaultOrgUser for the organization */
				setOpen(
					!apiResponse.data.getOrgUserWhere.seenOnboardingModalAt &&
						apiResponse.data.getOrgUserWhere.organization.defaultOrgUser.id ===
							apiResponse.data.getOrgUserWhere.id
				);
				setIsOrgOwner(
					apiResponse.data.getOrgUserWhere.organization.defaultOrgUser.id ===
						apiResponse.data.getOrgUserWhere.id
				);
			}
		};
		// fetch if signed in, and we haven't already run this, so we don't immediately hide the modal after just showing it
		if ( orgUser && typeof open === 'undefined' ) {
			fetchAndUpdate();
		}
	}, [
		apiResponse,
		orgUser,
		open
	] );

	useEffect( () => {
		if ( open && orgUser ) {
			updateOrgUser( {
				variables: {
					where: { id: orgUser.id },
					data: { seenOnboardingModalAt: new Date() },
				},
			} );
		}
	}, [
		open,
		orgUser,
		updateOrgUser
	] );

	const onClose = useCallback( () => setOpen( false ), [] );

	return {
		open: !!open,
		onClose,
		setOpen,
		isOrgOwner,
	};
};
