import { SVGProps } from 'react';

/* eslint max-len: 0 */
const UploadFile = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 25' { ...props } xmlns='http://www.w3.org/2000/svg'>
		<path d='M20.5372 22.4156H3.44312C2.93226 22.4156 2.5 22.0226 2.5 21.5118V12.8665C2.5 12.3753 2.91262 11.9627 3.44312 11.9627C3.97363 11.9627 4.38625 12.3556 4.38625 12.8665V20.6276H19.6138V12.8665C19.6138 12.3753 20.0264 11.9627 20.5569 11.9627C21.0874 11.9627 21.5 12.3556 21.5 12.8665V21.5118C21.4607 22.003 21.0481 22.4156 20.5372 22.4156Z' />
		<path d='M16.3128 7.22741L12.6779 3.59246C12.3242 3.23878 11.7347 3.23878 11.3614 3.59246L7.68718 7.22741C7.51034 7.40424 7.4121 7.64003 7.4121 7.89545C7.4121 8.15088 7.51034 8.36702 7.68718 8.54385C7.86401 8.72069 8.0998 8.81893 8.33558 8.81893C8.59101 8.81893 8.82679 8.72069 9.00362 8.54385L11.0078 6.55936V15.8924C11.0078 16.4425 11.44 16.8748 11.9902 16.8748C12.5403 16.8748 12.9726 16.4425 12.9726 15.8924V6.52007L14.9964 8.54385C15.3501 8.89752 15.9395 8.89752 16.3128 8.54385C16.4897 8.36702 16.5879 8.13124 16.5879 7.89545C16.5879 7.64003 16.4897 7.40424 16.3128 7.22741Z' />
	</svg>
);

export default UploadFile;
