import React from 'react';

import { useRouter } from 'next/router';
import CustomMenuItemComponent, {
	CustomMenuItemType,
} from './CustomMenuItemComponent';

export default function CustomMenuItemContainer( {
	menuItem,
}: {
	menuItem: CustomMenuItemType;
} ) {
	const router = useRouter();
	const isActive = 'href' in menuItem ? menuItem.href === router.asPath : false;

	return (
		<CustomMenuItemComponent menuItem={ { ...menuItem, isActive: isActive } } />
	);
}
