import { SVGProps } from 'react';

/* eslint max-len: 0 */
const CreditCard = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path d='M16.286 15.1069C15.8718 15.1069 15.536 15.4427 15.536 15.8569C15.536 16.2711 15.8718 16.6069 16.286 16.6069H18.8574C19.2716 16.6069 19.6074 16.2711 19.6074 15.8569C19.6074 15.4427 19.2716 15.1069 18.8574 15.1069H16.286Z' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0.107422 5.57122V18.4284C0.107422 19.7894 1.21072 20.8926 2.57171 20.8926H21.4289C22.7898 20.8926 23.8931 19.7894 23.8931 18.4284V5.57122C23.8931 4.21023 22.7898 3.10693 21.4289 3.10693H2.57171C1.21072 3.10693 0.107422 4.21023 0.107422 5.57122ZM2.57171 4.60693C2.03915 4.60693 1.60742 5.03866 1.60742 5.57122V9.10693H22.3931V5.57122C22.3931 5.03866 21.9614 4.60693 21.4289 4.60693H2.57171ZM22.3931 18.4284V10.6069H1.60742V18.4284C1.60742 18.9609 2.03915 19.3926 2.57171 19.3926H21.4289C21.9614 19.3926 22.3931 18.9609 22.3931 18.4284Z'
		/>
	</svg>
);

export default CreditCard;
