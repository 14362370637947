/* eslint max-len: 0 */
const InvoiceReceived = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M18 0c1.19 0 2.166.925 2.245 2.096l.005.154v9.077l.568-.45c.41-.325.956-.41 1.44-.236l.144.06c.518.25.847.775.848 1.351v9.7c0 1.243-1.007 2.25-2.25 2.25H3c-1.243 0-2.25-1.007-2.25-2.25v-9.7c0-.576.33-1.1.848-1.35s1.133-.182 1.584.175l.568.451V2.25c0-1.19.925-2.166 2.096-2.245L6 0h12zm3.75 12.053l-4.817 3.819 2.347 2.348c.293.293.293.767 0 1.06-.266.267-.683.29-.976.073l-.084-.073-2.47-2.469-1.434 1.138c-1.303 1.023-3.114 1.066-4.462.126l-.173-.128-1.432-1.136-2.469 2.47c-.293.292-.767.292-1.06 0-.267-.267-.29-.684-.073-.977l.073-.084 2.346-2.348-4.816-3.819v9.699c0 .38.282.693.648.743l.102.007h18c.414 0 .75-.336.75-.75v-9.7zM18 1.5H6c-.38 0-.693.282-.743.648l-.007.102v10.267l5.36 4.252c.768.603 1.83.639 2.63.108l.147-.106 5.363-4.255V2.25c0-.38-.282-.693-.648-.743L18 1.5zM14.062 3c.38 0 .693.282.743.648l.007.102v.353c.672.154 1.278.538 1.705 1.1.25.329.187.8-.143 1.05-.3.228-.716.196-.977-.06l-.074-.083c-.289-.38-.743-.596-1.249-.58l-.012.001-.01-.001-.14.006c-.684.05-1.162.432-1.162.792 0 .36.478.743 1.162.793l.15.005c1.513 0 2.809.973 2.809 2.296 0 1.102-.9 1.961-2.06 2.218l.001.36c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L13.312 12v-.353c-.673-.153-1.28-.537-1.709-1.099-.25-.33-.187-.8.143-1.05.3-.229.715-.197.977.058l.074.084c.291.382.75.599 1.261.579.764 0 1.313-.412 1.313-.797 0-.36-.476-.741-1.16-.79l-.149-.006c-1.513 0-2.812-.975-2.812-2.298 0-1.103.902-1.963 2.061-2.219l.001-.359c0-.414.336-.75.75-.75zM9 9.75c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L9 11.25H7.5c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L7.5 9.75H9zm0-3c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L9 8.25H7.5c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L7.5 6.75H9zm0-3c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L9 5.25H7.5c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L7.5 3.75H9z'
			transform='translate(-344 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(200)'
		/>
	</svg>
);

export default InvoiceReceived;
