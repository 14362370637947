import { ApiResponseType } from '../../../../hooks/useMutation.js';
import Query from '../../../Query.js';
import { CreateProposalReturnFields } from '../../returnFields/proposals';
import { UpdateProposalData, UpdateProposalDataResponse } from '../types.js';

const updateProposal = async function(
	this: any,
	paramsData: UpdateProposalData,
	id: string
): Promise<ApiResponseType<UpdateProposalDataResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateProposal',
			params: {
				data: paramsData,
				where: {
					id,
				},
			},
			returnFields: CreateProposalReturnFields,
			excludeEnumParams: [ 'type' ],
			disableRemovingSemicolonsFromStringParams: true,
		} )
	);

	if ( errors ) return { errors };

	return data.data.updateProposal;
};

export default updateProposal;
