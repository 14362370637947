/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Expand = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		width='32'
		height='33'
		viewBox='0 0 32 33'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path
			d='M19.4277 13.0954L30.8563 1.19067'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M21.7139 1.19067H30.8567V10.7145'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.5711 13.0954L1.14258 1.19067'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.2854 1.19067H1.14258V10.7145'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M19.4277 20.2383L30.8563 32.143'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M21.7139 32.143H30.8567V22.6191'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.5711 20.2383L1.14258 32.143'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.2854 32.143H1.14258V22.6191'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Expand;
