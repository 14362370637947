/* eslint-disable indent */
import { Box } from '@mui/material';
import React from 'react';
import { StandardEnum } from '../../../lib/helpers/apollo';
import MuiButton from '../../MuiButton';
import styles from './MuiStepperButtonRow.module.scss';

export type MuiStepperButtonRowProps<T extends StandardEnum<string>> = {
	steps: T;
	activeStep: number;
	loading?: boolean;
	onNext?: React.MouseEventHandler<HTMLButtonElement>;
	onBack?: React.MouseEventHandler<HTMLButtonElement>;
};

const MuiStepperButtonRow = <T extends StandardEnum<string>>( {
	steps,
	activeStep,
	loading,
	onNext,
	onBack,
}: MuiStepperButtonRowProps<T> ) => (
	<Box className={ styles.buttonRow }>
		{ activeStep === 0 ? null : (
			<MuiButton
				loading={ loading }
				onClick={ onBack }
				variant='text'
				color='secondary'
				data-cy='stepper-back'
			>
				Back
			</MuiButton>
		) }
		{ onNext ? (
			<>
				<Box />
				<MuiButton
					loading={ loading }
					onClick={ onNext }
					variant='text'
					data-cy='stepper-next'
				>
					{ activeStep === Object.keys( steps ).length - 1 ? <>Done</> : <>Next</> }
				</MuiButton>
			</>
		) : null }
	</Box>
);

export default MuiStepperButtonRow;
