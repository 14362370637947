/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Calendar = ( props: SVGProps<SVGSVGElement> ) => (
	<svg { ...props } viewBox='0 0 24 24' { ...props }>
		<path
			d='M17.25 0c.38 0 .693.282.743.648L18 .75V3h5.25c.38 0 .693.282.743.648L24 3.75v19.5c0 .414-.336.75-.75.75H.75c-.414 0-.75-.336-.75-.75V3.75C0 3.336.336 3 .75 3H6V.75c0-.414.336-.75.75-.75.38 0 .693.282.743.648L7.5.75V3h9V.75c0-.414.336-.75.75-.75zm5.25 10.5h-21v12h21v-12zM5.625 18l.123.007c.563.06 1.002.538 1.002 1.118 0 .621-.504 1.125-1.125 1.125S4.5 19.746 4.5 19.125c0-.58.439-1.057 1.002-1.118L5.625 18zM12 18l.123.007c.563.06 1.002.538 1.002 1.118 0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125c0-.58.439-1.057 1.002-1.118L12 18zm6.375 0l.123.007c.563.06 1.002.538 1.002 1.118 0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125c0-.58.439-1.057 1.002-1.118l.123-.007zm-12.75-5.25l.123.007c.563.06 1.002.538 1.002 1.118 0 .621-.504 1.125-1.125 1.125S4.5 14.496 4.5 13.875c0-.58.439-1.057 1.002-1.118l.123-.007zm6.375 0l.123.007c.563.06 1.002.538 1.002 1.118 0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125c0-.58.439-1.057 1.002-1.118L12 12.75zm6.375 0l.123.007c.563.06 1.002.538 1.002 1.118 0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125c0-.58.439-1.057 1.002-1.118l.123-.007zM6 4.5H1.5V9h21V4.5H18V6c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L16.5 6V4.5h-9V6c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L6 6V4.5z'
			transform='translate(-184 -1020) translate(144 183) translate(0 783) translate(0 53) translate(40 1)'
		/>
	</svg>
);

export default Calendar;
