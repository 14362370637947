import React, {
	useState,
	useEffect,
	useLayoutEffect,
	useCallback,
} from 'react';
import styled from 'styled-components';
import Router from 'next/router';

// Utils
import { getIntercom } from '../../lib/helpers/segment';

const Container = styled.div`
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
	pointer-events: none;
	opacity: 0;
	display: none;
`;

const Intercom = ( props ) => {
	const [ route, setRoute ] = useState( null );
	const [ isLoaded, setIsLoaded ] = useState( false );

	/**
	 * Gets an array of launcher elements, and prunes out the ones it can't find
	 * @returns { Array< Element | HTMLElement > }
	 */
	const getLauncherElements = () =>
		[
			document.querySelector( '.intercom-launcher' ),
			document.querySelector( '.intercom-messenger-frame' ),
			document.querySelector( '.intercom-launcher-frame' )?.parentElement,
		].reduce( ( acc, element ) => ( element ? [ ...acc, element ] : acc ), [] );

	/**
	 * @param {Element | HTMLElement } element
	 * @param {boolean} isVisible
	 * @returns
	 */
	const setVisibility = ( element, isVisible ) => {
		if ( !isVisible ) {
			return 'none';
		} else {
			if ( element?.classList.contains( 'intercom-messenger-frame' ) ) {
				return '';
			} else {
				return 'block';
			}
		}
	};

	const visibilityChangeHandler = useCallback(
		/**
		 *
		 * @param {boolean} isVisible
		 */
		( isHidden ) => {
			if ( isLoaded ) {
				getLauncherElements().forEach(
					( element ) =>
						element &&
						( element.style.display = setVisibility( element, !isHidden ) )
				);
			}
		},
		[ isLoaded ]
	);

	useEffect( () => {
		visibilityChangeHandler( props.hide );
	}, [ visibilityChangeHandler, props.hide ] );

	const isPaddedRoute = () => {
		const currentRoute = Router.router.pathname;
		if (
			currentRoute &&
			( currentRoute.indexOf( '/document/' ) > -1 ||
				currentRoute.indexOf( '/contract/' ) > -1 ||
				currentRoute.indexOf( '/invoice/' ) > -1 ||
				currentRoute.indexOf( '/proposal/' ) > -1 ||
				currentRoute.indexOf( '/resources/' ) > -1 ||
				currentRoute.indexOf( '/edit' ) > -1 ||
				currentRoute.indexOf( '/template/' > -1 ) > -1 )
		) {
			return true;
		}
		return false;
	};

	/**
	 * Get the padding for the intercom widget based on if the route requires it
	 * @param { Element | HTMLElement } element
	 */
	const getPadding = useCallback( ( element ) => {
		if ( isPaddedRoute( Router.router.pathname ) ) {
			if ( element?.classList.contains( 'intercom-messenger-frame' ) ) {
				return '160px';
			} else if ( element?.classList.contains( 'intercom-launcher' ) ) {
				return '100px';
			} else {
				return '100px';
			}
		} else {
			if ( element?.classList.contains( 'intercom-messenger-frame' ) ) {
				return '';
			} else if ( element?.classList.contains( 'intercom-launcher' ) ) {
				return '32px';
			} else {
				return '';
			}
		}
	}, [] );

	const isHiddenRoute = () => {
		if ( Router.router.pathname === '/timelines' ) {
			return true;
		} else {
			return false;
		}
	};

	const setDisplay = useCallback(
		/**
		 * Show or hide the intercom widget based on if the route requires it
		 * @param { Element | HTMLElement } element
		 */
		( element ) => setVisibility( element, !isHiddenRoute( Router.router.pathname ) ),
		[]
	);

	const setPosition = useCallback( () => {
		setTimeout( () => {
			getLauncherElements().forEach(
				( element ) => element && ( element.style.bottom = getPadding( element ) )
			);
		}, 1000 );
	}, [ getPadding ] );

	useEffect( () => {
		// eslint-disable-next-line no-floating-promise/no-floating-promise
		( async () => {
			await getIntercom();

			if ( window.Intercom ) {
				window.Intercom( 'onHide', () => {
					setPosition();
				} );

				/* This is so other bits of this code can know this is loaded, but wait for the next render loop so
				padding introduced here doesn't overwrite what's set lower in this component */
				setTimeout( () => setIsLoaded( true ), 0 );
			}
		} )();
	}, [ setPosition ] );

	useEffect( () => {
		if ( props.currentRoute !== route || !route ) {
			setPosition();
			setRoute( props.currentRoute );
		}
	}, [
		props.currentRoute,
		route,
		setPosition
	] );

	/* Hide the intercom widget on certain routes (currently only /timelines), but only if it's
	loaded (including on initial page load) */
	useLayoutEffect( () => {
		if ( isLoaded ) {
			getLauncherElements().forEach(
				( element ) => element && ( element.style.display = setDisplay( element ) )
			);
		}
	}, [ isLoaded, setDisplay ] );

	return <Container />;
};

export default Intercom;
