import * as Types from '../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventRow_DismissEventMutationVariables = Types.Exact<{
  where: Types.EventWhereUniqueInput;
}>;


export type EventRow_DismissEventMutation = { __typename?: 'Mutation', dismissEvent: boolean };


export const EventRow_DismissEventDocument = gql`
    mutation EventRow_DismissEvent($where: EventWhereUniqueInput!) {
  dismissEvent(where: $where)
}
    `;
export type EventRow_DismissEventMutationFn = Apollo.MutationFunction<EventRow_DismissEventMutation, EventRow_DismissEventMutationVariables>;

/**
 * __useEventRow_DismissEventMutation__
 *
 * To run a mutation, you first call `useEventRow_DismissEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventRow_DismissEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventRowDismissEventMutation, { data, loading, error }] = useEventRow_DismissEventMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventRow_DismissEventMutation(baseOptions?: Apollo.MutationHookOptions<EventRow_DismissEventMutation, EventRow_DismissEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EventRow_DismissEventMutation, EventRow_DismissEventMutationVariables>(EventRow_DismissEventDocument, options);
      }
export type EventRow_DismissEventMutationHookResult = ReturnType<typeof useEventRow_DismissEventMutation>;
export type EventRow_DismissEventMutationResult = Apollo.MutationResult<EventRow_DismissEventMutation>;
export type EventRow_DismissEventMutationOptions = Apollo.BaseMutationOptions<EventRow_DismissEventMutation, EventRow_DismissEventMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    