import { Typography } from '@mui/material';
import { FlattenedUser } from '../../../types/user/index';

// Utils
import {
	getDetailLink,
	linkObject,
	getIconTypeForDocument,
} from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';
import { EventCategory, UserType } from '../../../types/generated';
import { EventItem } from '../helpers';

const documentEvents = (
	event?: { category: EventCategory; [key: string]: any },
	user?: FlattenedUser
) => {
	if ( !event || !user ) {
		return {};
	}
	const { document } = event;
	// artificially make the user getDetailLink wants
	const {
		orgUser: customerOrgUser,
		clientUser: customerClientUser,
		...rest
	} = document.customer;
	const customer = {
		...( customerOrgUser || customerClientUser ),
		user: rest,
	};
	const vendor = document.organization;

	// Use for comparison between dashboard or user detail pages
	const documentLink = getDetailLink( {
		object: document,
		type: LinkObjectType.Document,
	} );

	const authenticatedUser = user.isLoggedIn ? user : undefined;
	const orgUser =
		user.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;

	let eventObj: EventItem = {};

	switch ( event.category ) {
		case EventCategory.DocumentFileReplaced:
			// Add your condition
			eventObj = {
				icon: getIconTypeForDocument( document ),
				links: [
					{
						type: event.resolvedBy ? 'secondary' : 'primary',
						text: 'View',
						link: event.resolvedBy ? documentLink : undefined,
					},
				],
			};
			break;
		case EventCategory.DocumentSharedWithCustomer:
			if (
				authenticatedUser?.user.id === document.customer?.id ||
				authenticatedUser?.user.id === event.contact?.customer?.id
			) {
				eventObj = {
					icon: getIconTypeForDocument( document ),
					message: (
						<Typography>
							{ linkObject( vendor ) } shared a document with you:{ ' ' }
							{ linkObject( document ) }
						</Typography>
					),
					links: [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: 'View',
							link: event.resolvedBy ? undefined : documentLink,
						},
					],
				};
			}
			if (
				typeof event.customer?.clientUser?.assignedPlanner?.id === 'string' &&
				typeof orgUser?.id === 'string' &&
				( event.customer?.clientUser?.assignedPlanner?.id === orgUser?.id ||
					( event.customer?.clientUser?.assignedPlanner?.organization?.id ===
						orgUser?.organization?.id &&
						orgUser?.isAdmin ) )
			) {
				eventObj = {
					icon: getIconTypeForDocument( document ),
					message: (
						<Typography>
							{ linkObject( vendor ) } shared a document with { linkObject( customer ) }
							: { linkObject( document ) }
						</Typography>
					),
					links: [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: 'View',
							link: event.resolvedBy ? undefined : documentLink,
						},
					],
				};
			}
			break;
		case EventCategory.DocumentSharedWithOrg:
			if ( orgUser ) {
				eventObj = {
					icon: getIconTypeForDocument( document ),
					links: [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: 'View',
							link: event.resolvedBy ? undefined : documentLink,
						},
					],
				};
				if ( !vendor ) {
					// if it's a client owned document
					eventObj.message = (
						<Typography>
							{ linkObject( document ) } was shared with you by{ ' ' }
							{ linkObject( customer ) }.
						</Typography>
					);
				} else if ( vendor?.id !== orgUser.organization.id ) {
					// if it's shared with a different organization
					eventObj.message = (
						<Typography>
							{ linkObject( document ) } was shared with you by { linkObject( vendor ) }{ ' ' }
							for your shared client { linkObject( customer ) }.
						</Typography>
					);
					// If the event is not about the users organization.
				} else {
					eventObj.message = (
						<Typography>
							{ linkObject( document ) } was shared with { linkObject( vendor ) } for
							your shared client { linkObject( customer ) }.
						</Typography>
					);
				}
			}
			break;
		case EventCategory.DocumentSharedWithOrgByPlannerObo:
			if ( orgUser ) {
				eventObj = {
					icon: getIconTypeForDocument( document ),
					message: (
						<Typography>
							{ linkObject( document ) } was shared with { linkObject( vendor ) }.
						</Typography>
					),
					links: [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: 'View',
							link: event.resolvedBy ? undefined : documentLink,
						},
					],
				};
			}
			if ( orgUser && orgUser.organization.id === vendor.id ) {
				eventObj = {
					icon: getIconTypeForDocument( document ),
					message: (
						<Typography>
							{ linkObject( event.clientUser.assignedPlanner.organization ) } has
							shared { linkObject( document ) } with you on behalf of{ ' ' }
							{ linkObject( customer ) }.
						</Typography>
					),
					links: [
						{
							type: event.resolvedBy ? 'secondary' : 'primary',
							text: 'View',
							link: event.resolvedBy ? undefined : documentLink,
						},
					],
				};
			}
			break;
		default:
			break;
	}

	return eventObj;
};

export default documentEvents;
