import React, { SVGProps } from 'react';
const InvoiceDue = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M18.107 12.214c3.255 0 5.893 2.639 5.893 5.893C24 21.362 21.362 24 18.107 24c-3.254 0-5.893-2.638-5.893-5.893 0-3.254 2.639-5.893 5.893-5.893zM12.612 0h.038l.06.004.097.004c.517.04 1.008.244 1.403.58l5.426 6.416c.415.416.667.965.713 1.546l.008.195v2.29c0 .415-.336.75-.75.75-.38 0-.693-.281-.743-.648l-.007-.101V9H14.15c-1.19 0-2.166-.925-2.245-2.096L11.9 6.75V1.5H2.464c-.494 0-.902.372-.958.852l-.006.112v18.857c0 .495.372.903.852.958l.112.007h6.857c.415 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H2.464c-1.306 0-2.375-1.017-2.459-2.303L0 21.321V2.464C0 1.158 1.017.09 2.302.005L2.464 0h10.148zm5.495 13.714c-2.426 0-4.393 1.967-4.393 4.393 0 2.426 1.967 4.393 4.393 4.393 2.426 0 4.393-1.967 4.393-4.393 0-2.426-1.967-4.393-4.393-4.393zM9.113 18.75c.414 0 .737.336.737.75 0 .38-.27.693-.635.743l-.102.007H5.25c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h3.863zm8.994-3.964c.38 0 .694.282.743.648l.007.102v1.821h1.822c.414 0 .75.336.75.75 0 .38-.283.694-.649.743l-.101.007H18l-.083-.007h-.06c-.276 0-.5-.224-.5-.5v-2.814c0-.414.336-.75.75-.75zM6.562 4.5c.38 0 .693.282.743.648l.007.102v.353c.672.154 1.278.538 1.705 1.1.25.329.187.8-.143 1.05-.3.228-.716.196-.977-.06l-.074-.083c-.29-.383-.75-.6-1.261-.579-.764 0-1.312.411-1.312.797 0 .36.478.743 1.162.793l.15.005c1.513 0 2.809.973 2.809 2.296 0 1.102-.9 1.961-2.06 2.218l.001.36c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648l-.007-.102v-.353c-.673-.153-1.28-.537-1.709-1.099-.25-.33-.187-.8.143-1.05.3-.229.715-.197.977.058l.074.084c.29.38.746.596 1.253.58l.012-.001h.007l.139-.005c.685-.05 1.163-.432 1.163-.792 0-.36-.476-.741-1.16-.79l-.149-.006c-1.513 0-2.812-.975-2.812-2.298 0-1.103.902-1.963 2.061-2.219l.001-.359c0-.414.336-.75.75-.75zM13.4 1.91v4.84c0 .38.282.693.648.743l.102.007h3.951L13.4 1.91z'
			transform='translate(-464 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(320)'
		/>
	</svg>
);

export default InvoiceDue;
