import {
	Box,
	Step,
	StepButton,
	StepContent,
	StepLabel,
	StepLabelProps,
	Stepper,
	StepperProps,
	StepProps,
} from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import useElementSizeObserver from '../../hooks/useElementSizeObserver';
import { StandardEnum } from '../../lib/helpers/apollo';
import styles from './MuiStepper.module.scss';
import MuiStepperButtonRow, {
	MuiStepperButtonRowProps,
} from './MuiStepperButtonRow';

export type MuiStepperProps<T extends StandardEnum<string>> = {
	steps: T;
	labels: Record<T[keyof T], string>;
	activeStep: number;
	setActiveStep?: ( step: T[keyof T] ) => void; // if this is provided it lets the user jump anywhere in the flow
	stepProps?: StepProps;
	stepLabelProps?: StepLabelProps;
	stepChildrenClassName?: string;
} & Omit<StepperProps, 'orientation'> &
Pick<MuiStepperButtonRowProps<T>, 'loading' | 'onNext' | 'onBack'>;

/**
 * A reusable stepper component
 * The children of this component should only contain the "active" step's content
 */
const MuiStepper = <T extends StandardEnum<string>>( {
	steps,
	labels,
	activeStep,
	setActiveStep,
	loading,
	onNext,
	onBack,
	stepProps,
	stepLabelProps,
	stepChildrenClassName,
	children,
	...stepperProps
}: MuiStepperProps<T> ) => {
	const [ minHeight, setMinHeight ] = useState<number | undefined>();
	const { isMobile } = useDeviceDetection();
	const orientation: StepperProps['orientation'] = isMobile
		? 'vertical'
		: 'horizontal';
	/* To avoid the back/next buttons shifting due to validation errors, set
	the min-height of the children's container to the height of the
	children + 1.4rem, but only when the active step changes. */
	const { height, ref } = useElementSizeObserver();
	const minHeightNeedsToBeSet = useRef( true );
	/* useEffect fired before useLayoutEffect. And make sure that changing
	orientation re-calculates things. Of course if you change orientations
	with an existing validation error you may get a larger min-height than
	desired, but it's better than nothing. */
	useEffect( () => {
		minHeightNeedsToBeSet.current = true;
		setMinHeight( undefined );
	}, [ activeStep, orientation ] );
	useLayoutEffect( () => {
		if ( minHeightNeedsToBeSet.current ) {
			minHeightNeedsToBeSet.current = false;
			setMinHeight( height );
		}
	}, [
		orientation,
		height,
		activeStep
	] );
	const getMinHeightForChildren = () =>
		minHeight ? `calc( ${ minHeight }px + 1.4rem )` : undefined;
	const getMinHeightForContainer = () =>
		minHeight
			? orientation === 'horizontal'
				? `calc( ${ minHeight }px + 5.75rem )`
				: `calc( ${ minHeight }px + 14.75rem )`
			: undefined;
	return (
		<Box sx={ { minHeight: getMinHeightForContainer() } }>
			<Stepper
				activeStep={ activeStep }
				orientation={ orientation }
				nonLinear={ !!setActiveStep }
				{ ...stepperProps }
			>
				{ Object.values( steps ).map( ( key, index ) => (
					<Step key={ key }>
						{ setActiveStep ? (
							<StepButton onClick={ () => setActiveStep( key as T[keyof T] ) }>
								{ labels[ key as T[keyof T] ] }
							</StepButton>
						) : (
							<StepLabel>{ labels[ key as T[keyof T] ] }</StepLabel>
						) }
						{ orientation === 'vertical' && index === activeStep ? (
							<StepContent>
								<Box sx={ { minHeight: getMinHeightForChildren() } }>
									<Box
										className={ classNames(
											styles.children,
											stepChildrenClassName
										) }
										ref={ ref }
									>
										{ children }
									</Box>
								</Box>
								<MuiStepperButtonRow
									steps={ steps }
									activeStep={ activeStep }
									loading={ loading }
									onNext={ onNext }
									onBack={ onBack }
								/>
							</StepContent>
						) : null }
					</Step>
				) ) }
			</Stepper>
			{ orientation === 'horizontal' ? (
				<>
					<Box sx={ { minHeight: getMinHeightForChildren() } }>
						<Box
							className={ classNames( styles.children, stepChildrenClassName ) }
							ref={ ref }
						>
							{ children }
						</Box>
					</Box>
					<MuiStepperButtonRow
						steps={ steps }
						activeStep={ activeStep }
						loading={ loading }
						onNext={ onNext }
						onBack={ onBack }
					/>
				</>
			) : null }
		</Box>
	);
};

export default MuiStepper;
