/* eslint max-len: 0 */
const DisableAutopay = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M21.446 10.894l.084.073 2.25 2.25c.293.293.293.767 0 1.06-.266.267-.683.29-.976.073l-.084-.073-1.022-1.022c-.376 3.628-2.76 6.78-6.219 8.1-3.776 1.443-8.05.406-10.745-2.606-.276-.309-.25-.783.059-1.059.309-.276.783-.25 1.059.059 2.28 2.549 5.897 3.426 9.092 2.206 2.835-1.084 4.817-3.62 5.227-6.567l-.89.89c-.267.266-.684.29-.977.072l-.084-.073c-.267-.266-.29-.683-.073-.976l.073-.084 2.25-2.25c.266-.267.683-.29.976-.073zM12 6.25c1.598 0 2.904 1.249 2.995 2.824l.005.173h.75c.38 0 .693.282.743.648l.007.102v6c0 .414-.336.75-.75.75h-7.5c-.414 0-.75-.336-.75-.75v-6c0-.414.336-.75.75-.75H9l.005-.173C9.096 7.499 10.402 6.25 12 6.25zm3 4.497l-.706.002-.044.001-.044-.003-4.412.002-.044.001-.044-.003H9v4.5h6v-4.5zm-3 1.125c.621 0 1.125.504 1.125 1.125s-.504 1.125-1.125 1.125-1.125-.504-1.125-1.125c0-.58.439-1.057 1.002-1.118l.123-.007zm7.266-6.126c.276.309.25.783-.059 1.059-.309.276-.783.25-1.059-.059-2.28-2.549-5.897-3.426-9.092-2.205C6.22 5.624 4.239 8.16 3.829 11.107l.89-.89c.267-.267.684-.29.977-.073l.084.073c.267.266.29.683.073.976l-.073.084-2.228 2.23c-.137.15-.333.243-.552.243-.219 0-.415-.094-.552-.243L.22 11.277c-.293-.293-.293-.767 0-1.06.266-.267.683-.29.976-.073l.084.073 1.022 1.021c.376-3.626 2.76-6.777 6.218-8.099 3.776-1.442 8.05-.405 10.746 2.607zM12 7.75c-.78 0-1.42.595-1.493 1.356l-.007.14h3l-.007-.14C13.42 8.345 12.78 7.75 12 7.75z'
			transform='translate(-304 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(160)'
		/>
	</svg>
);

export default DisableAutopay;
