import { ApiResponseType } from '../../../../hooks/useMutation';
import Query from '../../../Query.js';
import { DeleteProposalTemplateFields } from '../../returnFields/proposals';
import { DeleteProposalTemplateResponse } from '../types';

const deleteProposalTemplate = async function(
	this: any,
	id: string
): Promise<ApiResponseType<DeleteProposalTemplateResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'deleteProposalTemplate',
			params: {
				where: { id },
			},
			returnFields: DeleteProposalTemplateFields,
		} )
	);

	if ( errors ) return { errors };

	return data.data.deleteProposalTemplate;
};

export default deleteProposalTemplate;
