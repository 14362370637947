/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Home = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M11.553 2.401L2.85 8.961c-.38.346-.574.757-.599 1.196L2.25 20.215c.003.846.686 1.53 1.53 1.533H9c.414 0 .75-.336.75-.75V16.8c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25V21c0 .414.336.75.75.75h5.217c.847-.003 1.53-.685 1.533-1.53V10.2c-.026-.48-.22-.892-.543-1.19L12.456 2.4c-.268-.201-.636-.201-.903 0zM3.81 10.11l8.192-6.172 8.244 6.22h-.009l.013.083-.001 9.977-.005.015c-.006.01-.017.017-.031.017H15.75V16.8c0-2.07-1.679-3.749-3.75-3.749l-.2.005c-1.978.104-3.55 1.74-3.55 3.745v3.446H3.782c-.016 0-.032-.015-.032-.034V10.199c.002-.008.023-.055.06-.09z'
			transform='translate(-1082 -558) translate(170 138) translate(0 251) translate(912) translate(0 168) translate(0 1)'
		/>
	</svg>
);

export default Home;
