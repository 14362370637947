/* eslint max-len: 0 */
const Phone = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<g fillRule='evenodd'>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path
									d='M17.576.997L14.39 4.184c-.996.998-.996 2.614 0 3.612l.086.094c.137.164.224.404.224.652 0 .28-.111.548-.309.745L9.288 14.39c-.38.38-.98.409-1.393.087l-.245-.222c-1.036-.864-2.53-.801-3.466.134L.998 17.576C0 18.574 0 20.19.998 21.188l.758.758.188.18c1.983 1.822 4.924 2.145 7.253.796l.142-.085c.032-.015.063-.032.093-.05 2.268-1.443 4.37-3.13 6.271-5.03l2.048-2.048c1.902-1.902 3.59-4.006 5.035-6.275l.05-.093.003-.01-.053.09c1.551-2.436 1.202-5.623-.84-7.665l-.759-.759c-.997-.996-2.613-.996-3.61 0zm2.55 1.061l.759.758.16.17c1.402 1.547 1.607 3.852.475 5.63l-.045.08.045-.067c-1.385 2.176-3.005 4.195-4.83 6.019l-2.047 2.048-.553.54c-1.49 1.426-3.1 2.718-4.815 3.863l-.592.384-.068.038c-1.843 1.173-4.254.909-5.799-.636l-.758-.758c-.41-.411-.41-1.079 0-1.49l3.187-3.187c.386-.385 1.003-.412 1.419-.065l.096.089c.973.973 2.59.973 3.588-.023l5.102-5.103c.48-.478.75-1.128.75-1.806 0-.601-.213-1.182-.598-1.642l-.13-.142c-.433-.434-.433-1.102-.022-1.513l3.187-3.187c.38-.38.977-.408 1.39-.087l.1.087z'
									transform='translate(-544 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) matrix(-1 0 0 1 424 0)'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Phone;
