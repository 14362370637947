import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FileTable = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M2.25 3c0-.966.784-1.75 1.75-1.75h12.903c.463 0 .907.183 1.235.51l3.096 3.082c.33.328.516.775.516 1.24V21A1.75 1.75 0 0 1 20 22.75H4A1.75 1.75 0 0 1 2.25 21V3ZM4 2.75a.25.25 0 0 0-.25.25v18c0 .138.112.25.25.25h16a.25.25 0 0 0 .25-.25V6.082a.25.25 0 0 0-.074-.177L17.08 2.823a.25.25 0 0 0-.176-.073H4ZM5.25 9A.75.75 0 0 1 6 8.25h12a.75.75 0 0 1 .75.75v10a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V9Zm1.5 4.75v1.5h3.5v-1.5h-3.5Zm0 3v1.5h3.5v-1.5h-3.5Zm5 1.5h5.5v-1.5h-5.5v1.5Zm5.5-3v-1.5h-5.5v1.5h5.5Zm0-3H6.75v-2.5h10.5v2.5Z'
		/>
	</svg>
);

export default FileTable;
