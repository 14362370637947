/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Download = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M23.25 22.498c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h22.5zM12-.002c.38 0 .693.282.743.648l.007.102v16.189l2.47-2.47c.266-.266.683-.29.976-.072l.084.073c.267.266.29.683.073.976l-.073.084-3.75 3.75c-.025.026-.052.05-.081.071l.081-.07c-.037.036-.077.069-.119.096-.02.014-.041.026-.063.037l-.026.014c-.027.012-.055.024-.085.034l-.019.006c-.068.02-.142.032-.218.032-.038 0-.076-.003-.113-.008l-.035-.007c-.025-.005-.05-.01-.073-.018l-.036-.012-.067-.028-.036-.019c-.026-.014-.05-.03-.074-.046l-.012-.01c-.033-.024-.06-.047-.084-.072l-3.75-3.75c-.293-.293-.293-.767 0-1.06.266-.267.683-.29.976-.073l.084.073 2.47 2.469V.748c0-.414.336-.75.75-.75z'
			transform='translate(-501 -241) translate(72 56) translate(413 169) translate(16 16)'
		/>
	</svg>
);

export default Download;
