import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Globals from '../lib/Globals';

export default function withPortal( WrappedComponent ) {
	return class extends Component {
		constructor( props ) {
			super( props );
			this.state = {
				safeToRender: false,
			};
		}

		componentDidMount() {
			this.setState( { safeToRender: true } );
		}

		render() {
			if ( !this.state.safeToRender ) {
				return <></>;
			} else {
				const appRoot = document.getElementById( Globals.rootPortalId );
				return ReactDOM.createPortal(
					<WrappedComponent { ...this.props } />,
					appRoot
				);
			}
		}
	};
}
