const Vendor = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='48'
		height='48'
		viewBox='0 0 48 48'
	>
		{ /* eslint-disable-next-line max-len */ }
		<path
			d='M16.5 7.907c.621 0 1.125.504 1.125 1.125v6c0 .621-.504 1.125-1.125 1.125l-4.875-.001v2.219h8.663c2.663.09 4.772 2.244 4.838 4.845l-.001 7.155H43.5c2.194 0 3.988 1.713 4.117 3.874l.008.251v9c0 .621-.504 1.125-1.125 1.125-.57 0-1.04-.423-1.115-.972l-.01-.153v-9c0-.97-.738-1.77-1.683-1.865l-.192-.01h-39c-.97 0-1.77.738-1.865 1.683l-.01.192v9c0 .621-.504 1.125-1.125 1.125-.57 0-1.04-.423-1.115-.972l-.01-.153v-9c0-2.194 1.713-3.988 3.874-4.117l.251-.008h.375v-6.84c-.082-2.635 1.887-4.859 4.5-5.134v-2.245l-4.875.001c-.58 0-1.057-.439-1.118-1.002l-.007-.123v-6c0-.621.504-1.125 1.125-1.125zm-6.016 12.717h-.697c-1.443.048-2.59 1.197-2.66 2.645l-.002.231v6.875h15.75v-7.082c-.041-1.367-1.083-2.496-2.405-2.651l-.22-.017H10.5l-.016-.001zM15 24.375c.621 0 1.125.504 1.125 1.125 0 .57-.423 1.04-.972 1.115l-.153.01h-3c-.621 0-1.125-.504-1.125-1.125 0-.57.423-1.04.972-1.115l.153-.01h3zm23.362-8.236c3.791 1.564 6.264 5.26 6.263 9.361 0 .622-.504 1.125-1.125 1.125-.622 0-1.125-.504-1.125-1.125 0-3.19-1.922-6.065-4.87-7.282-2.95-1.216-6.34-.535-8.59 1.727-.437.44-1.15.443-1.59.005-.44-.438-.443-1.15-.005-1.591 2.892-2.908 7.252-3.785 11.042-2.22zM34.5.375c3.935 0 7.125 3.19 7.125 7.125s-3.19 7.125-7.125 7.125-7.125-3.19-7.125-7.125S30.565.375 34.5.375zm-19.125 9.781h-9.75v3.75h4.612c.085-.02.173-.031.263-.031.09 0 .176.01.26.03l4.615.002v-3.75zM34.5 2.625c-2.692 0-4.875 2.183-4.875 4.875s2.183 4.875 4.875 4.875 4.875-2.183 4.875-4.875-2.183-4.875-4.875-4.875z'
			transform='translate(-472 -543) translate(376 385) translate(0 103) translate(50 55) translate(46) translate(0 1.5)'
		/>
	</svg>
);

export default Vendor;
