enum EnvironmentBackendVariableNames {
	API_URL = 'API_URL',
	LRS_URL = 'LRS_URL',
}

export enum EndpointNames {
	Query = '/graphql',
	DeleteFile = '/delete',
	UploadFile = '/upload',
}

// if we're in cypress-land, we have to get env vars from an object it attaches to the window
declare global {
	interface Window {
		Cypress?: {
			env: ( key: string ) => string;
		};
	}
}
const endpointValues: Record<
EnvironmentBackendVariableNames,
string | undefined
> = {
	[ EnvironmentBackendVariableNames.API_URL ]:
		process.env.API_URL || process.env.NEXT_PUBLIC_API_URL,
	[ EnvironmentBackendVariableNames.LRS_URL ]:
		process.env.LRS_URL || process.env.NEXT_PUBLIC_LRS_URL,
};

// Jest has no concept of the window object, so we need to check that window is declared before trying to access it.
if ( typeof window !== 'undefined' ) {
	endpointValues[ EnvironmentBackendVariableNames.API_URL ] =
		process.env.API_URL ||
		process.env.NEXT_PUBLIC_API_URL ||
		window?.Cypress?.env( 'API_URL' );
	endpointValues[ EnvironmentBackendVariableNames.LRS_URL ] =
		process.env.LRS_URL ||
		process.env.NEXT_PUBLIC_LRS_URL ||
		window?.Cypress?.env( 'LRS_URL' );
}

const mapping: Record<EndpointNames, EnvironmentBackendVariableNames> = {
	[ EndpointNames.Query ]: EnvironmentBackendVariableNames.API_URL,
	[ EndpointNames.UploadFile ]: EnvironmentBackendVariableNames.API_URL,
	[ EndpointNames.DeleteFile ]: EnvironmentBackendVariableNames.API_URL,
};

const getBackendUrl = ( endpoint: EndpointNames ): string | never => {
	const value = endpointValues[ mapping[ endpoint ] ];
	if ( value ) {
		return `${ value }${ endpoint }`;
	} else {
		throw new Error(
			`Environment variable ${ endpoint } not found at ${ mapping[ endpoint ] }`
		);
	}
};

export default getBackendUrl;
