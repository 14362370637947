/* eslint max-len: 0 */
const Trash = ( props ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M14.25 2.25c1.243 0 2.25 1.007 2.25 2.25v.75h5.25c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H19.5V19.5c0 1.19-.925 2.166-2.096 2.245l-.154.005H6.75c-1.243 0-2.25-1.007-2.25-2.25V6.75H2.25c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007H7.5V4.5c0-1.19.925-2.166 2.096-2.245l.154-.005zM18 6.75H6V19.5c0 .345.233.636.55.723l.098.02.102.007h10.5c.414 0 .75-.336.75-.75V6.75zm-8.25 3c.38 0 .693.282.743.648l.007.102v6c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L9 16.5v-6c0-.414.336-.75.75-.75zm4.5 0c.38 0 .693.282.743.648L15 10.5v6c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L13.5 16.5v-6c0-.414.336-.75.75-.75zm0-6h-4.5c-.414 0-.75.336-.75.75v.75h6V4.5c0-.38-.282-.693-.648-.743l-.102-.007z'
			transform='translate(-144 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193)'
		/>
	</svg>
);

export default Trash;
