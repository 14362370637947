import Query from '../../../Query.js';
import {
	ClientUserDetailReturnFields,
	OrgUserDetailReturnFields,
	OrgReturnFields,
	OrgUserReturnFields,
} from '../../returnFields';

const getVerificationTokenForSuperAdminByEmail = async function( email ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getVerificationTokenByEmail',
			params: {
				where: {
					user: {
						email,
					},
				},
			},
			returnFields: [ 'id' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return data;
};

const getPasswordResetTokenByEmail = async function( email ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getPasswordResetTokenByEmail',
			params: {
				where: {
					email,
				},
			},
			returnFields: [ 'passwordResetToken' ],
		} )
	);
	if ( errors ) {
		return { errors };
	}
	return data.data.getPasswordResetTokenByEmail;
};

const getClientsForSuperAdmin = async function( limit, skip ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUsersWhere',
			params: {
				where: {
					NOT: { id: null },
				},
				orderBy: [ { createdAt: 'desc' } ],
				take: limit + 1,
				skip,
			},
			returnFields: [
				{
					clientUsers: [
						...ClientUserDetailReturnFields,
						'weddingDate',
						'createdAt',
						'updatedAt',
						{ user: [ 'isVerified' ] },
					],
				},
			],
		} )
	);
	if ( errors ) return { errors };

	const clientUsers = data.data.getClientUsersWhere.clientUsers;
	let moreToLoad = false;

	if ( clientUsers.length > limit ) {
		clientUsers.pop();
		moreToLoad = true;
	}
	const newSkip = skip + clientUsers.length;

	return {
		clientUsers,
		moreToLoad,
		skip: newSkip,
	};
};

/**
 * Gets a detailed user for a superadmin.
 *
 * @param {String} id - Client or Org user id.
 *
 * @returns {Promise<{{ user } | { errors }}>}
 */
const getUserForSuperAdmin = async function( id ) {
	let user;
	const userFragment = [ { user: [ 'createdAt' ] } ];
	const { data: clientData, errors: clientErrors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUserWhere',
			params: {
				where: { id },
			},
			returnFields: [ ...ClientUserDetailReturnFields, ...userFragment ],
		} )
	);
	if ( clientErrors ) return { errors: clientErrors };

	user = clientData.data.getClientUserWhere;

	if ( user ) {
		return { user };
	}

	const { data: orgData, errors: orgErrors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrgUserWhere',
			params: {
				where: { id },
			},
			returnFields: [ ...OrgUserDetailReturnFields, ...userFragment ],
		} )
	);

	if ( orgErrors ) return { errors: orgErrors };

	user = orgData.data.getOrgUserWhere;

	return { user };
};

const getOrganizationsForSuperAdmin = async function( limit, skip ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationsWhere',
			params: {
				where: {
					NOT: { id: null },
				},
				orderBy: [ { createdAt: 'desc' } ],
				take: limit + 1,
				skip,
			},
			returnFields: [
				{
					organizations: [
						...OrgReturnFields,
						{ defaultOrgUser: OrgUserReturnFields },
						'createdAt',
						'updatedAt',
					],
				},
			],
		} )
	);
	if ( errors ) return { errors };

	const organizations = data.data.getOrganizationsWhere.organizations;
	let moreToLoad = false;

	if ( organizations.length > limit ) {
		organizations.pop();
		moreToLoad = true;
	}
	const newSkip = skip + organizations.length;

	return {
		organizations,
		moreToLoad,
		skip: newSkip,
	};
};

export {
	getVerificationTokenForSuperAdminByEmail,
	getClientsForSuperAdmin,
	getUserForSuperAdmin,
	getOrganizationsForSuperAdmin,
	getPasswordResetTokenByEmail,
};
