import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { displayConfirm } from '../../redux/actions';
import Modal from '../../elements/Modal';
import { H2 } from '../../elements/Headers.jsx';
import P from '../../elements/Paragraph.jsx';
import Buttons from './buttons.jsx';

const StyledModal = styled( Modal )`
	#confirm {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 35rem;
		margin: 0 auto;
		padding: 0 2rem 0 2rem;

		@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
			min-width: 34rem;
		}

		h2 {
			margin-top: 3rem;
			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				margin-top: 0;
			}
		}

		#prevent-confirm-display-checkbox {
			p {
				margin: 0;
			}
		}
	}
`;

class Confirm extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			preventDisplay: false,
		};
	}

	getLocalStorage() {
		return window.localStorage.getItem( 'RockPaperCoinPreventConfirm' );
	}

	setLocalStorage() {
		window.localStorage.setItem( 'RockPaperCoinPreventConfirm', 'true' );
	}

	removeLocalStorage() {
		window.localStorage.removeItem( 'RockPaperCoinPreventConfirm' );
	}

	componentDidMount() {
		const { confirm, cancel } = this.props;
		const preventDisplay = this.getLocalStorage();

		if ( preventDisplay ) {
			cancel();
			if ( confirm.props.defaultCallback ) confirm.props.defaultCallback();
		}
	}

	render() {
		const {
			title,
			message,
			options,
			cancel,
			closable = true,
			zIndex = 11,
			dataCy,
		} = this.props;

		return (
			<StyledModal
				id='invite-modal'
				className='invite-modal modal modal__fit-content'
				closable={ closable }
				cover='true'
				closeModal={ cancel }
				zIndex={ zIndex }
				data-cy={ dataCy }
			>
				<div id='confirm'>
					{ title && <H2 className='h2--secondary h2--bold'>{ title }</H2> }
					{ message && <P className='p--secondary'>{ message }</P> }
					<Buttons options={ options } />
				</div>
			</StyledModal>
		);
	}
}

const mapStateToProps = ( state ) => ( {
	confirm: state.confirm,
} );

const mapDispatchToProps = ( dispatch ) => ( {
	displayConfirm: ( data ) => {
		dispatch( displayConfirm( data ) );
	},
} );

export default connect( mapStateToProps, mapDispatchToProps )( Confirm );
