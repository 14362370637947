import { BundleStatusEnum } from '../../../types/generated';
import Globals from '../../Globals';
import { createBundleObject } from '../bundle';
import { setCookie } from '../setCookie';
import { BundleCookie } from './bundleCookieType';

export const setDocumentOptionCookie = ( args?: BundleCookie ) => {
	// unset it if we should, or it is meaningless
	const bundlesRemain = args
		? args.proposalBundleStatus === BundleStatusEnum.Awaiting ||
		  args.contractBundleStatus === BundleStatusEnum.Awaiting ||
		  args.invoiceBundleStatus === BundleStatusEnum.Awaiting
		: false;
	if ( !args || !bundlesRemain ) {
		// we've been using no-args as a signal to delete this cookie
		setCookie( {
			cookieName: Globals.DocumentBundle,
			cookieString: JSON.stringify( {} ),
			options: { expires: 'Thu, 01 Jan 1970 00:00:00 UTC' },
		} );
	} else {
		const {
			contactID,
			bundle = false,
			contractBundleStatus,
			invoiceBundleStatus,
			proposalBundleStatus,
			eventDate,
		} = args;
		const twentyMinutes = 1000 * 20 * 60;
		const now = new Date().getTime();
		const soon = new Date( now + twentyMinutes ).toUTCString();
		const existingCookieData = createBundleObject( contactID );
		// else set it
		const cookieObject: BundleCookie = {
			contactID,
			bundle,
			contractBundleStatus,
			invoiceBundleStatus,
			proposalBundleStatus,
			eventDate: existingCookieData?.eventDate || eventDate,
		};
		setCookie( {
			cookieName: Globals.DocumentBundle,
			cookieString: JSON.stringify( cookieObject ),
			options: { expires: soon },
		} );
	}
};
