/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const LearnMore = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm.013 15.75l.102.005c.642.058 1.137.597 1.137 1.245 0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25c0-.605.431-1.115 1.02-1.228l.128-.018.113-.004zm4.13-9.594c.484 2.173-.686 4.377-2.722 5.123-.338.123-.585.424-.654.778l-.016.12-.003.093v1.217c0 .42-.332.763-.746.763-.345 0-.637-.24-.723-.58l-.019-.104-.004-.08v-1.215c0-.979.536-1.868 1.379-2.305l.161-.077.122-.048c1.323-.484 2.087-1.924 1.77-3.345-.315-1.42-1.606-2.364-2.997-2.2-1.392.163-2.447 1.386-2.447 2.844 0 .42-.332.763-.746.763s-.746-.344-.746-.763c0-2.23 1.62-4.109 3.769-4.362 2.15-.252 4.138 1.203 4.622 3.378z'
			transform='translate(-956 -502) translate(170 250) translate(146.996 104) translate(0 113) translate(557.004) translate(81.998 35)'
		/>
	</svg>
);

export default LearnMore;
