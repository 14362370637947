import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function BurgerIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.25 1.27857C0.25 0.710507 0.710507 0.25 1.27857 0.25H23.2214C23.7895 0.25 24.25 0.710507 24.25 1.27857C24.25 1.84664 23.7895 2.30714 23.2214 2.30714H1.27857C0.710507 2.30714 0.25 1.84664 0.25 1.27857ZM0.25 8.13571C0.25 7.56765 0.710507 7.10714 1.27857 7.10714H23.2214C23.7895 7.10714 24.25 7.56765 24.25 8.13571C24.25 8.70378 23.7895 9.16429 23.2214 9.16429H1.27857C0.710507 9.16429 0.25 8.70378 0.25 8.13571ZM1.27857 13.9643C0.710507 13.9643 0.25 14.4248 0.25 14.9929C0.25 15.5609 0.710507 16.0214 1.27857 16.0214H23.2214C23.7895 16.0214 24.25 15.5609 24.25 14.9929C24.25 14.4248 23.7895 13.9643 23.2214 13.9643H1.27857Z'
			/>
		</SvgIcon>
	);
}
