import React from 'react';
import rpcShared from '@rockpapercoin/rpc-shared';
import { Typography } from '@mui/material';
import {
	DefaultEventProps,
	UserForContractPastDueEventFeed,
	userIsSenderOnContractEvent,
	userIsAssignedMemberOrVendorAdminOnContractEvent,
} from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

type GetContractPastDueEventPropsProps = {
	eventProps: DefaultEventProps;
	user: UserForContractPastDueEventFeed;
	resendContract: ( id: string ) => Promise<void>;
};

const getContractPastDueEventProps = ( {
	eventProps,
	user,
	resendContract,
}: GetContractPastDueEventPropsProps ) => {
	const { event } = eventProps;
	if ( !event.contract ) {
		throw new Error( `Contract missing on ContractPastDue event ${ event.id }` );
	}
	if ( !user || user.isLoggedIn === false ) {
		// user logged out and this component is re-rendering
		return;
	}

	eventProps.icon = 'contract-overdue';

	/* When you're signed in as the customer and looking at your own event feed, or looking at the
	event feed on the org detail page */
	if ( rpcShared.contracts.isCustomer( event.contract, user?.user?.id ) ) {
		eventProps.message = (
			<Typography variant='body1'>
				{ linkObject( event.contract ) } from { linkObject( event.vendor ) } is past
				due.
			</Typography>
		);
		eventProps.links = [
			{
				type: event.resolvedBy ? 'secondary' : 'primary',
				text: event.resolvedBy ? 'View' : 'Sign',
				link: getDetailLink( {
					object: event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
		return eventProps;
	}

	// If we're from the sending vendor
	if (
		userIsSenderOnContractEvent( event, user ) ||
		userIsAssignedMemberOrVendorAdminOnContractEvent( event, user )
	) {
		eventProps.message = (
			<Typography variant='body1'>
				{ linkObject( event.contract ) } is past due. Resend?
			</Typography>
		);
		eventProps.links = [
			{
				type: event.resolvedBy ? 'secondary' : 'primary',
				text: event.resolvedBy ? 'View' : 'Resend',
				link: event.resolvedBy
					? getDetailLink( {
						object: event.contract,
						type: LinkObjectType.Contract,
					  } )
					: null,
				onClick: event.resolvedBy
					? undefined
					: () =>
						event.contract?.id
							? resendContract( event.contract.id )
							: undefined,
			},
		];
		return eventProps;
	}

	// When you're signed in as the APPA and looking at this event in your own event feed or in tabs
	if (
		rpcShared.contracts.isAssignedPlannerOrAdminOfCustomersPlannerOrg(
			event.contract,
			user
		)
	) {
		eventProps.message = (
			<Typography variant='body1'>
				{ linkObject( event.contract ) } is past due.
			</Typography>
		);
		eventProps.links = [
			{
				type: 'secondary',
				text: 'View',
				link: getDetailLink( {
					object: event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
		return eventProps;
	}

	// eslint-disable-next-line max-len
	throw new Error(
		`getContractPastDueEventProps unable to determine user role.\n event:\n ${ JSON.stringify(
			event,
			null,
			2
		) }\n user: \n ${ user } `
	);
};

export default getContractPastDueEventProps;
