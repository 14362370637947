/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Link = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.9345 5.92267C12.9344 5.92268 12.9345 5.92267 12.9345 5.92267L11.3353 7.52198C11.0004 7.85687 10.4575 7.85688 10.1226 7.52202C9.78769 7.18716 9.78767 6.64422 10.1225 6.30933L11.7218 4.71C13.8117 2.62011 17.2001 2.62011 19.2901 4.70995C21.38 6.79986 21.38 10.1883 19.2901 12.2782L17.6909 13.8775C17.356 14.2124 16.813 14.2124 16.4782 13.8775C16.1433 13.5426 16.1433 12.9997 16.4781 12.6648L18.0774 11.0655C19.4976 9.64533 19.4976 7.34282 18.0775 5.92269C16.6572 4.50252 14.3546 4.50257 12.9345 5.92267Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.52184 10.1227C7.85673 10.4576 7.85675 11.0005 7.52188 11.3354L5.92267 12.9347C4.5025 14.3549 4.50248 16.6575 5.92264 18.0776C7.34282 19.4977 9.64538 19.4977 11.0656 18.0776L12.6647 16.4783C12.9996 16.1434 13.5425 16.1433 13.8774 16.4782C14.2123 16.813 14.2123 17.356 13.8775 17.6909L12.2783 19.2902C10.1884 21.3801 6.79989 21.3802 4.70997 19.2903C2.62004 17.2004 2.62004 13.812 4.70994 11.7221C4.70994 11.7221 4.70995 11.7221 4.70994 11.7221L6.30915 10.1228C6.64402 9.78787 7.18696 9.78785 7.52184 10.1227Z'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15.1486 8.85126C15.4835 9.18614 15.4835 9.72908 15.1486 10.0639L10.0641 15.1483C9.72924 15.4832 9.1863 15.4832 8.85143 15.1483C8.51656 14.8134 8.51656 14.2705 8.85144 13.9356L13.9359 8.85125C14.2708 8.51638 14.8137 8.51638 15.1486 8.85126Z'
		/>
	</svg>
);
export default Link;
