import Query from '../../Query.js';
import {
	OfflineTransactionHistory,
	SinglePaymentSourceReturnFields,
	StripeInvoiceReturnFields,
	StripeSubscriptionReturnFields,
	StripeUserReturnFields,
	PaymentSourcesReturnFields,
} from '../returnFields';

import { flattenStripeMetadata } from '../../helpers';

/**
 * Update an external account for an org.
 *
 * @param {String} organizationID - The organization's id.
 * @param {String} updateData - "input StripeBankAccountUpdates"
 *
 * @returns {Object} res - { errors, externalAccount }
 */
const updateExternalAccount = async function( organizationID, updateData ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateExternalAccount',
			params: {
				where: { id: organizationID },
				data: updateData,
			},
			returnFields: SinglePaymentSourceReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const externalAccount = data.data.updateExternalAccount;

	return { externalAccount };
};

/**
 * Get the pay-to and pay-from accounts of a given org.
 *
 * @param {String} id - Organization id.
 */
const getOrgStripeAccounts = async function( id ) {
	let payFrom = {},
		payTo = {},
		errors = [],
		hasFutureNeeds = false,
		defaultSource = '';

	const [
		// eslint-disable-next-line array-element-newline
		{ StripeUser, errors: customerErrors },
		{ connectAccount, errors: connectAccountErrors },
	] = await Promise.all( [
		// eslint-disable-next-line array-element-newline
		this.getStripeVendorCustomer( id ),
		this.getStripeConnectAccount( id ),
	] );

	if ( StripeUser?.sources?.data ) {
		payFrom = StripeUser.sources.data[ 0 ];
		defaultSource = StripeUser.default_source;
	}

	if ( customerErrors ) {
		errors = errors.concat( customerErrors );
	}

	if ( connectAccountErrors ) {
		errors = errors.concat( connectAccountErrors );
	}

	if ( connectAccount ) {
		hasFutureNeeds =
			!!connectAccount.future_requirements.currently_due.length ||
			!!connectAccount.future_requirements.eventually_due.length;
		payTo = connectAccount.external_accounts.data[ 0 ];
	}

	return { payTo, payFrom, defaultSource, hasFutureNeeds, errors };
};

/**
 * Get an Org's stripe customer account/object.
 *
 * @param {string} organizationID - Org's ID.
 *
 * @returns { Promise<{ errors: Error[] } | { StripeUser: Record<string, any> }> } res - {errors, StripeUser}
 */
const getStripeVendorCustomer = async function( organizationID ) {
	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getStripeVendorCustomer',
			params: { where: { id: organizationID } },
			returnFields: StripeUserReturnFields,
		} )
	);

	if ( errors || data.errors ) {
		return { errors: errors || data.errors };
	}

	const StripeUser = data.data.getStripeVendorCustomer;

	if ( StripeUser?.sources?.data ) {
		StripeUser.sources.data[ 0 ] = flattenStripeMetadata(
			StripeUser.sources.data[ 0 ]
		);
	}

	return { StripeUser };
};

/**
 * Begins a vendor's subscription.
 *
 * @param {String} orgID - Organization ID.
 * @param {String} subscriptionPlanID - Stripe ID of the subscription plan being created/started.
 * @param {String} coupon - ID of coupon code to apply to the subscription.
 *
 * @returns {object} res - {success, errors}
 */
const createVendorStripeSubscription = async function( subscriptionPlanID ) {
	const { errors, data: responseData } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createVendorStripeSubscription',
			params: { data: { subscriptionPlanID } },
			returnFields: StripeSubscriptionReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const subscription = responseData.data.createVendorStripeSubscription;
	if ( subscription.id ) {
		return { subscription };
	}

	return {}; // not sure what to return here
};

/**
 * Cancels/ends an organization's RPC subscription.
 *
 * @param {Object} org - Organization object.
 * @param {String} org.id - Organization ID.
 * @param {Object[]} org.appSubscriptions - Subscriptions.
 *
 * @returns {Object} res - {success, subscription, errors}
 */
const cancelVendorStripeSubscription = async function() {
	const { data, errors: cancelErrors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'cancelVendorStripeSubscription',
			returnFields: StripeSubscriptionReturnFields,
		} )
	);
	if ( cancelErrors ) return { errors: cancelErrors };

	const cancelledSubscription = data.data.cancelVendorStripeSubscription;

	return { cancelledSubscription };
};

/**
 * Get the stripe subscription object for an organization.
 *
 * @returns { Promise< { subscription: object } | { errors: string[] } >} res - { errors, subscription }
 */
const getVendorStripeSubscription = async function() {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getStripeVendorSubscription',
			returnFields: StripeSubscriptionReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const subscription = data.data.getStripeVendorSubscription;

	return { subscription };
};

/**
 * Update a card for an organization.
 *
 * @param {Object} data - "input OrganizationStripeCardUpdateArgs"
 *
 * @returns { Promise<{ success: string } | { errors: Array<Error> }> } res - { errors, success }
 */
const updateVendorStripeCard = async function( data ) {
	const { data: updateData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateVendorStripeCard',
			params: { data },
			returnFields: StripeUserReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const success =
		updateData.data.updateVendorStripeCard &&
		updateData.data.updateVendorStripeCard.default_source;

	return { success };
};

const createCustomerStripeCard = async function( data ) {
	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createCustomerStripeCard',
			params: { data },
			returnFields: SinglePaymentSourceReturnFields,
		} )
	);
	if ( res.data?.data?.createCustomerStripeCard ) {
		return { source: res.data.data.createCustomerStripeCard };
	} else if ( res && res.errors ) {
		return { errors: res.errors };
	}
};

/**
 * A method for updating a vendor organization's subscription
 * @param { string } planID
 * @returns { Promise< object | Error > }
 */
const changeVendorSubscription = async function( planID ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'changeVendorSubscription',
			params: { data: { planID } },
			returnFields: StripeSubscriptionReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const subscription = data.data.changeVendorSubscription;

	return { subscription };
};

const getNextVendorStripeInvoice = async function() {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getNextStripeInvoice',
			params: null,
			returnFields: StripeInvoiceReturnFields,
		} )
	);
	if ( errors ) {
		return { errors };
	}

	return { nextInvoice: data.data.getNextStripeInvoice };
};

const generatePlaidLinkToken = async function(
	redirect,
	invoiceID = null,
	invoiceToken = null
) {
	const params = { data: { redirect } };

	if ( invoiceID && invoiceToken ) {
		params.where = {
			invoice: { id: invoiceID },
			invoiceToken,
		};
	}
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'generatePlaidLinkToken',
			params,
		} )
	);
	if ( errors ) {
		return { errors };
	}
	return { linkToken: data.data.generatePlaidLinkToken };
};

/**
 * Verify a client user's ACH account.
 *
 * @param { String } userId - base user's ID.
 * @param { String } bankAccountID - Payment source's ID.
 * @param { Array<number> } amounts - Cent amounts used to verify the account.
 *
 * @returns { Promise<{ success: boolean } | { errors: Array<Error> }> }
 */
const verifyCustomerStripeBankAccount = async function(
	userId,
	bankAccountID,
	amounts
) {
	const where = {
		id: userId,
	};

	const data = {
		bankAccountID,
		amounts,
	};

	const { data: responseData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'verifyCustomerStripeBankAccount',
			params: { where, data },
		} )
	);

	if ( errors || responseData.errors ) {
		return { errors: errors || responseData.errors };
	}

	const success = responseData.data.verifyCustomerStripeBankAccount;

	return { success };
};

/**
 * Requests a history of offline payments for a specific client user.
 *
 * @param { String } userID - the id of the base user for which to request a history
 * @param { Object } after - an object containing the ids to search after
 * @param { String } after.paymentAfter - the id of the paid installment to search after
 * @param { String } after.refundAfter - the id of the refunded installment to search after
 *
 * @returns { Promise< { offlineHistory: Array<object>, hasMore: boolean } | { errors: Array<string> } > }
 */
const getCustomerOfflineTransactionHistory = async function(
	clientUserID,
	after,
	limit = 10
) {
	const params = {
		where: { id: clientUserID },
		take: limit + 1,
	};
	if ( after ) params.after = after;

	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			params: params,
			name: 'getCustomerOfflineTransactionHistory',
			returnFields: OfflineTransactionHistory,
		} )
	);
	if ( errors ) {
		return { errors };
	}
	const offlineHistory =
		data.data.getCustomerOfflineTransactionHistory.offlinePaymentHistoryItems;
	let hasMore = false;
	if ( offlineHistory.length > limit ) {
		offlineHistory.pop();
		hasMore = true;
	}
	return {
		offlineHistory: offlineHistory,
		hasMore: hasMore,
	};
};

/**
 * Update a bank account for a client user.
 *
 * @param { { source: string } } where
 * @param { {
 *   nickname?: string,
 *   isDefault?: boolean,
 *   accountUpdates: {
 *     account_holder_name?: string,
 *     account_holder_type?: string,
 *   }
 * } } data
 *
 * @returns {Object} res - { errors, success }
 */
const updateCustomerStripeBankAccount = async function( where, data ) {
	const { data: updateData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateCustomerStripeBankAccount',
			params: { where, data },
			returnFields: StripeUserReturnFields,
		} )
	);
	if ( errors ) return { errors };
	const success =
		updateData.data.updateCustomerStripeBankAccount &&
		updateData.data.updateCustomerStripeBankAccount.default_source;

	return { success };
};

/** Adds a card payment source to a vendor or customer.
 * Get all of a client user's payment sources.
 *
 * @param { { id: string } } baseUserId the ID of a base user
 *
 * @returns {Promise<{sources, defaultSource, errors}>}
 */
const getPaymentSourcesForCustomer = async function( baseUserId ) {
	const { data, errors } = await this.request(
		new Query( {
			// get all sources
			type: 'query',
			name: 'getPaymentSourcesForCustomer',
			params: { where: { id: baseUserId } },
			returnFields: PaymentSourcesReturnFields,
		} )
	);

	if ( errors || data.errors ) return { errors: errors || data.errors };

	const sources = data.data.getPaymentSourcesForCustomer.data.map( ( source ) =>
		flattenStripeMetadata( source )
	);

	const { StripeUser, errors: stripeCustomerErrors } =
		await this.getStripeCustomer( baseUserId );
	if ( stripeCustomerErrors ) {
		return { errors: stripeCustomerErrors };
	}

	let defaultSource;
	if ( StripeUser ) {
		defaultSource = StripeUser.default_source;
	}

	return {
		defaultSource,
		sources: sources || [],
	};
};
/**
 * Adds a card payment source to a vendor or customer.
 *
 * @param { Object } user - User object.
 * @param { String } user.userType - "ClientUser" | "OrgUser"
 * @param { Object } cardData - Stripe card creation input args.
 * @param { String } cardData.token - Stripe card token.
 * @param { String } cardData.nickname - Nickname for this payment source.
 * @param { Boolean } cardData.isDefault - Whether or not this is the user's default payment source.
 *
 * @returns { Object } res - { source, errors}
 */
const createVendorStripeCard = async function( user, cardData ) {
	const params = {
		data: {
			organizationID: user.organization.id,
			...cardData,
		},
	};

	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createVendorStripeCard',
			params,
			returnFields: SinglePaymentSourceReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const source = data.data.createVendorStripeCard;

	return {
		source,
	};
};

/**
 * Update a card for a client user.
 *
 * @param {Object} data - "input ClientStripeCardUpdateArgs"
 *
 * @returns { Promise<{ success: string } | { errors: Array<Error> }> } res - { errors, success }
 */
const updateCustomerStripeCard = async function( where, data ) {
	const { data: updateData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateCustomerStripeCard',
			params: { where, data },
			returnFields: StripeUserReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const success =
		updateData.data.updateCustomerStripeCard &&
		updateData.data.updateCustomerStripeCard.default_source;

	return { success };
};

/**
 * Delete a payment source for a customer.
 * @param { Object } params
 * @param { Object } params.where - StripePaymentSourceInput
 * @param { string } params.where.source - id for the payment source
 * @returns { Promise<{ success: string } | { errors: Array<Error> }> } res - { errors, success }
 */
const deleteCustomerStripePaymentSource = async function( sourceId ) {
	const { data: updateData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'deleteCustomerStripePaymentSource',
			params: { where: { source: sourceId } },
			returnFields: StripeUserReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const success =
		updateData.data.deleteCustomerStripePaymentSource &&
		updateData.data.deleteCustomerStripePaymentSource.default_source;

	return { success };
};

export {
	cancelVendorStripeSubscription,
	createCustomerStripeCard,
	createVendorStripeCard,
	createVendorStripeSubscription,
	getNextVendorStripeInvoice,
	getOrgStripeAccounts,
	getStripeVendorCustomer,
	getVendorStripeSubscription,
	updateExternalAccount,
	updateVendorStripeCard,
	changeVendorSubscription,
	generatePlaidLinkToken,
	verifyCustomerStripeBankAccount,
	getCustomerOfflineTransactionHistory,
	updateCustomerStripeBankAccount,
	getPaymentSourcesForCustomer,
	updateCustomerStripeCard,
	deleteCustomerStripePaymentSource,
};
