import React, { SVGProps } from 'react';

const Client = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' { ...props }>
		{ /* eslint-disable-next-line max-len */ }
		<path
			d='M24 25.875c11.39 0 20.625 9.234 20.625 20.625 0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125c0-10.148-8.227-18.375-18.375-18.375S5.625 36.352 5.625 46.5c0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125c0-11.39 9.234-20.625 20.625-20.625zm0-25.5C30.42.375 35.625 5.58 35.625 12S30.42 23.625 24 23.625 12.375 18.42 12.375 12 17.58.375 24 .375zm0 2.25c-5.178 0-9.375 4.197-9.375 9.375s4.197 9.375 9.375 9.375 9.375-4.197 9.375-9.375S29.178 2.625 24 2.625z'
			transform='translate(-921 -560) translate(376 385) translate(449 103) translate(62 72) translate(34)'
		/>
	</svg>
);

export default Client;
