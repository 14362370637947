import { IconButton, ListItem, Typography } from '@mui/material';
import { RPCShared } from '@rockpapercoin/rpc-shared';
import React from 'react';
import Icon from '../../../../../elements/icons';
import styles from './InquiriesFormSimpleItem.module.scss';
import classNames from 'classnames';
import { HookFieldData } from '../../useInquiriesConfigurationFields';

type Field = RPCShared.Inquiries.Field;

export type InquiriesFormSimpleItemProps = Pick<
HookFieldData,
'forceRequired' | 'type' | 'description' | 'included'
> & {
	onToggle: ( type: Field['type'], value: boolean ) => void;
};

export const InquiriesFormSimpleItem = ( {
	type,
	description,
	included,
	forceRequired,
	onToggle,
}: InquiriesFormSimpleItemProps ) => (
	<ListItem
		className={ styles.container }
		secondaryAction={
			forceRequired ? undefined : (
				<IconButton onClick={ () => onToggle( type, !included ) }>
					<Icon
						type={ included ? 'view' : 'hide' }
						className={ classNames(
							styles.icon,
							included ? styles.view : styles.hide
						) }
					/>
				</IconButton>
			)
		}
	>
		<Typography component='span'>{ description }</Typography>
	</ListItem>
);
