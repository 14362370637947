import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ProposalsIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				xmlns='http://www.w3.org/2000/svg'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.41176 2.58823C1.41176 1.93849 1.93849 1.41176 2.58824 1.41176H12.2755C12.2494 1.48536 12.2353 1.56456 12.2353 1.64706V5.41176C12.2353 6.84121 13.3941 8 14.8235 8H18.5882C18.6707 8 18.7499 7.98585 18.8235 7.95983V21.4118C18.8235 22.0615 18.2968 22.5882 17.6471 22.5882H2.58824C1.93849 22.5882 1.41176 22.0615 1.41176 21.4118V2.58823ZM18.1852 6.58824L13.6471 1.95491V5.41176C13.6471 6.06151 14.1738 6.58824 14.8235 6.58824H18.1852ZM2.58824 0C1.15879 0 0 1.15879 0 2.58823V21.4118C0 22.8412 1.15879 24 2.58824 24H17.6471C19.0765 24 20.2353 22.8412 20.2353 21.4118V7.72015C20.2353 7.04301 19.9699 6.39284 19.4961 5.90908L14.4697 0.777172C13.9828 0.280118 13.3164 0 12.6206 0H2.58824ZM4.94118 19.0588V18.1765L8.20585 15.728L11.07 18.183C11.3007 18.3807 11.6321 18.4087 11.8926 18.2523L13.8385 17.0848L15.2941 18.1765V19.0588H4.94118ZM7.81178 14.2588L4.94118 16.4118V12.4706H15.2941V16.4118L14.3059 15.6706C14.0763 15.4984 13.7653 15.4823 13.5192 15.63L11.6025 16.78L8.69469 14.2876C8.4435 14.0723 8.07646 14.0603 7.81178 14.2588ZM3.52941 12C3.52941 11.4802 3.95079 11.0588 4.47059 11.0588H15.7647C16.2845 11.0588 16.7059 11.4802 16.7059 12V19.5294C16.7059 20.0492 16.2845 20.4706 15.7647 20.4706H4.47059C3.95079 20.4706 3.52941 20.0492 3.52941 19.5294V12ZM3.76471 5.41176C3.76471 5.02192 4.08074 4.70588 4.47059 4.70588L10.1176 4.70588C10.5075 4.70588 10.8235 5.02192 10.8235 5.41177C10.8235 5.80161 10.5075 6.11765 10.1176 6.11765L4.47059 6.11765C4.08074 6.11765 3.76471 5.80161 3.76471 5.41176ZM4.47059 7.52941C4.08074 7.52941 3.76471 7.84545 3.76471 8.23529C3.76471 8.62514 4.08074 8.94118 4.47059 8.94118H10.1176C10.5075 8.94118 10.8235 8.62514 10.8235 8.23529C10.8235 7.84545 10.5075 7.52941 10.1176 7.52941H4.47059Z'
			/>
		</SvgIcon>
	);
}
