import { ApiResponseType } from '../../../../hooks/useMutation';
import Query from '../../../Query.js';
import { CreateProposalTemplateReturnFields } from '../../returnFields/proposals';
import { CreateProposalDataResponse, ProposalTemplateParams } from '../types';

const updateProposalTemplate = async function(
	this: any,
	paramsData: Partial<ProposalTemplateParams>,
	id?: string
): Promise<ApiResponseType<CreateProposalDataResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateProposalTemplate',
			params: {
				data: paramsData,
				where: {
					id,
				},
			},
			returnFields: CreateProposalTemplateReturnFields,
			excludeEnumParams: [ 'type' ],
			disableRemovingSemicolonsFromStringParams: true,
		} )
	);

	if ( errors ) return { errors };

	return data.data.updateProposalTemplate;
};

export default updateProposalTemplate;
