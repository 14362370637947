import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function InvoiceTemplatesIcon( props: SvgIconProps ) {
	return (
		<SvgIcon viewBox='0 0 32 32' { ...props }>
			<path d='M23 6.0459H24.25V10.2959C24.25 10.7101 24.5858 11.0459 25 11.0459C25.4142 11.0459 25.75 10.7101 25.75 10.2959V6.0459H27C27.4142 6.0459 27.75 5.71011 27.75 5.2959C27.75 4.88168 27.4142 4.5459 27 4.5459L23 4.5459C22.5858 4.5459 22.25 4.88169 22.25 5.2959C22.25 5.71011 22.5858 6.0459 23 6.0459Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M32 7.2959C32 11.1619 28.866 14.2959 25 14.2959C21.134 14.2959 18 11.1619 18 7.2959C18 3.42991 21.134 0.295898 25 0.295898C28.866 0.295898 32 3.42991 32 7.2959ZM30.5 7.2959C30.5 10.3335 28.0376 12.7959 25 12.7959C21.9624 12.7959 19.5 10.3335 19.5 7.2959C19.5 4.25833 21.9624 1.7959 25 1.7959C28.0376 1.7959 30.5 4.25833 30.5 7.2959Z'
			/>
			<path d='M5.75 6.2959C5.75 5.60554 6.30964 5.0459 7 5.0459H15.5C15.9142 5.0459 16.25 4.71011 16.25 4.2959C16.25 3.88168 15.9142 3.5459 15.5 3.5459H7C5.48122 3.5459 4.25 4.77712 4.25 6.2959V26.2959C4.25 27.8147 5.48122 29.0459 7 29.0459H23C24.5188 29.0459 25.75 27.8147 25.75 26.2959V16.7959C25.75 16.3817 25.4142 16.0459 25 16.0459C24.5858 16.0459 24.25 16.3817 24.25 16.7959V26.2959C24.25 26.9863 23.6904 27.5459 23 27.5459H7C6.30964 27.5459 5.75 26.9863 5.75 26.2959V6.2959Z' />
			<path d='M11.25 9.63008C10.8724 9.71588 10.5101 9.86712 10.1981 10.0831C9.67336 10.4464 9.25 11.0305 9.25 11.7959C9.25 12.5759 9.7443 13.0757 10.2167 13.3823C10.6663 13.6741 11.2379 13.8857 11.7105 14.0606L11.7396 14.0714C12.2641 14.2655 12.6791 14.4218 12.9724 14.6084C13.2503 14.7852 13.2501 14.8794 13.25 14.9098L13.25 14.9113C13.25 15.0535 13.1903 15.1828 12.9819 15.311C12.7538 15.4514 12.3992 15.5459 12 15.5459C11.3185 15.5459 10.8447 15.1318 10.5087 14.8217C10.2043 14.5408 9.72985 14.5597 9.4489 14.8641C9.16794 15.1685 9.18692 15.643 9.49129 15.9239L9.50962 15.9409C9.78158 16.1922 10.3726 16.7386 11.25 16.955V17.2959C11.25 17.7101 11.5858 18.0459 12 18.0459C12.4142 18.0459 12.75 17.7101 12.75 17.2959V16.9738C13.1093 16.9031 13.4602 16.7779 13.7681 16.5885C14.3097 16.2552 14.75 15.6921 14.75 14.9113C14.75 14.1323 14.245 13.6402 13.7776 13.3428C13.3382 13.0632 12.7801 12.8569 12.3149 12.6848L12.2604 12.6647C11.7384 12.4714 11.3244 12.3131 11.0333 12.1241C10.7557 11.9439 10.75 11.8428 10.75 11.7959C10.75 11.6382 10.8266 11.4723 11.0519 11.3164C11.2861 11.1543 11.6327 11.0459 12 11.0459C12.6933 11.0459 13.0073 11.3233 13.4913 11.7701C13.7957 12.051 14.2701 12.0321 14.5511 11.7277C14.8321 11.4233 14.8131 10.9488 14.5087 10.6679L14.493 10.6534C14.0887 10.28 13.5785 9.80902 12.75 9.62481V9.2959C12.75 8.88169 12.4142 8.5459 12 8.5459C11.5858 8.5459 11.25 8.88169 11.25 9.2959V9.63008Z' />
			<path d='M15.25 17.2959C15.25 16.8817 15.5858 16.5459 16 16.5459H21C21.4142 16.5459 21.75 16.8817 21.75 17.2959C21.75 17.7101 21.4142 18.0459 21 18.0459H16C15.5858 18.0459 15.25 17.7101 15.25 17.2959Z' />
			<path d='M9 19.5459C8.58579 19.5459 8.25 19.8817 8.25 20.2959C8.25 20.7101 8.58579 21.0459 9 21.0459H21C21.4142 21.0459 21.75 20.7101 21.75 20.2959C21.75 19.8817 21.4142 19.5459 21 19.5459H9Z' />
			<path d='M8.25 23.2959C8.25 22.8817 8.58579 22.5459 9 22.5459H21C21.4142 22.5459 21.75 22.8817 21.75 23.2959C21.75 23.7101 21.4142 24.0459 21 24.0459H9C8.58579 24.0459 8.25 23.7101 8.25 23.2959Z' />
		</SvgIcon>
	);
}
