/* eslint max-len: 0 */
const BellOn = () => (
	<svg viewBox='0 0 24 24'>
		<g transform='translate(-304 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241) translate(162.249)'>
			<path
				fill='#000'
				d='M11.968 21.03c.397.117.625.534.508.931C12.121 23.17 11.013 24 9.754 24c-1.26 0-2.368-.83-2.722-2.038-.117-.397.11-.814.508-.93.364-.108.745.075.896.412l.035.096c.167.57.69.96 1.283.96.548 0 1.035-.333 1.238-.832l.045-.128c.116-.398.533-.625.93-.509zM9.75 0c.38 0 .694.282.744.648L10.5.75v1.534c4.205.379 7.5 3.912 7.5 8.216 0 3.011.272 5.144.715 6.54.146.459.295.79.435 1.012.063.098.09.129.07.113.552.444.239 1.335-.47 1.335h-18c-.625 0-.976-.72-.59-1.212l.034-.048c.134-.197.353-.66.587-1.452.447-1.516.72-3.586.72-6.288l.003-.252c.126-4.189 3.374-7.593 7.496-7.964V.75c0-.414.336-.75.75-.75zm0 3.75c-3.728 0-6.75 3.022-6.75 6.75 0 2.84-.29 5.052-.78 6.713-.071.24-.143.46-.215.658L1.957 18h15.506l-.101-.279-.075-.227c-.495-1.558-.786-3.84-.786-6.994 0-3.728-3.022-6.75-6.75-6.75z'
			/>
			<circle
				cx='17'
				cy='7'
				r='5'
				fill='#F38E7D'
				fillRule='nonzero'
				stroke='#FFF'
			/>
		</g>
	</svg>
);

export default BellOn;
