import { searchInvoices } from '../invoices/index';
import { searchOrganizations } from '../organization/index';
import { searchProposals } from '../proposals/index';
import { searchContactCustomers } from '../users/client/index';
import { searchOrgUsers } from '../users/org/index';

type self = {
	searchInvoices: typeof searchInvoices;
	searchOrganizations: typeof searchOrganizations;
	searchProposals: typeof searchProposals;
	searchContactCustomers: typeof searchContactCustomers;
	searchOrgUsers: typeof searchOrgUsers;
};

export enum SearchTypes {
	Clients = 'clients',
	Organizations = 'organizations',
}

type SearchClientUsersProps = {
	subject: SearchTypes.Clients;
	orgUserId: Parameters<typeof searchContactCustomers>[0]['orgUserId'];
	isAdmin: Parameters<typeof searchContactCustomers>[0]['isAdmin'];
	limit: Parameters<typeof searchContactCustomers>[0]['limit'];
	skip: Parameters<typeof searchContactCustomers>[0]['skip'];
};
type SearchOrganizationsProps = {
	subject: SearchTypes.Organizations;
} & {
	id: Parameters<typeof searchOrganizations>[0]['id'];
	limit: Parameters<typeof searchOrganizations>[0]['limit'];
	skip: Parameters<typeof searchOrganizations>[0]['skip'];
};

export type SearchProps = { queryString: string } & (
	| SearchClientUsersProps
	| SearchOrganizationsProps
);

export const getSearchParameters = (
	props: { subject: SearchTypes; queryString: string } & Partial<
	Omit<SearchClientUsersProps, 'subject'> &
	Omit<SearchOrganizationsProps, 'subject'>
	>
) => {
	if ( props.subject === SearchTypes.Clients ) {
		return {
			subject: props.subject,
			queryString: props.queryString,
			orgUserId: props.orgUserId,
			isAdmin: props.isAdmin,
			limit: props.limit,
			skip: props.skip,
		} as { queryString: string } & SearchClientUsersProps;
	}
	if ( props.subject === SearchTypes.Organizations ) {
		return {
			subject: props.subject,
			queryString: props.queryString,
			id: props.id,
			limit: props.limit,
			skip: props.skip,
		} as { queryString: string } & SearchOrganizationsProps;
	}
};

const search = async function( this: self, props: SearchProps ) {
	// cheater way to get around "this" in typescript here...
	const self = this as self;
	// regex for hyphenated names?
	const queries = props.queryString.split( ' ' );

	switch ( props.subject ) {
		// org user searching for clients
		case SearchTypes.Clients:
			return await self.searchContactCustomers( {
				queries,
				orgUserId: props.orgUserId,
				isAdmin: props.isAdmin,
				limit: props.limit,
				skip: props.skip,
			} );
		case SearchTypes.Organizations:
			return await self.searchOrganizations( {
				queries,
				id: props.id,
				limit: props.limit,
				skip: props.skip,
			} );
		default:
			break;
	}
};

export default {
	search,
};
