/* eslint max-len: 0 */
const ExistingUser = () => (
	<svg viewBox='0 0 32 32'>
		<path
			d='M20 24.25c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L20 25.75l-3.12.001c.863 2.507 3.154 4.3 5.859 4.485 3.016.206 5.795-1.64 6.774-4.501.134-.392.56-.601.952-.467.392.134.601.56.467.952-1.199 3.503-4.601 5.765-8.295 5.512-3.046-.207-5.663-2.08-6.887-4.768V30c0 .38-.282.693-.648.743L15 30.75c-.38 0-.693-.282-.743-.648L14.25 30v-5c0-.38.282-.693.648-.743L15 24.25h5zm-6.173-10.217c.381.162.559.603.396.984-.162.38-.603.558-.984.396-2.548-1.086-5.472-.821-7.784.706C3.144 17.645 1.752 20.23 1.75 23c0 .414-.336.75-.75.749-.415 0-.75-.336-.75-.75.002-3.274 1.647-6.329 4.379-8.133 2.732-1.804 6.186-2.118 9.198-.834zm9.535.235c3.048.208 5.666 2.083 6.89 4.773L30.25 16c0-.38.282-.693.648-.743L31 15.25c.38 0 .693.282.743.648l.007.102v5c0 .38-.282.693-.648.743L31 21.75h-5c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L26 20.25h3.12c-.864-2.508-3.155-4.301-5.86-4.486-3.017-.206-5.795 1.64-6.774 4.501-.134.392-.56.601-.953.467-.392-.134-.6-.56-.467-.952 1.2-3.503 4.602-5.765 8.296-5.512zM10 .25c3.452 0 6.25 2.798 6.25 6.25s-2.798 6.25-6.25 6.25S3.75 9.952 3.75 6.5 6.548.25 10 .25zM5.507 4.96l-.028.078c-.149.46-.229.952-.229 1.462 0 2.623 2.127 4.75 4.75 4.75 2.518 0 4.578-1.96 4.74-4.436-.94.29-1.92.437-2.907.436-2.322 0-4.559-.817-6.326-2.29zM10 1.75c-1.534 0-2.898.727-3.766 1.855 1.532 1.377 3.525 2.145 5.6 2.145.94 0 1.873-.157 2.758-.465C14.055 3.249 12.202 1.75 10 1.75z'
			transform='translate(-144 -1434) translate(144 183) translate(0 1197) translate(0 54)'
		/>
	</svg>
);

export default ExistingUser;
