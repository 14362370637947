/* eslint max-len: 0 */
const StarOutline = ( props ) => (
	<svg { ...props } viewBox='0 0 25 25'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.6034 0.858329C10.8495 0.377832 11.3231 0.060704 11.854 0.0111861L12 0.00439453C12.5889 0.00439453 13.1282 0.33415 13.3986 0.862186L16.558 7.12314L22.5771 7.71971C23.1353 7.7661 23.6216 8.10831 23.8563 8.60918L23.9096 8.73762C24.1171 9.30785 23.974 9.94669 23.543 10.3738L18.554 15.3171L20.4049 22.0396C20.5496 22.5847 20.389 23.161 19.9934 23.5521L19.8798 23.654C19.4023 24.0418 18.7417 24.1136 18.1962 23.8393L12 20.7711L5.81696 23.8332C5.31316 24.0865 4.7161 24.0474 4.25231 23.7403L4.1293 23.65C3.65182 23.2621 3.44627 22.6302 3.60593 22.0291L5.45403 15.3131L0.462079 10.3698C0.0670001 9.97828 -0.0862092 9.40885 0.0508316 8.87745L0.095439 8.73362C0.302944 8.1634 0.823195 7.76597 1.41609 7.71679L7.44603 7.11914L10.6034 0.858329ZM12.0614 1.54195C12.0496 1.5189 12.0259 1.50439 12 1.50439C11.9741 1.50439 11.9504 1.5189 11.9407 1.53785L8.59969 8.16285C8.48412 8.39202 8.25938 8.54618 8.00397 8.57149L1.55214 9.21056C1.53075 9.21234 1.51235 9.2264 1.50501 9.24656C1.49767 9.26673 1.50273 9.28933 1.51779 9.30425L6.82179 14.5573C7.01439 14.748 7.08908 15.0278 7.01713 15.2892L5.05391 22.4206C5.04754 22.4446 5.05583 22.4701 5.07507 22.4857C5.09432 22.5013 5.12095 22.5042 5.14718 22.491L11.6672 19.262C11.8769 19.1582 12.1231 19.1582 12.3328 19.262L18.866 22.4971C18.8881 22.5082 18.9147 22.5053 18.934 22.4897C18.9532 22.4741 18.9615 22.4486 18.9569 22.4312L16.9919 15.2932C16.92 15.0319 16.9946 14.7522 17.1871 14.5615L22.4871 9.30844C22.5023 9.29333 22.5074 9.27073 22.5 9.25057C22.4927 9.2304 22.4743 9.21634 22.4411 9.21349L16.0011 8.57549C15.7458 8.55019 15.5211 8.39614 15.4055 8.16709L12.0614 1.54195Z'
		/>
	</svg>
);

export default StarOutline;
