import { ApiResponseType } from '../../../../hooks/useMutation';
import Query from '../../../Query.js';
import { AcceptProposalReturnFields } from '../../returnFields/proposals';
import { AcceptProposalResponse } from '../types';

const acceptProposal = async function(
	this: any,
	id: string,
	guestToken?: string
): Promise<ApiResponseType<AcceptProposalResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'acceptProposal',
			params: {
				where: { proposal: { id }, ...( guestToken && { guestToken } ) },
			},
			returnFields: AcceptProposalReturnFields,
		} )
	);

	if ( errors ) return { errors };

	return data.data.acceptProposal;
};

export default acceptProposal;
