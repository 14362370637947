const ClientUserScalarFields = [
	'addressLine1',
	'addressLine2',
	'city',
	'createdAt',
	'country',
	'description',
	'facebook',
	'firstNameOne',
	'firstNameTwo',
	'id',
	'image',
	'imageScale',
	'imageRotation',
	'imagePositionX',
	'imagePositionY',
	'originalImage',
	'instagram',
	'lastNameOne',
	'lastNameTwo',
	'phone',
	'pinterest',
	'postalCode',
	'plannerOrgCanPayInvoices',
	'plannerOrgCanSignContracts',
	'plannerOrgHasConnectionPermissions',
	'plannerOrgHasDocumentsPermissions',
	'state',
	'twitter',
	'updatedAt',
	'venue',
	'weddingDate',
];

const ClientUserFieldsForGetContractWhere = [ 'plannerOrgCanSignContracts', 'id', ];

export { ClientUserScalarFields, ClientUserFieldsForGetContractWhere };
