/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Collapse = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		width='33'
		height='32'
		viewBox='0 0 33 32'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path
			d='M1.52409 30.6071L10.6669 21.4642'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2.66699 21.4642H10.667V29.4642'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M31.2384 30.6071L22.0955 21.4642'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M30.0955 21.4642H22.0955V29.4642'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M1.52409 0.892578L10.6669 10.0354'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2.66699 10.0352H10.667V2.03516'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M31.2384 0.892578L22.0955 10.0354'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M30.0955 10.0352H22.0955V2.03516'
			fill='none'
			strokeWidth='1.71429'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Collapse;
