import styled from 'styled-components';

export const H1 = styled.h1`
	margin: 0;
	font-size: 2.938rem;
	font-weight: bold;
	color: ${ ( { theme } ) => theme.pink };

	&.h1 {
		&--medium {
			font-size: 2.438rem;
		}

		&--small {
			font-size: 2rem;
		}

		&--centered {
			text-align: center;
		}

		&--right {
			text-align: right;
		}

		&--secondary {
			color: ${ ( { theme } ) => theme.darkBlue };
		}

		&--black {
			color: ${ ( { theme } ) => theme.black };
		}

		&--opacity-20 {
			opacity: 0.2;
		}
	}
`;

export const H2 = styled.h2`
	margin: 0;
	font-size: 1.688rem;
	font-weight: normal;
	color: ${ ( { theme } ) => theme.pink };

	&.h2 {
		&--secondary {
			color: ${ ( { theme } ) => theme.darkBlue };
		}

		&--bold {
			font-weight: bold;
		}

		&--centered {
			text-align: center;
		}

		&--right {
			text-align: right;
		}
	}
`;

export const H3 = styled.h3`
	margin: 0;
	font-size: 1.375rem;
	font-weight: normal;
	color: ${ ( { theme } ) => theme.pink };

	&.h3 {
		&--secondary {
			color: ${ ( { theme } ) => theme.darkBlue };
		}

		&--bold {
			font-weight: bold;
		}

		&--centered {
			text-align: center;
		}
	}
`;
