import { split, ApolloLink, HttpLink, ApolloClient } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import { cache } from './cache';
import {
	getCookieString,
	getInfoFromCookie,
	getVersion,
} from '../../lib/helpers';
import getBackendUrl, { EndpointNames } from '../../lib/API/request/serviceMap';
import { isDevelopment } from '../../lib/constants';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const apiUrl = getBackendUrl( EndpointNames.Query );
/* we want websocketUrl to have the protocol wss when using https and
ws when using http, hence why we only replace http */
const websocketUrl = apiUrl
	.replace( 'http', 'ws' )
	.replace( '/graphql', '/subscriptions' );

const wsLink = new GraphQLWsLink(
	createClient( {
		url: websocketUrl,
		connectionParams: () => {
			const cookieString = getCookieString();
			const response: Record<string, any> = {
				'rpc-appversion': getVersion(),
			};
			if ( cookieString ) {
				const [ token ] = getInfoFromCookie( cookieString );
				if ( token ) {
					// If we have a token from the cookie, use that
					response.authorization = `Bearer ${ token }`;
				}
			}
			return response;
		},
	} )
);

const httpLink = new HttpLink( {
	uri: apiUrl,
	credentials: 'include',
} );

const splitLink = split(
	( { query } ) => {
		const definition = getMainDefinition( query );
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink
);

const errorHandlerMiddleware = onError( ( errors ) => {
	// we can access graphQLErrors and networkError here
	if (
		errors.networkError &&
		'statusCode' in errors.networkError &&
		'result' in errors.networkError
	) {
		const { statusCode, result } = errors.networkError;
		// I tested in FireFox and Chrome, and Apollo says result should be a record but it's actually a string
		const resultCastToStringBecauseOfBadTyping = result as unknown as string;
		if ( typeof document !== 'undefined' ) {
			if (
				statusCode === 400 &&
				resultCastToStringBecauseOfBadTyping ===
					'No RPC-AppVersion header submitted.'
			) {
				document.location.reload();
			} else if (
				statusCode === 403 &&
				resultCastToStringBecauseOfBadTyping ===
					'RPC-AppVersion header mismatch.'
			) {
				document.location.reload();
			}
		}
		// We could log other network errors as well if we wanted
	}
} );

const authMiddleware = new ApolloLink( ( operation, forward ) => {
	operation.setContext( ( { headers }: { headers?: Record<string, any> } ) => {
		const cookieString = getCookieString( headers ? { headers } : undefined );
		const response: Record<string, any> = {
			headers: {
				...( headers ? headers : {} ),
				'rpc-appversion': getVersion(),
			},
		};
		if ( cookieString ) {
			const [ token ] = getInfoFromCookie( cookieString );
			if ( token ) {
				// If we have a token from the cookie, use that
				response.headers.authorization = `Bearer ${ token }`;
			}
		}
		return response;
	} );
	// return the headers to the context so httpLink can read them
	return forward( operation );
} );

const apolloLoggerMiddleware = ApolloLink.from( [ apolloLogger ] );

// only log in development
const link = errorHandlerMiddleware.concat(
	isDevelopment
		? apolloLoggerMiddleware.concat( authMiddleware.concat( splitLink ) )
		: authMiddleware.concat( splitLink )
);

export const client = new ApolloClient( {
	connectToDevTools: process.env.NODE_ENV === 'development',
	cache,
	link,
	credentials: 'omit',
	defaultOptions: {
		// https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object
		watchQuery: {
			/* For example, suppose you call watchQuery on a GraphQL query that
			fetches a person's first and last name and this person has a
			particular object identifier, provided by dataIdFromObject. Later, a
			different query fetches that same person's first and last name and
			the first name has now changed. Then, any observers associated with
			the results of the first query will be updated with a new result object. */
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'all',
		},
		query: {
			// By default, ignore cache when making queries
			fetchPolicy: 'network-only',
			/* Error policy described here:
			https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies */
			errorPolicy: 'all',
		},
		mutate: {
			// Mutations only update cache on success, otherwise cache is ignored
			errorPolicy: 'all',
		},
	},
} );
