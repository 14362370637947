/*
 * ProfileImagePicker used in profile & admin pages
 */

// packages
import AvatarEditor from 'react-avatar-editor';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// markup
import Button from '../../../elements/Button';
import Slider from './Slider';
import Icon from '../../../elements/icons';
import Droppable from './Droppable';
import { H1 } from '../../../elements/Headers';
import P from '../../../elements/Paragraph';
import A from '../../../elements/Anchor';

// logic
import { dataURIToFile, fileNameToMimeType } from '../../../lib/helpers';

const ImagePickerContainer = styled.div`
	&.imagePicker__container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
			width: 31rem;
		}

		.image-picker__header {
			display: flex;
			margin-bottom: 1.5rem;
			max-width: 20rem;

			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				max-width: none;
			}

			h1 {
				margin-left: 0.5rem;
			}

			.imagePicker__icon--back svg {
				margin-top: 0.5rem;
				width: 1.5rem;
				height: 1.5rem;
				fill: ${ ( props ) => props.theme.green };
			}
		}

		.image-picker__display {
			margin-bottom: 1.5rem;
		}

		.dropzone {
			margin: 0 auto;
			width: 100%;
			height: 30rem;
			svg {
				fill: ${ ( { theme } ) => theme.gray };
				width: 8rem;
				margin: 0 auto 1.5rem;
				object-fit: contain;
			}
		}

		.image-picker__control-console {
			display: flex;
			flex-direction: column;
			user-select: none;
			@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
				flex-direction: row;
			}

			.scale-control__label,
			.rotation-control__label {
				margin-bottom: 1rem;
			}

			.rotation-control__label {
				margin-top: 2rem;
				@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
					margin-top: 0;
				}
			}

			.scale-control,
			.rotation-control,
			.reset-control {
				display: flex;
				flex-direction: column;
				&__icon--minus,
				&__icon--plus {
					margin-top: 0.2rem;
					width: 1.5rem;
					height: 1.5rem;
					svg {
						width: 1.5rem;
						height: 1.5rem;
						fill: ${ ( props ) => props.theme.pink };
					}
				}

				.scale-control__icon--minus:hover,
				.scale-control__icon--plus:hover {
					cursor: pointer;
				}

				.scale-control__input-wrapper {
					display: flex;
					flex-direction: row;
				}

				.scale-control__input,
				.rotation-control__input {
					width: 100%;
					@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
						width: 10rem;
					}
				}
			}

			.rotation-control {
				@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
					margin-left: 2rem;
				}
			}

			.reset-control {
				justify-content: flex-end;
				margin-top: 2rem;
				@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
					margin-left: 2rem;
					margin-top: 0;
				}
			}

			.anchor--reset {
				font-weight: bold;
				&:hover {
					text-decoration: none;
				}
			}
		}

		.image-picker__save-settings {
			display: flex;
			button {
				margin: 2.5rem auto 0 auto;
			}
		}
	}
`;

class ProfileImagePicker extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			editor: {
				color: [
					255,
					255,
					255,
					0.6
				],
				border: 20,
				width: 340,
				height: 340,
				borderRadius: 170,
			},

			// fields
			imageScale: 1.0,
			imageRotation: parseInt( props.values.imageRotation ) || 0,
			image: props.values.originalImage,
			imagePositionX: parseFloat( props.values.imagePositionX ) || 0,
			imagePositionY: parseFloat( props.values.imagePositionY ) || 0,
			hasPickedImage: false,
		};

		this.saveImageSettings = this.saveImageSettings.bind( this );
		this.reset = this.reset.bind( this );
		this.onChange = this.onChange.bind( this );
		this.setEditorRef = this.setEditorRef.bind( this );
		this.onPositionChange = this.onPositionChange.bind( this );
		this.uploadPicture = this.uploadPicture.bind( this );
		this.close = this.close.bind( this );
		this.zoomIn = this.zoomIn.bind( this );
		this.zoomOut = this.zoomOut.bind( this );
	}

	onPositionChange( e ) {
		this.setState( {
			imagePositionX: parseInt( e.x ),
			imagePositionY: parseInt( e.y ),
		} );
	}

	async saveImageSettings() {
		const data = this.editor.getImageScaledToCanvas().toDataURL();

		let name = this.state.image.name;
		if ( typeof this.state.image == 'string' ) {
			const image = this.state.image.split( '/' );
			name = image[ image.length - 1 ] && image[ image.length - 1 ].split( '?' )[ 0 ];
		}

		const mimeType = fileNameToMimeType( name );
		const imageFile = await dataURIToFile( data, name, mimeType );

		const fieldsToUpdate = {
			imageRotation: this.state.imageRotation,
			imageScale: parseInt( this.state.imageScale * 100 ),
			imagePositionX: this.state.imagePositionX,
			imagePositionY: this.state.imagePositionY,
			originalImage: this.state.image,
			imageFile,
		};

		this.props.onChange( {
			target: {
				name: this.props.mapToState,
				value: fieldsToUpdate,
			},
		} );
		this.props.closeModal();
	}

	setEditorRef( editor ) {
		this.editor = editor;
	}

	reset() {
		this.setState( {
			imageScale: parseFloat( this.props.imageScale ) / 100.0 || 1.0,
			imageRotation: 0,
		} );
	}

	onChange( name, value ) {
		this.setState( { [ name ]: parseFloat( value ) } );
	}

	uploadPicture( files ) {
		this.setState( { image: files[ 0 ], hasPickedImage: true } );
	}

	close() {
		this.reset();
		this.props.closeModal();
	}

	zoomIn( max ) {
		if ( this.state.imageScale < max ) {
			this.setState( ( prevProps ) => {
				const newImageScale = parseFloat( prevProps.imageScale ) + 0.1;
				return {
					imageScale: newImageScale,
				};
			} );
		}
	}

	zoomOut( min ) {
		if ( min < this.state.imageScale ) {
			this.setState( ( prevProps ) => {
				const newImageScale = parseFloat( prevProps.imageScale ) - 0.1;
				return {
					imageScale: newImageScale,
				};
			} );
		}
	}

	render() {
		const style = {
			width: '100%',
			height: 'auto',
			border: '1px solid #E1E1E1',
			background: 'rgb(225, 225, 225)',
		};

		const acceptedFilesTypes = [
			'image/jpeg',
			'image/png',
			'image/gif'
		];

		const minZoom = 1.0;
		const maxZoom = 4.0;
		const stepZoom = 0.1;

		const minRotate = -180;
		const maxRotate = 180;
		const stepRotate = 1;

		const editor = Object.assign( {}, this.state.editor, {
			scale: this.state.imageScale,
			rotate: this.state.imageRotation,
			image: this.state.image,
		} );

		return (
			<ImagePickerContainer className='imagePicker__container'>
				<section className='image-picker__header'>
					<div className='imagePicker__icon--back' onClick={ this.props.onBack }>
						<Icon type='chevron-left' />
					</div>
					<H1 className='h1--small h1--secondary'>Update Profile Picture</H1>
				</section>
				{ this.state.hasPickedImage && (
					<>
						<section className='image-picker__display'>
							<AvatarEditor
								ref={ this.setEditorRef }
								crossOrigin='anonymous'
								onPositionChange={ this.onPositionChange }
								style={ style }
								{ ...editor }
							/>
						</section>
						<section className='image-picker__control-console'>
							<div className='image-picker__control-console scale-control'>
								<div className='image-picker__control-console scale-control__label'>
									<P className='p--black p--opacity-50'>Adjust zoom level</P>
								</div>
								<div className='image-picker__control-console scale-control__input-wrapper'>
									<div
										className='image-picker__control-console scale-control__icon--minus'
										onClick={ () => this.zoomOut( minZoom ) }
									>
										<Icon type='minus' />
									</div>
									<Slider
										className='scale-control__input'
										name='imageScale'
										mapToState='imageScale'
										value={ this.state.imageScale }
										step={ stepZoom }
										min={ minZoom }
										max={ maxZoom }
										disabled={ false }
										onChange={ this.onChange }
									/>
									<div
										className='image-picker__control-console scale-control__icon--plus'
										onClick={ () => this.zoomIn( maxZoom ) }
									>
										<Icon type='plus' />
									</div>
								</div>
							</div>
							<div className='image-picker__control-console rotation-control'>
								<div className='image-picker__control-console rotation-control__label'>
									<P className='p--black p--opacity-50'>Rotate picture</P>
								</div>
								<Slider
									className='rotation-control__input'
									name='imageRotation'
									mapToState='imageRotation'
									value={ this.state.imageRotation }
									step={ stepRotate }
									min={ minRotate }
									max={ maxRotate }
									disabled={ false }
									onChange={ this.onChange }
								/>
							</div>
							<div className='image-picker__control-console reset-control'>
								<A className='a--green anchor--reset' onClick={ this.reset }>
									RESET
								</A>
							</div>
						</section>
						<section className='image-picker__save-settings'>
							<Button text='Save' onClick={ this.saveImageSettings } />
						</section>
					</>
				) }
				{ !this.state.hasPickedImage && (
					<>
						<Droppable
							className='imagePicker__droppable'
							handleDrop={ this.uploadPicture }
							acceptedFilesTypes={ acceptedFilesTypes }
							content={
								<>
									<Icon type='plus' />
									<P className='p--secondary p--centered p--gray'>
										Click here to add a profile picture
									</P>
									<P className='p--secondary p--centered p--gray'>
										or drag and drop an image
									</P>
									<P className='p--secondary p--centered p--gray'>
										(.png or .jpg)
									</P>
								</>
							}
						/>
						<section className='image-picker__save-settings'>
							<Button text='Save' disabled={ !this.state.hasPickedImage } />
						</section>
					</>
				) }
			</ImagePickerContainer>
		);
	}
}

ProfileImagePicker.propTypes = {
	values: PropTypes.shape( {
		imageScale: PropTypes.number,
		imageRotation: PropTypes.number,
		imagePositionX: PropTypes.number,
		imagePositionY: PropTypes.number,
		originalImage: PropTypes.oneOfType( [ PropTypes.object, PropTypes.string ] ),
	} ).isRequired,

	closeModal: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onBack: PropTypes.func.isRequired,
};

export default ProfileImagePicker;
