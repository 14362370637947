/* eslint max-len: 0 */
const Circle = () => (
	<svg viewBox='-1 -1 24 24'>
		<path
			d='M0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10ZM18.25 10C18.25 5.44365 14.5563 1.75 10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10Z'
			transform='translate(-144 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241)'
		/>
	</svg>
);
export default Circle;
