/* eslint max-len: 0 */
const Warning2 = ( props ) => (
	<svg { ...props } viewBox='0 0 24 24'>
		<path
			d='M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm0 14.25c.58 0 1.057.439 1.118 1.002l.007.123c0 .58-.439 1.057-1.002 1.118L12 18c-.621 0-1.125-.504-1.125-1.125S11.379 15.75 12 15.75zM12 6c.38 0 .693.282.743.648l.007.102v6.75c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648l-.007-.102V6.75c0-.414.336-.75.75-.75z'
			transform='translate(-144 -866) translate(144 183) translate(0 54) translate(0 501) translate(0 37) translate(0 90) translate(0 1)'
		/>
	</svg>
);

export default Warning2;
