import Query from '../../Query';
import { InvitationReturnFields } from '../returnFields';
import * as request from '../types/request';

export enum InviteTypes {
	NewClient = 'NewClient',
	NewOrgMember = 'NewOrgMember',
	NewOrg = 'NewOrg',
}

type InvitationResponse = { data: { createInvitation?: Record<string, any> } };

export type InvitationResponseSelf = request.self<InvitationResponse>;

interface CreateInvitationProps {
	invitationType: string;
	emailAddress: string;
	recipientFirstName: string;
	recipientLastName: string;
	shouldSendContract?: boolean;
	shouldSendInvoice?: boolean;
	onBehalfOfClient?: string;
}

export const createInvitation = async function(
	this: InvitationResponseSelf,
	{
		invitationType,
		emailAddress,
		recipientFirstName,
		recipientLastName,
		shouldSendContract = false,
		shouldSendInvoice = false,
		onBehalfOfClient,
	}: CreateInvitationProps
) {
	// cheater way to get around "this" in typescript here...
	const self = this as InvitationResponseSelf;
	const data = {
		invitationType,
		emailAddress,
		recipientFirstName,
		recipientLastName,
		sendWithContract: shouldSendContract,
		sendWithInvoice: shouldSendInvoice,
		...( onBehalfOfClient && { onBehalfOfClient: { id: onBehalfOfClient } } ),
	};

	const response = await self.request(
		new Query( {
			type: 'mutation',
			name: 'createInvitation',
			params: { data },
			returnFields: [ ...InvitationReturnFields, 'contact { id }' ],
		} )
	);

	// it looks like the backend returns `errors: undefined` on a non-error response...someday we'll change that
	if ( 'errors' in response && response.errors ) {
		return response;
	}

	if ( 'data' in response ) {
		const invitation = response.data.createInvitation;
		return { invitation };
	}

	// We have to do this until we can fix that backend
	return {
		errors: [ new Error( 'An unknown invitation error has occured' ) ],
	};
};
