/* eslint max-len: 0 */
const Pay = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm0 3.75c.38 0 .693.282.743.648L12.75 6l.001.845C14.045 7.178 15 8.353 15 9.75c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L13.5 9.75c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5c0 .78.595 1.42 1.356 1.493l.144.007c1.657 0 3 1.343 3 3 0 1.397-.955 2.572-2.249 2.905L12.75 18c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L11.25 18v-.845C9.956 16.822 9 15.648 9 14.25c0-.414.336-.75.75-.75s.75.336.75.75c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5-.672-1.5-1.5-1.5c-1.657 0-3-1.343-3-3 0-1.398.956-2.572 2.25-2.905V6c0-.414.336-.75.75-.75z'
			transform='translate(-504 -1116) translate(144 183) translate(0 783) translate(0 53) translate(0 97) translate(360)'
		/>
	</svg>
);

export default Pay;
