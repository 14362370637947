/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Underline = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path d='M6.34236 12.2914V3.55536C6.34236 3.34736 6.52956 3.16016 6.73756 3.16016H8.13116C8.35996 3.16016 8.52636 3.34736 8.52636 3.55536V12.1458C8.52636 14.2258 9.85756 15.8482 11.9792 15.8482C14.1216 15.8482 15.4736 14.2466 15.4736 12.1874V3.55536C15.4736 3.34736 15.64 3.16016 15.8688 3.16016H17.2624C17.4704 3.16016 17.6576 3.34736 17.6576 3.55536V12.2914C17.6576 15.453 15.2656 17.9282 11.9792 17.9282C8.71356 17.9282 6.34236 15.453 6.34236 12.2914Z' />
		<path d='M4.51196 19.8002H19.488V20.8402H4.51196V19.8002Z' />
	</svg>
);
export default Underline;
