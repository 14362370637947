import React from 'react';
import Link from 'next/link';
import styles from './SideNavLinkItem.module.scss';
import SideNavButtonItem from '../SideNavButtonItem/SideNavButtonItem';
import { CustomMenuItemType } from '../../CustomMenuItem/CustomMenuItemComponent';
import { useNewLeadsSubscription } from '../../../hooks/useNewLeadsSubscription';
import { MenuItemsIds } from '../../../config/useGetNavigationItems';

export default function SideNavLinkItem( {
	menuItem,
}: {
	menuItem: CustomMenuItemType;
} ) {
	const { badgeContent } = useNewLeadsSubscription(
		menuItem.id !== MenuItemsIds.Leads
	);

	if ( 'href' in menuItem ) {
		return (
			<Link href={ menuItem.href } className={ styles.linkWrap } passHref>
				<SideNavButtonItem menuItem={ menuItem } badgeContent={ badgeContent } />
			</Link>
		);
	} else if ( 'onClick' in menuItem ) {
		return (
			<SideNavButtonItem menuItem={ menuItem } badgeContent={ badgeContent } />
		);
	} else {
		return null;
	}
}
