import { SVGProps } from 'react';

/* eslint max-len: 0 */
const Organization = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<g>
			<path
				d='M3.75 24c-1.19 0-2.166-.925-2.245-2.096L1.5 21.75V10.099C.603 9.58 0 8.61 0 7.5l.018-.163 1.37-6.164C1.54.487 2.148 0 2.851 0h18.295c.704 0 1.313.488 1.465 1.174l1.37 6.163L24 7.5c0 1.11-.603 2.08-1.5 2.598V21.75c0 1.19-.925 2.166-2.096 2.245L20.25 24H3.75zm10.5-6H3v3.75c0 .38.282.693.648.743l.102.007h10.5V18zm4.5-8.517l-.148.158c-.502.493-1.177.81-1.926.854l-.176.005c-.259 0-.51-.033-.75-.094V22.5h4.5c.38 0 .693-.282.743-.648L21 21.75V10.5c-.888 0-1.685-.385-2.234-.998l-.016-.019zm-4.484.019l-.017-.019-.015.019c-.512.572-1.241.946-2.058.993L12 10.5c-.888 0-1.685-.385-2.234-.998l-.017-.019-.015.019c-.512.572-1.241.946-2.058.993L7.5 10.5c-.888 0-1.685-.385-2.234-.998l-.016-.019-.148.158c-.502.493-1.177.81-1.926.854L3 10.5v6h11.25V9.75c0-.08.012-.155.035-.227l-.02-.021zm6.882-8.003L2.852 1.5 1.503 7.568l.004.076c.068.714.635 1.281 1.349 1.35L3 9c.828 0 1.5-.672 1.5-1.5l.008-.12C4.622 6.502 6 6.542 6 7.5 6 8.328 6.672 9 7.5 9S9 8.328 9 7.5l.008-.12c.114-.878 1.492-.838 1.492.12 0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5l.008-.12C13.622 6.502 15 6.542 15 7.5c0 .828.672 1.5 1.5 1.5S18 8.328 18 7.5l.008-.12c.114-.878 1.492-.838 1.492.12 0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5l-.003.07v-.002l-1.35-6.069z'
				transform='translate(-344 -1244) translate(144 183) translate(0 767) translate(0 53) translate(0 241) translate(200)'
			/>
		</g>
	</svg>
);

export default Organization;
