/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronRight = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M5.226 8.234c.275-.283.705-.31 1.008-.078l.087.077 5.687 5.842 5.67-5.841c.275-.283.705-.31 1.008-.078l.087.077c.275.284.3.727.075 1.039l-.075.09-6.217 6.404c-.274.283-.704.31-1.007.078l-.087-.077-6.235-6.405c-.302-.311-.303-.816 0-1.128z'
			transform='translate(-424 -1020) translate(144 183) translate(0 783) translate(0 53) matrix(0 -1 1 0 280 25)'
		/>
	</svg>
);

export default ChevronRight;
