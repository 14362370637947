import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import styles from './HelpButton.module.scss';
import QuestionMarkIconThin from '../Icons/QuestionMarkIconThin';
import { gql } from '@apollo/client';
import MuiSpinner from '../MuiSpinner';
import { useHelpButton_GetGlobalVariablesQuery } from './__generated__/HelpButton';
import { useOrgOnboardingModal } from '../../hooks/useOrgOnboardingModal';
import { OrgOnboardingModal } from '../../blocks/OrgOnboardingModal';
import Link from 'next/link';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query HelpButton_getGlobalVariables {
		getGlobalVariables {
			HelpUrl
		}
	}
`;

export const HelpButton = () => {
	const [ open, setOpen ] = useState( false );

	const ref = useRef<HTMLButtonElement>( null );

	const { data: globalVariableData, loading: globalVariableLoading } =
		useHelpButton_GetGlobalVariablesQuery();
	const href = globalVariableData?.getGlobalVariables.HelpUrl;

	const {
		setOpen: setOrgOnboardingModalOpen,
		isOrgOwner,
		...orgOnboardingModalProps
	} = useOrgOnboardingModal();

	const handleClose = useCallback( () => setOpen( false ), [] );

	// close this menu if the org onboarding modal opens
	useEffect( () => {
		if ( orgOnboardingModalProps.open ) {
			setOpen( false );
		}
	}, [ orgOnboardingModalProps ] );

	if ( globalVariableLoading || !href ) {
		return <MuiSpinner />;
	}

	const buttonProps = isOrgOwner
		? {
			onClick: () => setOpen( true ),
		  }
		: {
			href,
			component: 'a',
			LinkComponent: Link,
			target: '_blank',
			rel: 'noreferrer',
		  };

	return (
		<>
			<IconButton data-cy='help-button' size='small' ref={ ref } { ...buttonProps }>
				<QuestionMarkIconThin className={ styles.icon } />
			</IconButton>
			{ isOrgOwner ? (
				<>
					<Menu open={ open } onClose={ handleClose } anchorEl={ ref.current }>
						<MenuItem
							onClick={ handleClose }
							href={ href }
							component='a'
							LinkComponent={ Link }
							target='_blank'
							rel='noreferrer'
							data-cy='help-link'
						>
							Help Center
						</MenuItem>
						<MenuItem onClick={ () => setOrgOnboardingModalOpen( true ) }>
							Getting Started
						</MenuItem>
					</Menu>
					<OrgOnboardingModal { ...orgOnboardingModalProps } />
				</>
			) : null }
		</>
	);
};
