/* eslint max-len: 0 */
const Menu = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M21.286 17c.394 0 .714.448.714 1 0 .506-.269.925-.617.99l-.097.01H2.714C2.32 19 2 18.552 2 18c0-.506.269-.925.617-.99l.097-.01h18.572zm0-6c.394 0 .714.448.714 1 0 .506-.269.925-.617.99l-.097.01H2.714C2.32 13 2 12.552 2 12c0-.506.269-.925.617-.99l.097-.01h18.572zm0-6c.394 0 .714.448.714 1 0 .506-.269.925-.617.99l-.097.01H2.714C2.32 7 2 6.552 2 6c0-.506.269-.925.617-.99L2.714 5h18.572z'
			transform='translate(-327 -33) translate(327 33)'
		/>
	</svg>
);

export default Menu;
