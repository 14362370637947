/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ArrowRight = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path d='M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z' />
	</svg>
);

export default ArrowRight;
