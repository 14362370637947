import React, { useCallback, useEffect, useState } from 'react';
import {
	flagExistsAndIsActive,
	isClient,
	isOrgAdmin,
	isOrgUser,
} from '../lib/helpers/index';
import {
	ProposalsIcon,
	DashboardIcon,
	ContractsIcon,
	ContactsIcon,
	InvoicesIcon,
	ClientFoldersIcon,
	CompanyFoldersIcon,
	ProposalTemplatesIcon,
	ContractTemplatesIcon,
	InvoiceTemplatesIcon,
	ResourcesIcon,
	VendorsIcon,
	InquiryIcon,
} from '../mui/Icons/index';
import { Routes } from './routes';
import styles from './getNavigationItems.module.scss';
import { CustomMenuItemType } from '../mui/CustomMenuItem/CustomMenuItemComponent';
import TimelinesIcon from '../mui/Icons/TimelinesIcon';
import { gql } from '@apollo/client';
import { useGetNavigationItems_GetOrgUserWhereQuery } from '../types/pages';
import { FlattenedUser } from '../types/user';
import { useSelector } from 'react-redux';
import rpcShared from '@rockpapercoin/rpc-shared';
import { showError } from '../blocks/Toast';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getNavigationItems_getOrgUserWhere = gql`
	query getNavigationItems_getOrgUserWhere($where: OrgUserWhereUniqueInput!) {
		getOrgUserWhere(where: $where) {
			organization {
				id
				createdAt
				subscription {
					id
					status
					subscriptionPlan {
						id
						type
					}
				}
				groups {
					name
				}
			}
		}
	}
`;

export enum MenuItemsIds {
	Dashboard = 'dashboard',
	Contacts = 'contacts',
	Proposals = 'proposals',
	Contracts = 'contracts',
	Invoices = 'invoices',
	Vendors = 'vendors',
	CustomerFolders = 'customerFolders',
	CompanyFolders = 'companyFolders',
	MyFolder = '/myFolder',
	ProposalTemplates = 'proposalTemplates',
	ContractTemplates = 'contractTemplates',
	InvoiceTemplates = 'invoiceTemplates',
	Resources = 'resources',
	Profile = 'profile',
	Admin = 'admin',
	Wallet = 'wallet',
	ClientUsers = 'clientUsers',
	OrgUsers = 'orgUsers',
	Organizations = 'organizations',
	OrgOnboarding = 'orgOnboarding',
	ClientSignUp = 'clientSignUp',
	OrgMemberSignUp = 'orgMemberSignUp',
	Timelines = 'timelines',
	Leads = 'leads',
}

export type NavigationItems = { [index: string]: CustomMenuItemType };

const useGetNavigationItems = ( featureFlags: any ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state?.user );
	const userId = user?.isLoggedIn ? user.id : '';
	const { data, error } = useGetNavigationItems_GetOrgUserWhereQuery( {
		variables: { where: { id: userId } },
		skip: !user?.isLoggedIn,
	} );
	const isUserOrgAdmin = user?.isLoggedIn ? isOrgAdmin( user ) : false;
	const isUserOrgUser = user?.isLoggedIn ? isOrgUser( user ) : false;
	const isUserClient = user?.isLoggedIn ? isClient( user ) : false;
	const isUserSuperAdmin = user && 'isSuperAdmin' in user && user.isSuperAdmin;
	const canViewLeads = rpcShared.inquiries.canAccessLeads(
		data?.getOrgUserWhere?.organization
	);

	const [ subscriptionNeededModalOpen, setSubscriptionNeededModalOpen ] =
		useState( false );

	const closeSubscriptionNeededModal = useCallback(
		() => setSubscriptionNeededModalOpen( false ),
		[]
	);

	useEffect( () => {
		if ( error ) {
			showError( error );
		}
	}, [ error ] );

	const menuItems: NavigationItems = {
		[ MenuItemsIds.Dashboard ]: {
			id: 'dashboard',
			label: 'Dashboard',
			href: Routes.Dashboard,
			icon: <DashboardIcon />,
			isDisabled: isUserSuperAdmin,
		},
		[ MenuItemsIds.Contacts ]: {
			id: 'clients',
			label: 'Clients',
			href: Routes.Clients,
			icon: <ContactsIcon />,
			isVisible: isUserOrgAdmin || isUserOrgUser,
		},
		[ MenuItemsIds.Proposals ]: {
			id: 'proposals',
			label: 'Proposals',
			href: Routes.Proposals,
			icon: <ProposalsIcon />,
			isDisabled: isUserSuperAdmin,
		},
		[ MenuItemsIds.Contracts ]: {
			id: 'contracts',
			label: 'Contracts',
			href: Routes.Contracts,
			icon: <ContractsIcon />,
			isDisabled: isUserSuperAdmin,
		},
		[ MenuItemsIds.Invoices ]: {
			id: 'invoices',
			label: 'Invoices',
			href: Routes.Invoices,
			icon: <InvoicesIcon />,
		},
		[ MenuItemsIds.Vendors ]: {
			id: 'vendors',
			label: 'Vendors',
			href: '/vendors',
			isVisible: isUserClient,
			icon: <VendorsIcon />,
		},
		[ MenuItemsIds.ClientUsers ]: {
			id: 'clientUsers',
			label: 'Client Users',
			href: '/ClientUsers',
			isVisible: isUserSuperAdmin,
		},
		[ MenuItemsIds.OrgUsers ]: {
			id: 'orgUsers',
			label: 'Org Users',
			href: '/OrgUsers',
			isVisible: isUserSuperAdmin,
		},
		[ MenuItemsIds.Organizations ]: {
			id: 'organizations',
			label: 'Organizations',
			href: '/Organizations',
			isVisible: isUserSuperAdmin,
		},

		[ MenuItemsIds.CustomerFolders ]: {
			id: 'customerFolders',
			label: 'Client Folders',
			href: Routes.CustomerFolders,
			icon: <ClientFoldersIcon />,
			isDisabled: !isUserOrgUser,
		},
		[ MenuItemsIds.CompanyFolders ]: {
			id: 'companyFolders',
			label: 'Company Folders',
			href: Routes.CompanyFolders,
			icon: <CompanyFoldersIcon />,
			isVisible: isUserOrgAdmin,
		},
		[ MenuItemsIds.MyFolder ]: {
			id: 'myFolder',
			label: isUserClient ? 'Folder' : 'Personal Folder',
			href: Routes.MyFolder,
			icon: <ClientFoldersIcon />,
		},
		[ MenuItemsIds.ProposalTemplates ]: {
			id: 'proposalTemplates',
			label: 'Proposal Templates',
			href: Routes.ProposalTemplates,
			icon: <ProposalTemplatesIcon />,
			isDisabled: !isUserOrgAdmin,
		},
		[ MenuItemsIds.ContractTemplates ]: {
			id: 'contactTemplates',
			label: 'Contract Templates',
			href: Routes.ContractTemplates,
			icon: <ContractTemplatesIcon className={ styles.contractTemplateIcon } />,
			isVisible: isUserOrgAdmin,
		},
		[ MenuItemsIds.InvoiceTemplates ]: {
			id: 'invoiceTemplates',
			label: 'Invoice Templates',
			href: Routes.InvoiceTemplates,
			icon: <InvoiceTemplatesIcon className={ styles.invoiceTemplateIcon } />,
			isVisible: isUserOrgAdmin,
		},
		[ MenuItemsIds.Resources ]: {
			id: 'resources',
			label: 'Resources',
			href: Routes.Resources,
			icon: <ResourcesIcon />,
			isVisible: isUserOrgAdmin,
		},
		[ MenuItemsIds.Timelines ]: {
			id: 'timelines',
			href: '/timelines',
			label: 'Timeline Genius',
			isVisible:
				isUserOrgUser && flagExistsAndIsActive( featureFlags, 'TGIntegrationV1' ),
			icon: <TimelinesIcon />,
		},
		[ MenuItemsIds.Leads ]: {
			id: 'leads',
			...( !canViewLeads
				? {
					onClick: () => setSubscriptionNeededModalOpen( true ),
				  }
				: {
					href: '/leads',
				  } ),
			label: 'Leads',
			isVisible: isUserOrgAdmin,
			icon: <InquiryIcon />,
		},
	};

	return {
		items: menuItems,
		subscriptionNeededModalOpen,
		closeSubscriptionNeededModal,
	};
};

export default useGetNavigationItems;
