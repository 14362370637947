import Query from '../../Query.js';
import {
	ClientUserDetailReturnFields,
	ContractDetailReturnFields,
	ContractTemplateReturnFields,
	EmbeddedRequestReturnFields,
	OrgUserReturnFields,
	GetContractsWhereFields,
} from '../returnFields';
import { convertContract } from '../../helpers';

const ContractStatuses = {
	Complete: 'Complete',
	Deleted: 'Deleted',
	Draft: 'Draft',
	Expired: 'Expired',
	ReadyToBeSignedByVendor: 'ReadyToBeSignedByVendor',
	Sent: 'Sent',
	Signed: 'Signed',
	Void: 'Void',
};

const queryReducer = ( constraints, query ) => {
	return [
		...constraints,
		{ title_contains: query },
		{
			signatures: {
				some: {
					customerSignature: {
						contact: {
							OR: [ { firstName_contains: query }, { lastName_contains: query } ],
						},
					},
				},
			},
		},
		{
			sentByUser: {
				OR: [ { firstName_contains: query }, { lastName_contains: query } ],
			},
		},
		{ vendor: { name_contains: query } },
		{
			signatures: {
				some: {
					customerSignature: {
						contact: {
							customer: { email_contains: query },
						},
					},
				},
			},
		},
	];
};

/**
 * Creates a draft contract template.
 *
 * @param { String } title - A title to apply to the template.
 * @param { Boolean } vendorWillSign - Wether or not the vendor will have a signing role.
 *
 * @returns {Promise<Object>} - { contractTemplate | errors }
 */
const createContractTemplate = async function( title, vendorWillSign ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createContractTemplate',
			params: {
				data: {
					title,
					vendorWillSign,
				},
			},
			returnFields: [ 'id' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contractTemplate: data.data.createContractTemplate,
	};
};

/**
 * finalizes contract template.
 *
 * @param { String } contractTemplateID - the ID of the contract template
 * @param { String } sourceFileUrl - The url of the file to use for the contract template.
 * @param { Boolean } vendorWillSign - Wether or not the vendor will have a signing role.
 *
 * @returns {Promise<Object>} - { contractTemplate | errors }
 */
const createContractTemplateMarkupUrl = async function(
	contractTemplateID,
	sourceFileUrl,
	vendorWillSign
) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createContractTemplateMarkupUrl',
			params: {
				where: { id: contractTemplateID },
				data: {
					vendorWillSign,
					sourceFileUrl,
				},
			},
			returnFields: [ 'id', 'editUrl' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contractTemplate: data.data.createContractTemplateMarkupUrl,
	};
};

/**
 * Updates a contract template.
 *
 * @param { String } id - The ID of the template to update.
 * @param { Object } updateData - "input ContractTemplateUpdateInput"
 *
 * @returns { Promise<Object> } { contractTemplate | errors }
 */
const updateContractTemplate = async function( id, updateData ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateContractTemplate',
			params: {
				where: { id },
				data: updateData,
			},
			returnFields: ContractTemplateReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contractTemplate: data.data.updateContractTemplate,
	};
};

/**
 * Deletes a contract template.
 *
 * @param { string } id - The ID of the contract template to delete.
 *
 * @returns {Promise<Object>} - { success | errors }
 */
const deleteContractTemplate = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'deleteContractTemplate',
			params: {
				where: {
					id,
				},
			},
			returnFields: [ 'id' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const success = !!data.data.deleteContractTemplate;

	return {
		success,
	};
};

/**
 * Update a single contract.
 *
 * @param {object} updateData - "input ContractUpdateCustomInput".
 * @param {string} id - ID of the contract to update.
 *
 * @returns {object} res - { errors, contract }
 */
const updateContract = async function( updateData, id ) {
	const where = { id };

	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateContract',
			params: { data: updateData, where },
			returnFields: ContractDetailReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contract: data.data.updateContract,
		errors: data.data.errors,
	};
};

/**
 * As a planner, approve a contract for a client.
 *
 * @param {String} id - Contract ID.
 *
 * @returns {Promise< responseObject: { { contract: any } | { errors: Error } } > }
 */
const approveContract = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'approveContract',
			params: { where: { id } },
			returnFields: [ 'id' ],
		} )
	);

	const responseObject = {};
	if ( errors ) {
		responseObject.errors = errors;
	}

	if ( data.data?.approveContract ) {
		const contract = convertContract( data.data.approveContract );
		responseObject.contract = contract;
	}

	return responseObject;
};

/**
 * Get an embedded request object for a given contract
 *
 * @param {string} id - The ID of the contract to request.
 *
 * @returns {object} res - { errors, embeddedRequest }
 */
const createEmbeddedRequest = async function( id ) {
	const where = { id };

	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createEmbeddedRequest',
			params: { where },
			returnFields: EmbeddedRequestReturnFields,
		} )
	);
	if ( errors ) {
		return { errors };
	}

	return {
		embeddedRequest: data.data.createEmbeddedRequest,
		errors: data.data.errors,
	};
};

/**
 * Refresh contract guest token.
 *
 * @param {string} id - ID of the contract.
 *
 * @returns { Promise< { errors: Array< Error > } | true > } res - {success}
 */
const refreshContractGuestToken = async function( id ) {
	const where = { id };

	const { errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'refreshContractGuestToken',
			params: { where },
		} )
	);
	if ( errors ) {
		return { errors };
	}

	return true;
};

/**
 * Resend a contract to the assigned client.
 *
 * @param {string} id - ID of the contract.
 *
 * @returns {Promise< { success: boolean } | { errors: Array< Error > } > } res - {success}
 */
const resendContract = async function( id ) {
	const where = { id };

	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'resendContract',
			params: { where },
		} )
	);
	if ( errors ) {
		return { errors };
	}

	const success = data.data.resendContract;

	return { success };
};

/**
 * Void a contract.
 *
 * @param {string} id - ID of the contract.
 *
 * @returns { Promise< { contract: Record< string, any > } | { errors: Array< Error > } } > } res - {errors, contract}
 */
const voidContract = async function( id ) {
	const where = { id };

	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'voidContract',
			params: { where },
			returnFields: ContractDetailReturnFields,
		} )
	);
	if ( errors || data.errors ) return { errors: errors || data.errors };

	const contract = data.data.voidContract;

	return { contract };
};

/**
 * Toggle the archive status of a contract for a user.
 *
 * @param {Object} id - Contract ID.
 *
 * @returns {Object} res - { errors, contract}
 */
const toggleContractArchiveState = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'toggleContractArchiveState',
			params: { where: { id } },
			returnFields: ContractDetailReturnFields,
		} )
	);
	if ( errors || data.errors ) return { errors: errors || data.errors };

	const contract = data.data.toggleContractArchiveState;

	return { contract };
};

/**
 * Void an existing contract and port details to a new Draft.
 *
 * @param {String} id - Contract ID.
 *
 * @return {Object} res - { errors, contract }
 */
const voidAndPortContract = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'voidAndPortContract',
			params: { where: { id } },
			returnFields: ContractDetailReturnFields,
		} )
	);
	if ( errors || data.errors ) return { errors: errors || data.errors };

	return { contract: data.data.voidAndPortContract };
};

/**
 * Get an embeddedSigning url for a given contract
 *
 * @param { string } id - The ID of the contract to sign.
 * @param { string } guestToken - an optionally submitted guestToken
 *
 * @returns {object} res - { errors, url }
 */
const createEmbeddedSigning = async function( id, guestToken = null ) {
	const where = { contract: { id } };
	if ( guestToken ) {
		where.guestToken = guestToken;
	}

	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createEmbeddedSigning',
			params: { where },
			returnFields: [ 'signingUrl' ],
		} )
	);
	if ( errors || data.errors ) return { errors: errors || data.errors };

	return {
		url: data.data.createEmbeddedSigning.signingUrl,
		errors: data.data.errors,
	};
};

/**
 * Gets an organization's contract templates.
 *
 * @param { Int } first - The maximum number of nodes to return.
 * @param { Int } skip - The number of nodes to skip before returning.
 * @param { object | null } orderBy - The number of nodes to skip before returning.
 *
 * @returns { Promise<Object>} - { contractTemplates, moreToLoad | errors }
 */
const getOrganizationContractTemplates = async function(
	first = 10,
	skip = 0,
	orderBy = { createdAt: 'desc' }
) {
	let orderByParam;
	if ( Array.isArray( orderBy ) ) {
		orderByParam = orderBy;
	} else {
		orderByParam = [ orderBy ];
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationContractTemplates',
			params: {
				where: { status: 'Complete' },
				orderBy: orderByParam,
				take: first + 1,
				skip,
			},
			returnFields: [ '_count', { contractTemplates: ContractTemplateReturnFields }, ],
		} )
	);
	if ( errors ) {
		return { errors };
	}

	const contractTemplates =
		data.data.getOrganizationContractTemplates.contractTemplates;
	let moreToLoad = false;

	if ( contractTemplates.length > first ) {
		contractTemplates.pop();
		moreToLoad = true;
	}

	return {
		contractTemplates,
		moreToLoad,
		count: data.data.getOrganizationContractTemplates._count,
	};
};

/**
 * Gets a contract template belonging to the requester's organization.
 *
 * @param { Object } where - "input ContractTemplateWhereUniqueInput"
 *
 * @returns { Promise<Object>} - { contractTemplate | errors }
 */
const getOrganizationContractTemplate = async function( where ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationContractTemplate',
			params: {
				where,
			},
			returnFields: ContractTemplateReturnFields,
		} )
	);
	if ( errors ) {
		return { errors };
	}

	const contractTemplate = data.data.getOrganizationContractTemplate;
	return {
		contractTemplate,
	};
};

/**
 * Get a list of a user's contracts, by date, for use in the contracts index.
 *
 * @param { {
 *   take?: number,
 *   skip?: number,
 *   orderBy?: import('../../../mui/MuiSortableTable/types').MuiSortableTableOrderByType
 *   userToFetchFor?: { user: { id } }
 *   filter?: Array<string>
 * } }
 *
 * @returns { object } res - { errors, contracts, beforeDate, moreToLoad }
 */
const getContractsForVendor = async function( {
	take = 10,
	skip = 0,
	orderBy = [ { updatedAt: 'desc' } ],
	userToFetchFor = null,
	filter,
} ) {
	const contractWhere = {
		archivedByVendor: false,
		NOT: [ { status: ContractStatuses.Void } ],
		...( userToFetchFor
			? {
				signatures: {
					some: {
						customerSignature: {
							contact: {
								customer: {
									id: userToFetchFor.user.id,
								},
							},
						},
					},
				},
			  }
			: {} ),
		...( filter?.length ? { OR: filter.reduce( queryReducer, [] ) } : {} ),
	};

	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getContractsForVendor',
			params: {
				where: contractWhere,
				take: take + 1,
				skip,
				orderBy,
			},
			returnFields: [
				'_count',
				{
					contracts: GetContractsWhereFields,
				},
			],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const contracts = data.data.getContractsForVendor.contracts.map( ( contract ) =>
		convertContract( contract )
	);

	return {
		contracts: contracts,
		errors: data.data.errors,
		moreToLoad: contracts.length > take,
		skip: skip + take,
		count: data.data.getContractsForVendor._count,
	};
};

/**
 * Get a list of a user's contracts, by date, for use in the contracts index.
 *
 * @param { {
 *   take?: number,
 *   skip?: number,
 *   orderBy?: import('../../../mui/MuiSortableTable/types').MuiSortableTableOrderByType
 *   userToFetchFor?: { user: { id } }
 *   vendorToFetchFor?: { id: string }
 *   filter?: Array<string>
 * } }
 *
 * @returns { object } res - { errors, contracts, beforeDate, moreToLoad }
 */
const getContractsForCustomer = async function( {
	take = 10,
	skip = 0,
	orderBy = [ { updatedAt: 'desc' } ],
	userToFetchFor = null,
	vendorToFetchFor = null,
	filter,
} ) {
	const contractWhere = {
		archivedByVendor: false,
		NOT: [ { status: ContractStatuses.Void } ],
		signatures: {
			some: {
				customerSignature: {
					contact: {
						customer: {
							id: userToFetchFor?.user.id,
						},
					},
				},
			},
		},
		...( vendorToFetchFor ? { vendor: { id: vendorToFetchFor.id } } : {} ),
		...( filter?.length ? { OR: filter.reduce( queryReducer, [] ) } : {} ),
	};

	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getContractsForCustomer',
			params: {
				where: contractWhere,
				take: take + 1,
				skip,
				orderBy,
			},
			returnFields: [
				'_count',
				{
					contracts: GetContractsWhereFields,
				},
			],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const contracts = data.data.getContractsForCustomer.contracts.map(
		( contract ) => convertContract( contract )
	);

	return {
		contracts: contracts,
		errors: data.data.errors,
		moreToLoad: contracts.length > take,
		skip: skip + take,
		count: data.data.getContractsForCustomer._count,
	};
};

/**
 * reports a successful signing experience to the API and retrieves current contract state
 *
 * @param { {
 *   id: string,
 *   guestToken?: string,
 *   route?: string,
 *   bundle?: { contract: string, invoice: string, proposal: string }
 * } } args
 * @returns { Promise< Error[]| object> } CustomContract
 */
const reportContractSigning = async function( {
	id,
	guestToken = null,
	route = '',
	bundle,
} ) {
	const where = { contract: { id } };
	if ( guestToken ) {
		where.guestToken = guestToken;
	}

	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'reportContractSigning',
			params: {
				where,
				data: {
					page: route,
					...( bundle ? { bundle } : {} ),
				},
			},
			returnFields: ContractDetailReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contract: convertContract( data.data.reportContractSigning ),
		errors: data.data.errors,
	};
};

/**
 * Allows an orgUser to report a contract as ready for signing
 *
 * @param { string } id the id of the contract reported
 * @param { string } route - the page mutation is called from
 * @param { { contract: boolean, invoice: boolean } } bundle
 * @returns { Promise< object | Errors> } CustomContract
 */
const reportContractMarkup = async function( { id, route = '', bundle } ) {
	const where = { id };
	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'reportContractMarkup',
			params: {
				where,
				data: {
					page: route,
					...( bundle ? { bundle } : {} ),
				},
			},
			returnFields: [
				...ContractDetailReturnFields,
				{
					contact: [
						{ assignedMember: OrgUserReturnFields },
						{
							customer: [
								'id',
								'email',
								{ clientUser: ClientUserDetailReturnFields },
								{ guestUser: [ 'id' ] },
							],
						},
					],
				},
			],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contract: convertContract( data.data.reportContractMarkup ),
		errors: data.data.errors,
	};
};

const reportTemplateMarkup = async function( id ) {
	const where = { id };
	const { errors, data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'reportTemplateMarkup',
			params: { where },
			returnFields: ContractTemplateReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		contractTemplate: data.data.reportTemplateMarkup,
		errors: data.data.errors,
	};
};

export {
	approveContract,
	ContractStatuses,
	createContractTemplate,
	createEmbeddedRequest,
	createEmbeddedSigning,
	deleteContractTemplate,
	createContractTemplateMarkupUrl,
	queryReducer,
	getContractsForVendor,
	getContractsForCustomer,
	getOrganizationContractTemplate,
	getOrganizationContractTemplates,
	refreshContractGuestToken,
	reportContractSigning,
	reportContractMarkup,
	reportTemplateMarkup,
	resendContract,
	toggleContractArchiveState,
	updateContract,
	updateContractTemplate,
	voidAndPortContract,
	voidContract,
};
