import React, { SVGProps } from 'react';

const Due = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12 .75C18.213.75 23.25 5.787 23.25 12S18.213 23.25 12 23.25.75 18.213.75 12 5.787.75 12 .75zm0 1.5c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm0 2.25c.38 0 .693.282.743.648l.007.102v4.628c.874.31 1.5 1.142 1.5 2.122 0 .347-.079.676-.22.97l2.25 2.25c.293.293.293.767 0 1.06-.266.267-.683.29-.976.073l-.084-.073-2.25-2.25c-.294.141-.623.22-.97.22-1.243 0-2.25-1.007-2.25-2.25 0-.98.626-1.814 1.5-2.122V5.25c0-.414.336-.75.75-.75zm0 6.75c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75z'
			transform='translate(-384 -1068) translate(144 183) translate(0 783) translate(0 53) translate(0 49) translate(240)'
		/>
	</svg>
);

export default Due;
