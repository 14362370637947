import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function QuestionMarkIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path d='M12 6.6383C11.302 6.6383 10.6585 6.98982 10.2254 7.57928C9.90146 8.0201 9.70213 8.58756 9.70213 9.2147C9.70213 9.63773 9.3592 9.98066 8.93617 9.98066C8.51314 9.98066 8.17021 9.63773 8.17021 9.2147C8.17021 8.26099 8.4739 7.37577 8.99092 6.67217C9.68076 5.73339 10.7629 5.10638 12 5.10638C14.1765 5.10638 15.8298 7.00984 15.8298 9.2147C15.8298 10.4187 15.3457 11.5147 14.5579 12.2732C14.1897 12.6277 13.7537 12.9098 13.2691 13.0921C13.1026 13.1548 12.9621 13.2504 12.8725 13.3505C12.7869 13.4462 12.766 13.5223 12.766 13.5783V15.0638C12.766 15.4869 12.423 15.8298 12 15.8298C11.577 15.8298 11.234 15.4869 11.234 15.0638V13.5783C11.234 12.5549 12.0606 11.9101 12.7296 11.6583C13.0097 11.553 13.2697 11.3869 13.4953 11.1697C13.9797 10.7034 14.2979 10.0073 14.2979 9.2147C14.2979 7.72769 13.2077 6.6383 12 6.6383Z' />
			<path d='M13.0213 18.1277C13.0213 18.6917 12.564 19.1489 12 19.1489C11.436 19.1489 10.9787 18.6917 10.9787 18.1277C10.9787 17.5636 11.436 17.1064 12 17.1064C12.564 17.1064 13.0213 17.5636 13.0213 18.1277Z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM1.53191 12C1.53191 6.21864 6.21864 1.53191 12 1.53191C17.7814 1.53191 22.4681 6.21864 22.4681 12C22.4681 17.7814 17.7814 22.4681 12 22.4681C6.21864 22.4681 1.53191 17.7814 1.53191 12Z'
			/>
		</SvgIcon>
	);
}
