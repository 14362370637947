import { ApiResponseType } from '../../../../hooks/useMutation.js';
import Query from '../../../Query.js';
import { SendProposalReturnFields } from '../../returnFields/proposals';
import { SendProposalResponse } from '../types.js';

const sendProposal = async function(
	this: any,
	id: string,
	bundle: { [index: string]: string | Date | null } | null
): Promise<ApiResponseType<SendProposalResponse>> {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'sendProposal',
			params: {
				where: { id },
				...( bundle ? { data: { bundle } } : {} ),
			},
			returnFields: SendProposalReturnFields,
		} )
	);

	if ( errors ) return { errors };

	return data.data.sendProposal;
};

export default sendProposal;
