import { useState, useCallback, useRef } from 'react';

const useElementSizeObserver = <T extends Element>() => {
	const ref = useRef<T | null>( null );
	const [ dimensions, setDimensions ] = useState<{
		width: number;
		height: number;
	}>( { width: 0, height: 0 } );
	const sizer: ResizeObserverCallback = useCallback( () => {
		if ( ref.current ) {
			const element = ref.current;
			setDimensions( {
				width: element?.clientWidth || 0,
				height: element?.clientHeight || 0,
			} );
		}
	}, [ setDimensions ] );
	const setRef = useCallback( ( node: T | null ) => {
		if ( node ) {
			ref.current = node;
			/* Apparently browsers auto-unobserve Observers when the things they're attached to get
			disposed of. So we don't have to worry about "cleaning up after ourselves" here and
			disposing of this observer manually! */
			new ResizeObserver( sizer ).observe( node );
		}
	}, [] );
	return {
		width: dimensions.width,
		height: dimensions.height,
		ref: setRef,
	};
};

export default useElementSizeObserver;
