import { Button } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import Icon from '../../../elements/icons';
import styles from './MuiPaperBackButton.module.scss';

export type MuiPaperBackButtonProps =
	| {
		href: string;
		text: string;
	  }
	| {
		onClick: () => void;
		text: string;
	  };

export const couldRenderMuiPaperBackButton = (
	props: any
): props is MuiPaperBackButtonProps =>
	( typeof props?.href === 'string' || typeof props?.onClick !== 'undefined' ) &&
	typeof props.text === 'string';

const MuiPaperBackButton: React.FC<MuiPaperBackButtonProps> = ( props ) =>
	'onClick' in props ? (
		<Button
			onClick={ props.onClick }
			className={ styles.button }
			startIcon={ <Icon type='chevron-left' /> }
			data-cy='paper-back-button'
		>
			{ props.text }
		</Button>
	) : (
		<Link
			href={ props.href }
			className={ styles.link }
			passHref
			data-cy='paper-back-button'
		>
			<Icon type='chevron-left' />
			{ props.text }
		</Link>
	);

export default MuiPaperBackButton;
