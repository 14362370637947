import { SVGProps } from 'react';

/* eslint max-len: 0 */
const Plus = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M12 4.75c.38 0 .693.282.743.648l.007.102v5.75h5.75c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-5.75v5.75c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648l-.007-.102v-5.75H5.5c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h5.75V5.5c0-.414.336-.75.75-.75z'
			transform='translate(-144 -1020) translate(144 183) translate(0 783) translate(0 53) translate(0 1)'
		/>
	</svg>
);

export default Plus;
