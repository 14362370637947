import { getEnumKeyByValue } from '../../../lib/helpers/apollo';
import rpcShared from '@rockpapercoin/rpc-shared';
import { ReferralCategory } from '../../../types/generated';

export const exposureOptionFromString = (
	exposure?: string
): ReferralCategory | undefined => {
	if (
		typeof exposure === 'string' &&
		exposure &&
		Object.values<string>( ReferralCategory ).includes( exposure )
	) {
		return getEnumKeyByValue( ReferralCategory, exposure ) as ReferralCategory;
	}
};

export const errorMessageIncludesExpectedMessageByKeys = (
	keysToCheck: Array<keyof typeof rpcShared.strings.errorMessages>,
	errorMessage: string
) =>
	keysToCheck.some( ( key ) =>
		errorMessage.includes( rpcShared.strings.errorMessages[ key ] as string )
	);
