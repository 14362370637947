// packages
import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

// markup
import { showError } from '../../Toast';

const StyledDiv = styled.div`
	flex: 0.8;
	display: flex;
	background: #f1f1f1;
	flex-direction: column;
	justify-content: space-around;
`;

const Zone = styled.div`
	justify-content: center;
	display: flex;
	flex: 1;
	flex-direction: column;

	p {
		width: 100%;
		text-align: 'center';
	}
`;

const ActiveDiv = styled( StyledDiv )`
	border-color: green;
`;

class Droppable extends React.Component {
	constructor( props ) {
		super( props );

		this.onDrop = this.onDrop.bind( this );
	}

	onDrop( acceptedFiles ) {
		if ( acceptedFiles.length ) {
			this.props.handleDrop( acceptedFiles );
		} else {
			showError( 'Oops! Please upload a valid image file.' );
		}
	}

	render() {
		return (
			<Dropzone
				onDrop={ this.onDrop }
				accept={ this.props.acceptedFilesTypes }
				className={ this.props.className }
			>
				{ ( { getRootProps, getInputProps, isDragActive } ) => {
					const Div = isDragActive ? ActiveDiv : StyledDiv;
					return (
						<Div
							{ ...getRootProps() }
							className={ `dropzone ${ isDragActive ? 'dropzone--isActive' : '' }` }
						>
							<Zone className={ this.props.className || '' }>
								{ this.props.content }
								<input { ...getInputProps() } />
							</Zone>
						</Div>
					);
				} }
			</Dropzone>
		);
	}
}

export default Droppable;
