import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import SideNavItem from '../SideNavButtonItem/SideNavButtonItem';
import useMenu from '../../../hooks/useMenu';
import CustomMenuItemList from '../../CustomMenuItemList/CustomMenuItemList';
import CustomMenu from '../../CustomMenu/CustomMenu';
import styles from './SideNavDropdownItem.module.scss';
import { DropdownType } from '../SideNavContainer';

export default function SideNavDropdownItem( {
	menuItem: { id, label, icon, items },
}: {
	menuItem: DropdownType;
} ) {
	const { anchorEl, open, handleClick, handleClose } = useMenu();
	const [ isActive, setIsActive ] = useState( false );
	const router = useRouter();

	useEffect( () => {
		setIsActive(
			items.some( ( item ) =>
				'href' in item ? router.asPath === item.href : false
			)
		);
	}, [ router, items ] );

	return (
		<>
			<SideNavItem
				menuItem={ {
					id: id,
					onClick: handleClick,
					isActive: isActive,
					label: label,
					icon: icon,
					className: styles.sidenavItem,
				} }
			/>
			<CustomMenu
				anchorEl={ anchorEl }
				open={ open }
				onClose={ handleClose }
				onClick={ handleClose }
				PaperProps={ {
					elevation: 0,
				} }
				arrowPosition={ 'left' }
				data-cy={ 'sideNavDropdownMenu' }
			>
				<CustomMenuItemList items={ items } />
			</CustomMenu>
		</>
	);
}
