import { getFileType, getCookie } from '../../helpers';
import Query from '../../Query';
import { createAxiosInstance } from '../axios';
import { requestWithoutToken } from '../request';
import getBackendUrl, { EndpointNames } from '../request/serviceMap';
import {
	ClientUserDetailReturnFields,
	OrgDetailReturnFields,
	OrgUserDetailReturnFields,
} from '../returnFields';
import rpcShared from '@rockpapercoin/rpc-shared';

/**
 * @param { File } file
 * @param { string } uploadURL
 * @returns { Promise<{ errors: true } | { imageUrl: string }> }
 */
const putFile = async ( file, uploadURL ) => {
	const type = getFileType( file );
	const axiosInstance = createAxiosInstance();

	axiosInstance.interceptors.request.use( ( req ) => {
		req.headers = {
			'Content-Type': type,
		};
		return req;
	} );

	const uploadResponse = await axiosInstance.put( uploadURL, file );
	if ( uploadResponse.status === 200 ) {
		return {
			imageUrl: uploadURL.substring( 0, uploadURL.indexOf( '?' ) ),
		};
	}

	return {
		errors: true,
	};
};

/**
 * the purpose prop represents one of API.filePurposes properties.
 * "keyof" doesn't really work until we convert filePurposes to an enum type, but for now...
 * @param { {
 *   file: File,
 *   purpose: keyof import('../index').filePurposes,
 *   targetType: 'clientUser' | 'orgUser' | 'organization',
 *   target: string
 *   userID: string,
 *   documentID?: string
 * } } arg
 * @returns {Promise<{ errors: boolean, imageUrl: string }>} res - { errors, imageUrl }
 */
const uploadFile = async function( {
	file,
	purpose,
	targetType,
	target,
	userID,
	documentID,
} ) {
	const { name } = file;
	const type = getFileType( file );
	const data = {
		name: rpcShared.strings.escapeInvalidCharactersFromFilename( name ),
		purpose,
		targetType,
		target,
		fileType: type,
		user: userID,
	};

	if ( documentID ) {
		data.documentID = documentID;
	}

	const axiosInstance = createAxiosInstance();

	let token = null;
	if ( typeof document !== 'undefined' ) {
		token = getCookie( 'Authorization=', document.cookie );
	}

	const res = await axiosInstance.post(
		getBackendUrl( EndpointNames.UploadFile ),
		JSON.stringify( data ),
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		}
	);

	if ( res.data.errors ) {
		return { errors: res.data.errors };
	}
	const uploadURL = res.data.uploadURL;

	return await putFile( file, uploadURL );
};

/**
 * Upload a new profile image for a given user or organization.
 *
 * @param { {
 *   target: { userType: import('../../../types/generated').UserType, id: string  },
 *   data: {
 *     imageFile: File,
 *     originalImage: File,
 *     imageScale: number,
 *     imageRotation: number,
 *     imagePositionX: number,
 *     imagePositionY: number
 *   },
 *   userID: string
 * } } args
 * @returns { Promise<{ errors: Error[] } | { data: Record<string, any> }> } "data" is a ClientUser, OrgUser, or Organization
 */
const uploadProfileImage = async ( { target, data } ) => {
	const { userType, id } = target;
	const {
		imageFile,
		imageRotation,
		imageScale,
		imagePositionX,
		imagePositionY,
	} = data;
	let targetType, mutationName, returnFields;
	switch ( userType ) {
		case 'ClientUser':
			targetType = 'clientUser';
			mutationName = 'updateClientUser';
			returnFields = ClientUserDetailReturnFields;
			break;

		case 'OrgUser':
			targetType = 'orgUser';
			mutationName = 'updateOrgUser';
			returnFields = OrgUserDetailReturnFields;
			break;

		default:
			targetType = 'organization';
			mutationName = 'updateOrganization';
			returnFields = OrgDetailReturnFields;
			break;
	}

	const params = { imageRotation, imageScale, imagePositionX, imagePositionY };

	/**
	 * Helper to condense logic around feature-flag usage
	 * @returns { Promise<{ errors: Error[] } | string> }
	 */
	const uploadImage = async () => {
		const resolverName =
			targetType === 'organization'
				? 'requestOrgProfileImageUploadUrl'
				: 'requestUserProfileImageUploadUrl';
		const uploadUrlRequest = await requestWithoutToken(
			new Query( {
				type: 'query',
				name: resolverName,
				params: {
					data: { fileType: imageFile.type },
				},
				returnFields: [ 'uploadURL' ],
			} )
		);
		if ( uploadUrlRequest.errors ) {
			return uploadUrlRequest.errors;
		} else {
			const putResponse = await putFile(
				imageFile,
				uploadUrlRequest.data.data[ resolverName ].uploadURL
			);
			if ( putResponse.errors ) {
				return [ { message: rpcShared.strings.errorMessages.default } ];
			} else {
				return putResponse.imageUrl;
			}
		}
	};

	const uploadResponse = await uploadImage();
	if ( typeof uploadResponse !== 'string' ) {
		return uploadResponse;
	}

	params.image = uploadResponse;
	params.originalImage = uploadResponse;

	const response = await requestWithoutToken(
		new Query( {
			type: 'mutation',
			name: mutationName,
			params: { where: { id }, data: params },
			returnFields,
		} )
	);

	if ( response.errors || response.data?.errors )
		return { errors: response.errors || response.data?.errors };

	return { data: response.data.data[ mutationName ] };
};

export { uploadFile, putFile, uploadProfileImage };
