/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Bold = ( props: SVGProps<SVGSVGElement> ) => (
	<svg
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{ ...props }
	>
		<path d='M6.39569 19.7455V4.25466C6.39569 4.02066 6.58289 3.81006 6.84029 3.81006H12.1989C14.9835 3.81006 17.1363 5.68206 17.1363 8.13906C17.1363 9.94086 15.6621 11.3449 14.5623 11.8597C15.8025 12.2575 17.6043 13.5211 17.6043 15.6739C17.6043 18.2947 15.3579 20.1901 12.5031 20.1901H6.84029C6.58289 20.1901 6.39569 19.9795 6.39569 19.7455ZM9.41429 10.6897H11.9181C13.1115 10.6897 13.9305 9.75366 13.9305 8.58366C13.9305 7.41366 13.1115 6.59466 11.9181 6.59466H9.41429V10.6897ZM9.41429 17.4289H12.2457C13.4157 17.4289 14.3517 16.5163 14.3517 15.2995C14.3517 14.1295 13.1817 13.2637 11.9649 13.2637H9.41429V17.4289Z' />
	</svg>
);
export default Bold;
