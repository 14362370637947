/* eslint max-len: 0 */
const CircleQuestionmark = ( props ) => (
	<svg { ...props } viewBox='0 0 25 24'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0.997986 12C0.997986 5.37258 6.37057 0 12.998 0C19.6254 0 24.998 5.37258 24.998 12C24.998 18.6274 19.6254 24 12.998 24C6.37057 24 0.997986 18.6274 0.997986 12ZM23.498 12C23.498 6.20101 18.797 1.5 12.998 1.5C7.199 1.5 2.49799 6.20101 2.49799 12C2.49799 17.799 7.199 22.5 12.998 22.5C18.797 22.5 23.498 17.799 23.498 12ZM13 15.75L13.1226 15.7566C13.6862 15.8177 14.125 16.2951 14.125 16.875C14.125 17.4963 13.6213 18 13 18C12.3787 18 11.875 17.4963 11.875 16.875C11.875 16.2951 12.3137 15.8177 12.8774 15.7566L13 15.75ZM16.75 10.5C16.75 8.42893 15.0711 6.75 13 6.75C10.9289 6.75 9.24999 8.42893 9.24999 10.5C9.24999 10.9142 9.58577 11.25 9.99999 11.25C10.4142 11.25 10.75 10.9142 10.75 10.5C10.75 9.25736 11.7573 8.25 13 8.25C14.2426 8.25 15.25 9.25736 15.25 10.5C15.25 11.7426 14.2426 12.75 13 12.75L12.8982 12.7568C12.5321 12.8065 12.25 13.1203 12.25 13.5C12.25 13.9142 12.5858 14.25 13 14.25C15.0711 14.25 16.75 12.5711 16.75 10.5Z'
		/>
	</svg>
);

export default CircleQuestionmark;
