const OrgUserScalarFields = [
	'createdAt',
	'firstName',
	'image',
	'imageScale',
	'imageRotation',
	'imagePositionX',
	'imagePositionY',
	'originalImage',
	'invitationID',
	'id',
	'lastName',
	'passWordLastUpdated',
	'phone',
	'updatedAt',
];

const OrgUserFieldsForGetContractWhere = [
	'firstName',
	'image',
	'imageScale',
	'imageRotation',
	'imagePositionX',
	'imagePositionY',
	'originalImage',
	'id',
	'lastName',
	'updatedAt',
];

export { OrgUserScalarFields, OrgUserFieldsForGetContractWhere };
