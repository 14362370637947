import * as Types from '../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgOnboarding_CreateOrgUserMutationVariables = Types.Exact<{
  data: Types.OrgUserCreationInput;
}>;


export type OrgOnboarding_CreateOrgUserMutation = { __typename?: 'Mutation', createOrgUser: { __typename?: 'OrgUser', id: string, firstName: string, lastName: string } };

export type OrgOnboarding_UpdateOrgUserMutationVariables = Types.Exact<{
  data: Types.OrgUserUpdateCustomInput;
  where: Types.OrgUserWhereUniqueInput;
}>;


export type OrgOnboarding_UpdateOrgUserMutation = { __typename?: 'Mutation', updateOrgUser?: { __typename?: 'OrgUser', id: string, firstName: string, lastName: string } | null };

export type OrgOnboarding_LoginQueryVariables = Types.Exact<{
  data: Types.UserLoginInput;
}>;


export type OrgOnboarding_LoginQuery = { __typename?: 'Query', login?: { __typename?: 'AuthPayload', token: string } | null };


export const OrgOnboarding_CreateOrgUserDocument = gql`
    mutation OrgOnboarding_createOrgUser($data: OrgUserCreationInput!) {
  createOrgUser(data: $data) {
    id
    firstName
    lastName
  }
}
    `;
export type OrgOnboarding_CreateOrgUserMutationFn = Apollo.MutationFunction<OrgOnboarding_CreateOrgUserMutation, OrgOnboarding_CreateOrgUserMutationVariables>;

/**
 * __useOrgOnboarding_CreateOrgUserMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_CreateOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_CreateOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingCreateOrgUserMutation, { data, loading, error }] = useOrgOnboarding_CreateOrgUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrgOnboarding_CreateOrgUserMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_CreateOrgUserMutation, OrgOnboarding_CreateOrgUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_CreateOrgUserMutation, OrgOnboarding_CreateOrgUserMutationVariables>(OrgOnboarding_CreateOrgUserDocument, options);
      }
export type OrgOnboarding_CreateOrgUserMutationHookResult = ReturnType<typeof useOrgOnboarding_CreateOrgUserMutation>;
export type OrgOnboarding_CreateOrgUserMutationResult = Apollo.MutationResult<OrgOnboarding_CreateOrgUserMutation>;
export type OrgOnboarding_CreateOrgUserMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_CreateOrgUserMutation, OrgOnboarding_CreateOrgUserMutationVariables>;
export const OrgOnboarding_UpdateOrgUserDocument = gql`
    mutation OrgOnboarding_updateOrgUser($data: OrgUserUpdateCustomInput!, $where: OrgUserWhereUniqueInput!) {
  updateOrgUser(data: $data, where: $where) {
    id
    firstName
    lastName
  }
}
    `;
export type OrgOnboarding_UpdateOrgUserMutationFn = Apollo.MutationFunction<OrgOnboarding_UpdateOrgUserMutation, OrgOnboarding_UpdateOrgUserMutationVariables>;

/**
 * __useOrgOnboarding_UpdateOrgUserMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_UpdateOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_UpdateOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingUpdateOrgUserMutation, { data, loading, error }] = useOrgOnboarding_UpdateOrgUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_UpdateOrgUserMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_UpdateOrgUserMutation, OrgOnboarding_UpdateOrgUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_UpdateOrgUserMutation, OrgOnboarding_UpdateOrgUserMutationVariables>(OrgOnboarding_UpdateOrgUserDocument, options);
      }
export type OrgOnboarding_UpdateOrgUserMutationHookResult = ReturnType<typeof useOrgOnboarding_UpdateOrgUserMutation>;
export type OrgOnboarding_UpdateOrgUserMutationResult = Apollo.MutationResult<OrgOnboarding_UpdateOrgUserMutation>;
export type OrgOnboarding_UpdateOrgUserMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_UpdateOrgUserMutation, OrgOnboarding_UpdateOrgUserMutationVariables>;
export const OrgOnboarding_LoginDocument = gql`
    query OrgOnboarding_login($data: UserLoginInput!) {
  login(data: $data) {
    token
  }
}
    `;

/**
 * __useOrgOnboarding_LoginQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_LoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_LoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_LoginQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrgOnboarding_LoginQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>(OrgOnboarding_LoginDocument, options);
      }
export function useOrgOnboarding_LoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>(OrgOnboarding_LoginDocument, options);
        }
export type OrgOnboarding_LoginQueryHookResult = ReturnType<typeof useOrgOnboarding_LoginQuery>;
export type OrgOnboarding_LoginLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_LoginLazyQuery>;
export type OrgOnboarding_LoginQueryResult = Apollo.QueryResult<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    