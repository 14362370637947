// Packages
import React, { useCallback, useState } from 'react';
import { gql } from '@apollo/client';

// Elements
import Icon from '../../../elements/icons';
import { showSuccess, showError } from '../../Toast';
import {
	Avatar,
	Box,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from '@mui/material';
import MuiButton from '../../../mui/MuiButton';
import styles from './EventRow.module.scss';

// Lib
import Globals from '../../../lib/Globals';
import { useEventRow_DismissEventMutation } from '../__generated__/EventRow';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { notEmpty } from '../../../lib/helpers/apollo';

dayjs.extend( relativeTime );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dismissEventMutation = gql`
	mutation EventRow_DismissEvent($where: EventWhereUniqueInput!) {
		dismissEvent(where: $where)
	}
`;

type Link = {
	type: 'primary' | 'secondary';
	text: string;
} & ( { link?: { href: string } } | { onClick?: () => void } );

export type EventRowProps = {
	className?: string;
	event: Record<string, any>;
	icon: string | JSX.Element;
	message: string;
	links: Link[];
	hideDismissButtons: boolean; // used to determine whether to hide the dismiss buttons icon,
	hideAvatar?: true;
	hideActionButtons?: true;
};

export const EventRow: React.FC<EventRowProps> = ( {
	icon,
	message,
	links,
	className,
	event,
	hideAvatar,
	hideDismissButtons,
	hideActionButtons,
} ) => {
	const [ isHidden, hideEvent ] = useState( false );

	const [ dismissEvent ] = useEventRow_DismissEventMutation();

	const showDismissButton = hideDismissButtons
		? false
		: !Globals.nonDismissableEvents.includes( event.category );

	const onDismiss = useCallback( async () => {
		hideEvent( true );

		const result = await dismissEvent( {
			variables: { where: { id: event.id } },
		} );

		if ( result.errors ) {
			hideEvent( false );
			result.errors.map( ( error ) => showError( error ) );
		} else {
			showSuccess( 'Dismissed' );
		}
	}, [ dismissEvent, event.id ] );

	const formattedDate = dayjs().to( event.createdAt );
	if ( isHidden ) return null;

	const eventIcon =
		typeof icon === 'string' ? (
			<Icon type={ icon } className={ styles.icon } />
		) : (
			icon
		);

	const buttons = !hideActionButtons
		? links
			?.map( ( { type, text, ...action }, index ) => {
				const spreadProps =
						'link' in action && action.link
							? action.link
							: 'onClick' in action && action.onClick
								? { onClick: action.onClick }
								: undefined;
				if ( !spreadProps ) {
					return null;
				}
				return (
					<MuiButton
						key={ `button_${ index }` }
						variant='text'
						color={ type }
						className={ styles.button }
						data-cy='event-feed-row-button'
						{ ...spreadProps }
					>
						{ text }
					</MuiButton>
				);
			} )
			.filter( notEmpty )
		: [];

	return (
		<ListItem
			id={ event.id }
			className={ classNames( styles.container, className ) }
			dense={ !showDismissButton && hideDismissButtons }
			disableGutters
			data-cy='event-feed-row'
			data-category={ event.category }
			secondaryAction={
				showDismissButton ? (
					<IconButton onClick={ onDismiss } data-cy='event-feed-row-button'>
						<Icon type='trash' className={ styles.icon } />
					</IconButton>
				) : null
			}
		>
			{ !hideAvatar ? (
				<ListItemAvatar data-cy='event-feed-row-avatar'>
					<Avatar className={ styles.avatar }>{ eventIcon }</Avatar>
				</ListItemAvatar>
			) : null }

			<ListItemText
				classes={ {
					primary: classNames(
						styles.copyAndPrimaryButtons,
						buttons && buttons.length > 1 ? styles.multipleButtons : undefined
					),
				} }
			>
				<Box
					component='span'
					className={ styles.copy }
					data-cy='event-feed-row-copy'
				>
					{ message }
					<Typography component='time' className={ styles.timestamp }>
						{ formattedDate }
					</Typography>
				</Box>
				{ buttons }
			</ListItemText>
		</ListItem>
	);
};

export default EventRow;
