import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function MenuIcon( props: SvgIconProps ) {
	return (
		<SvgIcon { ...props }>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.27273 0C5.0802 0 6.54545 1.46525 6.54545 3.27273C6.54545 5.0802 5.0802 6.54545 3.27273 6.54545C1.46525 6.54545 0 5.0802 0 3.27273C0 1.46525 1.46525 0 3.27273 0ZM4.36364 3.27273C4.36364 2.67023 3.87522 2.18182 3.27273 2.18182C2.67023 2.18182 2.18182 2.67023 2.18182 3.27273C2.18182 3.87522 2.67023 4.36364 3.27273 4.36364C3.87522 4.36364 4.36364 3.87522 4.36364 3.27273Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 0C13.8075 0 15.2727 1.46525 15.2727 3.27273C15.2727 5.0802 13.8075 6.54545 12 6.54545C10.1925 6.54545 8.72727 5.0802 8.72727 3.27273C8.72727 1.46525 10.1925 0 12 0ZM13.0909 3.27273C13.0909 2.67023 12.6025 2.18182 12 2.18182C11.3975 2.18182 10.9091 2.67023 10.9091 3.27273C10.9091 3.87522 11.3975 4.36364 12 4.36364C12.6025 4.36364 13.0909 3.87522 13.0909 3.27273Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24 3.27273C24 1.46525 22.5347 0 20.7273 0C18.9198 0 17.4545 1.46525 17.4545 3.27273C17.4545 5.0802 18.9198 6.54545 20.7273 6.54545C22.5347 6.54545 24 5.0802 24 3.27273ZM20.7273 2.18182C21.3298 2.18182 21.8182 2.67023 21.8182 3.27273C21.8182 3.87522 21.3298 4.36364 20.7273 4.36364C20.1248 4.36364 19.6364 3.87522 19.6364 3.27273C19.6364 2.67023 20.1248 2.18182 20.7273 2.18182Z'
			/>
		</SvgIcon>
	);
}
