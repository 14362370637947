import * as Types from '../../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MobileMenuHelpAccordion_GetGlobalVariablesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MobileMenuHelpAccordion_GetGlobalVariablesQuery = { __typename?: 'Query', getGlobalVariables: { __typename?: 'FormattedGlobalVariables', HelpUrl?: string | null } };


export const MobileMenuHelpAccordion_GetGlobalVariablesDocument = gql`
    query MobileMenuHelpAccordion_getGlobalVariables {
  getGlobalVariables {
    HelpUrl
  }
}
    `;

/**
 * __useMobileMenuHelpAccordion_GetGlobalVariablesQuery__
 *
 * To run a query within a React component, call `useMobileMenuHelpAccordion_GetGlobalVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileMenuHelpAccordion_GetGlobalVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileMenuHelpAccordion_GetGlobalVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMobileMenuHelpAccordion_GetGlobalVariablesQuery(baseOptions?: Apollo.QueryHookOptions<MobileMenuHelpAccordion_GetGlobalVariablesQuery, MobileMenuHelpAccordion_GetGlobalVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobileMenuHelpAccordion_GetGlobalVariablesQuery, MobileMenuHelpAccordion_GetGlobalVariablesQueryVariables>(MobileMenuHelpAccordion_GetGlobalVariablesDocument, options);
      }
export function useMobileMenuHelpAccordion_GetGlobalVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobileMenuHelpAccordion_GetGlobalVariablesQuery, MobileMenuHelpAccordion_GetGlobalVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobileMenuHelpAccordion_GetGlobalVariablesQuery, MobileMenuHelpAccordion_GetGlobalVariablesQueryVariables>(MobileMenuHelpAccordion_GetGlobalVariablesDocument, options);
        }
export type MobileMenuHelpAccordion_GetGlobalVariablesQueryHookResult = ReturnType<typeof useMobileMenuHelpAccordion_GetGlobalVariablesQuery>;
export type MobileMenuHelpAccordion_GetGlobalVariablesLazyQueryHookResult = ReturnType<typeof useMobileMenuHelpAccordion_GetGlobalVariablesLazyQuery>;
export type MobileMenuHelpAccordion_GetGlobalVariablesQueryResult = Apollo.QueryResult<MobileMenuHelpAccordion_GetGlobalVariablesQuery, MobileMenuHelpAccordion_GetGlobalVariablesQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    