import { Avatar, AvatarProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { showError } from '../../blocks/Toast';
import API from '../../lib/API/index';

export default function TimelinesIcon( props: AvatarProps ) {
	const [ src, setSrc ] = useState<string>();
	useEffect( () => {
		const fetchSrc = async () => {
			const response = await API.getGlobalVariables();
			if ( 'errors' in response ) {
				response.errors.map( ( error ) => showError( error ) );
			} else {
				setSrc( response.globalVariables.TGLogo );
			}
		};
		fetchSrc();
	}, [] );
	if ( !src ) {
		return null;
	} else {
		return <Avatar { ...props } src={ src } />;
	}
}
