import rpcShared from '@rockpapercoin/rpc-shared';
const { regex } = rpcShared;
import { getCookie } from '../../helpers';
import { createAxiosInstance } from '../axios';
import getBackendUrl, { EndpointNames } from './serviceMap';

const catchErrors = async function( callback ) {
	let response = { data: null, errors: null };

	try {
		const res = await callback();
		response = {
			data: res.data,
			errors: res.errors || res.data.errors,
		};
	} catch ( err ) {
		const errors =
			err && err.response && err.response.data && err.response.data.errors;

		response = {
			data: null,
			errors: errors || [ { message: 'Network Error' } ],
		};
	}

	return response;
};

/**
 * @param { Promise<any> } - query
 */
const requestWithToken = async function( token = '', query ) {
	return catchErrors( async () => {
		const axiosConfig = {
			headers: {
				...( token ? { Authorization: token } : {} ),
			},
		};
		const axiosInstance = createAxiosInstance( axiosConfig );
		const data = regex.formatForGraphQL( `{"query": "${ query }"}` );
		const requestConfig = {
			method: 'POST',
			url: getBackendUrl( EndpointNames.Query ),
			data,
		};
		return await axiosInstance( requestConfig );
	} );
};

/**
 * @param { Promise<any> } - query
 */
const requestWithoutToken = async function( query ) {
	return catchErrors( async () => {
		const data = regex.formatForGraphQL( `{"query": "${ query }"}` );
		const token =
			typeof document !== 'undefined'
				? getCookie( 'Authorization=', document.cookie )
				: null;
		const axiosConfig = {
			headers: {
				...( token ? { Authorization: token } : {} ),
			},
		};
		const axiosInstance = createAxiosInstance( axiosConfig );
		const requestConfig = {
			method: 'POST',
			url: getBackendUrl( EndpointNames.Query ),
			data,
		};
		return await axiosInstance( requestConfig );
	} );
};

export { catchErrors, requestWithToken, requestWithoutToken };
