import React from 'react';
import styled from 'styled-components';
import Button from '../../elements/Button';

const Container = styled.div`
	width: 100%;
	margin: 2rem 0;
	display: flex;
	justify-items: center;
	flex-direction: column;

	@media (min-width: ${ ( { theme } ) => theme.tablet }px) {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	.button {
		min-width: 6.25rem;
		margin: 1.5rem auto 0.5rem;

		@media (min-width: ${ ( { theme } ) => theme.desktop }px) {
			margin: auto;
		}
	}
`;

const Buttons = ( props ) => {
	const { options } = props;

	if ( !options ) return <div />;

	return (
		<Container>
			{ options.map( ( option, index ) => (
				<Button
					key={ index }
					text={ option.text }
					className={ option.className }
					onClick={ option.callback }
					loading={ option.loading || false }
					dataCy={ option.dataCy }
				/>
			) ) }
		</Container>
	);
};

export default Buttons;
