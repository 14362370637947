import styled from 'styled-components';
import PropTypes from 'prop-types';
import ScreenCover from './ScreenCover';
import WithPortal from '../lib/withPortalHOC';
import Image from 'next/image';
import LoadingHand from '../../public/LoadingHand.gif';
import LoadingHandTransparent from '../../public/LoadingHand_Transparent.gif';

const AnimationBase = styled.div`
	height: auto;
	${ ( { cover } ) =>
		cover
			? `
		position: fixed;
		z-index: 15;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);`
			: '' }
	width: ${ ( { width } ) => ( width ? width : '12rem' ) }
`;

const AnimationScreenCover = styled( ScreenCover )`
	z-index: 14;
	${ ( { coverColor, theme } ) =>
		coverColor && `background-color: ${ theme[ coverColor ] };` }
`;

const HandGif = ( { transparent } ) => (
	<Image
		src={ transparent ? LoadingHandTransparent : LoadingHand }
		alt='Looping animation a hand morphing from rock to paper and then into a coin'
		width={ 194 }
		height={ 194 }
	/>
);

const CalendarGif = () => (
	<Image
		src='/RPC_CalendarGif01_0420_transparent.gif'
		alt='A looping animation of a calendar flipping backwards, revealing different event related iconography'
		width={ 194 }
		height={ 194 }
	/>
);

const AnimationOptions = {
	Hand: 'Hand',
	Calendar: 'Calendar',
};

const getAnimationComponent = ( animation ) => {
	switch ( animation ) {
		case AnimationOptions.Calendar:
			return CalendarGif;
		case AnimationOptions.Hand:
		default:
			return HandGif;
	}
};

const LoadingAnimation = ( {
	cover,
	coverOpacity,
	width,
	transparent,
	animation,
	coverColor,
} ) => {
	const AnimationComponent = getAnimationComponent( animation );
	return (
		<>
			{ cover && (
				<AnimationScreenCover
					coverOpacity={ coverOpacity }
					coverColor={ coverColor }
				/>
			) }
			<AnimationBase cover={ cover }>
				<AnimationComponent
					transparent={ transparent }
					cover={ cover }
					width={ width }
				/>
			</AnimationBase>
		</>
	);
};

LoadingAnimation.propTypes = {
	animation: PropTypes.oneOf( Object.values( AnimationOptions ) ),
	cover: PropTypes.bool,
	coverOpacity: PropTypes.string,
	coverColor: PropTypes.oneOf( [ 'black', 'lightBlue' ] ),
	width: PropTypes.string,
	transparent: PropTypes.bool,
};

export default WithPortal( LoadingAnimation );
