import rpcShared from '@rockpapercoin/rpc-shared';

export const isDevelopment =
	// check when the request is server-side...
	process.env.SENTRY_ENV !== 'Production' &&
	// ...and when it's a client-side request
	process.env.NEXT_PUBLIC_SENTRY_ENV !== 'Production'; // formerly process.env.NODE_ENV === 'development'

export const maxDropBoxSignFileSize = 40000000;

// Just picking this number arbitrarily, based on the dropbox number...
export const maxUploadFileSize = 40000000;

export const preferredDayJsDateFormatWithTimeStamp = 'MMM D, YYYY [at] h:mm A';

export const preferredDayJsDateFormat = 'MMM D, YYYY';

export const errorStringsForWalletRedirect = [
	rpcShared.strings.errorMessages.incorrectZip,
	rpcShared.strings.errorMessages.incorrectCvc,
	rpcShared.strings.errorMessages.invalidExpiryYear,
	rpcShared.strings.errorMessages.insufficientFunds,
	rpcShared.strings.errorMessages.defaultDecline,
];
