import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const FileText = ( props: SVGProps<SVGSVGElement> ) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' { ...props }>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4 1.25A1.75 1.75 0 0 0 2.25 3v18c0 .966.784 1.75 1.75 1.75h16A1.75 1.75 0 0 0 21.75 21V6.082a1.75 1.75 0 0 0-.515-1.24L18.137 1.76a1.75 1.75 0 0 0-1.234-.51H4ZM3.75 3A.25.25 0 0 1 4 2.75h12.903a.25.25 0 0 1 .176.073l3.097 3.082a.25.25 0 0 1 .074.177V21a.25.25 0 0 1-.25.25H4a.25.25 0 0 1-.25-.25V3ZM7 15.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H7ZM6.25 10A.75.75 0 0 1 7 9.25h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75ZM7 12.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H7Z'
		/>
	</svg>
);

export default FileText;
