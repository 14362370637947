/**
 * Get user initials
 * @param {{
 *   name?: string | undefined | null ,
 *   firstName?: string | undefined | null ,
 *   lastName?: string | undefined | null ,
 *   firstNameOne?: string | undefined | null ,
 *   lastNameOne?: string | undefined | null ,
 * } | undefined | null } [user]
 * @returns {string}
 */
const getInitials = ( user ) => {
	let initials = '';

	if ( !user ) return initials;

	if ( user.name ) {
		const nameArr = user.name.split( ' ' );
		initials = nameArr[ 0 ][ 0 ] + nameArr[ nameArr.length - 1 ][ 0 ];
	}

	if ( user.firstName || user.firstNameOne ) {
		const first = user.firstName || user.firstNameOne;
		initials = first[ 0 ];
	}

	if ( user.lastName || user.lastNameOne ) {
		const last = user.lastName || user.lastNameOne;
		initials += last[ 0 ];
	}

	return initials;
};

export default getInitials;
