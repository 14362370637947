import Query from '../../../Query';
import { GetProposalsWhereReturnFields } from '../../returnFields/proposals';

const searchProposals = async function(
	this: any,
	props: {
		queries: string[];
		limit?: number;
		skip?: number;
		forSelect?: any;
	}
) {
	const { queries, limit, skip, forSelect = false } = props;
	const queryReducer = ( constraints: any, query: string ) => {
		return [
			...constraints,
			{ title_contains: query },
			{
				contact: {
					assignedMember: {
						// search by OrgUser name
						OR: [ { firstName_contains: query }, { lastName_contains: query } ],
					},
				},
			},
			{
				contact: {
					invitation: {
						OR: [
							{ recipientFirstName_contains: query },
							{ recipientLastName_contains: query },
							{ emailAddress_contains: query },
						],
					},
				},
			},
			{
				contact: {
					invoices: {
						some: {
							OR: [
								{ guestFirstName_contains: query },
								{ guestLastName_contains: query },
								{ guestEmail_contains: query },
							],
						},
					},
				},
			},
			{
				contact: {
					customer: {
						clientUser: {
							// search by client name(s)
							OR: [
								{ firstNameOne_contains: query },
								{ lastNameOne_contains: query },
								{ firstNameTwo_contains: query },
								{ lastNameTwo_contains: query },
							],
						},
					},
				},
			},
			{
				contact: {
					customer: { email_contains: query },
				},
			},
		];
	};

	const where: any = {
		OR: queries.reduce( queryReducer, [] ),
	};

	if ( forSelect ) {
		where.NOT = { status: 'Void' };
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getProposalsWhere',
			params: {
				where,
				take: limit,
				skip,
			},
			returnFields: [ { proposals: GetProposalsWhereReturnFields } ],
		} )
	);

	if ( errors ) return [];

	return data.data.getProposalsWhere as Array<Record<string, any>>;
};

export default searchProposals;
