/* eslint max-len: 0 */
const CheckFill = () => (
	<svg viewBox='0 0 24 24'>
		<path d='M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z' />
		<path
			d='M16.5085 8.43347C16.8214 8.16205 17.2951 8.19566 17.5665 8.50855C17.8133 8.793 17.808 9.21033 17.5699 9.48778L17.4915 9.56655L10.5749 15.5666C10.3203 15.7874 9.95432 15.8088 9.67819 15.6312L9.58975 15.5646L6.50633 12.8686C6.19451 12.5959 6.16275 12.1221 6.4354 11.8103C6.68326 11.5268 7.09738 11.4748 7.4049 11.6725L7.49369 11.7394L10.085 14.005L16.5085 8.43347Z'
			fill='white'
		/>
	</svg>
);

export default CheckFill;
